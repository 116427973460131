/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { HEADER_TYPE } from '@constants/layout';
import { useLayoutContext } from '@context/LayoutContext';
import { clear } from '@store/slice/alarmSlice';
import { homeSelector } from '@store/slice/homeSlice';
import { useOverlayContext } from '@context/OverlayContext';
import { ModalConsultationReserve } from '@components/overlay';
import callApp from '@interface/externalInterface';

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const isParentPage = useMatch('/parent');
  const isPasswordPage = useMatch('/confirm');
  const isConsultationPage = useMatch('/academy/consultation');

  const { hasAlarms } = useSelector(homeSelector);

  const { showDialog } = useOverlayContext();

  const {
    config: { headerType, name },
    popHistory,
  } = useLayoutContext();

  const handleBack = () => {
    // APP 인 경우 회원가입/비밀번호 변경에서 뒤로가기 누르면 로그인 페이지로 이동
    if (
      window.appInterface.DEVICE !== 'WEB' &&
      (isParentPage || isPasswordPage)
    ) {
      callApp('login');
      return;
    }

    // 뒤로가기 커스텀
    const historyBack = popHistory();
    if (historyBack) {
      historyBack();
      return;
    }

    const from = searchParams.get('from');
    if (from === 'intro') {
      window.location.href = '/html/intro.html';
      return;
    }

    // 처음 페이지가 홈이 아닐 경우 이상한 페이지로 갈 수 있음
    if (window.history.state?.idx === 0) {
      navigate('/home', { replace: true });
    }

    navigate(-1);
  };
  const goAlarm = () => {
    // 알람 새로 받기
    dispatch(clear());
    navigate('/alarm');
  };

  const showConsultationReserve = () => {
    showDialog(ModalConsultationReserve);
  };
  return (
    <>
      {headerType !== HEADER_TYPE.NONE && (
        <div className="topAppBar">
          <div
            className={classnames(
              'pageHeader',
              headerType === HEADER_TYPE.HOME && 'typeMain',
              headerType === HEADER_TYPE.DEFAULT && 'typeAccount',
            )}
          >
            <header className="header">
              {headerType === HEADER_TYPE.BACK && (
                <>
                  <div className="topLeft">
                    <button
                      type="button"
                      className="btnPrevious"
                      onClick={handleBack}
                    >
                      <span>뒤로가기</span>
                    </button>
                  </div>
                  <h1 className="h1">{name}</h1>

                  {isConsultationPage && (
                    <div className="topRight">
                      <button
                        type="button"
                        className="btnTopCounsel"
                        onClick={showConsultationReserve}
                      >
                        <span>상담요청</span>
                      </button>
                    </div>
                  )}
                </>
              )}
              {headerType === HEADER_TYPE.TITLE && (
                <>
                  <h1 className="h1">{name}</h1>
                </>
              )}

              {headerType === HEADER_TYPE.DEFAULT && (
                <h1 className="logoH1">
                  <span>학부모</span>
                </h1>
              )}

              {headerType === HEADER_TYPE.HOME && (
                <>
                  <h1 className="logoH1">
                    <span>학부모</span>
                  </h1>

                  <div className="topRight">
                    {/* <button type="button" className="btnTalk">
                      <span>캐츠톡</span>
                      <em className="flagNew" /> 
                    </button> */}
                    <button
                      type="button"
                      className="btnNotice"
                      onClick={goAlarm}
                    >
                      <span>알림</span>
                      {hasAlarms && (
                        <em className="flagNew" style={{ display: 'none' }} />
                      )}
                    </button>
                  </div>
                </>
              )}
            </header>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
