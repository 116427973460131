import { useLocation, useNavigate } from 'react-router-dom';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';

function Redirect({ url = '' }) {
  const navigate = useNavigate();

  const location = useLocation();
  const state = location?.state;

  useMount(() => {
    if (!isEmpty(url)) {
      window.location.href = url;
    } else if (state?.url) {
      window.location.href = state.url;
    } else {
      navigate(-1);
    }
  });

  return null;
}

export default Redirect;
