import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMount } from 'ahooks';

import { Input } from '@components/form';
import accountService from '@service/AccountService';

import TermsOfServiceItem from './TermsOfServiceItem';

function TermsOfService({ userType }) {
  const [termsOptions, setTermsOptions] = useState([]);
  const { setValue, trigger, getValues } = useFormContext();

  const handleAll = (e) => {
    const isAll = getValues('termsAll');

    termsOptions.forEach((_, index) => {
      setValue(`agreedTerms.${index}.isAgreed`, isAll);
      // revalidation
      trigger(`agreedTerms.${index}.isAgreed`);
    });
  };

  useMount(async () => {
    const result = await accountService.termsService({ userType });
    setTermsOptions(result);
  });
  return (
    <>
      <strong className="label">이용약관 동의</strong>
      <div className="termsCheckbox">
        <div className="fullCheck">
          <label className="checkBox">
            <Input onChange={handleAll} type="checkbox" name="termsAll" />
            <span className="labelText">
              <em>전체 동의합니다.</em>
            </span>
          </label>
        </div>
        {termsOptions.length > 0 && (
          <ul className="checkboxList">
            {termsOptions.map((item, index) => {
              return (
                <TermsOfServiceItem
                  key={`items_${item.id}_${index}`}
                  index={index}
                  {...item}
                />
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
}

export default TermsOfService;
