import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';

import Modal from './base/Modal';

function ModalAlert({ title, content, buttons = [], hasClose, id, ...rest }) {
  const { closeModal } = useOverlayContext();

  const handleClose = useCallback(() => {
    closeModal(id);
  }, [closeModal, id]);

  return (
    <Modal {...rest}>
      <Modal.Header id={id} hasClose={hasClose}>
        {title}
      </Modal.Header>
      <Modal.Body>
        <p
          className="textSub"
          style={{ textAlign: 'center', whiteSpace: 'break-spaces' }}
        >
          {content}
        </p>
      </Modal.Body>
      <Modal.Footer>
        {buttons.length === 0 && (
          <Button.Primary key={uuidv4()} onClick={handleClose} label="확인" />
        )}
        {buttons.map((item) => {
          return (
            <Button.Primary
              key={uuidv4()}
              onClick={item.onClick}
              label={item.label}
            />
          );
        })}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAlert;
