import { useState } from 'react';
import { useMount } from 'ahooks';
import classnames from 'classnames';

import { useOverlayContext } from '@context/OverlayContext';
import OverlayPortal from '@components/overlay/OverlayPortal';
import { Button } from '@components/ui';

function Toastbar({ message, buttonText, onClick }) {
  const { closeToast } = useOverlayContext();
  const [isOpen, setOpen] = useState(false);

  useMount(() => {
    // 애니메이션 적용
    setTimeout(() => setOpen(true), 100);
    // 3초후 닫기
    setTimeout(() => {
      setOpen(false);
    }, 3100);

    // 자연스럽게 사라지기
    setTimeout(() => {
      closeToast();
    }, 3500);
  }, []);

  return (
    <OverlayPortal>
      <div className="snackbarWrapper">
        <div
          className={classnames('boxSnackbar', isOpen && 'isOpen')}
          style={{ zIndex: '9999' }}
        >
          <p className="textMessage">{message}</p>
          <div className="actionBtns">
            <Button.Normal
              className="btnAction"
              label={buttonText}
              onClick={onClick}
            />
          </div>
        </div>
      </div>
    </OverlayPortal>
  );
}

export default Toastbar;
