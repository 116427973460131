import { boardSelector } from '@store/slice/boardSlice';
import { useSelector } from 'react-redux';

function FaqCategory({ onSearch }) {
  const {
    board: { depth },
    isFetch,
  } = useSelector(boardSelector);

  const handleSearch = (keyword) => {
    onSearch({ depth: keyword });
  };
  return (
    <>
      {isFetch && (
        <>
          <p className="boxTitle">문의 유형</p>
          <div className="boxInquiryCategory">
            <ul>
              {depth.map((item) => (
                <li key={`category_${item.idx}`}>
                  <button
                    type="button"
                    className="btnFaqCategory"
                    onClick={() => handleSearch(item.depth1)}
                  >
                    {item.depth1}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
}

export default FaqCategory;
