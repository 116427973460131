import dayjs from 'dayjs';
import { KO_DATE_INCLUDE_WEEK } from '@helper/timeHelper';

function GoodDeliveryDate({ paymentDate = new Date() }) {
  return (
    <dl className="goodsDeliveryDate">
      <div>
        <dt>발송일</dt>
        <dd>
          {dayjs(paymentDate).businessDaysAdd(4).format(KO_DATE_INCLUDE_WEEK)}{' '}
          까지 발송예정
        </dd>
      </div>
    </dl>
  );
}

export default GoodDeliveryDate;
