import { memo } from 'react';

import { useOverlayContext } from '@context/OverlayContext';
import { PAYMENT_TYPE } from '@constants/tab';
import { ModalOrderBook } from '@components/overlay';
import orderService from '@service/OrderService';

import Payment from './base/Payment';

function PaymentWaitingBook({
  parentNo,
  orderNumber,
  orderRequestLinkId,
  cartId,
  onSaleYn,
  answerGoodsPrice = 0,
  price,
  ...rest
}) {
  const { showDialog } = useOverlayContext();

  const handleSelect = async () => {
    try {
      const result = await orderService.getWatingDetail(
        {
          parentNo,
          orderNumber: cartId,
          type: PAYMENT_TYPE.BOOK,
        },
        { fail: { message: '구매할 수 없는 교재입니다.' } },
      );
      showDialog(ModalOrderBook, {
        orderNumber,
        cartId,
        orderRequestLinkId,
        onSaleYn,
        billing: {
          ...result,
          diffDueDay: result.diffDueDay || result.dueDay * -1 || 0,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Payment
      isBook
      onSelect={handleSelect}
      price={Number(answerGoodsPrice) + Number(price)}
      {...rest}
      isSale={onSaleYn === 'Y'}
    />
  );
}

export default memo(PaymentWaitingBook);
