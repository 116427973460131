import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Feed from './base/Feed';

/**
 * 상담 요청 피드
 */
function FeedConsultation({ feed }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/academy/consultation');
  };
  return <Feed feed={feed} onClick={handleClick} />;
}

export default memo(FeedConsultation);
