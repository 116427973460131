import { useMount } from 'ahooks';

import { useOverlayContext } from '@context/OverlayContext';
import { loadScript } from '@helper/commonHelper';

import Popup from './base/Popup';

const scriptUrl =
  'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';

function PopupZipCode({ id, oncomplete, ...rest }) {
  const { closePopup } = useOverlayContext();

  const handleComplate = (result) => {
    oncomplete?.(result);
    closePopup(id);
  };

  useMount(() => {
    loadScript('postCode', scriptUrl);

    // 팝업창이 뜨는 시간이 필요함
    const interval = setInterval(() => {
      if (daum?.Postcode) {
        const element = document.querySelector('#iframe');

        new daum.Postcode({
          oncomplete: handleComplate,
          width: '100%',
          height: '100%',
        }).embed(element);

        clearInterval(interval);
      }
    }, 100);
  });

  return (
    <Popup {...rest}>
      <Popup.Header id={id} hasClose>
        우편번호 검색
      </Popup.Header>
      <Popup.Body>
        <div id="iframe" style={{ height: '700px' }} />
      </Popup.Body>
    </Popup>
  );
}

export default PopupZipCode;
