import { useOverlayContext } from '@context/OverlayContext';
import { getCharacterImages } from '@helper/imageHelper';

import Modal from './base/Modal';

function ModalProfileImage({ profilePhoto = '01', onComplete, ...rest }) {
  const { closeModal } = useOverlayContext();
  const images = getCharacterImages();

  const handleChange = (e) => {
    const { name } = e.target;
    onComplete(name);
    closeModal();
  };

  return (
    <Modal isBasic className="typeChangeProfile" {...rest}>
      <Modal.Header hasClose>프로필 사진 변경</Modal.Header>
      <Modal.Body className="popChangeProfile">
        <p className="textpGuide">나의 학습 프로필 사진을 변경해보세요.</p>

        <ul className="photoList">
          {images.map((item, index) => {
            return (
              <li key={`photo_${index}`}>
                <label className="checkBox typeProfileCheck">
                  <input
                    name={item.key}
                    type="radio"
                    checked={profilePhoto === item.key}
                    onChange={handleChange}
                  />
                  <span className="labelText">
                    <strong className="img">
                      <img src={item.src} alt={`프로필 캐릭터 ${index}`} />
                    </strong>
                  </span>
                </label>
              </li>
            );
          })}
        </ul>
      </Modal.Body>
    </Modal>
  );
}

export default ModalProfileImage;
