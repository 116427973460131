import { memo } from 'react';

import { useOverlayContext } from '@context/OverlayContext';
import { PAYMENT_TYPE } from '@constants/tab';
import { ModalHistoryAcademy } from '@components/overlay';
import orderService from '@service/OrderService';

import Payment from './base/Payment';

function PaymentHistoryAcademy({ parentNo, orderNumber, ...rest }) {
  const { showDialog } = useOverlayContext();

  const handleSelect = async () => {
    try {
      const result = await orderService.getHistoryDetail({
        parentNo,
        orderNumber,
        type: PAYMENT_TYPE.ACADEMY,
      });
      showDialog(ModalHistoryAcademy, {
        orderNumber,
        payment: result,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return <Payment onSelect={handleSelect} {...rest} />;
}

export default memo(PaymentHistoryAcademy);
