import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useMount, useUnmount } from 'ahooks';

import {
  getAddress,
  deliverySelector,
  getOrderBook,
  orderSelector,
  clear,
} from '@store/slice/orderSlice';
import { accountSelector } from '@store/slice/accountSlice';
import { Button } from '@components/ui';
import {
  DefinitionBoxDelivery,
  DefinitionBoxDeliveryMethod,
  DefinitionBoxGoods,
  DefinitionBoxPrice,
} from '@components/box';
import { PAYMENT_TYPE } from '@constants/tab';
import useBrandPay from '@hooks/useBrandPay';
import { makeBillingParams } from '@helper/billingHelper';
import { toCommaNumber } from '@helper/commonHelper';
import { FormCheckbox } from '@components/form';
import { BOOK_DELIVERY_METHOD, BOOK_DELIVERY_TYPE } from '@constants/order';

// 결제대기 > 교재 결제
function OrderBookPage() {
  const dispatch = useDispatch();

  const { id } = useParams();

  const isOrderPage = useMatch('/order-book/:id');

  const parents = useSelector(accountSelector);
  const { delivery, demand, deliveryMethod } = useSelector(deliverySelector);
  const { order, orderAnswer } = useSelector(orderSelector);
  const { requestBilling } = useBrandPay({ memberNo: parents.memberNo });

  const method = useForm({ defaultValues: { billingAgreed: false } });
  const { handleSubmit, watch } = method;

  const billingAgreed = watch('billingAgreed');

  const submit = () => {
    requestBilling(
      makeBillingParams({
        parents,
        order,
        linkId: id,
        deliveryMethod,
        delivery: { ...delivery, demand },
      }),
      { ...order, paymentType: PAYMENT_TYPE.BOOK },
      null,
      true,
    );
  };

  const totalPrice = useMemo(() => {
    return (
      Number(order?.price || 0) +
      Number(orderAnswer?.answerGoodsPrice || 0) +
      (deliveryMethod === BOOK_DELIVERY_METHOD.IMMEDIATELY ? 2500 : 0)
    );
  }, [deliveryMethod, order?.price, orderAnswer?.answerGoodsPrice]);

  useEffect(() => {
    // 주소조회
    dispatch(getAddress({ memberNo: parents?.memberNo }));

    // 주문상세정보
    if (isOrderPage) {
      dispatch(
        getOrderBook({
          linkId: id,
        }),
      );
    }
  }, [dispatch, id, isOrderPage, parents?.memberNo]);

  useMount(() => {
    window.scrollTo({ top: 0, left: 0 });
  });

  useUnmount(() => {
    dispatch(clear());
  });

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(submit)} className="contentBox boxPaying">
        {order.textbookDeliveryKindCode === BOOK_DELIVERY_TYPE.ACADEMY && (
          <DefinitionBoxDeliveryMethod />
        )}
        <DefinitionBoxDelivery order={order} />
        <DefinitionBoxGoods title="상품정보" {...order} />
        <DefinitionBoxPrice
          price={totalPrice}
          category={[
            {
              name: '상품금액',
              topic: (
                <>
                  <b>
                    {toCommaNumber(
                      Number(order?.price || 0) +
                        Number(orderAnswer?.answerGoodsPrice || 0),
                    )}
                  </b>
                  원
                </>
              ),
            },
            {
              name: '배송비',
              hidden: deliveryMethod !== BOOK_DELIVERY_METHOD.IMMEDIATELY,
              topic: (
                <div className="shippingPrice">
                  <b>{toCommaNumber(2500)}</b>원
                </div>
              ),
            },
          ]}
        />

        <div className="definitionBox typeNolineCta">
          <div className="boxConsentCheck">
            <FormCheckbox
              className="checkBoxBorder"
              label="주문 내용을 확인하였으며, 결제에 동의합니다."
              name="billingAgreed"
            />
          </div>
          <Button.Wrapper>
            <Button.Normal
              large
              disabled={!billingAgreed || !order?.price}
              className="btnSecondary"
              type="submit"
              label={`${toCommaNumber(totalPrice)}원 결제`}
            />
          </Button.Wrapper>
        </div>
      </form>
    </FormProvider>
  );
}

export default OrderBookPage;
