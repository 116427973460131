import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useMount, useUpdateEffect } from 'ahooks';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { ModalAlert, ModalError, Toastbar } from '@components/overlay';
import api from '@utils/Api';
import { isEmpty, last } from 'lodash';

const OverlayContext = createContext();

function OverlayProvider({ children }) {
  const [modal, setModal] = useState([]);
  const [popup, setPopup] = useState([]);

  const [toast, setToast] = useState();

  const { pathname } = useLocation();

  const showPopup = useCallback((Component, props = {}) => {
    const id = uuidv4();
    setPopup((pre) => [...pre, { Component, id, props }]);
    return id;
  }, []);

  const showDialog = useCallback((Component, props = {}) => {
    const id = uuidv4();
    setModal((pre) => [
      ...pre,
      { Component, id, props: { ...props, dialog: true } },
    ]);
  }, []);

  const showBottomSheet = useCallback((Component, props = {}) => {
    const id = uuidv4();
    setModal((pre) => [
      ...pre,
      { Component, id, props: { ...props, bottomSheet: true } },
    ]);
  }, []);

  const showToast = useCallback((props = {}) => {
    setToast({ props });
  }, []);

  const closeModal = useCallback(
    (id) => {
      const target = id ? modal.find((item) => id === item.id) : last(modal);
      target?.props?.complete?.();

      if (target) {
        setModal((pre) => pre.filter((item) => target.id !== item.id));
      } else {
        setModal([]);
      }
    },
    [modal],
  );

  const closePopup = useCallback(
    (id) => {
      const target = popup.find((item) => id === item.id);
      target?.props.complete?.();

      setPopup((pre) => pre.filter((item) => id !== item.id));
    },
    [popup],
  );

  const closeAllPopup = useCallback(() => setPopup([]), []);

  const closeToast = useCallback(() => {
    setToast();
  }, []);

  const renderModal = useCallback(() => {
    return modal.map(({ Component, id, props }) => {
      return <Component key={`modal-${id}`} id={id} {...props} />;
    });
  }, [modal]);

  const renderPopup = useCallback(() => {
    return popup.map(({ Component, id, props }) => {
      return <Component key={`popup-${id}`} id={id} {...props} />;
    });
  }, [popup]);

  const renderToast = useCallback(() => {
    const { props } = toast;
    return <Toastbar {...props} />;
  }, [toast]);

  const isOverlay = useMemo(() => {
    return !isEmpty(popup) || !isEmpty(modal);
  }, [modal, popup]);

  // axios injection
  useMount(() => {
    const byPass = (args) => args;
    const errorDialog = (error) => {
      // 인증번호 요청 제한
      if (error?.response.status === 429) {
        showDialog(ModalAlert, {
          title: '알림',
          content:
            '인증번호 발송 횟수를 초과했습니다.\n잠시 후에 다시 시도해 주세요.',
        });
        return;
      }
      const { ignore, fail } = error.config;
      if (!ignore) {
        console.trace(error);
        showDialog(ModalError, { ...fail });
      }
      return Promise.reject(error);
    };
    api.ajax.interceptors.request.use(byPass, errorDialog);
    api.ajax.interceptors.response.use(byPass, errorDialog);
  });

  useUpdateEffect(() => {
    closeModal();
    closeAllPopup();
  }, [pathname]);

  return (
    <OverlayContext.Provider
      value={{
        showPopup,
        showDialog,
        showBottomSheet,
        showToast,
        closeModal,
        closePopup,
        closeToast,
        isOverlay,
      }}
    >
      {children}
      {modal && renderModal()}
      {popup && renderPopup()}
      {toast && renderToast()}
    </OverlayContext.Provider>
  );
}

function useOverlayContext() {
  return useContext(OverlayContext);
}

export { OverlayProvider, useOverlayContext };
