import AlarmAcademyResult from './AlarmAcademyResult';
import AlarmNotice from './AlarmNotice';
import AlarmEvent from './AlarmEvent';
import AlarmInquiry from './AlarmInquiry';
import AlarmSubscription from './AlarmSubscription';

class AlarmMapper {
  // CD13401 : 입회테스트 결과"
  // CD13402 : 정기결제"
  // CD13403 : 1:1문의"
  // CD13404 : 공지사항"
  // CD13405 : 이벤트"
  mapper = {
    CD13401: AlarmAcademyResult,
    CD13402: AlarmSubscription,
    CD13403: AlarmInquiry,
    CD13404: AlarmNotice,
    CD13405: AlarmEvent,
  };

  get(alarmLinkCd) {
    return this.mapper?.[alarmLinkCd];
  }
}

const alarmMapper = new AlarmMapper();
export default alarmMapper;
