/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DefinitionBoxPayment, OnlineInfoBox } from '@components/box';
import { Button } from '@components/ui';
import { accountSelector } from '@store/slice/accountSlice';
import { useOverlayContext } from '@context/OverlayContext';
import orderService from '@service/OrderService';

import Modal from './base/Modal';

function ModalSubscribeOnline({ payment, hasClose, onSuccess, ...rest }) {
  const { memberNo } = useSelector(accountSelector);
  const { showToast, closeModal } = useOverlayContext();
  const [billingAgreed, setBillingAgreed] = useState(false);

  const handleUnsubscirbe = () => {
    const fetchUnsubscribe = async () => {
      try {
        await orderService.unsubscribeOnline({
          memberNo,
          subscriptionContractId: payment.subscriptionContractId,
        });
        onSuccess?.();
        showToast({ message: '단과상품 자동결제를 해지하였습니다.' });
        closeModal();
      } catch (error) {
        console.warn(error);
      }
    };
    // TODO: 나중에 코드로 바꿔야 함
    if (payment.status === '미납') {
      showToast({
        message:
          '미납상태에는 구독을 해지할 수 없습니다. 완납 후 해지를 해주시기 바랍니다.',
      });
      return;
    }

    fetchUnsubscribe();
  };

  const isWinkMember = useMemo(() => {
    return payment.paymentAmount === 0;
  }, [payment.paymentAmount]);

  return (
    <Modal isBasic className="typePaymentDetails" {...rest}>
      <Modal.Header hasClose>상세정보</Modal.Header>
      <Modal.Body className="popPaymentDetails">
        <OnlineInfoBox
          {...payment}
          isWinkMember={isWinkMember}
          isVisible={false}
        />

        <DefinitionBoxPayment
          isModal
          isSmall
          className="typePaymentInfo"
          category={[
            {
              name: '구독정보',
              topic: [
                { subName: '자녀이름', value: payment.studentNm },
                {
                  subName: '구독시작일',
                  value: payment.serviceStartDate,
                },
                {
                  subName: '정기결제일',
                  value: `매월 ${payment.subscribeDay || ' - '}일`,
                },
              ],
            },
          ]}
          totalLabel="월 결제금액"
          amount={payment.paymentAmount}
        >
          {payment.subjectCd !== 'ALL' && (
            <div className="guidanceBox">
              <ul className="listDotted">
                <li>
                  서비스를 해지하면 앞으로 학원선생님이 내주는 학습/과제를 할 수
                  없습니다.
                </li>
                <li>
                  이미 결제한 금액은 남은 일수를 계산하여 부분 환불 해드립니다.
                </li>
              </ul>
            </div>
          )}
        </DefinitionBoxPayment>
        {payment.subjectCd !== 'ALL' && (
          <div className="popDefinitionBox typeTopDivider">
            <div className="boxConsentCheck">
              <label className="checkBoxBorder">
                <input
                  type="checkbox"
                  onChange={(e) => setBillingAgreed(e.target.checked)}
                />
                <span className="labelText">
                  <em>위 사항을 모두 확인하였습니다.</em>
                </span>
              </label>
            </div>
          </div>
        )}
      </Modal.Body>
      {payment.subjectCd !== 'ALL' && (
        <Modal.Footer>
          <Button.Primary
            disabled={!billingAgreed}
            label="구독 해지하기"
            onClick={handleUnsubscirbe}
          />
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default ModalSubscribeOnline;
