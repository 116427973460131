/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

import { Button } from '@components/ui';
import { accountSelector } from '@store/slice/accountSlice';
import { PAYMENT_TYPE } from '@constants/tab';
import { toCommaNumber } from '@helper/commonHelper';
import { PopupOrderFailed, PopupOrderSuccess } from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';
import { format } from '@helper/timeHelper';
import { OnlineInfoBox } from '@components/box';
import orderService from '@service/OrderService';

// 결제대기 > 온라인구독상품 > 미납 결제
function OrderOnlineNonPaymentPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [order, setOrder] = useState();

  const { memberNo } = useSelector(accountSelector);

  const { showPopup } = useOverlayContext();

  const goMethod = () => {
    navigate('/payment-method/online');
  };

  const handleBilling = () => {
    const fetchBilling = async () => {
      try {
        await orderService.requestBilling({
          memberNo,
          billId: order.billId,
        });

        showPopup(PopupOrderSuccess, { order });
      } catch (error) {
        console.warn(error);
        showPopup(PopupOrderFailed, { error, order });
      }
    };
    fetchBilling();
  };

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const result = await orderService.getWatingDetail({
          parentNo: memberNo,
          orderNumber: id,
          type: PAYMENT_TYPE.ONLINE,
        });

        setOrder(result);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrder();
  }, [id, memberNo]);

  useMount(() => {
    window.scrollTo({ top: 0, left: 0 });
  });

  return (
    <>
      {!isEmpty(order) && (
        <div className="contentBox boxPaying">
          <div className="definitionBox">
            <p className="boxTitle">상품정보</p>
            <OnlineInfoBox {...order} />

            <div className="boxPaymentCycle">
              <ul className="subtopicList">
                <li>
                  <div className="txtLabel">구독기간</div>
                  <div className="txtSubtopic">
                    {order.serviceStartDate || '-'} ~{' '}
                    {order.serviceEndDate || '-'}
                  </div>
                </li>
                <li className="typeUnpaid">
                  <div className="txtLabel">미납된 결제일</div>
                  <div className="txtSubtopic">
                    <div className="rowBox">
                      {order.regDate || '-'}
                      <span className="flagFilled tyUnpaid">미납</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="txtLabel">다음 결제일</div>
                  <div className="txtSubtopic">
                    {order.nextPaymentDate || '-'}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="definitionBox">
            <p className="boxTitle">결제금액</p>
            <dl className="boxOrderInfo">
              <div>
                <dt>상품금액</dt>
                <dd>
                  <b>{toCommaNumber(order.price)}</b>원/월
                </dd>
              </div>
            </dl>

            <dl className="boxOrderInfo typeTotalPrice">
              <dt className="colorBlack">오늘 결제금액</dt>
              <dd>
                <b>{toCommaNumber(order.price)}</b>원
              </dd>
            </dl>
            <dl className="boxOrderInfo">
              <div>
                <dt>{format(dayjs(), 'MM.DD')} 결제금액</dt>
                <dd>
                  <b>{toCommaNumber(order.price)}</b>원
                </dd>
              </div>
            </dl>
          </div>

          <div className="definitionBox typeFinalCta">
            <div className="guidanceBox">
              <ul className="listDotted">
                <li>2달동안 미납되면 서비스가 정지됩니다.</li>
                <li className="rowServiceCenter">
                  기타 문의사항이 있으시면 고객센터로 연락 해주시기 바랍니다.
                  <a href="tel:1660-1244" className="btnLink secondary">
                    <span>고객센터문의</span>
                  </a>
                </li>
              </ul>
              {/* 
              <ul className="listDotted" style={{ display: 'none' }}>
                <li>
                  2023년 3월 7일까지 미납건이 결제되지 않으면 서비스가
                  정지됩니다.
                </li>
                <li className="rowServiceCenter">
                  기타 문의사항이 있으시면 고객센터로 연락 해주시기 바랍니다.
                  <a href="tel:1660-1244" className="btnLink secondary">
                    <span>고객센터로 바로가기</span>
                  </a>
                </li>
              </ul> */}
            </div>

            <Button.Wrapper className="typeBasisAuto">
              <Button.Outline large label="결제수단 변경" onClick={goMethod} />
              <Button.Normal
                large
                wide
                className="btnSecondary"
                label={`${toCommaNumber(order.price)}원 결제`}
                onClick={handleBilling}
              />
            </Button.Wrapper>
          </div>
        </div>
      )}
    </>
  );
}
export default OrderOnlineNonPaymentPage;
