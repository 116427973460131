import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { FieldSet } from '@components/common';
import { FormAuthPassword } from '@components/form';
import { userSelector } from '@store/slice/accountSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AUTH_STATUS } from '@constants/common';
import { Button } from '@components/ui';

import profileService from '@service/ProfileService';

// MY 페이지 > 프로필변경, 비밀번호 확인
function MyProfileConfirmPage() {
  const navigate = useNavigate();

  const { memberNo } = useSelector(userSelector);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      authToken: AUTH_STATUS.NOT_CHECK,
    },
  });

  const {
    formState: { errors },
    trigger,
    setValue,
    handleSubmit,
  } = methods;

  const submit = async (formValue) => {
    const fetch = async () => {
      const result = await profileService.checkPassword({
        password: formValue.password,
        memberNo,
      });

      const isValidToken = result.authToken
        ? AUTH_STATUS.VALID
        : AUTH_STATUS.INVALID;
      setValue('authToken', isValidToken);
      trigger('password');

      if (isValidToken === AUTH_STATUS.VALID) {
        navigate('/my-profile', { state: { authToken: result.authToken } });
      }
    };

    fetch();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)} className="contentBox personalnfo">
        <FieldSet.Wrapper>
          <div className="topGuideText">
            <p>
              회원님의 정보를 안전하게 보호하기 위해
              <br />
              비밀번호를 입력해주세요.
            </p>
          </div>
          <FieldSet.List>
            <FieldSet.Item>
              <FormAuthPassword noPatten />
            </FieldSet.Item>
          </FieldSet.List>
        </FieldSet.Wrapper>

        <FieldSet.ButtonBox contact={false}>
          <Button.Primary
            large
            type="submit"
            label="확인"
            disabled={!isEmpty(errors)}
          />
        </FieldSet.ButtonBox>
      </form>
    </FormProvider>
  );
}

export default MyProfileConfirmPage;
