/* eslint-disable jsx-a11y/anchor-is-valid */
import { PAYMENT_GOODS_TYPE } from '@constants/order';
import { toCommaNumber } from '@helper/commonHelper';

function OnlineInfoBox({
  studentName,
  studentNm,
  goodsName,
  goodsKindCode,
  price,
  orderPrice,
  isVisible = true,
  isWinkMember = false,
}) {
  return (
    <div className="grayOrderItem typeSubscription">
      <a className="boxOrderProduct">
        <p className="textName">{studentName || studentNm || '-'}</p>
        <p className="textTitle">{goodsName}</p>
        {/* TODO:<div className="statusBox">
          <span className="flagOutline tyCancel">해지 신청</span>
        </div> */}
        <div className="statusBox">
          <span className="textSmall">{PAYMENT_GOODS_TYPE[goodsKindCode]}</span>
        </div>
        <div className="courseBox">
          {isVisible && isWinkMember && (
            <div className="textPrice">
              <span className="txtNum">결제완료</span>
            </div>
          )}
          {!isWinkMember && (
            <div className="textPrice">
              <span className="txtNum">
                {toCommaNumber(orderPrice || price || '0')}
              </span>
              <em className="txtUnit">원</em>
            </div>
          )}
        </div>
      </a>
    </div>
  );
}

export default OnlineInfoBox;
