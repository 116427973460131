import OverlayPortal from '@components/overlay/OverlayPortal';

import ModalBody from './ModalBody';
import ModalContainer from './ModalContainer';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';

function Modal({ children, ...rest }) {
  return (
    <OverlayPortal>
      <ModalContainer {...rest}>{children}</ModalContainer>
    </OverlayPortal>
  );
}

export default Object.assign(Modal, {
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
});
