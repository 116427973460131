/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { PaymentTab } from '@components/tabs';
import { Button } from '@components/ui';
import { PAYMENT_TYPE } from '@constants/tab';
import {
  all,
  next,
  paymentHistorySelector,
  setType,
} from '@store/slice/paymentHistorySlice';
import { accountSelector } from '@store/slice/accountSlice';
import { PaymentMapper } from '@components/payment';
import { CATS_ONLINE_PRODUCT_IMAGE } from '@constants/image';

// 결제내역
function PaymentHistoryPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { history, isFetch, hasNext, type } = useSelector(
    paymentHistorySelector,
  );
  const { memberNo } = useSelector(accountSelector);

  const goOnline = useCallback(() => {
    navigate('/online-learning');
  }, [navigate]);

  const goOnlineManage = useCallback(() => {
    navigate('/payment-online');
  }, [navigate]);

  const goAcademyManage = useCallback(() => {
    navigate('/payment-academy');
  }, [navigate]);

  const handleSelect = useCallback(
    (value) => {
      dispatch(setType(value));
      dispatch(
        all({
          type: value,
          parentNo: memberNo,
        }),
      );
    },
    [dispatch, memberNo],
  );

  const handleNext = useCallback(() => {
    dispatch(next());
  }, [dispatch]);

  // 처음 한번만 조회
  useEffect(() => {
    if (history.length === 0) {
      handleSelect(type);
    }
  }, [handleSelect, type, history.length]);

  return (
    <>
      <PaymentTab
        onSelect={handleSelect}
        setDefault={false}
        defaultType={type}
      />
      <div className="contentBox boxListType">
        {history.length > 0 && (
          <>
            <div className="listDivision topLine">
              <ul className="listItems">
                {history.map((item, index) => {
                  const Component = PaymentMapper.get(
                    item.goodsTypeCd,
                    'history',
                  );
                  return Component ? (
                    <Component key={`${item.orderNumber}_${index}`} {...item} />
                  ) : (
                    ''
                  );
                })}
              </ul>
            </div>
            {hasNext && (
              <Button.Wrapper>
                <Button.Normal
                  large
                  className="btnMore"
                  label="더보기"
                  onClick={() => handleNext()}
                />
              </Button.Wrapper>
            )}
          </>
        )}

        {!isEmpty(history) && type === PAYMENT_TYPE.ACADEMY && (
          <div className="boxOtherService">
            <div className="shortcutsBtn">
              <a className="btnLink" onClick={goAcademyManage}>
                <span>학원비 자동결제 관리</span>
              </a>
            </div>
          </div>
        )}

        {!isEmpty(history) && type === PAYMENT_TYPE.ONLINE && (
          <div className="boxOtherService">
            <p className="textTitle">다른 상품에 관심이 있다면</p>

            <div className="promotionalBox">
              <a className="bnrLink" onClick={goOnline}>
                <img
                  src={CATS_ONLINE_PRODUCT_IMAGE}
                  alt="학습&amp;케어를 온라인과 오프라인 동시에! CATS 온라인 맞춤학습"
                />
              </a>
            </div>

            <div className="shortcutsBtn">
              <a className="btnLink" onClick={goOnlineManage}>
                <span>CATS 정기구독 관리</span>
              </a>
            </div>
          </div>
        )}

        {isEmpty(history) && isFetch && (
          <>
            <div className="boxFlexGrow typeMiddle">
              <div className="noList">
                <div className="icoNoList">
                  <p>결제내역이 없습니다.</p>
                </div>
              </div>
            </div>
            <div className="boxFlexNone">
              <div className="boxOtherService">
                <div className="promotionalBox">
                  <a className="bnrLink" onClick={goOnline}>
                    <img
                      src={CATS_ONLINE_PRODUCT_IMAGE}
                      alt="학습&amp;케어를 온라인과 오프라인 동시에! CATS 온라인 맞춤학습"
                    />
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default PaymentHistoryPage;
