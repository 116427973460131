import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalClassInvitation } from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';
import { accountSelector } from '@store/slice/accountSlice';
import { getHomeInfo } from '@store/slice/homeSlice';

import Feed from './base/Feed';

/**
 * 학원연결 초대
 */
function FeedInviteAcademy({ feed }) {
  const dispatch = useDispatch();
  const { showDialog, showToast } = useOverlayContext();
  const { memberNo: memberId, students } = useSelector(accountSelector);

  const handleInitFeed = () => {
    const studentIds = students.map((item) => item.memberNo);
    dispatch(getHomeInfo({ memberId, studentIds }));
  };

  const handleClick = () => {
    if (feed.exposeYn === 'N') {
      showToast({ message: '이미 수락한 초대장입니다.' });
    } else {
      showDialog(ModalClassInvitation, {
        fromFeed: true,
        academyInvite: [feed],
        onSuccess: () => {
          handleInitFeed();
        },
      });
    }
  };
  return <Feed feed={feed} onClick={handleClick} />;
}

export default memo(FeedInviteAcademy);
