import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import Payment from './base/Payment';

function PaymentWaitingOnline({ billId, ...rest }) {
  const navigate = useNavigate();

  const handleSelect = () => {
    // 미납 건임. 결제로 바로 이동
    navigate(`/order-online/dealy/${billId}`);
  };

  return <Payment onSelect={handleSelect} {...rest} />;
}

export default memo(PaymentWaitingOnline);
