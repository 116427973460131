import { FormHidden, Input } from '@components/form';
import { PopupTermsDetail } from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';
import { useFormContext } from 'react-hook-form';

function TermsOfServiceItem({ index, conditionsId, title, content, required }) {
  const { showPopup } = useOverlayContext();
  const { trigger } = useFormContext();

  const handleTerms = () => {
    showPopup(PopupTermsDetail, { title, content });
  };
  return (
    <li>
      <label className="checkBox">
        <Input
          type="checkbox"
          name={`agreedTerms.${index}.isAgreed`}
          onChange={() => trigger(`agreedTerms.${index}.isAgreed`)}
          option={{ required }}
        />
        <span className="labelText">
          <em>
            {title}{' '}
            {required ? '(필수)' : <i className="txtOptional">(선택)</i>}
          </em>
        </span>
      </label>
      <button type="button" className="btnViewTerms" onClick={handleTerms}>
        <span>약관보기</span>
      </button>
      <FormHidden name={`agreedTerms.${index}.termId`} value={conditionsId} />
    </li>
  );
}

export default TermsOfServiceItem;
