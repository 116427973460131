/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useMemo } from 'react';
import classnames from 'classnames';
import { MathJaxText } from '@components/ui';

/**
 * 홈 > 학습현황
 */
function LearningAcademySummary({
  subjectName,
  homeworkNm,
  homeworkStatusCd,
  homeworkSectionNm,
  homeworkStatusNm,
  dueDateDiff,
}) {
  const isHighlight = useMemo(() => {
    return dueDateDiff > -3 && dueDateDiff < 1;
  }, [dueDateDiff]);

  return (
    <li>
      <a className="subjectInfo">
        <div className="statusFlag">
          <span
            className={classnames(
              isHighlight ? 'flagFilled' : 'flagOutline',
              'tyDday',
            )}
          >
            {dueDateDiff === 0
              ? 'D-DAY'
              : `D${dueDateDiff > 0 ? '+' : ''}${dueDateDiff}`}
          </span>
          {/* 학습중 tyDuring / 학습전 tyBefore / 학습완료 tyCompleted */}
          <span
            className={classnames(
              'flagFilled',
              homeworkStatusCd === 'CD12722' && 'tyBefore',
              homeworkStatusCd === 'CD12723' && 'tyDuring',
              homeworkStatusCd === 'CD12724' && 'tyCompleted',
            )}
          >
            {homeworkStatusNm}
          </span>
        </div>
        <p className="txtSubject">
          <strong>{subjectName}</strong>
        </p>
        <p className="txtTitle">
          <MathJaxText text={homeworkNm} />
        </p>
        <p className="txtUnit">
          <MathJaxText text={homeworkSectionNm} />
        </p>
      </a>
    </li>
  );
}

export default memo(LearningAcademySummary);
