import fileService from '@service/FileService';

const useFileUpload = () => {
  const upload = async (file) => {
    const { type, size } = file;

    const formData = new FormData();
    formData.append('files', file);

    const data = await fileService.imageUpload(formData);
    return { ...data, type, size };
  };

  return { upload };
};

export default useFileUpload;
