import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';

import { Report, SERVICE_TYPE } from '@danbiedu-cats/common';
import Pagination from '@components/ui/Pagination';
import reportService from '@service/ReportService';

function LearningReportAcademyPage() {
  const { id: reportSendId } = useParams();

  const [report, setReport] = useState({});
  const [learningHistory, setLearningHistory] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [subject, setSubject] = useState('');

  const handleSetPayloadLearningHistory = useCallback(
    ({ pageNo: page, subjectCd }) => {
      setSubject(subjectCd);
      setPageNo(page);
    },
    [],
  );

  const handlePagination = useCallback(
    ({ subjectCd }) => (
      <Pagination
        pageNo={pageNo}
        onPage={(page) =>
          handleSetPayloadLearningHistory({ subjectCd, pageNo: page })
        }
        {...learningHistory}
      />
    ),
    [handleSetPayloadLearningHistory, learningHistory, pageNo],
  );

  const handleChangeSubject = useCallback(
    (subjectCd) => {
      handleSetPayloadLearningHistory({ subjectCd, pageNo: 1 });
    },
    [handleSetPayloadLearningHistory],
  );

  useEffect(() => {
    const fetch = async () => {
      const result = await reportService.getAcademyLearningHistory({
        reportSendId,
        pageNo,
        subject,
      });
      setLearningHistory(result);
    };
    if (!!reportSendId && !!subject) {
      fetch();
    }
  }, [pageNo, reportSendId, subject]);

  useMount(() => {
    (async () => {
      const result = await reportService.getAcademyReport({ reportSendId });
      setReport(result);
    })();
  });

  return (
    <div className="contentBox boxReportDetails">
      {!isEmpty(report) && (
        <Report
          serviceType={SERVICE_TYPE.PARENT}
          report={report}
          learningHistory={learningHistory}
          learningHistoryPagination={handlePagination}
          onChangeSubject={handleChangeSubject}
        />
      )}
    </div>
  );
}

export default LearningReportAcademyPage;
