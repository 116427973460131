import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { useMount } from 'ahooks';

import { Button } from '@components/ui';
import { accountSelector } from '@store/slice/accountSlice';
import paymentService from '@service/PaymentService';
import { useOverlayContext } from '@context/OverlayContext';
import { FormRadio } from '@components/form';

// 학원비 결제수단 변경
function PaymentMethodAcademyPage() {
  const navigate = useNavigate();
  const { memberNo } = useSelector(accountSelector);

  const [academies, setAcademies] = useState([]);
  const [cards, setCards] = useState([]);

  const { showToast } = useOverlayContext();
  const methods = useForm({});
  const {
    formState: { errros },
    handleSubmit,
    setValue,
    watch,
  } = methods;

  const academyId = watch('academyId');

  const handleAddMethod = () => {
    const fetchUrl = async () => {
      try {
        const result = await paymentService.addMethodSeeroo({
          memberNo,
          academyId,
        });
        navigate('/redirect', {
          state: { url: `${result}&errorUrl=${window.location.href}` },
        });
      } catch (error) {
        console.warn(error);
      }
    };
    fetchUrl();
  };

  const submit = (formValue) => {
    const fetch = async () => {
      try {
        await paymentService.changeMethodSeeroo({
          ...formValue,
          memberNo,
        });
        showToast({ message: '변경된 카드가 반영되었습니다.' });
      } catch (error) {
        console.warn(error);
      }
    };
    fetch();
  };

  useEffect(() => {
    const fetchCard = async () => {
      try {
        const result = await paymentService.seerooCards({
          memberNo,
          academyId,
        });

        setCards(JSON.parse(result)?.walletList);
        if (result?.length > 0) {
          setValue('cardId', academies[0].cardId);
        }
      } catch (error) {
        console.warn(error);
      }
    };
    if (!isEmpty(academyId)) {
      fetchCard();
    }
  }, [academies, academyId, memberNo, setValue]);

  useMount(() => {
    const fetchAcademy = async () => {
      try {
        const result = await paymentService.paymentMethodSeeroo({ memberNo });
        setAcademies(result);
        // 기본설정
        if (result?.length > 0) {
          setValue('academyId', result[0].academyId);
        }
      } catch (error) {
        console.warn(error);
      }
    };

    fetchAcademy();
  });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(submit)}
        className="contentBox boxDetailedInfo"
      >
        <div className="boxFlexGrow">
          <div className="definitionBox">
            <p className="boxTitle">학원선택</p>
            <div className="graySelectionBox">
              <ul>
                {academies.map((item) => (
                  <li key={`academy_${item.academyId}`}>
                    <FormRadio
                      name="academyId"
                      value={item.academyId}
                      label={item.academyName}
                      option={{ required: true }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="definitionBox">
            <p className="boxTitle">결제수단</p>
            <div className="graySelectionBox">
              <ul>
                {cards?.map((item, index) => (
                  <li key={`cards_${index}`}>
                    <FormRadio
                      name="cardId"
                      value={item.cardId}
                      label={`${item.cardCompany} (${item.cardNum})`}
                      option={{ required: true }}
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div className="guidanceText">
              <p>
                이미 추가되어있는 결제수단 중 선택하거나 새로운 결제수단을
                추가해주세요.
              </p>
            </div>
          </div>
        </div>
        <div className="boxFlexNone">
          <p className="addPaymentMethod">
            <Button.Normal
              className="btnLink"
              label="새로운 결제수단 추가하기"
              onClick={handleAddMethod}
            />
          </p>
          <Button.Wrapper>
            <Button.Primary
              type="submit"
              large
              label="결제수단 변경"
              disabled={!isEmpty(errros)}
            />
          </Button.Wrapper>
        </div>
      </form>
    </FormProvider>
  );
}

export default PaymentMethodAcademyPage;
