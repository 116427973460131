import dayjs from 'dayjs';

import { MathJaxText } from '@components/ui';

function TaskReportSummary({
  reportSendStudentNm,
  reportTitle,
  reportSubTitle,
  reportTaskSummary,
}) {
  return (
    <div className="reportTopArea typeAssignmentsReport">
      <div className="boxTitle">
        <strong>{reportSendStudentNm} 학생의</strong>과제 리포트
      </div>

      <dl className="overallRating">
        <div>
          <dt>{reportTitle}</dt>
          <dd>{reportSubTitle}</dd>
        </div>
        <div>
          <dt>과제 단원 정보</dt>
          <dd className="rowUnit">
            <ul className="listLearningScope">
              <li className="startUnit">
                <div>
                  <strong className="textGrade">
                    {reportTaskSummary?.courseNmStart}
                  </strong>
                  <p>
                    <MathJaxText text={reportTaskSummary?.sectionNmStart} />
                  </p>
                </div>
              </li>
              <li className="finishUnit">
                <div>
                  <strong className="textGrade">
                    {reportTaskSummary?.courseNmEnd}
                  </strong>
                  <p>
                    <MathJaxText text={reportTaskSummary?.sectionNmEnd} />
                  </p>
                </div>
              </li>
            </ul>
          </dd>
        </div>
        <div>
          <dt>과제 수행 상태</dt>
          <dd>
            <div className="boxPerformance">
              <strong className="textTitle">마감일 이내 완료</strong>
              <div className="textsWrap">
                <p className="textDate">
                  <span>과제 수행일</span>
                  <strong>
                    {dayjs(reportTaskSummary?.submitDate).format('YY.MM.DD')}
                  </strong>
                </p>
                <p className="textDate">
                  <span>출제일</span>
                  <strong>
                    {dayjs(reportTaskSummary?.receiveDate).format('YY.MM.DD')}
                  </strong>
                </p>
                <p className="textDate">
                  <span>마감일</span>
                  <strong>
                    {dayjs(reportTaskSummary?.dueDate).format('YY.MM.DD')}
                  </strong>
                </p>
              </div>
            </div>
          </dd>
        </div>
      </dl>
    </div>
  );
}

export default TaskReportSummary;
