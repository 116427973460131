import { isNumber } from 'lodash';
import classnames from 'classnames';

import { toCommaNumber } from '@helper/commonHelper';

function DefinitionBoxPrice({
  title,
  price,
  refund,
  category,
  isOnline = false,
}) {
  return (
    <div className={classnames('definitionBox')}>
      {isOnline && (
        <dl className="boxOrderInfo typeTotalPrice noLine">
          <dt className="txtUpper">총 결제금액</dt>
          <dd>
            월 <b>{toCommaNumber(price || 0)}</b>원
          </dd>
        </dl>
      )}
      {!isOnline && <p className="boxTitle">{title || '결제금액'}</p>}
      <dl className="boxOrderInfo">
        {category.map(({ name, topic, hidden = false }, index) => {
          if (!hidden) {
            return (
              <div key={`${title || '-'}_결제금액_${index}`}>
                <dt>{name}</dt>
                <dd>{topic}</dd>
              </div>
            );
          }

          return '';
        })}
      </dl>
      {isNumber(price) && !isOnline && (
        <dl className="boxOrderInfo typeTotalPrice">
          <dt>총 결제금액</dt>
          <dd>
            <b>{toCommaNumber(price || 0)}</b>원
          </dd>
        </dl>
      )}
      {refund && (
        <dl className="boxOrderInfo typeTotalPrice">
          <dt className="txtUpper">환불 예상금액</dt>
          <dd>
            <b>{toCommaNumber(refund)}</b>원
          </dd>
        </dl>
      )}
    </div>
  );
}

export default DefinitionBoxPrice;
