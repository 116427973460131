import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Alarm from './base/Alarm';

function AlarmSubscription({ id, noticeLinkKey, ...rest }) {
  const navigate = useNavigate();

  // const handleClick = () => {
  //   navigate(`/notice?id=${noticeLinkKey}`);
  // };
  const handleClick = () => {
    console.log('정기구독 이동~');
  };

  return <Alarm {...rest} onClick={handleClick} />;
}

export default memo(AlarmSubscription);
