import classnames from 'classnames';

function ModalFooter({ children, isContent = false, noWrap = false }) {
  return (
    <div className={classnames(!isContent ? 'popFooter' : 'popContentFooter')}>
      {noWrap ? children : <div className="btnsWrapper">{children}</div>}
    </div>
  );
}
export default ModalFooter;
