import classnames from 'classnames';

function PopupBody({
  children,
  containerClassName,
  className = '',
  style = {},
  isReceipt = false,
}) {
  return (
    <div
      className={classnames('contentsRegion', containerClassName || 'typeGrow')}
    >
      <div
        className={classnames(!isReceipt && 'contentBox', className)}
        style={style}
      >
        {children}
      </div>
    </div>
  );
}

export default PopupBody;
