/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import classnames from 'classnames';
import { isNull } from 'lodash';
import { useDeepCompareEffect } from 'ahooks';

function DropDown({ items, defaultMessage, onSelect }) {
  const [toggle, setToggle] = useState(false);
  const [selectItem, setSelectItem] = useState(null);

  const handleSelect = (item) => {
    onSelect?.(item);
    setSelectItem(item);
  };

  useDeepCompareEffect(() => {
    setSelectItem(null);
  }, [items]);

  return (
    <div className={classnames('selectDropdown', toggle && 'isActive')}>
      <button
        type="button"
        className="dropdownButton"
        onClick={() => setToggle(() => !toggle)}
      >
        <span>
          {isNull(selectItem) && defaultMessage && defaultMessage}
          {selectItem && selectItem.label?.length > 0 && selectItem.label}
          {selectItem && selectItem.name?.length > 0 && selectItem.name}
        </span>
      </button>
      <div className="dropdownLayer">
        <ul>
          {items.map((item, index) => (
            <li
              key={`dropdown_${index}`}
              onClick={() => {
                handleSelect(item);
                setToggle(() => !toggle);
              }}
            >
              <button type="button" className="textData">
                {item.label || item.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default DropDown;
