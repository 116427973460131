/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo } from 'react';

import { MathJaxText } from '@components/ui';
import { isEmpty } from 'lodash';

/**
 * 홈 > 학습현황
 */
function LearningOnlineSummary({
  examPaperNm,
  semesterNm,
  sectionNm,
  completeYn,
  subjectNm,
  elements,
}) {
  return (
    <>
      {!isEmpty(semesterNm) && !isEmpty(subjectNm) && (
        <li>
          <div className="subjectHmeStudy">
            <div className="boxTopEtc">
              {examPaperNm && <span className="boxForExam">시험대비학습</span>}
              <span className="textSemester">{semesterNm}</span>
              {completeYn === 'Y' && (
                <span className="flagFilled tyCompleted">완료</span>
              )}
            </div>
            <div className="boxSubject">
              <strong className="textSubject">{subjectNm}</strong>
              <div className="textCurriculum">
                <p className="textMain">
                  <MathJaxText text={sectionNm || examPaperNm || ''} />
                </p>
              </div>
            </div>
            <div className="boxLearning">
              <div className="scrollBox">
                <ul className="learningSequence">
                  {elements?.map((item) => (
                    <li key={item.elementId}>
                      <div className="subSection typeDone">
                        {item.completeYn === 'Y' && (
                          <span className="flagDone">완료</span>
                        )}
                        <p>{item.elementNm}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </li>
      )}
    </>
  );
}

export default memo(LearningOnlineSummary);
