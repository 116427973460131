function GoodInfoBox({
  goodsImage,
  orderName,
  goodsName,
  academyName,
  className,
  writerName,
  courseNm,
  subjectNm,
  semesterNm,
}) {
  return (
    <div className="goodsInfoBox">
      <div className="goodsImg">
        <img src={goodsImage} alt="" />
      </div>
      <strong className="goodsName">{orderName || goodsName}</strong>
      <ul className="listDotted">
        <li>
          <span>{academyName || '-'}</span>
          <span className="line">{className || '-'}</span>
        </li>
        <li>편저자명 : {writerName}</li>
      </ul>
      <div className="tagsBox">
        <span className="tagRect">{courseNm}</span>
        <span className="tagRect">{subjectNm}</span>
        <span className="tagRect">{semesterNm || ''}</span>
      </div>
    </div>
  );
}

export default GoodInfoBox;
