import { useState } from 'react';
import dayjs from 'dayjs';

import { Button } from '@components/ui';
import { DefinitionBoxOrder, DefinitionBoxPayment } from '@components/box';
import { format } from '@helper/timeHelper';
import { useOverlayContext } from '@context/OverlayContext';
import { BILL_PAY_STATE_CODE } from '@constants/order';
import orderService from '@service/OrderService';

import Modal from './base/Modal';

// 결재내역 > 학원비 바텀시트
function ModalHistoryAcademyAutoPay({ payment, onSuccess, ...rest }) {
  const [agreed, setAgreed] = useState(false);
  const { showToast, closeModal } = useOverlayContext();

  const fetchCancelAutoPay = (message) => {
    (async () => {
      try {
        await orderService.cancelAutoPay({ ...payment, autoPayYn: 'N' });
        showToast({ message });
        onSuccess();
      } catch (error) {
        console.warn(error);
      }
    })();
  };

  const handleUnsubscribe = () => {
    // 정상 - 해지 가능
    if (
      payment.payStateCd === BILL_PAY_STATE_CODE.COMPLETE ||
      payment.payStateCd === BILL_PAY_STATE_CODE.MANUAL_COMPLETE
    ) {
      fetchCancelAutoPay('자동결제 해지 신청을 하였습니다.');
    }
    // 상태는 미납이니만 결제기한이 오늘까지인 경우 - 해지 가능
    else if (
      payment.payStateCd === BILL_PAY_STATE_CODE.NON_PAY &&
      dayjs(payment.payEndDate).diff(dayjs()) >= 0
    ) {
      fetchCancelAutoPay(
        '이미 청구서가 발행되어 이번 청구서까지는 자동결제된 후 자동결제 서비스가 해지될 예정입니다.',
      );
    }
    // 미납이고 결제기한이 지난 경우 - 해지 불가
    else if (
      payment.payStateCd === BILL_PAY_STATE_CODE.NON_PAY &&
      dayjs(payment.payEndDate).diff(dayjs()) < 0
    ) {
      showToast({
        message:
          '미납상태에는 자동결제를 해지할 수 없습니다. 수납 후 자동결제 서비스를 해지 해주시기 바랍니다.',
      });
    }

    closeModal();
  };

  return (
    <Modal isBasic {...rest}>
      <Modal.Header hasClose>상세정보</Modal.Header>
      <Modal.Body className="popPaymentDetails">
        <DefinitionBoxOrder
          isGray
          title={`${payment.academyName} - ${payment.className}`}
          category={[
            { name: '자녀 이름', topic: payment.studentName || '-' },
            {
              name: '결제시작일',
              topic: <>{format(payment.firstPayDate, 'YYYY.MM.DD')}</>,
            },
            {
              name: '정기결제일',
              topic: (
                <>
                  매월{' '}
                  {payment.routinePayEndDate === 31
                    ? '말'
                    : payment.routinePayEndDate}
                  일
                </>
              ),
            },
          ]}
        />

        <DefinitionBoxPayment
          isModal
          title="결제내역"
          category={[
            { name: '교습비', topic: payment.lessonFee },
            { name: '기타경비', topic: payment.chargeEtc },
            { name: '할인', topic: payment.discount },
          ]}
          totalLabel={`${format(payment.issueYearmon, 'YYYY년 MM월')} 결제금액`}
          amount={payment.payAmount}
        >
          <div className="guidanceBox">
            <ul className="listDotted">
              <li>
                자동결제 청구일 전까지 언제든지 해지를 취소할 수 있습니다.
                (청구일은 학원마다 다르므로 일정을 미리 안내드리지 못하는 점
                양해 바랍니다.)
              </li>
              <li>
                자동결제 청구일 전까지 해지를 취소하지 않으면 자동결제는 완전히
                해지되며 다음 청구일부터는 단건으로 결제할 수 있도록 청구서가
                발행됩니다.
              </li>
              <li>
                자동결제 해지를 취소할 경우, 예정된 다음 결제일에 결제됩니다.
              </li>
            </ul>
          </div>
        </DefinitionBoxPayment>

        <div className="popDefinitionBox typeTopDivider">
          <div className="boxConsentCheck">
            <label className="checkBoxBorder">
              <input
                type="checkbox"
                onChange={(e) => setAgreed(e.target.checked)}
              />
              <span className="labelText">
                <em>위 사항을 모두 확인하였습니다.</em>
              </span>
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button.Primary
          disabled={!agreed}
          label="자동결제 해지하기"
          onClick={handleUnsubscribe}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalHistoryAcademyAutoPay;
