/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isArray, isEmpty } from 'lodash';
import { useUnmount } from 'ahooks';
import classnames from 'classnames';

import { StudentTab } from '@components/tabs';
import { accountSelector, studentsSelector } from '@store/slice/accountSlice';

import { REPORT_TYPE } from '@constants/tab';
import {
  clear,
  getReportHistory,
  nextReportHistory,
  reportSelector,
  setType,
  typeSelector,
} from '@store/slice/reportSlice';
import ReportTab from '@components/tabs/ReportTab';
import useScroll from '@hooks/useScroll';

import academyReportImage from '@assets/images/learning/img_no_content_01.png';
import onlineReportImage from '@assets/images/learning/img_no_content_02.png';

import AcademyReportItem from './components/common/AcademyReportItem';
import OnlineReportItem from './components/common/OnlineReportItem';
import EnglishLevelTestReportItem from './components/common/EnglishLevelTestReportItem';
import MathTestReportItem from './components/common/MathTestReportItem';
import TaskReportItem from './components/common/TaskReportItem';

function LearningReportPage() {
  const dispatch = useDispatch();

  const { memberNo } = useSelector(accountSelector);
  const children = useSelector(studentsSelector({ hasAll: true }));

  const reportRef = useRef();
  const { isBottom, reset } = useScroll({ ref: reportRef });

  const { isFetch, contents, hasNext } = useSelector(reportSelector);
  const type = useSelector(typeSelector);

  const [studentIds, setStudentIds] = useState();

  const handleTabSelect = useCallback(
    (id) => {
      dispatch(clear());
      dispatch(setType(id));
    },
    [dispatch],
  );

  const handleStudentSelect = useCallback(
    (id) => {
      if (id.length === 0) {
        id = children
          .filter((item) => item.value.length !== 0)
          .map((item) => item.value);
      }
      dispatch(clear());
      setStudentIds(isArray(id) ? id : [id]);
    },
    [children, dispatch],
  );

  useEffect(() => {
    if (reportRef.length !== 0 && hasNext && isBottom) {
      reset();
      dispatch(nextReportHistory({ studentIds, parentNo: memberNo, type }));
    }
  }, [dispatch, hasNext, isBottom, memberNo, reset, studentIds, type]);

  useEffect(() => {
    if (!isEmpty(type) && studentIds?.length > 0 && !isFetch) {
      // dispatch(getReportHistory({ studentIds, parentNo: memberNo, type }));
      // TODO: 테스트 코드
      dispatch(
        getReportHistory({
          studentIds,
          parentNo: 1000170,
          type,
        }),
      );
    }
  }, [dispatch, isFetch, memberNo, studentIds, type]);

  useUnmount(() => {
    dispatch(clear());
  });

  return (
    <>
      <ReportTab onSelect={handleTabSelect} defaultType={type} />

      <StudentTab isSubMenu student={children} onSelect={handleStudentSelect} />

      <div
        className={classnames(
          'contentBox boxReport',
          isEmpty(contents) && isFetch && 'noList',
        )}
      >
        {isEmpty(contents) && isFetch && (
          <>
            {(type === REPORT_TYPE.ACADEMY || type === REPORT_TYPE.ONLINE) && (
              <div className="boxFlexGrow">
                <div className="noListWrap">
                  <p className="noTxt">
                    아직 도착한 리포트가 없습니다.
                    <br />
                    리포트가 도착하면 아래 내용을 확인할 수 있습니다.
                  </p>
                  <div className="noContentGuideBox">
                    <p>아래 화면은 예시 이미지입니다.</p>
                  </div>
                  <div className="imgWrap">
                    <img
                      src={
                        type === REPORT_TYPE.ACADEMY
                          ? academyReportImage
                          : onlineReportImage
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )}
            {(type === REPORT_TYPE.ENGLISH ||
              type === REPORT_TYPE.MATH ||
              type === REPORT_TYPE.TASK) && (
              <div className="boxFlexGrow typeMiddle">
                <div className="noList">
                  <div className="icoNoList">
                    <p>도착한 리포트가 없습니다.</p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {!isEmpty(contents) &&
          (type === REPORT_TYPE.ACADEMY || type === REPORT_TYPE.ONLINE) &&
          Object.keys(contents).map((key) => (
            <div key={`${key}`} className="listDivision">
              <div className="listMonth">
                <strong>{key}</strong>
              </div>
              <ul className="listItems" ref={reportRef}>
                {contents[key].map((item, index) => {
                  const Component =
                    type === REPORT_TYPE.ACADEMY
                      ? AcademyReportItem
                      : OnlineReportItem;
                  return (
                    <Component key={`reportItem_${key}_${index}`} {...item} />
                  );
                })}
              </ul>
            </div>
          ))}
        {!isEmpty(contents) && type === REPORT_TYPE.ENGLISH && (
          <ul className="listLevelTest" ref={reportRef}>
            {contents?.map((item, index) => (
              <EnglishLevelTestReportItem
                key={`reportItem_${index}`}
                {...item}
              />
            ))}
          </ul>
        )}
        {!isEmpty(contents) && type === REPORT_TYPE.MATH && (
          <ul className="listLevelTest" ref={reportRef}>
            {contents?.map((item, index) => (
              <MathTestReportItem key={`reportItem_${index}`} {...item} />
            ))}
          </ul>
        )}
        {!isEmpty(contents) && type === REPORT_TYPE.TASK && (
          <ul className="listLevelTest typeAssignment" ref={reportRef}>
            {contents?.map((item, index) => (
              <TaskReportItem key={`reportItem_${index}`} {...item} />
            ))}
          </ul>
        )}
      </div>
    </>
  );
}

export default LearningReportPage;
