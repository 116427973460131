import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { feedSelector, next } from '@store/slice/homeSlice';
import { FeedMapper } from '@components/feed';
import { accountSelector } from '@store/slice/accountSlice';
import { Button } from '@components/ui';
import HomeLink from './HomeLink';

function HomeFeed() {
  const dispatch = useDispatch();
  const { memberNo } = useSelector(accountSelector);
  const { feed, hasNext } = useSelector(feedSelector);

  const handleNext = useCallback(() => {
    dispatch(next({ memberId: memberNo }));
  }, [dispatch, memberNo]);

  return (
    <>
      {feed.length > 0 && (
        <>
          <div className="feedsSection">
            <ul className="feeds">
              {feed.map((item) => {
                const Component = FeedMapper.get(item.feedLinkCd);
                return Component ? (
                  <Component key={`feed_${item.feedId}`} feed={item} />
                ) : (
                  ''
                );
              })}
            </ul>
            {hasNext && (
              <Button.Wrapper>
                <Button.Normal
                  className="btnMore typeFeeds"
                  label="더보기"
                  onClick={handleNext}
                />
              </Button.Wrapper>
            )}
          </div>
        </>
      )}
      {/* <HomeLink /> */}
    </>
  );
}

export default HomeFeed;
