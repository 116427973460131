import { useCallback, useState } from 'react';
import classnames from 'classnames';
import { useMount } from 'ahooks';

function Tabs({
  tabs = [],
  defaultType,
  onSelect,
  isSubMenu = false,
  isRound = false,
  isFilter = false,
  isFixed = false,
  isScroll = false,
  setDefault = true,
}) {
  const [type, setType] = useState(defaultType);

  const handleClick = useCallback(
    (item) => {
      setType(item.value);
      onSelect?.(item.value, item?.origin);
    },
    [onSelect],
  );

  useMount(() => {
    if (setDefault) {
      onSelect?.(defaultType, tabs?.[0]?.origin);
    }
  });

  return (
    <div
      className={classnames(
        isFixed && 'typeFixed',
        isFilter && 'typeFilter',
        isSubMenu && 'tabsSubmenu',
        isRound ? 'contentTabsRound' : 'contentTabs',
      )}
    >
      <div className={classnames(isScroll && 'scrollAuto', 'tabBtns')}>
        <ul className={classnames(!isScroll && 'typeEquality', 'localMenu')}>
          {tabs.map((item) => {
            return (
              <li
                key={`${item.value}/index`}
                className={classnames(type === item.value && 'isActive')}
              >
                <button
                  type="button"
                  className="tabBtn"
                  onClick={() => handleClick(item)}
                >
                  <span>{item.name}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Tabs;
