import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Feed from './base/Feed';

/**
 * 미납
 */
function FeedNonPayment({ feed }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/order-online/dealy/${feed.feedLinkKey}`);
  };
  return <Feed feed={feed} onClick={handleClick} />;
}

export default memo(FeedNonPayment);
