import { findTextWithinBrackets } from '@helper/commonHelper';

function MathTestScoreTable({ questionList }) {
  return (
    <div className="reportSection sectionLvScorecard">
      <div className="sectionTitleBox">
        <p className="sectionTitle">문항별 정오답표</p>
      </div>

      <div className="reportDivision">
        <div className="tableWrap typeLvScorecard">
          <table>
            <colgroup>
              <col style={{ width: '15%' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: '16%' }} />
              <col style={{ width: '16%' }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  문항
                  <br />
                  번호
                </th>
                <th scope="col">단원(유형)</th>
                <th scope="col">정·오답</th>
                <th scope="col">난이도</th>
              </tr>
            </thead>
            <tbody>
              {questionList.map((item, index) => (
                <tr key={`questionList_${index}`}>
                  <td className="colNo">{item.no}</td>
                  <td className="colTit">{item.sectionNm}</td>
                  <td className="colScore">
                    {item.isCorrect && (
                      <span className="icoCorrect">
                        <em className="hidden">정답</em>
                      </span>
                    )}
                    {!item.isCorrect && (
                      <span className="icoIncorrect">
                        <em className="hidden">오답</em>
                      </span>
                    )}
                  </td>
                  <td className="colDifficulty">
                    {findTextWithinBrackets(item.difficultyNm)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MathTestScoreTable;
