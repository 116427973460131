/* eslint-disable jsx-a11y/alt-text */
import classnames from 'classnames';
import { format } from '@helper/timeHelper';

function AcademyInfoBox({
  issueDate,
  studentName,
  logo,
  academyName,
  className,
}) {
  return (
    <div className="grayOrderItem typeAcademy">
      <div className="boxOrderItem">
        <span className="textMonth">{format(issueDate, 'MM월')}</span>
        <span className="textName">{studentName}</span>
      </div>
      <div className="boxClassInfo">
        <span className={classnames('logoImg', !logo && 'noImg')}>
          {logo && <img src="../../public/images/temp/temp_manage_logo3.png" />}
        </span>
        <p className="txtAcademy">{academyName}</p>
        <p className="txtClass">{className}</p>
      </div>
    </div>
  );
}

export default AcademyInfoBox;
