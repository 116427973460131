import TaskReportScorecardItem from './TaskReportScorecardItem';

function TaskReportScorecard({ reportTaskQuestions, reportTaskPrescriptions }) {
  return (
    <div className="reportSection rptSectionScorecard">
      <div className="sectionTitleBox">
        <p className="sectionTitle">문항별 정오답표</p>
        <div className="rightLegend">
          <ul className="chartLegend">
            <li>
              <span className="legendColor typeExcellence" />
              <span className="legendTxt">우수단원</span>
            </li>
            <li>
              <span className="legendColor typeWeak" />
              <span className="legendTxt">취약단원</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="reportDivision">
        <TaskReportScorecardItem
          title="원과제 결과"
          questions={reportTaskQuestions}
          open
        />
        {reportTaskPrescriptions?.map(
          ({ prescriptionNo, autoPrescriptYn, questions }, index) => (
            <TaskReportScorecardItem
              key={`prescription_${index}`}
              title={`${prescriptionNo}차 (${
                autoPrescriptYn === 'Y' ? '자동' : '수동'
              }) 문항 결과`}
              questions={questions}
            />
          ),
        )}
      </div>
    </div>
  );
}

export default TaskReportScorecard;
