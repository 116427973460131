import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import { toCommaNumber } from '@helper/commonHelper';
import { KO_DATE_INCLUDE_WEEK } from '@helper/timeHelper';

import GoodInfoBox from './GoodInfoBox';

function DefinitionBoxGoods({
  title,
  answerGoodsPrice = 0,
  price = 0,
  ...rest
}) {
  return (
    <div className="definitionBox typeGoodsInfo">
      {title && <p className="boxTitle">{title}</p>}
      <GoodInfoBox {...rest} />

      <dl className="grayOrderInfo typePrice">
        <div>
          <dt>상품금액</dt>
          <dd>
            <b>
              {toCommaNumber(Number(price) + Number(answerGoodsPrice) || 0)}
            </b>
            원
          </dd>
        </div>
        <div>
          <dt>발송일</dt>
          <dd className="textSmall">
            오늘 결제 시{' '}
            {dayjs(
              isEmpty(rest.dueDay) || rest.dueDay < 0
                ? Date.now()
                : rest.dueDate,
            )
              .businessDaysAdd(4)
              .format(KO_DATE_INCLUDE_WEEK)}{' '}
            까지 발송예정
          </dd>
        </div>
      </dl>
    </div>
  );
}

export default DefinitionBoxGoods;
