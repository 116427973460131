import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { FormName, FormMobileVerify } from '@components/form';
import { useDispatch } from 'react-redux';
import { setParents } from '@store/slice/accountSlice';
import { VERIFY_STATUS } from '@constants/common';
import { FieldSet } from '@components/common';
import { Button } from '@components/ui';

function ResetPasswordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: { verifyStatus: VERIFY_STATUS.READY },
  });

  const {
    formState: { errors },
    handleSubmit,
  } = methods;

  const submit = (formValue) => {
    if (!isEmpty(errors)) {
      return;
    }
    dispatch(setParents(formValue));
    navigate('/reset');
  };

  return (
    <FormProvider {...methods}>
      <form className="contentBox findPassword" onSubmit={handleSubmit(submit)}>
        <FieldSet.Wrapper>
          <FieldSet.List>
            <FieldSet.Item>
              <FormName />
            </FieldSet.Item>

            <FieldSet.Item>
              <FormMobileVerify password noWrap />
            </FieldSet.Item>
          </FieldSet.List>
        </FieldSet.Wrapper>

        <FieldSet.ButtonBox>
          <Button.Primary
            large
            type="submit"
            disabled={!isEmpty(errors)}
            label="비밀번호 찾기"
          />
        </FieldSet.ButtonBox>
      </form>
    </FormProvider>
  );
}

export default ResetPasswordPage;
