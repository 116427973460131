function DefinitionBoxOrder({ category, title, price, isGray = false }) {
  return (
    <div className={!isGray ? 'popDefinitionBox' : 'grayDefinitionBox'}>
      {title && <p className="boxTitle">{title}</p>}
      <dl className="grayOrderInfo">
        {category.map(({ name, topic, className }, index) => (
          <div key={`order_topic_${index}`}>
            <dt>{name}</dt>
            <dd className={className && className}>{topic}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

export default DefinitionBoxOrder;
