import { combineReducers } from '@reduxjs/toolkit';
import account from './slice/accountSlice';
import home from './slice/homeSlice';
import alarm from './slice/alarmSlice';
import paymentWaiting from './slice/paymentWaitingSlice';
import paymentHistory from './slice/paymentHistorySlice';
import order from './slice/orderSlice';
// export default 중복되어 다른이름으로..
import learningStatusReducer from './slice/learningStatusSlice';
import notice from './slice/noticeSlice';
import report from './slice/reportSlice';
import consultation from './slice/consultationSlice';
import board from './slice/boardSlice';

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    ...asyncReducers,
    account,
    alarm,
    home,
    paymentWaiting,
    paymentHistory,
    order,
    notice,
    consultation,
    report,
    board,
    learningStatus: learningStatusReducer,
  });

  return combinedReducer(state, action);
};

export default createReducer;
