/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

import { useOverlayContext } from '@context/OverlayContext';
import ModalLeaveConfirm from '@components/overlay/modal/ModalLeaveConfirm';

function useLeaveConfirm(isDirty) {
  const blocker = useBlocker(isDirty);

  const { showBottomSheet, closeModal } = useOverlayContext();

  const handleConfirm = useCallback(() => {
    blocker.proceed?.();
  }, [blocker]);

  const handleCancel = useCallback(() => {
    blocker.reset?.();
    closeModal();
  }, [blocker]);

  // Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === 'blocked' && !isDirty) {
      blocker.reset();
    }
    if (blocker.state === 'blocked' && isDirty) {
      showBottomSheet(ModalLeaveConfirm, {
        onCancel: handleCancel,
        onConfirm: handleConfirm,
      });
    }
  }, [blocker, isDirty]);

  return { blocker };
}

export default useLeaveConfirm;
