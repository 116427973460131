import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';

import { useLayoutContext } from '@context/LayoutContext';
import { BOARD } from '@constants/board';
import { FormInput } from '@components/form';
import { boardSelector, getBoardInfo } from '@store/slice/boardSlice';
import boardService from '@service/BoardService';

import FaqHashTag from './components/FaqHashTag';
import FaqCategory from './components/FaqCategory';
import FaqBestPost from './components/FaqBestPost';
import FaqPosts from './components/FaqPosts';

function FaqPage() {
  const dispatch = useDispatch();
  const { setHeaderName, pushHistory, resetLayout } = useLayoutContext();
  const {
    board: { depth: boardDepth },
    isFetch: isBoardFetch,
  } = useSelector(boardSelector);

  const [isFetch, setFetch] = useState(false);
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState('');
  const [keyword, setKeyword] = useState('');

  const methods = useForm({});
  const { handleSubmit, setValue } = methods;

  const handleSearch = ({ keyword: searchKeyword = '', depth = '' }) => {
    const fetch = async () => {
      try {
        const result = await boardService.getBoardList({
          boardId: BOARD.FAQ,
          depth1: depth,
          keyword: searchKeyword,
          pageSize: 100,
        });
        setKeyword(searchKeyword);
        setPosts(result?.list);
        setFetch(true);
      } catch (error) {
        console.warn(error);
      }
    };
    setCategory(depth);
    fetch();
  };

  const handleClear = useCallback(() => {
    setPosts([]);
    setCategory('');
    setValue('keyword', '');
    setFetch(false);
    // header 이름 리셋
    resetLayout();
  }, [resetLayout, setValue]);

  useEffect(() => {
    if (!isEmpty(category)) {
      setHeaderName(category);
      // 뒤로가기 눌렀을때
      pushHistory(() => handleClear());
    }
  }, [category, handleClear, pushHistory, setHeaderName]);

  useMount(() => {
    dispatch(getBoardInfo(BOARD.FAQ));
  });

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(handleSearch)}
          className="contentBox boxFaq"
        >
          {/* 기본 페이지 */}
          {!isEmpty(boardDepth) && isEmpty(category) && (
            <>
              <div className="definitionBox">
                <div className="faqSearchArea">
                  <FormInput
                    name="keyword"
                    className="typeFaq"
                    placeholder="키워드를 입력해주세요"
                  />

                  <FaqHashTag onSearch={handleSearch} />
                </div>

                {isEmpty(posts) && !isFetch && (
                  <FaqCategory onSearch={handleSearch} />
                )}
              </div>

              {/* 키워드 검색의 경우 */}
              {!isEmpty(posts) && (
                <div className="definitionBox">
                  <FaqPosts posts={posts} keyword={keyword} />
                  <div className="listBottomBox">
                    <button
                      type="button"
                      className="btnBack"
                      onClick={handleClear}
                    >
                      <span>이전 페이지로 돌아가기</span>
                    </button>
                  </div>
                </div>
              )}
              {/* 기본값 - 베스트글 */}
              {isEmpty(posts) && !isFetch && <FaqBestPost />}
              {/* 내용 없음 */}
              {isEmpty(posts) && isFetch && (
                <div className="boxFlexGrow typeMiddle">
                  <div className="noList">
                    <div className="icoNoList">
                      <p>검색어와 일치한 내용이 없습니다.</p>
                    </div>
                  </div>
                  <div className="listBottomBox">
                    <button
                      type="button"
                      className="btnBack"
                      onClick={handleClear}
                    >
                      <span>이전 페이지로 돌아가기</span>
                    </button>
                  </div>
                </div>
              )}
            </>
          )}

          {/* 카테고리 검색인 경우 */}
          {!isEmpty(category) && (
            <div className="faqCategoryBox">
              <div className="definitionBox">
                <FaqPosts posts={posts} isCategory />
              </div>
            </div>
          )}

          {isBoardFetch && isEmpty(boardDepth) && (
            <div className="boxFlexGrow typeMiddle">
              <div className="noList">
                <div className="icoNoList">
                  <p>자주묻는 질문 목록이 없습니다.</p>
                </div>
              </div>
            </div>
          )}
        </form>
      </FormProvider>
    </>
  );
}

export default FaqPage;
