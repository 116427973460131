import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';

import { FormTextArea, Input } from '@components/form';
import { accountSelector } from '@store/slice/accountSlice';
import academyService from '@service/AcademyService';
import { useOverlayContext } from '@context/OverlayContext';
import { Button } from '@components/ui';

function NoticeWriterBox({
  notificationId,
  notificationCommentId,
  parentsNotificationCommentId,
  onRefresh,
  onCancel,
  contents = '',
  isSecret = false,
  isEditMode = false,
  isNew = false,
}) {
  const { memberNo, memberName } = useSelector(accountSelector);

  const { showToast } = useOverlayContext();

  const methods = useForm({
    defaultValues: {
      isSecret,
      comment: contents,
    },
  });
  const { handleSubmit, reset, watch } = methods;

  const comment = watch('comment');

  const submit = (formValue) => {
    // 아무것도 안 적었으면 패스
    if (formValue.comment.length === 0) {
      return;
    }

    const fetch = async () => {
      try {
        const actionComment = isEditMode
          ? academyService.modifyComment.bind(academyService)
          : academyService.addComment.bind(academyService);

        await actionComment({
          notificationId,
          notificationCommentId,
          parentsNotificationCommentId,
          memberName,
          memberId: memberNo,
          notificationCommentContents: formValue.comment,
          secretYn: formValue.isSecret ? 'Y' : 'N',
        });

        showToast({
          message: isEditMode ? '수정 되었습니다' : '등록 되었습니다.',
        });
        reset({
          isSecret: false,
          comment: contents,
        });
        onRefresh?.();
      } catch (error) {
        console.warn(error);
      }
    };

    fetch();
  };

  return (
    <FormProvider {...methods}>
      <div className="replyCommenters">
        <p>{memberName} 학부모</p>
      </div>
      <form onSubmit={handleSubmit(submit)} className="replyWriteBox">
        <FormTextArea
          name="comment"
          rows="4"
          placeholder="댓글을 남겨보세요"
          maxLength="300"
        />
        <div className="boxEtcInfo typeAlignBothSide">
          <div className="secretCommentBox">
            <label className="checkBox">
              <Input type="checkbox" name="isSecret" />
              <span className="labelText">
                <em>비밀댓글</em>
              </span>
            </label>
            <p className="textGuidance">
              (비밀댓글은 게시글/댓글 작성자만 볼 수 있습니다.)
            </p>
          </div>

          <div className="postInfoBox">
            <div className="textsLimit">
              <strong>{comment?.length || 0}</strong>
              <span>300</span>
            </div>
            {!isNew && (
              <Button.Outline
                className="btnReplyCancel"
                label="취소"
                onClick={onCancel}
              />
            )}
            <Button.Primary
              type="submit"
              className="btnReply"
              label={isEditMode ? '수정' : '등록'}
            />
          </div>
        </div>

        {/* <div className="boxEtcInfo">
          <Button.Normal
            className={classnames('btnSetSecret', !secret && 'disabled')}
            style={{ marginRight: secret ? '5px' : 'auto' }}
            onClick={handleSecret}
            label="비밀댓글"
          />
          {secret && (
            <p
              style={{
                fontSize: '.8571428571rem',
                marginRight: 'auto',
                color: '#a5a5a5',
              }}
            >
              게시글/댓글 작성자만 확인가능
            </p>
          )}
          <div className="textsLimit">
            <strong>{comment?.length || 0}</strong>
            <span>300</span>
          </div>
          {!isNew && (
            <Button.Outline
              className="btnReplyCancel"
              label="취소"
              onClick={onCancel}
            />
          )}
          <Button.Primary
            type="submit"
            className="btnReply"
            label={isEditMode ? '수정' : '등록'}
          />
        </div> */}
      </form>
    </FormProvider>
  );
}

export default NoticeWriterBox;
