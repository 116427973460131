/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';
import {
  DefinitionBoxPrice,
  GoodDeliveryDate,
  GoodInfoBox,
} from '@components/box';
import { accountSelector } from '@store/slice/accountSlice';
import { toCommaNumber, toPhoneNumber } from '@helper/commonHelper';
import { format } from '@helper/timeHelper';
import { getCardInfo, getStudent } from '@helper/billingHelper';
import { BOOK_DELIVERY_METHOD, SHIPPING_TYPE } from '@constants/order';
import { PopupReceipt } from '@components/overlay';
import orderService from '@service/OrderService';

import PaymentHistoryDetailActions from './components/PaymentHistoryDetailActions';

// 결제내역 > 교재 > 결제내역 상세
function PaymentHistoryDetailPage() {
  const { showPopup } = useOverlayContext();

  const { id, type } = useParams();

  const [history, setHistory] = useState({});
  const [order, setOrder] = useState({});
  const [payment, setPayment] = useState({});
  const [delivery, setDelivery] = useState({});

  const { memberNo, students } = useSelector(accountSelector);

  const openReceipt = () => {
    showPopup(PopupReceipt, { src: payment.receiptUrl });
  };

  const openDelivery = () => {
    window.open(
      `http://nplus.doortodoor.co.kr/web/detail.jsp?slipno=${delivery.invoiceNumber}`,
      '_blank',
    );
  };

  const handleHistory = useCallback(async () => {
    const result = await orderService.getHistoryDetail({
      type,
      orderNumber: id,
      parentNo: memberNo,
    });
    setHistory(result);
    setOrder(result.orderGoodsDtoList?.[0]);
    setPayment(result.orderPayDtoList?.[0]);
    setDelivery(result.orderGoodsDtoList?.[0].orderDeliveryList?.[0]);
  }, [id, memberNo, type]);

  useEffect(() => {
    if (isEmpty(history)) {
      handleHistory();
    }
  }, [handleHistory, history, id, memberNo, type]);

  return (
    <>
      {!isEmpty(history) && (
        <div className="contentBox boxDetailedInfo">
          <div className="orderNumber">
            <p>
              주문번호{' '}
              <a className="linkOrderNumber" onClick={openReceipt}>
                <span>{history.orderId}</span>
              </a>
            </p>
            <Button.Outline
              disabled={!delivery?.invoiceNumber}
              label={
                SHIPPING_TYPE[order.orderGoodsStepCode] ||
                order.orderGoodsStepName
              }
              onClick={openDelivery}
            />
          </div>
          <div className="orderItem">
            <div className="roundBox">
              {/* 배송전 상태인 경우에만 보여주기, 취소 완료인 경우에도 숨기기 */}
              {!delivery?.invoiceNumber &&
                order.orderGoodsStepCode !== 'B032_CANCEL_COMPLETE_MANAGER' &&
                order.orderGoodsStepCode !== 'B031_CANCEL_COMPLETE_MEMBER' && (
                  <GoodDeliveryDate paymentDate={history.paymentDate} />
                )}
              <GoodInfoBox {...order} />

              <PaymentHistoryDetailActions
                id={id}
                order={order}
                orderGoods={history.orderGoodsDtoList}
                onRefresh={handleHistory}
              />
            </div>
            {(order.orderGoodsStepCode === 'C013_EXCHANGE_REJECT' ||
              order.orderGoodsStepCode === 'D013_RETURN_REJECT') && (
              <div className="roundBox rejectGuide">
                <strong className="textMain">교환거부 사유</strong>
                <p className="textSub">{order.rejectDesc}</p>
              </div>
            )}
          </div>

          {/* 배송타입 정리 필요 */}
          <div className="orderDetails">
            <div className="definitionBox">
              <p className="boxTitle">배송지 정보</p>
              <dl className="boxOrderInfo typeShipping">
                {order.deliveryMethodCode ===
                  BOOK_DELIVERY_METHOD.BATCH_SEND && (
                  <div>
                    <dt>배송지</dt>
                    <dd>
                      {order.academyName || '-'} ({delivery?.addrBase || '-'})
                    </dd>
                  </div>
                )}
                {order.deliveryMethodCode ===
                  BOOK_DELIVERY_METHOD.IMMEDIATELY && (
                  <>
                    <div>
                      <dt>수령인</dt>
                      <dd>{delivery?.receiverName || '-'}</dd>
                    </div>
                    <div>
                      <dt>연락처</dt>
                      <dd>{toPhoneNumber(delivery?.receiverTel || '-')}</dd>
                    </div>
                    <div>
                      <dt>배송지</dt>
                      <dd>{delivery?.addrBase || '-'}</dd>
                    </div>
                    <div>
                      <dt>배송메모</dt>
                      <dd>{delivery?.demand || '-'}</dd>
                    </div>
                  </>
                )}
              </dl>
            </div>

            <div className="definitionBox">
              <p className="boxTitle">자녀이름</p>
              <div className="boxTextRow">
                {order.studentName ||
                  getStudent({ students, order })?.memberName ||
                  '-'}
              </div>
            </div>

            {!isEmpty(history) && (
              <DefinitionBoxPrice
                price={history.orderPaymentAmount}
                category={[
                  {
                    name: '상품금액',
                    topic: (
                      <>
                        <b>{toCommaNumber(history.orderPrice || 0)}</b>원
                      </>
                    ),
                  },
                  {
                    name: '배송비',
                    topic: (
                      <>
                        <b>{toCommaNumber(history.orderDeliveryFee || 0)}</b>원
                      </>
                    ),
                  },
                ]}
              />
            )}

            <DefinitionBoxPrice
              title="결제상세"
              category={[
                {
                  name: '결제일시',
                  topic: <>{format(history.orderDate)}</>,
                },
                {
                  name: '결제정보',
                  topic: <>{getCardInfo(payment)}</>,
                },
              ]}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentHistoryDetailPage;
