import { useMemo } from 'react';
import classnames from 'classnames';

import { ModalMathDiagnosis } from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';

import grade1 from '@assets/images/diagnostic_report/diagnosis_chart_grade1.png';
import grade2 from '@assets/images/diagnostic_report/diagnosis_chart_grade2.png';
import grade3 from '@assets/images/diagnostic_report/diagnosis_chart_grade3.png';
import grade4 from '@assets/images/diagnostic_report/diagnosis_chart_grade4.png';
import grade5 from '@assets/images/diagnostic_report/diagnosis_chart_grade5.png';

function MathTestGrade({ studentNm, levelInfo }) {
  const { showDialog } = useOverlayContext();

  const gradeImage = useMemo(() => {
    switch (levelInfo.level) {
      case 0:
      case 1:
        return grade1;
      case 2:
        return grade2;
      case 3:
        return grade3;
      case 4:
        return grade4;
      case 5:
        return grade5;
      default:
        return '';
    }
  }, [levelInfo.level]);

  const handleClick = () => {
    showDialog(ModalMathDiagnosis);
  };
  // TODO: 차트 이미지로 교체
  return (
    <div className="reportDivision typeChart">
      <div className="chartTopBox">
        <button type="button" className="btnGradeInfo" onClick={handleClick}>
          <span>평가 등급이란?</span>
        </button>
      </div>
      <div>
        <div
          className={classnames('areaChartBox', `typeGrade${levelInfo.level}`)}
        >
          {/* <div className="averageScoreBox typeAcademy">
            <p className="textTitle">우리반 평균 점수</p>
            <strong className="textScore">
              {levelInfo.allExamineeAverageScore}점
            </strong>
            <span className="textPersonnel">
              (총 {levelInfo.allExamineeCnt}명)
            </span>
          </div>
          <div className="averageScoreBox typeOverall">
            <p className="textTitle">전체 응시자 평균 점수</p>
            <strong className="textScore">
              {levelInfo.taskExamineeAverageScore}점
            </strong>
            <span className="textPersonnel">
              (총 {levelInfo.taskExamineeCnt}명)
            </span>
          </div> */}

          <div className="chartImgBox">
            <img src={gradeImage} alt="" />
            {/* <span className="overallPoint" /> */}
            <div className="averageScoreBox typeMyScore">
              <span className="textName">{studentNm}</span>
              <strong className="textMyGrade">
                {levelInfo.level || 1}등급
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MathTestGrade;
