import api from '@utils/Api';

class FileService {
  constructor(client) {
    this.httpClient = client;
  }

  download(fileId) {
    return this.httpClient.post(`/common/files/${fileId}`);
  }

  imageUpload(files, options = {}) {
    return this.httpClient.post(`/common/files/private`, files, {
      ...options,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

const fileService = new FileService(api);
export default fileService;
