import { memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Feed from './base/Feed';

/**
 * 알림장 피드
 */
function FeedNotice({ feed }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/academy/notice/${feed.feedLinkKey}`);
  };
  // feedContent에 dueDate 넣기로 했음
  const feedData = useMemo(
    () => ({
      ...feed,
      feedContents: '알림장이 도착했습니다.',
      message: feed.feedContents,
    }),
    [feed],
  );
  return <Feed feed={feedData} onClick={handleClick} />;
}

export default memo(FeedNotice);
