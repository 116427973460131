/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useDispatch } from 'react-redux';
import { NavLink, useMatch } from 'react-router-dom';

import { changeRoute } from '@store/action/commonAction';
import useBlockPage from '@hooks/useBlockPage';

function RouteLink({ to, label, className, disabled = false }) {
  const dispatch = useDispatch();

  const { isBlockPage, handleBlock } = useBlockPage();

  const match = useMatch({
    path: `/${to.split('/')[1]}/*`,
  });

  const handleClick = () => {
    dispatch(changeRoute(to));
  };

  return (
    <li className={match ? 'isActive' : ''}>
      {isBlockPage(to) ? (
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              width: '108px',
              height: '70px',
              zIndex: 9999,
            }}
            onClick={handleBlock}
          />
          <button type="button" disabled={disabled} className={className}>
            <span>{label}</span>
          </button>
        </div>
      ) : (
        <NavLink to={to} onClick={handleClick} className={className}>
          <span>{label}</span>
        </NavLink>
      )}
    </li>
  );
}

export default RouteLink;
