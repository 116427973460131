import classnames from 'classnames';

import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';

function ModalBody({ id, children, className, isClose }) {
  const { closeModal } = useOverlayContext();

  return (
    <div className="popBody">
      <div
        className={classnames(
          'popContent typeSimpleMessage',
          className && className,
        )}
      >
        {children}
      </div>
      {isClose && (
        <Button.Wrapper>
          <Button.Primary large label="닫기" onClick={() => closeModal(id)} />
        </Button.Wrapper>
      )}
    </div>
  );
}

export default ModalBody;
