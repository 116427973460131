import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';

import Modal from './base/Modal';

function ModalDeleteNoticeConfirm({ complete, ...rest }) {
  const { closeModal } = useOverlayContext();

  const handleDelete = () => {
    complete?.();
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
  };

  return (
    <Modal {...rest}>
      <Modal.Header>알림 삭제</Modal.Header>
      <Modal.Body>
        <p className="textSub">
          알림을 삭제하면 더이상 메시지를 볼 수 없습니다.
        </p>
        <p className="textSub">알림을 모두 삭제하시겠습니까?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button.Primary label="네" onClick={handleDelete} />
        <Button.Outline label="아니요" onClick={handleCancel} />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalDeleteNoticeConfirm;
