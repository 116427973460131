import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { accountSelector, studentsSelector } from '@store/slice/accountSlice';
import { useOverlayContext } from '@context/OverlayContext';
import { FormHidden } from '@components/form';
import { Button, DropDown } from '@components/ui';
import { CONSULTATION_TYPE } from '@constants/academy';
import { getConsultation } from '@store/slice/consultationSlice';
import academyService from '@service/AcademyService';

import Modal from './base/Modal';

function ModalConsultationReserve({ complete, createAt, ...rest }) {
  const dispatch = useDispatch();
  const { memberNo, memberName } = useSelector(accountSelector);
  const students = useSelector(studentsSelector({ hasAll: false }));

  const [classes, setClasses] = useState([]);
  const [consultationType] = useState([
    ...Object.keys(CONSULTATION_TYPE).map((key) => ({
      name: CONSULTATION_TYPE[key],
      value: key,
    })),
  ]);

  const { showToast, closeModal } = useOverlayContext();

  const methods = useForm({});
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = methods;

  const handleSelectStudent = (e) => {
    const student = students.find((item) => item.value === Number(e.value));

    const {
      origin: { enrolledAcademyClass },
    } = student;

    setValue('studentId', e.value);
    setValue('classId', null);

    trigger('studentId');

    setClasses([
      ...enrolledAcademyClass.map((item) => ({
        name: `${item.teacherName}(${item.academyName}/${item.className})`,
        value: item.classId,
        origin: item,
      })),
    ]);
  };

  const submit = (formValue) => {
    const fetchSubmit = async () => {
      const targetClass = classes.find(
        (item) => item.value === formValue.classId,
      );

      const student = students.find(
        (item) => item.value === Number(formValue.studentId),
      );

      await academyService.reserve({
        ...formValue,
        ...targetClass.origin,
        parentsId: memberNo,
        parentsNm: memberName,
        studentNm: student.origin.memberName,
      });

      showToast({ message: '상담 요청이 접수되었습니다.' });
      closeModal();
      dispatch(getConsultation({ memberNo }));
    };
    fetchSubmit();
  };
  return (
    <FormProvider {...methods}>
      <Modal isBasic className="typeAppointment" {...rest}>
        <form onSubmit={handleSubmit(submit)}>
          <Modal.Header hasClose>상담 요청</Modal.Header>
          <Modal.Body className="popAppointment">
            <ul className="formEnterList">
              <li>
                <div className="colContent">
                  <FormHidden name="studentId" option={{ required: true }} />
                  <DropDown
                    defaultMessage="상담 요청 자녀 선택"
                    items={students}
                    onSelect={handleSelectStudent}
                  />
                </div>
              </li>
              <li>
                <div className="colContent">
                  <FormHidden name="classId" option={{ required: true }} />
                  <DropDown
                    defaultMessage="학원/선생님 선택"
                    items={classes}
                    onSelect={(e) => {
                      setValue('classId', e.value);
                      trigger('classId');
                    }}
                  />
                </div>
              </li>
              <li>
                <div className="colContent">
                  <FormHidden
                    name="counslReservCategoryCd"
                    option={{ required: true }}
                  />
                  <DropDown
                    defaultMessage="상담 사유 선택"
                    items={consultationType}
                    onSelect={(e) => {
                      setValue('counslReservCategoryCd', e.value);
                      trigger('counslReservCategoryCd');
                    }}
                  />
                </div>
              </li>
            </ul>
            <p className="textGuidance">
              상담을 요청하시면 선생님이 확인 후<br /> 연락드려 상담 시간을
              잡도록 하겠습니다.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button.Primary
              type="submit"
              label="상담 요청하기"
              disabled={!isEmpty(errors)}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </FormProvider>
  );
}

export default ModalConsultationReserve;
