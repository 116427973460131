import { BaseModal } from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';

const NOT_OPEN_URL = [
  '/faq',
  '/inquiry',
  '/notice',
  '/event',
  '/terms',
  '/setting',
];

function useBlockPage() {
  const { showDialog, closeModal } = useOverlayContext();

  const isBlockPage = (url) => {
    // return false;
    return NOT_OPEN_URL.includes(url);
  };

  const handleBlock = () => {
    showDialog(BaseModal, {
      title: '알림',
      content: '곧 만날 수 있도록 준비중입니다.',
      buttons: [
        {
          label: '확인',
          action: () => closeModal(),
          className: 'btnPrimary',
        },
      ],
    });
  };

  return { isBlockPage, handleBlock };
}

export default useBlockPage;
