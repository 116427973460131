import Input from './Input';

function FormCheckbox({ className, label, ...rest }) {
  return (
    <label className={className || 'checkBox'}>
      <Input type="checkbox" {...rest} />
      <span className="labelText">
        <em>{label}</em>
      </span>
    </label>
  );
}

export default FormCheckbox;
