/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import classnames from 'classnames';

import { MathJaxText } from '@components/ui';

function TaskReportScorecardItem({ title, questions, open = false }) {
  const [isOpen, setIsOpen] = useState(open);
  return (
    <div className={classnames('roundBox typeToggleBox', isOpen && 'isOpen')}>
      <div className="tableTitle" onClick={() => setIsOpen(!isOpen)}>
        <p>{title} 결과</p>
        <button type="button" className="btnToggleArrow">
          <span className="hidden">결과 보기</span>
        </button>
      </div>

      <div className="tableWrap typeScorecard">
        <table>
          <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '18%' }} />
            {/* <col style={{ width: '24%' }} /> */}
          </colgroup>
          <thead>
            <tr>
              <th scope="col">구분</th>
              <th scope="col">단원/유형</th>
              <th scope="col">정·오답</th>
              {/* <th scope="col">
                전국
                <br /> 평균 정답률
              </th> */}
            </tr>
          </thead>
          <tbody className="borderN">
            {questions.map((item, index) => (
              <tr key={`${title}_questions_${index}`}>
                <td className="colNo">{item?.questionNo}번</td>
                <td
                  className={classnames(
                    'colTit',
                    item?.achievementStatus === '우수단원' && 'textExcellence',
                    item?.achievementStatus === '취약단원' && 'textWeak',
                  )}
                >
                  <MathJaxText text={item?.sectionNm} />
                </td>
                <td className="colScore">
                  <span
                    className={classnames(
                      item?.correctYn === 'Y' ? 'icoCorrect' : 'icoIncorrect',
                    )}
                  >
                    <em className="hidden">정답</em>
                  </span>
                </td>
                {/* <td className="colCorrectRate">{item?.nationwideAvg}%</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TaskReportScorecardItem;
