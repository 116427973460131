import { OnlineLearning } from '@pages';
import { HEADER_TYPE } from '@constants/layout';

const onlineRouteConfig = [
  {
    path: 'online-learning',
    element: OnlineLearning,
    option: {
      name: '캐츠 학습 안내',
      headerType: HEADER_TYPE.BACK,
    },
  },
];

export default onlineRouteConfig;
