function EnglishLevelTestDescription({ level, description }) {
  return (
    <div className="reportSection boxResultDescription">
      <div className="sectionTitleBox">
        <p className="sectionTitle">Level Description</p>
      </div>
      <div className="divisionWrapper">
        <div className="reportDivision">
          <div className="description" style={{ whiteSpace: 'pre-wrap' }}>
            {description}
          </div>
          {level >= 10 && (
            <div className="textReady">
              <p>* Lv. 10, 11, 12을 위한 커리큘럼은 현재 개발 중입니다.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EnglishLevelTestDescription;
