import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { flatten, isEmpty, uniqBy } from 'lodash';

import accountService from '@service/AccountService';
import profileService from '@service/ProfileService';

export const getParent = createAsyncThunk(
  'account/getParent',
  async ({ id }) => {
    const response = await accountService.parent({ id });
    return response;
  },
);

const initialState = {
  // 로그인 계정
  user: {},
  // 회원가입 / 비밀번호 찾기
  parents: {},
  viewHomePopup: false,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setParents: (state, action) => {
      state.parents = action.payload;
    },
    setUser: (state, action) => {
      state.parents = {};
      state.user = action.payload;
    },
    viewHomePopup: (state) => {
      state.viewHomePopup = true;
    },
    clear: (state) => {
      state.user = {};
      state.parents = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getParent.fulfilled, (state, action) => {
      state.parents = {};
      state.user = action.payload;
    });
  },
});

// My 페이지에서 사용
const userState = (state) => state.account.user;
export const userSelector = createSelector(userState, (state) => state);

const studentsState =
  ({ hasAll = false, hasSelect = false }) =>
  (state) => {
    let options = state.account?.user?.family?.map(({ studentMember }) => {
      return {
        origin: studentMember,
        value: studentMember.memberNo,
        name: studentMember.memberName,
      };
    });
    // 전체 추가
    if (hasAll && options) {
      options = [{ name: '전체', value: '', origin: {} }, ...options];
    }
    if (hasSelect && options) {
      options = [{ name: '선택해주세요', value: '', origin: {} }, ...options];
    }
    return options;
  };

export const studentsSelector = createSelector(studentsState, (state) => state);

const studentState = (studentNo) => (state) => {
  const { studentMember: student } = state.account?.user?.family?.find(
    ({ studentMember }) => studentMember.memberNo === Number(studentNo),
  );

  return student;
};
export const studentSelector = createSelector(studentState, (state) => state);

const classState = (state) => {
  const students = state.account?.user?.family.map(
    ({ studentMember }) => studentMember,
  );

  return uniqBy(
    flatten(students?.map((item) => item.enrolledAcademyClass)),
    'classId',
  );
};
export const classSelector = createSelector(classState, (state) => state);

// parnets 는 회원가입할 때 사용 / user는 로그인 정보
const accountState = (state) => {
  const account = isEmpty(state.account.parents)
    ? state.account.user
    : state.account.parents;

  let students = [];
  if (account?.family?.length > 0) {
    students = account?.family?.map(({ studentMember }) => studentMember);
  }

  return { ...account, students, viewHomePopup: state.account.viewHomePopup };
};

export const accountSelector = createSelector(accountState, (state) => state);

export const { setParents, setUser, viewHomePopup, clear } =
  accountSlice.actions;

export default accountSlice.reducer;
