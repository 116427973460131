import { useDispatch, useSelector } from 'react-redux';
import { useMount, useUnmount } from 'ahooks';

import { accountSelector } from '@store/slice/accountSlice';
import {
  clear,
  consultationSelector,
  getConsultation,
} from '@store/slice/consultationSlice';

import Consultation from './components/Consultation';

function AcademyConsultationPage() {
  const dispatch = useDispatch();
  const { memberNo } = useSelector(accountSelector);
  const { confirmReserve, noConfirmReserve, isFetch } =
    useSelector(consultationSelector);

  useMount(() => {
    dispatch(getConsultation({ memberNo }));
  });

  useUnmount(() => {
    dispatch(clear());
  });

  return (
    <div className="contentBox boxConsultation">
      {confirmReserve.length > 0 && (
        <div className="listDivision">
          <div className="listTopTitle">
            <strong className="titleDot">상담일 확정</strong>
          </div>
          <ul className="listConsultation">
            {confirmReserve.map((item, index) => (
              <Consultation key={`confirm_${index}`} {...item} />
            ))}
          </ul>
        </div>
      )}

      {noConfirmReserve.length > 0 && (
        <div className="listDivision">
          <div className="listTopTitle">
            <strong className="titleDot">상담일 미정</strong>
          </div>
          <ul className="listConsultation">
            {noConfirmReserve.map((item, index) => (
              <Consultation key={`confirm_${index}`} {...item} />
            ))}
          </ul>
        </div>
      )}
      {isFetch &&
        confirmReserve.length === 0 &&
        noConfirmReserve.length === 0 && (
          <>
            <div className="boxFlexGrow typeMiddle">
              <div className="noList">
                <div className="icoNoList">
                  <p>진행중인 상담이 없습니다.</p>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
}

export default AcademyConsultationPage;
