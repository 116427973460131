import { useState } from 'react';
import classnames from 'classnames';

function OrderOnlineConfirm({ onApply }) {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="definitionBox typeConfirm">
      <div className={classnames('consentConfirmation', toggle && 'isOpen')}>
        <div className="optionBar">
          <label className="checkBox">
            <input
              type="checkbox"
              onChange={(e) => onApply(e.target.checked)}
            />
            <span className="labelText">
              <em>매월 자동 결제 등 학습서비스 이용 관련 동의</em>
            </span>
          </label>
          <button
            type="button"
            className="btnToggle"
            onClick={() => setToggle(() => !toggle)}
          >
            <span>열기</span>
          </button>
        </div>
        <div className="optionDescription">
          <ul className="listDotted">
            <li>등록해 주신 결제수단으로 매월 이용료가 자동 결제됩니다.</li>
            <li>
              신용카드 결제창을 통해 입력되는 결제정보는 암호화되어 전송되며,
              처리 후 결제정보는 성공/실패 여부와 상관없이 자동으로 폐기되므로
              안전합니다.
            </li>
            <li>
              국내 모든 신용/체크 카드 사용이 가능하며, 해외에서 발행된 카드는
              이용하실 수 없습니다.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default OrderOnlineConfirm;
