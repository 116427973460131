/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import classnames from 'classnames';

import { Button } from '@components/ui';
import { DefinitionBoxAcademy, DefinitionBoxPayment } from '@components/box';
import { PAYMENT_TYPE } from '@constants/tab';
import { toCommaNumber } from '@helper/commonHelper';
import { accountSelector } from '@store/slice/accountSlice';
import { useOverlayContext } from '@context/OverlayContext';
import { PopupOrderSuccess, PopupOrderFailed } from '@components/overlay';
import { FormCheckbox } from '@components/form';
import orderService from '@service/OrderService';
import { BILL_METHOD_CODE } from '@constants/order';

// 결제대기 > 학원비 결제
function OrderAcademyPage() {
  const navigate = useNavigate();
  const { showPopup } = useOverlayContext();

  const [billing, setBilling] = useState();

  const { memberNo } = useSelector(accountSelector);
  const { id, type } = useParams();

  const [searchParams] = useSearchParams();
  // 성공 / 실패
  const resultMessage = searchParams.get('resultMessage');

  const method = useForm({
    defaultValues: { autoPay: false, billingAgreed: false },
  });

  const { handleSubmit, watch } = method;

  const [autoPay, autoPayAgreed, billingAgreed] = watch([
    'autoPay',
    'autoPayAgreed',
    'billingAgreed',
  ]);

  const isNonPayment = useMemo(() => {
    return billing?.diffDueDate > 0;
  }, [billing?.diffDueDate]);

  const isEnable = useMemo(() => {
    // 미납인 경우 바로 결제할 수 있음
    if (isNonPayment) {
      return true;
    }
    if (autoPay && autoPayAgreed) {
      return true;
    }
    if (billingAgreed) {
      return true;
    }
    return false;
  }, [autoPay, autoPayAgreed, billingAgreed, isNonPayment]);

  const goMethodChange = () => {
    navigate('/payment-method/academy');
  };
  const submit = async (formValue) => {
    // 자동결제에 따라서 url이 달라짐
    navigate('/redirect', {
      state: {
        url: `${
          formValue.autoPay ? billing.encodeAutoPay : billing.encode
        }&errorUrl=${window.location.href}`,
      },
    });
  };

  useEffect(() => {
    const fetchBilling = async () => {
      try {
        const fetcher =
          type === 'success'
            ? orderService.getHistoryDetail
            : orderService.getWatingDetail;
        const result = await fetcher.bind(orderService)(
          {
            parentNo: memberNo,
            orderNumber: id,
            type: PAYMENT_TYPE.ACADEMY,
          },
          { fail: { message: '유효하지 않는 청구서입니다.', isBack: true } },
        );
        setBilling(() => result);

        // 시루페이 결제 완료 후 urlParam으로 결과값을 알려줌
        if (type === 'success') {
          showPopup(PopupOrderSuccess, {
            order: { ...result, paymentType: PAYMENT_TYPE.ACADEMY },
          });
        }
        if (type === 'fail') {
          showPopup(PopupOrderFailed, { message: resultMessage });
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchBilling();
  }, [id, memberNo, type, showPopup, resultMessage]);

  return (
    <FormProvider {...method}>
      {billing && (
        <form onSubmit={handleSubmit(submit)} className="contentBox boxPaying">
          <DefinitionBoxAcademy
            title="상품정보"
            isNonPayment={isNonPayment}
            {...billing}
          />

          <DefinitionBoxPayment
            title="결제금액"
            amount={billing.payAmount}
            category={[
              { name: '교습비', topic: billing.lessonFee },
              { name: '기타경비', topic: billing.chargeEtc },
              { name: '할인', topic: billing.discount },
            ]}
          />

          {!isNonPayment &&
            billing.autoPayYn === 'N' &&
            billing.billMethodCd === BILL_METHOD_CODE.REGULAR && (
              <div className="definitionBox typeConfirm">
                <div className="boxConsentCheck">
                  <p className="title">
                    <b>학원비 자동결제를 신청하시면</b>
                    <br /> 매달 결제하는 번거로움을 덜 수 있습니다!{' '}
                  </p>
                  <FormCheckbox
                    className="checkBoxBorder"
                    name="autoPay"
                    label="다음달부터 자동결제를 신청하시겠습니까?"
                  />
                </div>

                {autoPay && (
                  <div className="consentConfirmation isOpen">
                    <div className="optionBar">
                      <FormCheckbox
                        name="autoPayAgreed"
                        label="자동결제 결제/해지/취소 안내"
                      />
                    </div>
                    <div className="optionDescription">
                      <ul className="listDotted">
                        <li>
                          학원비 자동결제 상품 최초 결제시 등록한 결제수단으로
                          자동 결제됩니다.
                        </li>
                        <li>
                          별도로 해지 또는 취소 신청을 하지 않은 경우, 수강기간
                          만료일에 동일한 상품이 결제되어 수강 기간이
                          연장됩니다.
                        </li>
                        <li>
                          자동결제 해지 또한 구독상품 관리 메뉴에서 언제든지
                          해지 가능합니다.
                        </li>
                        <li>
                          자동결제 해지 시 다음 청구서부터 자동결제 되지
                          않습니다.
                        </li>
                        <li>주문 내용을 확인하였으며, 결제에 동의합니다.</li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            )}

          <div className="definitionBox typeFinalCta">
            {!isNonPayment && !autoPay && (
              <div className="boxConsentCheck">
                <FormCheckbox
                  className="checkBoxBorder"
                  name="billingAgreed"
                  label="주문 내용을 확인하였으며, 결제에 동의합니다."
                />
              </div>
            )}
            {isNonPayment && (
              <div className="guidanceBox">
                <ul className="listDotted">
                  <li className="rowCallCenter">
                    학원 연락처
                    <a
                      href={`tel:${billing?.academyTel}`}
                      className="btnCallCenter"
                    >
                      <span>
                        {billing?.academyName} {billing?.academyTel}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            )}

            <Button.Wrapper
              className={classnames(isNonPayment && 'typeBasisAuto')}
            >
              {isNonPayment && billing?.autoPay === 'Y' && (
                <Button.Outline
                  large
                  label="결제수단 변경"
                  onClick={goMethodChange}
                />
              )}
              <Button.Primary
                disabled={!isEnable}
                large
                wide={isNonPayment}
                type="submit"
                label={`${toCommaNumber(billing.payAmount)}원 결제`}
              />
            </Button.Wrapper>
          </div>
        </form>
      )}
    </FormProvider>
  );
}

export default OrderAcademyPage;
