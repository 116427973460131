import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button } from '@components/ui';
import { clear } from '@store/slice/homeSlice';

import marktingImage from '@assets/images/marketing/advertisement_naver.png';
import trialImage from '@assets/images/account/img_trial_start.svg';

function TrialPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goHome = () => {
    dispatch(clear());
    navigate('/home');
  };

  return (
    <div className="contentBox">
      <div className="boxFlexGrow typeMiddle">
        <div className="membershipMarketing">
          <div className="advertisementImg typeTopImg">
            <span className="adImg noticeImg">
              <img src={trialImage} alt="" />
            </span>
          </div>

          <div className="topTexts">
            <p className="textMain typeBlack">무료체험 학습시작 안내</p>
            <div className="textSub">
              <p>무료체험 학습이 시작되었습니다.</p>
            </div>
          </div>

          <div className="advertisementImg">
            <span className="adImg naverImg">
              <img src={marktingImage} alt="네이버 검색 - 캐츠홈" />
            </span>
          </div>

          <div className="guidanceBox">
            <ul>
              <li>
                <p>
                  네이버에서 캐츠홈을 검색하셔서 자녀가 캐츠홈에 로그인 할 수
                  있도록 해주세요.
                </p>
                <p className="textSub">
                  (자녀 로그인 초기 비밀번호는 자녀 휴대폰 번호 마지막
                  8자리입니다.)
                </p>
              </li>
              <li>
                자녀의 학습현황 및 결과는 본 학부모앱을 통해 확인할수 있으며
                자녀는 보유하고 있는 노트북, 태블릿, PC 등을 통해 언제 어디서나
                쉽게 학습하실 수 있습니다.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Button.Wrapper flexNone>
        <Button.Primary large label="홈으로 이동하기" onClick={goHome} />
      </Button.Wrapper>
    </div>
  );
}

export default TrialPage;
