import { useNavigate } from 'react-router-dom';

import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';

import Modal from './base/Modal';

function ModalRejectTrial({ ...rest }) {
  const { closeModal } = useOverlayContext();
  const navigate = useNavigate();

  const goHome = () => {
    setTimeout(() => {
      closeModal();
      navigate('/home');
    });
  };

  return (
    <Modal {...rest}>
      <Modal.Header hasClose>무료체험을 시작할 수 없습니다.</Modal.Header>
      <Modal.Body>
        <p className="textSub">
          이미 이용중인 서비스가 있거나,
          <br />
          서비스가 종료된지 90일이 지나지 않았습니다.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button.Primary label="확인" onClick={goHome} />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalRejectTrial;
