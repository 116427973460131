import FeedPayAcademy from './FeedPayAcademy';
import FeedPayBook from './FeedPayBook';
import FeedNotice from './FeedNotice';
import FeedConsultation from './FeedConsultation';
import FeedInviteAcademy from './FeedInviteAcademy';
import FeedNonPayment from './FeedNonPayment';
import FeedAcademyReport from './FeedAcademyReport';
import FeedOnlineReport from './FeedOnlineReport';
import FeedLevelTest from './FeedLevelTest';
import FeedLevelTestReport from './FeedLevelTestReport';
import FeedNonPaymentAcademy from './FeedNonPaymentAcademy';
import FeedAssessment from './FeedAssessment';
import FeedMathTest from './FeedMathTest';
import FeedMathTestReport from './FeedMathTestReport';
import FeedTaskReport from './FeedTaskReport';

class FeedMapper {
  // CD13383: 학습레포트 > 학원학습/숙제"
  // CD13384: 학습레포트 > 온라인 가정학습"
  // CD13385: 알림장"
  // CD13386: 요청상담수락"
  // CD13387: 요청상담거절"
  // CD13388: 상담요청수신"
  // CD13389: 교재 청구서"
  // CD13390: 학원비 청구서"
  // CD13391: 정기구독 미납"
  // CD13984: 학원비 미납
  // CD13392: 학원연결 초대"
  // CD13393: 자녀연결 요청"
  //  v 상담일정변경
  // CD13507 구독상품 구매요청
  // 수준진단평가
  // CD13996: 수준진단평가 발송
  // CD14021: 수준진단평가 완료
  // CD15102: 과제 리포트
  mapper = {
    // 비슷한 기능끼리 묶기
    CD13383: FeedAcademyReport,
    CD13384: FeedOnlineReport,
    // 알림장 화면은 어디로?
    CD13385: FeedNotice,
    // 일정확정 / 일정변경 / 상담_요청 만 존재
    CD13386: FeedConsultation,
    CD13387: FeedConsultation,
    CD13388: FeedConsultation,
    // 결제
    CD13389: FeedPayBook,
    CD13390: FeedPayAcademy,
    // 정기 구독 미납
    CD13391: FeedNonPayment,
    CD13984: FeedNonPaymentAcademy,
    CD13392: FeedInviteAcademy,
    CD13931: FeedLevelTest,
    CD13932: FeedLevelTestReport,
    CD13994: FeedAssessment,
    // CD13407: 상담일정변경
    // CD13507 구독상품 구매요청
    // 자녀연결은 이제 없나??
    CD13393: null,
    CD13996: FeedMathTest,
    CD14021: FeedMathTestReport,
    CD15102: FeedTaskReport,
  };

  get(feedLinkCd) {
    return this.mapper?.[feedLinkCd];
  }
}

const feedMapper = new FeedMapper();
export default feedMapper;
