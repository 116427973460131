import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';

import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';
import { accountSelector } from '@store/slice/accountSlice';
import homeService from '@service/HomeService';
import { toPhoneNumber } from '@helper/commonHelper';

import Modal from './base/Modal';

function ModalClassInvitation({
  id,
  fromFeed,
  academyInvite,
  onSuccess,
  ...rest
}) {
  const [student, setStudent] = useState();
  const [index, setIndex] = useState(0);
  const [currentInvite, setCurrentInvite] = useState();
  const [academy, setAcademy] = useState();

  const { closeModal, showToast } = useOverlayContext();
  const { memberNo, memberName, students } = useSelector(accountSelector);

  const handleSelect = (item) => {
    setStudent(item);
  };

  const handleReadFeed = (feedId) => {
    (async () => {
      if (feedId && !fromFeed) {
        await homeService.read({
          feedId,
        });
      }
    })();
  };

  const handleInvite = () => {
    (async () => {
      try {
        await homeService.inviteAcademy(
          {
            parentNo: memberNo,
            parentName: memberName,
            studentNo: student.memberNo,
            studentName: student.memberName,
            courseCode: student.courseCd,
            feedLinkKey: currentInvite.feedLinkKey,
          },
          { ignore: true },
        );
        showToast({
          message: '초대를 수락했습니다.',
        });
        onSuccess?.();
        closeModal(id);
      } catch (error) {
        const [, errorMsg] = error?.response?.data?.resultMsg?.split('|');
        showToast({
          message: errorMsg || '유효하지 않은 초대장입니다.',
        });
        onSuccess?.();
        closeModal(id);
      }
    })();
  };

  const handleDetail = (feedLinkKey) => {
    (async () => {
      const result = await homeService.inviteDetail(feedLinkKey);
      setAcademy(result);
    })();
  };

  const handleNext = () => {
    if (academyInvite?.length > index + 1) {
      setAcademy({});
      setIndex(index + 1);
      setCurrentInvite(academyInvite[index + 1]);
      handleReadFeed(academyInvite[index + 1]?.feedId);
      handleDetail(academyInvite[index + 1]?.feedLinkKey);
    } else {
      closeModal(id);
    }
  };

  useMount(() => {
    if (academyInvite?.length > 0) {
      setCurrentInvite(academyInvite[0]);
      handleReadFeed(academyInvite[0]?.feedId);
      handleDetail(academyInvite[0]?.feedLinkKey);
    }
  });

  return (
    <Modal isBasic className="typeClassInvitation" {...rest}>
      <Modal.Header hasClose>
        우리아이 맞춤처방 숙제를 위한 <br />
        클래스 초대장
      </Modal.Header>
      <Modal.Body className="popClassInvitation">
        {!isEmpty(academy) && (
          <>
            <div className="invitationMessage">
              <b>{academy?.academyName || '-'}</b>{' '}
              <b>{academy?.teacherName || '-'}</b> 선생님이 맞춤숙제를 위해{' '}
              <b>{academy?.className || '-'}</b>에 초대했습니다. 원활한 숙제
              제공 및 처방을 위해 클래스 등록을 완료해 주세요.
            </div>
            <div className="labelGroupBlock">
              {students.map((item) => (
                <label
                  key={`student_${item.memberNo}`}
                  className="checkBoxBorder typeRight"
                >
                  <input
                    type="radio"
                    name="invitation"
                    onChange={() => handleSelect(item)}
                  />
                  <span className="labelText">
                    <em>{item.memberName}</em>
                    <b className="txtPhone">
                      {toPhoneNumber(item?.mobileNumber) || '-'}
                    </b>
                  </span>
                </label>
              ))}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button.Outline label="나중에 하기" onClick={handleNext} />
        <Button.Primary
          disabled={isEmpty(student)}
          label="확인"
          onClick={handleInvite}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalClassInvitation;
