import { Button } from '@components/ui';
import { useNavigate } from 'react-router-dom';

function ErrorPage() {
  const navigate = useNavigate();

  return (
    <div className="systemBox">
      <div className="errorMessageBox">
        <p className="textTitle">
          죄송합니다.
          <br />
          원하시는 페이지를 찾을 수 없습니다.
        </p>
        <p className="textSub">
          페이지의 주소가 잘못 입력되었거나, 주소의 변경 혹은 삭제로 인해
          페이지를 찾을 수 없습니다.
        </p>
        <p className="textSub">
          입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주세요.
        </p>
      </div>
      <Button.Wrapper>
        <Button.Outline
          large
          label="이전 페이지로"
          onClick={() => navigate(-2)}
        />
        <Button.Primary
          large
          label="홈으로 가기"
          onClick={() => navigate('/home')}
        />
      </Button.Wrapper>

      <div className="guidanceText">
        <p>
          혹시 문의사항이 있으시다면
          <br />
          <a href="tel:1660-1244" className="aLink">
            고객센터
          </a>
          로 연락주세요!
        </p>
      </div>
    </div>
  );
}

export default ErrorPage;
