import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { Button } from '@components/ui';
import { accountSelector } from '@store/slice/accountSlice';
import { FormProvider, useForm } from 'react-hook-form';
import { FormRadio } from '@components/form';
import useBrandPay from '@hooks/useBrandPay';

// 온라인구독상품 > 결제 수단 변경
function PaymentMethodOnlinePage() {
  const { memberNo } = useSelector(accountSelector);

  const method = useForm({});
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = method;

  const {
    billingCard,
    cardList,
    changeMethod,
    addPaymentMethod,
    deletPaymentMethod,
    requestAgreement,
  } = useBrandPay({ memberNo, loadCard: true });

  const handleAddMethod = useCallback(() => {
    addPaymentMethod();
  }, [addPaymentMethod]);

  const handleDelete = useCallback(
    ({ methodKey }) => {
      const fetchDelete = async () => {
        deletPaymentMethod({ methodKey });
      };
      fetchDelete();
    },
    [deletPaymentMethod],
  );

  const submit = useCallback(
    (formValue) => {
      const fetch = async () => {
        if (isEmpty(billingCard)) {
          await requestAgreement({ scope: 'BILLING' });
        }
        await changeMethod({
          memberNo,
          methodKey: formValue.card,
        });
      };
      fetch();
    },
    [billingCard, changeMethod, memberNo, requestAgreement],
  );

  useEffect(() => {
    if (!isEmpty(billingCard)) {
      setValue('card', billingCard.methodKey);
    }
  }, [billingCard, setValue]);

  return (
    <FormProvider {...method}>
      <form
        onSubmit={handleSubmit(submit)}
        className="contentBox boxDetailedInfo"
      >
        <div className="boxFlexGrow">
          <div className="definitionBox">
            <p className="boxTitle">현재 결제정보</p>
            <dl className="grayOrderInfo">
              <div>
                <dt>결제수단</dt>
                <dd>
                  {!isEmpty(billingCard) && (
                    <>
                      {billingCard?.cardName}(
                      {billingCard?.cardNumber.slice(0, 4)})
                    </>
                  )}
                </dd>
              </div>
            </dl>
          </div>

          {cardList.length > 0 && (
            <div className="definitionBox">
              <p className="boxTitle">추가한 결제수단</p>
              <div className="graySelectionBox typeListModify">
                <ul>
                  {cardList.map((card) => (
                    <li key={`card_${card.methodKey}`}>
                      <FormRadio
                        option={{
                          required: true,
                        }}
                        name="card"
                        value={card.methodKey}
                        label={`${card.cardName} (${card.cardNumber.slice(
                          0,
                          4,
                        )})`}
                      />

                      {billingCard?.methodKey !== card?.methodKey && (
                        <button
                          type="button"
                          className="btnRemove"
                          onClick={() => handleDelete(card)}
                        >
                          <span />
                        </button>
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              <ul className="guidanceText">
                <li>
                  이미 추가되어있는 결제수단 중 선택하거나 새로운 결제수단을
                  추가해주세요.
                </li>
                <li>
                  결제예정일 오전 6시부터 9시까지는 결제 수단을 변경할 수
                  없습니다.
                </li>
                <li>
                  결제수단 변경 시 다음 결제일부터 변경한 결제수단으로
                  결제됩니다.
                </li>
              </ul>
            </div>
          )}
        </div>

        <div className="boxFlexNone">
          <p className="addPaymentMethod">
            <Button.Normal
              disabled={!isEmpty(errors)}
              className="btnLink"
              label="새로운 결제수단 추가하기"
              onClick={handleAddMethod}
            />
          </p>

          <Button.Wrapper>
            <Button.Primary type="submit" large label="변경한 결제수단 반영" />
          </Button.Wrapper>
        </div>
      </form>
    </FormProvider>
  );
}

export default PaymentMethodOnlinePage;
