import api from '@utils/Api';
import storage from '@utils/Storage';

class AccountService {
  constructor(client) {
    this.httpClient = client;
  }

  login({ username, mobileNum, password }, options) {
    return this.httpClient.post(
      '/auth/login',
      {
        username,
        mobileNum,
        password,
      },
      options,
    );
  }

  parent({ id }, options) {
    return this.httpClient.post(
      '/auth/service-member',
      { id },
      {
        ...options,
        onError: () => {
          // 사용자 정보가 없는 경우 or 에러 난 경우 세션 지우기
          storage.clearToken();
          window.location = '/login';
        },
      },
    );
  }

  sendCode({ mobileNumber, validityTimeoutSec }, options) {
    return this.httpClient.post(
      '/auth/send-msg',
      {
        mobileNumber,
        validityTimeoutSec,
        codeType: 'NUMBER',
      },
      options,
    );
  }

  passSendCode({ mobileNumber, memberName }, options) {
    return this.httpClient.post(
      '/auth/password/send-code',
      {
        mobileNumber,
        memberName,
      },
      options,
    );
  }

  verifyCode(
    { memberName, requestId, mobileNumber, verificationCode },
    options,
  ) {
    return this.httpClient.post(
      '/auth/verify-msg',
      {
        memberName,
        mobileNumber,
        requestId,
        verificationCode,
      },
      options,
    );
  }

  passVerifyCode(
    { memberName, requestId, mobileNumber, verificationCode },
    options,
  ) {
    return this.httpClient.post(
      '/auth/password/verify-code',
      {
        memberName,
        mobileNumber,
        requestId,
        verificationCode,
      },
      options,
    );
  }

  changePassword(
    { memberNo, memberName, mobileNumber, authToken, newRawPassword },
    options,
  ) {
    return this.httpClient.post(
      '/auth/password/reset',
      { memberNo, memberName, mobileNumber, authToken, newRawPassword },
      options,
    );
  }

  changeAddress(
    { memberNo, address, detailAddress: addressDetail, zipCode: postalCode },
    options,
  ) {
    return this.httpClient.post(
      `/mypage/service-member/${memberNo}/address`,
      { address, addressDetail, postalCode },
      options,
    );
  }

  checkDuplicate({ memberName, mobileNumber }, options) {
    return this.httpClient.post(
      '/auth/check-dup',
      { memberName, mobileNumber },
      options,
    );
  }

  join({ inboundChannel, parent, student }, options) {
    return this.httpClient.post(
      '/auth/join',
      { inboundChannel, parent, student },
      options,
    );
  }

  link(formValue, options) {
    return this.httpClient.post('/relation/link', { ...formValue }, options);
  }

  integrated({ memberNo, student }, options) {
    return this.httpClient.post(
      '/auth/add-role',
      { memberNo, student },
      options,
    );
  }

  termsService({ userType = 'PARENTS' }, options) {
    return this.httpClient.get(`/auth/agree-terms/${userType}`, {}, options);
  }

  findByAuth(params, options) {
    return this.httpClient.get(`/auth/find-by-auth`, { ...params }, options);
  }

  isExists(params, options) {
    return this.httpClient.get(`/auth/is-exists`, { ...params }, options);
  }
}

const accountService = new AccountService(api);
export default accountService;
