import Tabs from './base/Tabs';

function StudentTab({ student, hasAll = false, value = false, ...rest }) {
  const defaultValue = value || student?.[0]?.value;
  return (
    <Tabs
      tabs={student}
      defaultType={hasAll ? '' : defaultValue}
      isRound
      {...rest}
    />
  );
}

export default StudentTab;
