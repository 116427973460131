import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import { useOverlayContext } from '@context/OverlayContext';
import { Button } from '@components/ui';
import { FormInput } from '@components/form';
import accountService from '@service/AccountService';

import Modal from './base/Modal';
import ModalFailedVerify from './ModalFailedVerify';

const LIMIT_COUNT = 4;

function ModalVerifyUser({ studentName, studentMobile, onSuccess, ...rest }) {
  const [verifyCount, setVerifyCount] = useState(0);
  const [isVerify, setVerify] = useState(true);

  const navigate = useNavigate();
  const { showDialog, closeModal } = useOverlayContext();

  const methods = useForm({ defaultValues: { studentName, studentMobile } });
  const { handleSubmit } = methods;

  const goKakao = () => {
    navigate('/redirect', {
      state: { url: 'https://pf.kakao.com/_zsxdLxj' },
    });
  };

  const submit = useCallback(
    async (formValue) => {
      try {
        const result = await accountService.findByAuth(formValue, {
          ignore: true,
        });
        setVerify(true);
        onSuccess(result);
        closeModal();
      } catch (error) {
        setVerify(false);
        // 5회이상 넘는 경우
        if (verifyCount > LIMIT_COUNT) {
          showDialog(ModalFailedVerify);
        }
        setVerifyCount(verifyCount + 1);
      }
    },
    [closeModal, onSuccess, showDialog, verifyCount],
  );

  return (
    <FormProvider {...methods}>
      <Modal className="typememberConfirm" {...rest}>
        <form onSubmit={handleSubmit(submit)} className="memberBox">
          <Modal.Header hasClose>
            우리학원 온라인 맞춤학습관 캐츠홈
            <br />
            학생 무료 이용여부 확인
          </Modal.Header>
          <Modal.Body>
            <p className="textSub">
              자녀의 학원에서 발급한 무료사용권으로
              <br />
              학습중인 ‘우리학원 온라인 맞춤학습관 캐츠홈’의 비밀번호를 자녀에게
              확인하고 입력해 주세요.
            </p>
            <p className="textSub">
              해당사항과 관련한 문의는 아래를 이용해 주세요.
            </p>

            <div className="fieldsetWrap">
              <ul className="fieldsetList">
                <li>
                  <FormInput
                    disabled
                    label="학생명"
                    placeholder="이름"
                    option={{ required: '이름을 입력해주세요.' }}
                    name="studentName"
                  />
                </li>
                <li>
                  <FormInput
                    disabled
                    type="number"
                    label="휴대번호"
                    placeholder="휴대번호 11자리 -없이 입력"
                    option={{ required: '휴대폰 번호를 입력해주세요.' }}
                    name="studentMobile"
                  />
                </li>
                <li>
                  <FormInput
                    type="password"
                    label="비밀번호"
                    placeholder="비밀번호"
                    option={{ required: '비밀번호를 입력해주세요.' }}
                    name="password"
                  />
                  {!isVerify && (
                    <p style={{ display: 'block' }} className="errorMessage">
                      이름, 전화번호 또는 비밀번호를 잘못 입력하였습니다.
                      <br />
                      입력하신 내용을 다시 확인해주세요.
                    </p>
                  )}
                </li>
              </ul>
              <div className="boxContact">
                <strong className="textTitle">문의하기</strong>
                <a href="tel:1660-1244" className="btnCS">
                  <span>고객센터문의</span>
                </a>
                <button type="button" className="btnKakao" onClick={goKakao}>
                  <span>카카오톡 문의</span>
                </button>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button.Primary type="submit" label="인증하기" />
          </Modal.Footer>
        </form>
      </Modal>
    </FormProvider>
  );
}

export default ModalVerifyUser;
