import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'ahooks';

import { useAuthContext } from '@context/AuthContext';
import { userSelector } from '@store/slice/accountSlice';
import { changeRoute } from '@store/action/commonAction';
import storage from '@utils/Storage';
import { useOverlayContext } from '@context/OverlayContext';

import { ModalAlert } from '@components/overlay';
import MyPageMenu from './components/MyPageMenu';

// MY 페이지
function MyPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showDialog } = useOverlayContext();
  const { logout, refreshAccount } = useAuthContext();

  const user = useSelector(userSelector);

  const handleLogout = () => logout();

  const goPage = (url) => {
    dispatch(changeRoute(url));
    navigate(url);
  };

  const handleCheckMaxChild = () => {
    if (user?.family?.length >= 3) {
      showDialog(ModalAlert, {
        content: '하나의 부모 계정에는 세 명의 자녀까지만 등록 가능합니다.',
      });
      return;
    }
    goPage('/child-link');
  };

  useMount(() => {
    window.scrollTo({ top: 0, left: 0 });
    refreshAccount({ id: storage.getMemberNo() });
  });
  return (
    <div className="contentBox">
      <div className="userInfoBox">
        <button
          type="button"
          onClick={() => goPage('/my-confirm')}
          className="userNameBar"
        >
          <strong>{user.memberName}</strong>
        </button>
      </div>
      <div className="roundBox boxOffspring">
        <div className="offspringInfo">
          <strong className="listTitle">연결된 자녀</strong>
          <button
            type="button"
            className="btnAddChild"
            onClick={() => handleCheckMaxChild()}
          >
            <span>자녀 추가</span>
          </button>

          <ul className="childrenList">
            {user?.family?.map((item, index) => (
              <li key={`student_${index}`}>
                <strong className="txtChild">
                  {item.studentMember.memberName}
                </strong>
                <span className="txtSchoolYear">
                  {item.studentMember.gradeNm}
                </span>
                {item?.serviceStatus === 'PAUSE' && (
                  <span className="txtSubscription typeStandstill">
                    [구독 휴지]
                  </span>
                )}
                {item?.serviceStatus === 'STOP' && (
                  <span className="txtSubscription typeSuspended">
                    [구독 정지]
                  </span>
                )}

                {/* <p className="txtItemInUse">{item.subscribes?.join(', ')}</p> */}
                <p className="txtItemInUse" />
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="roundBox boxMenu">
        <dl className="fullMenu">
          <MyPageMenu
            menuName="내 정보"
            buttons={[
              {
                type: 'button',
                title: '내 정보 관리',
                handleClick: () => goPage('/my-confirm'),
              },
            ]}
          />
          <MyPageMenu
            menuName="신청/결제"
            buttons={[
              {
                type: 'button',
                title: '신청&결제내역',
                handleClick: () => goPage('/payment-history'),
              },
              {
                type: 'button',
                title: '결제대기',
                handleClick: () => goPage('/payment-waiting'),
              },
              {
                type: 'button',
                title: '결제하기',
                handleClick: () => goPage('/online-learning'),
              },
            ]}
          />
          <MyPageMenu
            menuName="고객센터"
            buttons={[
              {
                type: 'button',
                title: '자주묻는질문',
                handleClick: () => goPage('/faq'),
              },
              {
                type: 'button',
                title: '공지사항',
                handleClick: () => goPage('/notice'),
              },
              // {
              //   type: 'button',
              //   title: '1:1 문의',
              //   handleClick: () => goPage('/inquiry'),
              // },

              // {
              //   type: 'button',
              //   title: '이벤트',
              //   handleClick: () => goPage('/event'),
              // },
            ]}
          />

          <MyPageMenu
            menuName="서비스 안내"
            buttons={[
              {
                type: 'button',
                title: '약관 및 정책',
                handleClick: () => goPage('/terms'),
              },
              // {
              //   type: 'button',
              //   title: '알림 설정',
              //   handleClick: () => goPage('/setting'),
              // },
              // {
              //   type: 'version',
              //   title: '버전정보',
              //   subTitle: '1.0.0',
              //   handleClick: () => {},
              // },
            ]}
          />
        </dl>
      </div>
      <div className="customerCenter">
        <span>캐츠 공감센터</span>
        <a href="tel:1660-1244" className="aLinkTel">
          <span>1660-1244</span>
        </a>
      </div>
      <div className="bottomBox">
        <button type="button" onClick={handleLogout} className="btnLogOut">
          <span>로그아웃</span>
        </button>
      </div>
    </div>
  );
}

export default MyPage;
