/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

import reportService from '@service/ReportService';

import MathTestSummary from './components/math/MathTestSummary';
import MathTestGrade from './components/math/MathTestGrade';
import MathTestCorrectAnswersBySection from './components/math/MathTestCorrectAnswerBySection';
import MathTestCorrectAnswersByDifficulty from './components/math/MathTestCorrectAnswersByDifficulty';
import MathTestCorrectAnswersByArea from './components/math/MathTestCorrectAnswersByArea';
import MathTestScoreTable from './components/math/MathTestScoreTable';
import MathTestRecommandBook from './components/math/MathTestRecommandBook';

function LearningReportMathPage() {
  const { id: reportSendId } = useParams();

  const [report, setReport] = useState();

  useMount(() => {
    (async () => {
      try {
        const result = await reportService.getMathReport({
          reportSendId,
        });
        setReport({ ...result, level: result.level === 0 ? 1 : result.level });
      } catch (error) {
        console.warn(error);
      }
    })();
  });

  return (
    <div className="contentBox boxReportDetails">
      <div className="wrapLearningReport wrapLevelReport">
        {!isEmpty(report) && (
          <>
            <div className="reportTopArea typeEngLvTest">
              <div className="boxTitle">
                <strong>{report?.studentNm} 학생의</strong>수준 진단 평가 리포트
              </div>

              <ul className="topTestInfo typeLvDiagnosis">
                <li>
                  <strong>수업과정</strong>
                  <p>
                    {report?.courseNm} {report?.gradeNm} {report?.semesterNm}
                  </p>
                </li>
                <li>
                  <strong>학원/클래스</strong>
                  <p>
                    {report?.academyNm}/{report?.classNm}
                  </p>
                </li>
                <li>
                  <strong>평가지/난이도</strong>
                  <p>
                    {report?.exampaperNm} / {report?.difficultyNm}
                  </p>
                </li>
                <li>
                  <strong>응시일</strong>
                  <p>{dayjs(report?.testDate).format('YYYY년 MM월 DD일')}</p>
                </li>
              </ul>
            </div>

            <div className="reportContentWrap typeDiagnosticReport">
              <div className="reportSection sectionLvDiagnostic">
                <div className="sectionTitleBox">
                  <p className="sectionTitle">
                    {report?.studentNm} 학생의 종합 수준 진단 결과
                  </p>
                </div>
                {/* MathTestSummary */}
                <MathTestSummary {...report} />

                {/* MathTestGrade */}
                <MathTestGrade {...report} />
              </div>
              {/* MathTestCorrectAnswersBySection */}
              <MathTestCorrectAnswersBySection {...report} />
              {/* MathTestCorrectAnswersByDifficulty */}
              <MathTestCorrectAnswersByDifficulty {...report} />
              {/* MathTestCorrectAnswersByArea */}
              <MathTestCorrectAnswersByArea {...report} />
              {/* MathTestScoreTable */}
              <MathTestScoreTable {...report} />
              {/* MathTestRecommandBook */}
              <MathTestRecommandBook {...report} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default LearningReportMathPage;
