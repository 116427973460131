/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from 'react-redux';

import { studentSelector } from '@store/slice/accountSlice';
import { useNavigate } from 'react-router-dom';

function OnlineReportItem({ studentId, statisticsYearMonth }) {
  const navigate = useNavigate();

  const { memberName } = useSelector(studentSelector(studentId));

  const goDetail = (id) => {
    navigate(`/learning-report-online/${id}/${statisticsYearMonth}`);
  };

  return (
    <li>
      <a className="linkLearningReport" onClick={() => goDetail(studentId)}>
        <div className="boxClassInfo">
          <span className="logoImg logoCats">
            <em className="hidden">CATS</em>
          </span>
          <p className="txtAcademy">
            <span>캐츠 학습</span>
          </p>
          <p className="txtName">{memberName}</p>
        </div>
      </a>
    </li>
  );
}

export default OnlineReportItem;
