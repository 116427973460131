import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

function FormWithButton({ children, button, label, className = '' }) {
  const {
    formState: { errors },
  } = useFormContext();
  const { name } = children.props;
  return (
    <>
      <label className="label" htmlFor={name}>
        {label}
      </label>
      <div
        className={classNames(
          className,
          'inputBtnSet',
          errors?.[name] && 'error',
        )}
      >
        {children}
        <div className="btnBox">
          <button
            type="button"
            className="btnOutline"
            onClick={button.action}
            disabled={!!button.disabled}
          >
            <span>{button.label}</span>
          </button>
        </div>
      </div>
      {errors?.[name] && (
        <p className="errorMessage">{errors?.[name].message}</p>
      )}
    </>
  );
}

export default FormWithButton;
