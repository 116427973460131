import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { sumBy } from 'lodash';

import { humanFileSize } from '@helper/commonHelper';
import { useOverlayContext } from '@context/OverlayContext';
import { BaseModal } from '@components/overlay';
import useFileUpload from '@hooks/useFileUpload';
import { MAX_FILE_SIZE } from '@constants/file';

function FileUpload() {
  const { showDialog, closeModal } = useOverlayContext();

  const { setValue, watch } = useFormContext();

  const fileRef = useRef();
  const { upload } = useFileUpload();

  const files = watch('files');

  const handleInput = () => {
    fileRef.current.click();
  };

  const handleChange = async (event) => {
    const imageFile = event.target?.files?.[0];
    if (imageFile) {
      const size = sumBy([...files, imageFile], 'size');

      if (size > MAX_FILE_SIZE) {
        showDialog(BaseModal, {
          title: '알림',
          content: `최대 10MB까지 업로드가 가능합니다.`,
          buttons: [
            {
              label: '확인',
              action: () => closeModal(),
              className: 'btnPrimary',
            },
          ],
        });
        return;
      }

      try {
        const { resultData } = await upload(imageFile);
        setValue('files', [...resultData, ...files]);
      } catch (error) {
        console.warn(error);
      }
    }
  };

  const handleDelete = (fileId) => {
    const deleteFile = (id) => {
      setValue(
        'files',
        files.filter((item) => item.fileId !== id),
      );
    };
    showDialog(BaseModal, {
      title: '알림',
      content: `파일을 삭제하시겠습니까?`,
      buttons: [
        {
          label: '아니오',
          action: () => closeModal(),
          className: 'btnOutline',
        },
        {
          label: '네',
          action: () => {
            deleteFile(fileId);
            closeModal();
          },
          className: 'btnPrimary',
        },
      ],
    });
  };
  return (
    <div className="attachedList">
      <ul>
        {files.length < 5 && (
          <li>
            <button type="button" className="btnAddFile" onClick={handleInput}>
              <span>파일추가</span>
            </button>
            <input
              type="file"
              ref={fileRef}
              onChange={handleChange}
              style={{ display: 'none' }}
            />
          </li>
        )}
        {files.map((item) => (
          <li key={`image_${item.fileId}`}>
            <span className="img">
              <img src={item.file} alt={item.fileId} />
            </span>
            <p className="textFileSize">{humanFileSize(item.size)}</p>
            <button
              type="button"
              className="btnRemoveFile"
              onClick={() => handleDelete(item.fileId)}
            >
              <span>첨부파일삭제</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FileUpload;
