import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { PAYMENT_TYPE } from '@constants/tab';
import { SHIPPING_TYPE } from '@constants/order';

import Payment from './base/Payment';

function PaymentHistoryBook({ orderNumber, status, statusNm, ...rest }) {
  const navigate = useNavigate();

  const handleSelect = async () => {
    navigate(`/payment-detail/${PAYMENT_TYPE.BOOK}/${orderNumber}`);
  };

  return (
    <Payment
      onSelect={handleSelect}
      bookType={SHIPPING_TYPE[status] || statusNm}
      {...rest}
    />
  );
}

export default memo(PaymentHistoryBook);
