import api from '@utils/Api';

class CouponService {
  constructor(client) {
    this.httpClient = client;
  }

  getCoupons({ memberNo, couponType }, options = {}) {
    return this.httpClient.get(
      `/v1/payments/coupon/available/${memberNo}`,
      { couponType },
      options,
    );
  }

  apply({ memberNo, type }, options = {}) {
    return this.httpClient.post(
      `/v1/payments/coupon/${memberNo}/${type}`,
      {},
      options,
    );
  }
}

const couponService = new CouponService(api);
export default couponService;
