import { useState } from 'react';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';

import accountService from '@service/AccountService';
import { useOverlayContext } from '@context/OverlayContext';
import { PopupTermsDetail } from '@components/overlay';
import { useLocation } from 'react-router-dom';

function TermsPage() {
  const { state } = useLocation();

  const { showPopup } = useOverlayContext();
  const [termsOptions, setTermsOptions] = useState([]);

  const handleTerms = ({ title, content }) => {
    showPopup(PopupTermsDetail, { title, content });
  };

  useMount(async () => {
    const result = await accountService.termsService({ userType: 'PARENTS' });
    setTermsOptions(result);

    if (!isEmpty(state)) {
      const term = result.find(
        (item) => `${item.conditionsId}` === `${state.conditionsId}`,
      );
      handleTerms(term);
    }
  });

  return (
    <div className="contentBox boxTearms">
      <ul className="listTerms">
        {termsOptions.map((item, index) => (
          <li key={`terms_${index}`}>
            <button
              type="button"
              className="btnViewTerms"
              onClick={() => handleTerms(item)}
            >
              <span>{item.title}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TermsPage;
