import axios from 'axios';
import { parse, stringify } from 'qs';

import callApp from '@interface/externalInterface';
import storage from './Storage';

export const HttpMethod = {
  GET: 'GET',
  HEAD: 'HEAD',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  CONNECT: 'CONNECT',
  OPTIONS: 'OPTIONS',
  PATCH: 'PATCH',
};

export const StatusCode = {
  Ok: '200',
  Created: '201',
  Unauthorized: '401',
  Forbidden: '403',
  PageNotFound: '404',
  Authorized: '426',
  TooManyRequests: '429',
  InternalServerError: '500',
};

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'X-Requested-With': 'XMLHttpRequest',
};

const injectRequestFulfilled = (config) => {
  try {
    const { accessToken, refreshToken } = storage.getToken();

    if (accessToken !== null && refreshToken !== null) {
      if (!config.headers) config.headers = {};
      config.headers.X_CATS_AT = accessToken || '';
      config.headers.X_CATS_RT = refreshToken || '';
    }
    return config;
  } catch (error) {
    throw new Error(error);
  }
};

const injectRequestRejected = (error) => Promise.reject(error);

const injectResponseFulfilled = (response) => {
  const { resultCd, resultMsg, resultData } = response.data;
  const newToken = response.headers.x_cats_at;
  const { accessToken, refreshToken } = storage.getToken();

  // 토큰 업데이트
  if (newToken !== accessToken) {
    storage.setToken(newToken, refreshToken);
    callApp('setToken', { accessToken: newToken, refreshToken });
  }

  if (resultCd !== StatusCode.Ok) {
    return Promise.reject(resultMsg);
  }

  return resultData;
};

const injectResponseRejected = (instance) => (error) => {
  const originalRequest = error.config;
  const { resultCd, resultData } = error.response.data;
  if (
    resultCd === StatusCode.Unauthorized ||
    resultCd === StatusCode.Forbidden
  ) {
    storage.clearToken();
    // 인증 만료 or 재 로그인 필요
    callApp('logout');

    if (window.location.pathname !== '/login') {
      window.location = '/login';
    }
  } else if (resultCd === StatusCode.Authorized) {
    const { accessToken, refreshToken } = resultData;
    storage.setToken(accessToken, refreshToken);

    callApp('setToken', { accessToken, refreshToken });

    return instance(originalRequest);
  }
  return Promise.reject(error);
};

class Api {
  _instance = null;

  get ajax() {
    return this._instance != null ? this._instance : this.initHttp();
  }

  initHttp(
    baseURL = process.env.REACT_APP_PUBLIC_API_BASE_URL ||
      'https://dev-papi.cats4me.net:443/',
  ) {
    const ajax = axios.create({
      baseURL,
      headers,
      paramsSerializer: {
        encode: parse,
        serialize: stringify,
      },
    });

    ajax.interceptors.request.use(
      injectRequestFulfilled,
      injectRequestRejected,
    );

    ajax.interceptors.response.use(
      injectResponseFulfilled,
      injectResponseRejected(ajax),
    );

    this._instance = ajax;
    return ajax;
  }

  get(url, params = {}, { onSuccess, onError, loading = true, ...rest } = {}) {
    return this.ajax
      .get(url, { ...rest, loading, params })
      .then((result) => (onSuccess ? onSuccess(result) : result))
      .catch((error) => (onError ? onError(error) : Promise.reject(error)));
  }

  post(url, data = {}, { onSuccess, onError, loading = true, ...rest } = {}) {
    return this.ajax
      .post(url, data, { ...rest, loading })
      .then((result) => (onSuccess ? onSuccess(result) : result))
      .catch((error) => (onError ? onError(error) : Promise.reject(error)));
  }

  put(url, data = {}, { onSuccess, onError, loading = true, ...rest } = {}) {
    return this.ajax
      .put(url, data, { ...rest, loading })
      .then((result) => (onSuccess ? onSuccess(result) : result))
      .catch((error) => (onError ? onError(error) : Promise.reject(error)));
  }

  delete(url, { onSuccess, onError, loading = true, ...rest } = {}) {
    return this.ajax
      .delete(url, { ...rest, loading })
      .then((result) => (onSuccess ? onSuccess(result) : result))
      .catch((error) => (onError ? onError(error) : Promise.reject(error)));
  }
}

const api = new Api();
export default api;
