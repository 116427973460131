import character01 from '@assets/images/account/profile_character_01.svg';
import character02 from '@assets/images/account/profile_character_02.svg';
import character03 from '@assets/images/account/profile_character_03.svg';
import character04 from '@assets/images/account/profile_character_04.svg';
import character05 from '@assets/images/account/profile_character_05.svg';
import character06 from '@assets/images/account/profile_character_06.svg';

const profileImages = [
  {
    key: '01',
    src: character01,
  },
  {
    key: '02',
    src: character02,
  },
  {
    key: '03',
    src: character03,
  },
  {
    key: '04',
    src: character04,
  },
  {
    key: '05',
    src: character05,
  },
  {
    key: '06',
    src: character06,
  },
];

function getCharacterImages() {
  return profileImages;
}

function getCharacterImage(key) {
  return profileImages.find((item) => item.key === key);
}

export { getCharacterImage, getCharacterImages };
