function Pagination({ pageNo, pages, onPage }) {
  const handlePage = (page) => {
    onPage(page);
  };
  return (
    <div className="paginationWrap">
      <button
        type="button"
        className="pagePrev"
        disabled={pageNo === 1}
        onClick={() => handlePage(pageNo - 1)}
      >
        <span>이전페이지</span>
      </button>
      <span className="textPages">
        <em className="current">{pageNo}</em>
        <strong className="totalNum">{pages}</strong>
      </span>
      <button
        type="button"
        className="pageNext"
        disabled={pageNo === pages}
        onClick={() => handlePage(pageNo + 1)}
      >
        <span>다음페이지</span>
      </button>
    </div>
  );
}

export default Pagination;
