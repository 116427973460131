import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from '@components/ui';
import {
  GoodInfoBox,
  DefinitionBoxImage,
  DefinitionBoxSelection,
  DefinitionBoxPrice,
} from '@components/box';
import { accountSelector } from '@store/slice/accountSlice';
import { PAYMENT_TYPE } from '@constants/tab';
import orderService from '@service/OrderService';
import { FormRadio } from '@components/form';
import { toCommaNumber } from '@helper/commonHelper';
import {
  CLAIM_SOLUTION_TYPE,
  CLAIM_TYPE,
  CLAIM_TYPE_MESSAGE,
} from '@constants/order';
import { useOverlayContext } from '@context/OverlayContext';
import { PopupClaimSuccess } from '@components/overlay';

// 결제내역 > 교재 > 교환/환불 취소 신청
function PaymentHistoryClaimPage() {
  const { showPopup } = useOverlayContext();
  const { id, type } = useParams();

  const [history, setHistory] = useState({});
  const [order, setOrder] = useState({});

  const { memberNo } = useSelector(accountSelector);

  const method = useForm({ defaultValues: { files: [] } });
  const { handleSubmit, setValue, watch } = method;

  const [claimType, solutionType] = watch(['claimType', 'solutionType']);

  const isEnable = useMemo(() => {
    if (isEmpty(claimType) || isEmpty(solutionType)) {
      return false;
    }
    return true;
  }, [claimType, solutionType]);

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(
      'solutionType',
      value === CLAIM_TYPE.ORDER_MISTAKE ? CLAIM_SOLUTION_TYPE.REFUND : '',
    );
  };
  const submit = (formValue) => {
    const fetchRefund = async () => {
      try {
        await orderService.refund({
          memberNo,
          orderId: id,
          claimGoodsRequestList: order.map((item) => ({
            orderEa: 1,
            orderGoodsId: item.orderGoodsId,
          })),
          fileIds: formValue.files.map((item) => item.fileId),
          fileGroupIds: formValue.files.map((item) => item.fileGroupId),
          fileNms: formValue.files.map((item) => item.fileNm),
          claimReason: CLAIM_TYPE_MESSAGE[formValue.claimType],
          claimType: formValue.solutionType,
        });

        // 교환/환불 신청 완료 페이지로 이동
        showPopup(PopupClaimSuccess);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchRefund();
  };

  useEffect(() => {
    const fetchHistory = async () => {
      const result = await orderService.getHistoryDetail({
        // 교환 환불 페이지는 교재밖에 없음
        type: PAYMENT_TYPE.BOOK,
        orderNumber: id,
        parentNo: memberNo,
      });
      setHistory(result);
      setOrder(result.orderGoodsDtoList);
    };

    if (isEmpty(history)) {
      fetchHistory();
    }
  }, [history, id, memberNo, type]);

  return (
    <>
      {history && order && (
        <FormProvider {...method}>
          <form
            onSubmit={handleSubmit(submit)}
            className="contentBox boxDetailedInfo"
          >
            <div className="boxFlexGrow">
              <div className="definitionBox typeGoodsInfo">
                <GoodInfoBox {...order?.[0]} />
              </div>

              <DefinitionBoxSelection
                title="어떤 문제가 있으신가요?"
                category={[
                  {
                    topic: (
                      <FormRadio
                        label={
                          <em>
                            {CLAIM_TYPE_MESSAGE[CLAIM_TYPE.ORDER_MISTAKE]}
                            <i className="textOptional">(배송비 구매자 부담)</i>
                          </em>
                        }
                        name="claimType"
                        value={CLAIM_TYPE.ORDER_MISTAKE}
                        onChange={handleChange}
                      />
                    ),
                  },
                  {
                    topic: (
                      <FormRadio
                        label={
                          <em>
                            {CLAIM_TYPE_MESSAGE[CLAIM_TYPE.BROKEN_BOOK]}
                            <i className="textOptional">(배송비 CATS 부담)</i>
                          </em>
                        }
                        name="claimType"
                        value={CLAIM_TYPE.BROKEN_BOOK}
                        onChange={handleChange}
                      />
                    ),
                  },
                  {
                    topic: (
                      <FormRadio
                        label={
                          <em>
                            {CLAIM_TYPE_MESSAGE[CLAIM_TYPE.DELIVERY]}
                            <i className="textOptional">(배송비 CATS 부담)</i>
                          </em>
                        }
                        name="claimType"
                        value={CLAIM_TYPE.DELIVERY}
                        onChange={handleChange}
                      />
                    ),
                  },
                ]}
              />

              {!isEmpty(claimType) && (
                <DefinitionBoxSelection
                  title="해결 방법"
                  category={[
                    {
                      topic: (
                        <FormRadio
                          label={<em>환불</em>}
                          name="solutionType"
                          value={CLAIM_SOLUTION_TYPE.REFUND}
                        />
                      ),
                    },
                    {
                      topic: (
                        <FormRadio
                          disabled={claimType === CLAIM_TYPE.ORDER_MISTAKE}
                          label={<em>교환</em>}
                          name="solutionType"
                          value={CLAIM_SOLUTION_TYPE.EXCHANGE}
                        />
                      ),
                    },
                  ]}
                />
              )}
              {/* 파손인 경우 사진 첨부 */}
              {claimType === CLAIM_TYPE.BROKEN_BOOK && solutionType !== '' && (
                <DefinitionBoxImage />
              )}

              {/* 환불일 경우 환불 금액 표시 */}
              {solutionType === CLAIM_SOLUTION_TYPE.REFUND && (
                <DefinitionBoxPrice
                  title="환불 정보"
                  refund={history.orderPaymentAmount}
                  category={[
                    {
                      name: '상품금액',
                      topic: (
                        <>
                          <b>{toCommaNumber(order?.[0].price)}</b>원
                        </>
                      ),
                    },
                    {
                      name: '배송비',
                      topic: (
                        <div className="shippingPrice">
                          <b>{toCommaNumber(history.orderDeliveryFee)}</b>원
                        </div>
                      ),
                    },
                    {
                      name: '결제금액',
                      topic: (
                        <>
                          <b>{toCommaNumber(history.orderPaymentAmount)}</b>원
                        </>
                      ),
                    },
                  ]}
                />
              )}
            </div>

            <div className="boxFlexNone">
              {solutionType === CLAIM_SOLUTION_TYPE.EXCHANGE && (
                <p className="helpPhrase">
                  교환 진행 방법은 담당자와의 확인 후 진행 될 예정입니다.
                </p>
              )}

              {solutionType === CLAIM_SOLUTION_TYPE.REFUND && (
                <p className="helpPhrase">
                  정확한 환불금액은 담당자와의 확인 후 안내드립니다.
                </p>
              )}

              <Button.Wrapper>
                <Button.Primary
                  large
                  type="submit"
                  label="요청하기"
                  disabled={!isEnable}
                />
              </Button.Wrapper>
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
}

export default PaymentHistoryClaimPage;
