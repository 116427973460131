import { useMatch } from 'react-router-dom';
import classnames from 'classnames';

function SignUpSteps() {
  const isParentPage = useMatch({
    path: '/parent',
  });

  const isChildAddPage = useMatch({
    path: '/child-add',
  });

  const isChildLinkPage = useMatch({
    path: '/child-link',
  });

  const isChildIntegratedPage = useMatch({
    path: '/child-integrated',
  });

  return (
    <>
      {!isChildLinkPage && !isChildIntegratedPage && (
        <div className="signupSteps">
          <dl>
            <dt>{isParentPage ? '학부모' : '자녀'}정보 입력</dt>
            <dd>
              <span
                className={classnames(
                  'orderNum',
                  isParentPage && 'currentStep',
                )}
              >
                1
              </span>
              <span
                className={classnames(
                  'orderNum',
                  isChildAddPage && 'currentStep',
                )}
              >
                2
              </span>
            </dd>
          </dl>
        </div>
      )}
    </>
  );
}

export default SignUpSteps;
