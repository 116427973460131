import api from '@utils/Api';

class DeliveryService {
  constructor(client) {
    this.httpClient = client;
  }

  address({ memberNo }, options = {}) {
    return this.httpClient.get(
      `/v1/payments/delivery/${memberNo}`,
      {},
      options,
    );
  }

  add(data, options = {}) {
    return this.httpClient.post(`/v1/payments/delivery/address`, data, options);
  }

  update(data, options = {}) {
    return this.httpClient.put(
      `/v1/payments/delivery/address/${data.addressId}`,
      data,
      options,
    );
  }

  remove({ addressId, memberNo }, options = {}) {
    return this.httpClient.delete(
      `/v1/payments/delivery/address/${addressId}?memberNo=${memberNo}`,
      {},
      options,
    );
  }
}

const deliveryService = new DeliveryService(api);
export default deliveryService;
