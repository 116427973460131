import { isEmpty } from 'lodash';

/* eslint-disable jsx-a11y/anchor-is-valid */
function MathTestRecommandBook({
  studentNm,
  courseNm,
  gradeNm,
  semesterNm,
  textbookList,
}) {
  return (
    <>
      {!isEmpty(textbookList) && (
        <div className="reportSection sectionLvMaterial">
          <div className="sectionTitleBox">
            <p className="sectionTitle">캐츠 추천 교재</p>
            <p className="sectionTextSub">
              수준 진단 평가 분석 결과 {studentNm} 학생이 학습하기에 적절한 추천
              시중교재 쌍둥이 문제집입니다.
            </p>
          </div>

          <div className="reportDivision">
            <ul className="recommendedList">
              {textbookList.map((item) => (
                <li key={`book_${item.textbookCategoryId}`}>
                  <a className="aLink" style={{ cursor: 'default' }}>
                    <div className="imgBox">
                      <img src={item.coverImgUrl} alt="" />
                    </div>
                    <div className="textBox">
                      <div className="bookTitle">{item.textbookNm}</div>
                      <div className="bookDescription">{`${courseNm} ${gradeNm} ${semesterNm}`}</div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default MathTestRecommandBook;
