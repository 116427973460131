import { useCallback } from 'react';
import { useOverlayContext } from '@context/OverlayContext';
import { Button } from '@components/ui';

import Modal from './base/Modal';

function ModalStudentUser({ complete, ...rest }) {
  const { closeModal } = useOverlayContext();

  const handleClose = useCallback(() => {
    complete?.();
    closeModal();
  }, [complete, closeModal]);

  return (
    <Modal {...rest}>
      <Modal.Header hasClose>가입된 계정 안내</Modal.Header>
      <Modal.Body>
        <p className="textSub">
          입력하신 휴대폰 번호는 이미 <b className="colorGreen">‘CATS학생’</b>
          서비스에 가입되어 있는 정보입니다.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button.Primary label="확인" onClick={handleClose} />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalStudentUser;
