import { useMemo } from 'react';
import ReactECharts from 'echarts-for-react';

import { findTextWithinBrackets } from '@helper/commonHelper';

function TaskReportDifficultyRate({
  reportSendStudentNm,
  reportTaskDifficulties,
  isBeforeRegDate,
}) {
  const series = useMemo(() => {
    return reportTaskDifficulties.reduce(
      (pre, item) => {
        const {
          correctAnswerRate,
          correctAnswerCnt,
          avgCorrectAnswerRate,
          totalCnt,
        } = item;
        return {
          correctAnswerRate: [...pre.correctAnswerRate, correctAnswerRate],
          avgCorrectAnswerRate: [
            ...pre.avgCorrectAnswerRate,
            avgCorrectAnswerRate,
          ],
          correctAnswerCnt: [...pre.correctAnswerCnt, correctAnswerCnt],
          totalCnt: [...pre.totalCnt, totalCnt],
        };
      },
      {
        correctAnswerRate: [],
        avgCorrectAnswerRate: [],
        correctAnswerCnt: [],
        totalCnt: [],
      },
    );
  }, [reportTaskDifficulties]);

  const chartOptions = useMemo(() => {
    return {
      legend: {
        show: true,
      },
      grid: {
        containLabel: false,
        bottom: '20',
        top: '40',
        left: '40',
        right: '20',
      },
      xAxis: {
        type: 'category',
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        axisLabel: {
          formatter: (value) => `${value}%`,
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      series: [
        ...[
          {
            color: '#25aa58',
            target: 'correctAnswerRate',
            name: `${reportSendStudentNm} 학생`,
          },
          {
            color: '#bdbdbd',
            target: 'avgCorrectAnswerRate',
            name: '평균 정답률',
          },
        ]
          // 마감일 이전이면 평균 정답률만 표시
          .filter(
            ({ target }) => isBeforeRegDate || target === 'correctAnswerRate',
          )
          .map(({ name, color, target }) => ({
            name,
            type: 'line',
            symbol: 'circle',
            symbolSize: 8,
            label: {
              show: true,
              formatter: '{@[1]}%',
              color,
            },
            itemStyle: {
              color,
            },
            data:
              reportTaskDifficulties?.map((item) => [
                findTextWithinBrackets(item.difficultyNm),
                item[target],
              ]) || [],
          })),
      ],
    };
  }, [isBeforeRegDate, reportSendStudentNm, reportTaskDifficulties]);
  return (
    <div className="reportSection rptSectionDifficulty">
      <div className="sectionTitleBox">
        <p className="sectionTitle">난이도별 성취율</p>
      </div>
      <div className="reportDivision">
        <div className="roundBox typeChart">
          <div className="chartArea">
            <ReactECharts option={chartOptions} />
          </div>
        </div>
        <div className="roundBox typeDataTable">
          <div className="tableWrap">
            <table>
              <colgroup>
                <col style={{ width: 'auto' }} />
                <col
                  style={{ width: '12%' }}
                  span={series.correctAnswerRate.length}
                />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">구분</th>
                  {reportTaskDifficulties?.map((item, index) => (
                    <th key={`${item?.difficultyNm}_${index}`} scope="col">
                      {findTextWithinBrackets(item?.difficultyNm || '')}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="borderN">
                <tr className="rowAchievementRate">
                  <th scope="row">성취율</th>
                  {series.correctAnswerRate?.map((item, index) => (
                    <td key={`rate_${index}`}>{item}</td>
                  ))}
                </tr>
                <tr>
                  <th scope="row">정답 문항수</th>
                  {series.correctAnswerCnt.map((item, index) => (
                    <td key={`answer_${index}`}>{item}</td>
                  ))}
                </tr>
                <tr>
                  <th scope="row">전체 문항수</th>
                  {series.totalCnt.map((item, index) => (
                    <td key={`total_${index}`}>{item}</td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskReportDifficultyRate;
