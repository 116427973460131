/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
import { getBrowserType } from '@helper/commonHelper';
import storage from '@utils/Storage';
import { isEmpty, isString } from 'lodash';

export const iosInterface = {
  setToken({ accessToken, refreshToken }) {
    window.webkit?.messageHandlers?.appInterface?.postMessage({
      action: 'setToken',
      value: {
        accessToken,
        refreshToken,
      },
    });
  },

  getSession() {
    window.webkit?.messageHandlers?.appInterface?.postMessage({
      action: 'getSession',
      function: 'setWebViewSession',
    });
  },

  setSession({
    accessToken,
    refreshToken,
    details,
    data,
    deviceInfo = '',
    pushToken = '',
    password,
  }) {
    window.webkit?.messageHandlers?.appInterface?.postMessage({
      action: 'setSession',
      value: {
        accessToken,
        refreshToken,
        details,
        data,
        deviceInfo,
        pushToken,
        password,
      },
    });
  },

  logout() {
    window.webkit?.messageHandlers?.appInterface?.postMessage({
      action: 'logout',
    });
  },

  login() {
    window.webkit?.messageHandlers?.appInterface?.postMessage({
      action: 'login',
    });
  },

  closeWindow() {
    window.webkit?.messageHandlers?.appInterface?.postMessage({
      action: 'closeWindow',
    });
  },

  appExit() {
    window.webkit?.messageHandlers?.appInterface?.postMessage({
      action: 'appExit',
    });
  },
};

export const androidInterface = {
  setToken({ accessToken, refreshToken }) {
    window.appInterface?.setToken?.(accessToken, refreshToken);
  },

  setSession({
    accessToken,
    refreshToken,
    details,
    data,
    deviceInfo = '',
    pushToken = '',
    password,
  }) {
    window.appInterface?.setSession?.(
      accessToken,
      refreshToken,
      details,
      data,
      deviceInfo,
      pushToken,
      password,
    );
  },

  logout() {
    window.appInterface?.logout?.();
  },

  login() {
    window.appInterface?.login?.();
  },

  appExit() {
    window.appInterface?.appExit?.();
  },

  closeWindow() {
    window.appInterface?.closeWindow?.();
  },
};

export const webviewInterface = {
  setWebViewSession(
    accessToken,
    refreshToken,
    details,
    data,
    deviceInfo,
    pushToken,
  ) {
    details = isString(details) ? JSON.parse(details) : details;
    data = isString(data) ? JSON.parse(data) : data;

    storage.setSession(
      accessToken,
      refreshToken,
      // 통합계정일 경우 detail가 null로 옴
      isEmpty(details) ? data?.id : details?.memberNo,
      isEmpty(data) ? '' : data?.userRoles,
    );
    // TODO: 나중에 deviceInfo 파싱해서 버전정보도 가져와야 함
    window.appInterface.DEVICE = deviceInfo;
    window.appInterface.PUSH_TOKEN = pushToken;
  },
};

const externalInterface = {
  ANDROID: androidInterface,
  IOS: iosInterface,
};
function callApp(name) {
  const appInterface = externalInterface[getBrowserType()];
  // WEB인 경우 없음
  if (!appInterface) {
    console.warn('appInterface is not defined');
    return;
  }

  if (!appInterface?.[name]) {
    console.warn(`${name} is not defined`);
    return;
  }

  return (
    appInterface[name] &&
    appInterface[name].apply(appInterface, [].slice.call(arguments, 1))
  );
}

export default callApp;
