import classnames from 'classnames';
import { toCommaNumber } from '@helper/commonHelper';

function DefinitionBoxPayment({
  title,
  category,
  totalLabel,
  amount,
  className,
  children,
  isWinkMember = false,
  isSmall = false,
  isModal = false,
  upper = false,
}) {
  return (
    <div className={isModal ? 'popDefinitionBox' : 'definitionBox'}>
      {title && (
        <p className={classnames('boxTitle', upper && 'txtUpper')}>{title}</p>
      )}
      <ul className={classnames('boxSubtopic', className && className)}>
        {category.map(({ name, topic }) => {
          // 항목이 비어 있으면 안보여주기
          if (topic.length === 0) {
            return '';
          }
          return (
            <li key={`category_${name}`}>
              <strong className="titCategory">{name}</strong>
              <ul className="subtopicList">
                {topic.map(
                  (
                    {
                      className: subClass,
                      itemDesc,
                      subName,
                      payAmount,
                      value,
                      cost = false,
                      hidden = false,
                    },
                    index,
                  ) => {
                    if (hidden) {
                      return '';
                    }
                    return (
                      <li key={`lesson_${name}_${index}`}>
                        <div className="txtLabel">
                          {subClass || itemDesc || subName}
                        </div>
                        <div
                          className={classnames(
                            'txtSubtopic',
                            cost && 'colorCost',
                          )}
                        >
                          {payAmount && (
                            <>
                              <b>{toCommaNumber(payAmount)}</b>원
                            </>
                          )}
                          {value && value}
                        </div>
                      </li>
                    );
                  },
                )}
              </ul>
            </li>
          );
        })}
      </ul>

      {isWinkMember && (
        <div className="popDefinitionBox">
          <p className="guidanceText">결제금액 안내</p>
          <p className="guidanceParagraph">
            실결제금액은 결제된 신용카드 또는 은행계좌를 통해 확인해 주시기
            바랍니다.
          </p>
        </div>
      )}
      {amount !== undefined && amount !== '0' && amount !== 0 && (
        <dl className="boxOrderInfo typeTotalPrice">
          <dt>{totalLabel || '결제금액'}</dt>
          <dd className={classnames(isSmall && 'textSmall')}>
            <b>{toCommaNumber(amount)}</b>원
          </dd>
        </dl>
      )}
      {children}
    </div>
  );
}

export default DefinitionBoxPayment;
