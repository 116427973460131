const ELEMENTARY = [
  { value: 'CD10012', name: '1학년' },
  { value: 'CD10013', name: '2학년' },
  { value: 'CD10014', name: '3학년' },
  { value: 'CD10015', name: '4학년' },
  { value: 'CD10016', name: '5학년' },
  { value: 'CD10017', name: '6학년' },
];
const MIDDLE = [
  { value: 'CD10018', name: '1학년' },
  { value: 'CD10019', name: '2학년' },
  { value: 'CD10020', name: '3학년' },
];
const HIGH = [
  { value: 'CD10021', name: '1학년' },
  { value: 'CD10022', name: '2학년' },
  { value: 'CD10023', name: '3학년' },
];

const SCHOOL_GRADE = {
  CD10012: '1학년',
  CD10013: '2학년',
  CD10014: '3학년',
  CD10015: '4학년',
  CD10016: '5학년',
  CD10017: '6학년',
  CD10018: '1학년',
  CD10019: '2학년',
  CD10020: '3학년',
  CD10021: '1학년',
  CD10022: '2학년',
  CD10023: '3학년',
};

const ELEMENTARY_SCHOOL = 'CD10009';
const MIDDLE_SCHOOL = 'CD10010';
const HIGH_SCHOOL = 'CD10011';
const ALL_COURSE = 'CD12426';

export {
  ELEMENTARY,
  MIDDLE,
  HIGH,
  ELEMENTARY_SCHOOL,
  MIDDLE_SCHOOL,
  HIGH_SCHOOL,
  ALL_COURSE,
  SCHOOL_GRADE,
};
