/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { learningSelector } from '@store/slice/homeSlice';
import { LearningOnlineSummary } from '@components/learning';
import { LEARNING_TYPE } from '@constants/tab';

function HomeLearningOnline({ studentNo }) {
  const navigate = useNavigate();
  const { online } = useSelector(learningSelector({ studentNo }));

  const goOnline = useCallback(() => {
    navigate(`/learning-status?type=${LEARNING_TYPE.ONLINE}`);
  }, [navigate]);

  return (
    <>
      {!isEmpty(online) && (
        <div className="groupStatus typeHome">
          <div className="roundBox">
            <a className="listTitle" onClick={goOnline}>
              <strong>캐츠 학습</strong>
              <span className="hidden">더보기</span>
            </a>
            <ul className="statusList">
              {online.map((item, index) => (
                <LearningOnlineSummary
                  key={`learning_online_${index}`}
                  {...item}
                />
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default HomeLearningOnline;
