import { isEmpty } from 'lodash';

/* eslint-disable class-methods-use-this */
class Storage {
  _keepLogin = 'true';

  // 잠시 로그인세션을 들고 있음
  holder = {};

  constructor() {
    this._keepLogin = localStorage.getItem('keepLogin');
  }

  setToken(accessToken, refreshToken) {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
  }

  setSession(accessToken, refreshToken, memberNo, userRoles) {
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('refresh_token', refreshToken);
    localStorage.setItem('member_no', memberNo);
    localStorage.setItem('user_roles', userRoles);
  }

  getSession() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const memberNo = localStorage.getItem('member_no');
    const userRoles = localStorage.getItem('user_roles');

    return {
      accessToken,
      refreshToken,
      memberNo,
      userRoles,
    };
  }

  clearToken() {
    localStorage.setItem('access_token', '');
    localStorage.setItem('refresh_token', '');
    localStorage.setItem('member_no', '');
    localStorage.setItem('user_roles', '');
  }

  getHolder() {
    return this.holder;
  }

  pushHolder() {
    this.holder = this.getSession();
    this.clearToken();
  }

  popHolder() {
    const { accessToken, refreshToken, memberNo, userRoles } = this.holder;
    this.setSession(accessToken, refreshToken, memberNo, userRoles);
  }

  getToken() {
    const accessToken = localStorage.getItem('access_token');
    const refreshToken = localStorage.getItem('refresh_token');
    return {
      accessToken,
      refreshToken,
    };
  }

  getUserRoles() {
    const userRoles = localStorage.getItem('user_roles');
    if (isEmpty(userRoles)) {
      return [];
    }
    return userRoles.split(',');
  }

  setUserRoles(userRoles) {
    localStorage.setItem('user_roles', userRoles);
  }

  isOnlyTeacherRole() {
    return (
      this.getUserRoles().includes('ROLE_TEACHER') &&
      !this.getUserRoles().includes('ROLE_PARENT')
    );
  }

  getMemberNo() {
    return localStorage.getItem('member_no');
  }

  setKeepLogin(keepLogin) {
    localStorage.setItem('keepLogin', `${keepLogin}`);
    this._keepLogin = `${keepLogin}`;
  }

  hasSession() {
    const memberNo = this.getMemberNo();
    const { accessToken, refreshToken } = this.getToken();

    return (
      !isEmpty(accessToken) && !isEmpty(refreshToken) && !isEmpty(memberNo)
    );
  }

  getKeepLogin() {
    return this._keepLogin === 'true';
  }

  set(key, value) {
    localStorage.setItem(key, value);
  }

  get(key) {
    localStorage.getItem(key);
  }
}

const storage = new Storage();
export default storage;
