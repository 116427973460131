import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { oneYears } from '@helper/timeHelper';
import orderService from '@service/OrderService';
import { changeRoute } from '@store/action/commonAction';
import { PAYMENT_TABS, PAYMENT_TYPE } from '@constants/tab';

const PAGE_SIZE = 10;

export const all = createAsyncThunk(
  'paymentWaiting/all',
  async ({ parentNo, type }) => {
    /**
     * 1년으로 조회한 이유 *
     * 결제대기에 6개월까지만 가지고 있고 이후에는 지운다고 함
     * 교제,구독상품,온라인 상품별로 데이터 형태가 달라 서버에서 페이지네이션 하기가 어려움
     * 결제 대기는 전체목록을 불러와 store에서 페이지 관리함.
     */
    const [startYearmon, endYearmon] = oneYears();

    const response = await orderService.getWatingList({
      parentNo,
      startYearmon,
      endYearmon,
      type,
    });
    return response;
  },
);

const initialState = {
  holder: [],
  waiting: [],
  type: PAYMENT_TYPE.ALL,
  isFetch: false,
  hasNext: false,
};

export const paymentWaitingSlice = createSlice({
  name: 'paymentWaiting',
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload;
    },
    next: (state) => {
      state.waiting = [...state.waiting, ...state.holder.slice(0, PAGE_SIZE)];
      state.holder = state.holder.slice(PAGE_SIZE, state.holder.length);
      state.hasNext = state.holder.length > 0;
      state.isFetch = true;
    },
    clear: (state) => {
      state.waiting = [];
      state.holder = [];
      state.type = PAYMENT_TYPE.ALL;
      state.hasNext = false;
      state.isFetch = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(all.fulfilled, (state, action) => {
      state.holder = action.payload.slice(PAGE_SIZE, action.payload.length);
      state.waiting = action.payload.slice(0, PAGE_SIZE);
      state.hasNext = state.holder.length > 0;
      state.isFetch = true;
    });

    builder.addCase(changeRoute, (state, action) => {
      if (action.payload.includes('/payment-waiting')) {
        state.waiting = [];
        state.holder = [];
        state.type = PAYMENT_TYPE.ALL;
        state.hasNext = false;
        state.isFetch = false;
      }
    });
  },
});

const paymentWaitingState = (state) => {
  return {
    waiting: state.paymentWaiting.waiting,
    hasNext: state.paymentWaiting.hasNext,
    isFetch: state.paymentWaiting.isFetch,
    type: state.paymentWaiting.type,
  };
};
export const paymentWaitingSelector = createSelector(
  paymentWaitingState,
  (state) => state,
);

export const { next, clear, setType } = paymentWaitingSlice.actions;
export default paymentWaitingSlice.reducer;
