import { v4 as uuidv4 } from 'uuid';

import { useOverlayContext } from '@context/OverlayContext';
import { Button } from '@components/ui';

import Modal from './base/Modal';

function ModalButtons({ title, content, buttons = [], ...rest }) {
  const { closeModal } = useOverlayContext();

  return (
    <Modal className="typeCommentManagement" {...rest}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>
        <ul className="btnList">
          {buttons.map((item) => (
            <li key={uuidv4()}>
              <Button.Outline
                className={item.className}
                onClick={item.onClick}
                label={item.label}
              />
            </li>
          ))}
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button.Outline label="취소" onClick={() => closeModal()} />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalButtons;
