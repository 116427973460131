import { useEffect, useRef } from 'react';

function Loading({ isLoading }) {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref) return;

    ref.current.style['transition-duration'] = isLoading ? '0ms' : '500ms';
    ref.current.style.visibility = isLoading ? 'visible' : 'hidden';
    ref.current.style.opacity = isLoading ? '1' : '0';
  }, [isLoading]);

  return (
    <div ref={ref} className="">
      <div className="loadingWrapper">
        <div className="loadingBox">
          <div className="dotFlashing" />
        </div>
      </div>
    </div>
  );
}

export default Loading;
