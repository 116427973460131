/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { memo } from 'react';
import { isNull } from 'lodash';
import classnames from 'classnames';

import { toCommaNumber } from '@helper/commonHelper';
import { PaymentDueDate } from '@components/ui';
import { BILL_PAY_STATE_CODE, PAYMENT_GOODS_TYPE } from '@constants/order';

function Payment({
  goodsNm,
  goodsTypeCd,
  price,
  orderPrice,
  studentNm,
  orderNumber,
  orderId,
  diffDueDate,
  payStateCd,
  chips,
  overPrice,
  onSelect,
  bookType,
  issueYearmon,
  isVisible = true,
  isBook = false,
  isSale = true,
  isSubscribe = false,
  noStudentName = false,
}) {
  return (
    <li>
      <a
        onClick={() => onSelect(orderNumber || orderId)}
        className={classnames('paymentItem', !isSale && 'typeDeletedTextbook')}
      >
        {studentNm && <p className="textName">{studentNm || ''}</p>}
        {!noStudentName && !studentNm && <p className="textName">&nbsp;</p>}
        {isSubscribe && (
          <div className="textPaymentDetails">
            <div className="leftBox">
              <p className="textTitle">{goodsNm}</p>
            </div>
          </div>
        )}
        {!isSubscribe && (
          <>
            {goodsNm && <p className="textTitle">{goodsNm || ''}</p>}
            {goodsTypeCd && (
              <div className="statusBox">
                <span className="textSmall">
                  {bookType || PAYMENT_GOODS_TYPE[goodsTypeCd] || '-'}
                </span>
                {/* TODO: status에 따라서 텍스트 보여주기 */}
                {/* <span className="flagOutline tyCancel">해지 신청</span> */}
              </div>
            )}
            <div className="courseBox">
              <div className="tagCourse">
                {isSale && !isNull(diffDueDate) && (
                  <PaymentDueDate isBook={isBook} dueDate={diffDueDate} />
                )}
                {chips &&
                  chips.map((item, index) => (
                    <span
                      key={`${goodsNm}_${item}_${index}`}
                      className="tagRect"
                    >
                      {item}
                    </span>
                  ))}
                {payStateCd === BILL_PAY_STATE_CODE.CANCEL && (
                  <div className="tagPayment">
                    <span className="flagOutline tyPaymentCancel">
                      결제취소
                    </span>
                  </div>
                )}
                {payStateCd === BILL_PAY_STATE_CODE.DIRECT_PAY && (
                  <div className="tagPayment">
                    <span className="flagOutline tyPaymentDirect">
                      직접납부
                    </span>
                  </div>
                )}
                {!!issueYearmon && (
                  <span className="tagRect">{issueYearmon} 청구서</span>
                )}
              </div>
              {isVisible && (price === '0' || orderPrice === 0) && (
                <div className="textPrice">
                  <span className="txtNum">결제완료</span>
                </div>
              )}
              {price !== '0' && orderPrice !== 0 && (
                <div className="textPrice">
                  <span className="txtNum">
                    {toCommaNumber(price || orderPrice)}
                  </span>
                  <em className="txtUnit">원 {overPrice && '~'}</em>
                </div>
              )}
            </div>
          </>
        )}
      </a>
    </li>
  );
}

export default memo(Payment);
