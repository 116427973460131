import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';

import Input from './Input';

function FormPopupInput({ label, name, ...rest }) {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <strong className="label">{label}</strong>
      <div className="inputWrap">
        <span className={classnames('inputBox', errors[name] && 'error')}>
          <Input name={name} {...rest} />
        </span>
        {errors[name] && <p className="errorMessage">{errors[name].message}</p>}
      </div>
    </>
  );
}

export default FormPopupInput;
