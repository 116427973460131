import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useMount } from 'ahooks';

import { FormInput } from '@components/form';
import { useAuthContext } from '@context/AuthContext';
import { useAppContext } from '@context/AppContext';
import { clear } from '@store/slice/accountSlice';
import storage from '@utils/Storage';
import { Button } from '@components/ui';

function LoginPage() {
  // const [isMobile] = useState(getBrowserType() !== 'WEB');
  const [isMobile] = useState(false);
  const [loginFail, setLoginFail] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { login } = useAuthContext();

  const { isWinkDevice } = useAppContext();

  const methods = useForm({ defaultValues: { keepLogin: true } });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const submit = async ({ username, mobileNum, password, keepLogin }) => {
    // validation check
    if (!isEmpty(errors)) {
      return;
    }
    try {
      const result = await login({
        username,
        mobileNum,
        password,
        // keepLogin,
        redirect: '/home',
      });
      setLoginFail(!result);
      if (!result) {
        setValue('password', '');
      }
    } catch (_) {
      // 에러면 무조건 로그인 실패
      setLoginFail(true);
      setValue('password', '');
    }
  };

  const goFindPassword = () => {
    navigate('/confirm');
  };

  const goSignUp = () => {
    navigate('/parent');
  };

  useMount(() => {
    // 토큰이 있으면 사용자 정보 조회 하고 진행
    if (storage.hasSession()) {
      navigate('/home', { replace: true });
    } else {
      // store 정리 리셋
      dispatch(clear());
    }
  });

  return (
    <>
      {/* 모바일 앱이 아닌 경우에만 로그인 화면 보여주기 */}
      {!isMobile ? (
        <FormProvider {...methods}>
          <form className="contentBox loginBox" onSubmit={handleSubmit(submit)}>
            <>
              <div className="loginTitle">
                <span className="hidden">캐츠</span>
                <strong>학부모</strong>
              </div>
              <div className="fieldsetWrap">
                <ul className="fieldsetList">
                  <li>
                    <FormInput
                      label={false}
                      placeholder="이름*"
                      option={{ required: '이름을 입력해주세요.' }}
                      name="username"
                    />
                  </li>
                  <li>
                    <FormInput
                      type="number"
                      label={false}
                      placeholder="휴대폰 번호 11자리 -없이 입력*"
                      option={{ required: '휴대폰 번호를 입력해주세요.' }}
                      name="mobileNum"
                    />
                  </li>
                  <li>
                    <FormInput
                      type="password"
                      placeholder="비밀번호*"
                      option={{ required: '비밀번호를 입력해주세요.' }}
                      name="password"
                    />
                    {loginFail && (
                      <p style={{ display: 'block' }} className="errorMessage">
                        이름, 전화번호 또는 비밀번호를 잘못 입력하였습니다.
                        <br />
                        입력하신 내용을 다시 확인해주세요.
                      </p>
                    )}
                  </li>
                </ul>

                <div className="loginOption">
                  <Button.Normal
                    className="btnLink"
                    label="비밀번호 찾기"
                    onClick={goFindPassword}
                  />
                </div>
                <Button.Wrapper className="loginBtnBox">
                  <Button.Primary
                    large
                    type="submit"
                    label="로그인"
                    disabled={!isEmpty(errors)}
                  />
                </Button.Wrapper>
              </div>

              <div className="membershipBox">
                <p className="textTitle">처음 방문하셨나요?</p>
                <Button.Wrapper className="typeBlock">
                  <Button.Outline large label="회원가입" onClick={goSignUp} />
                </Button.Wrapper>
              </div>
              {!isWinkDevice && (
                <div className="boxContact">
                  <strong className="textTitle">문의하기</strong>
                  <a href="tel:1660-1244" className="btnCS">
                    <span>고객센터문의</span>
                  </a>
                </div>
              )}
            </>
          </form>
        </FormProvider>
      ) : (
        ''
      )}
    </>
  );
}

export default LoginPage;
