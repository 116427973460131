import { useMount, useUnmount } from 'ahooks';
import { useDispatch } from 'react-redux';

import { PopupAlarms } from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';
import { clear } from '@store/slice/alarmSlice';

// 알람 페이지에서 스크롤 상태를 유지하기 위해 새 페이지에서 팝업을 띄우기로 함
function AlarmPage() {
  const dispatch = useDispatch();
  const { showPopup } = useOverlayContext();

  useMount(() => {
    setTimeout(() => {
      showPopup(PopupAlarms);
    });
  });

  useUnmount(() => {
    dispatch(clear());
  });

  return <></>;
}

export default AlarmPage;
