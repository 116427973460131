import api from '@utils/Api';

class OrderService {
  constructor(client) {
    this.httpClient = client;
  }

  confirm(data, options) {
    return this.httpClient.post(`/payments/confirm`, data, options);
  }

  orderConfirm(data, options) {
    return this.httpClient.put(
      `/v1/payments/order/goods/confirm`,
      data,
      options,
    );
  }

  preorder(data, options) {
    return this.httpClient.post(`/v1/payments/order/preorder`, data, options);
  }

  pricecheck(data, options) {
    return this.httpClient.post(`/v1/payments/order/pricecheck`, data, options);
  }

  preorderBook(data, options) {
    return this.httpClient.post(
      `/v1/payments/order/order-link/order`,
      data,
      options,
    );
  }

  autoPay(data, options) {
    return this.httpClient.put(`/v1/payments/order/auto-pay`, data, options);
  }

  refund({ orderId, ...data }, options) {
    return this.httpClient.post(
      `/v1/payments/order/${orderId}/change`,
      {
        ...data,
        orderId,
      },
      options,
    );
  }

  orderCancel(
    { memberNo, orderId, orderGoodsIds, reason, reasonDetail },
    options,
  ) {
    return this.httpClient.post(
      `/v1/payments/order/goods/cancel`,
      { memberNo, orderId, orderGoodsIds, reason, reasonDetail },
      options,
    );
  }

  exangeCancel({ memberNo, orderId, orderGoodsIds, type }, options) {
    return this.httpClient.delete(
      `/v1/payments/order/${orderId}/change?memberNo=${memberNo}&orderGoodsIds=${orderGoodsIds}&type=${type}`,
      {},
      options,
    );
  }

  refundCancel({ memberNo, orderId, orderGoodsIds, type }, options) {
    return this.httpClient.delete(
      `/v1/payments/order/${orderId}/change?memberNo=${memberNo}&orderGoodsIds=${orderGoodsIds}&type=${type}`,
      {},
      options,
    );
  }

  getHistoryList({ parentNo, type }, options) {
    return this.httpClient.get(
      `/v1/payments/order/${parentNo}/order`,
      {
        type,
      },
      options,
    );
  }

  getHistoryDetail({ parentNo, orderNumber, type }, options) {
    return this.httpClient.get(
      `/v1/payments/order/${parentNo}/order/${orderNumber}`,
      {
        type,
      },
      options,
    );
  }

  getWatingList(
    { parentNo, memberName, memberTel, type, startYearmon, endYearmon },
    options,
  ) {
    return this.httpClient.get(
      `/v1/payments/order/${parentNo}/waiting`,
      {
        memberName,
        memberTel,
        type,
        startYearmon,
        endYearmon,
      },
      options,
    );
  }

  getBookLink({ linkId }, options) {
    return this.httpClient.get(
      `/v1/payments/order/order-link/${linkId}`,
      {},
      options,
    );
  }

  getWatingDetail(
    { parentNo, orderNumber, orderRequestLinkId, type },
    options,
  ) {
    return this.httpClient.get(
      `/v1/payments/order/${parentNo}/waiting/${orderNumber}`,
      {
        orderRequestLinkId,
        type,
      },
      options,
    );
  }

  getOnline(_, options) {
    return this.httpClient.get('/v1/payments/order/subscribe', options);
  }

  getCheckOnline({ courseCd, subjectCd, memberNo, studentNo }, options) {
    return this.httpClient.get(
      `/v1/payments/order/subscribe/${courseCd}/${subjectCd}`,
      { memberNo, studentNo },
      options,
    );
  }

  getOnlineSubscribe({ memberNo }, options) {
    return this.httpClient.get(`/v1/payments/subscribe/${memberNo}`, options);
  }

  getAcademySubscribe({ memberNo }, options) {
    return this.httpClient.get(
      `/v1/payments/order/auto-pay/management/${memberNo}`,
      options,
    );
  }

  checkRefund({ memberNo, orderId, orderGoodsId }, options) {
    return this.httpClient.get(
      `/v1/payments/order/goods/refund/${memberNo}/${orderId}/${orderGoodsId}?orderEa=1`,
      options,
    );
  }

  removeOrder({ memberNo, cartId }, options) {
    return this.httpClient.delete(
      `/v1/payments/cart/${memberNo}/${cartId}`,
      options,
    );
  }

  billingTerms({ memberNo, scope }, options) {
    return this.httpClient.get(
      `/v1/payments/manage/agree-terms/${memberNo}?scope=${scope}`,
      options,
    );
  }

  requestBilling({ memberNo, billId }, options) {
    return this.httpClient.post(
      `/v1/payments/manage/subscribe/payments/${billId}?memberNo=${memberNo}`,
      options,
    );
  }

  // 단과상품 구독해지
  unsubscribeOnline({ subscriptionContractId }, options) {
    return this.httpClient.post(
      `/v1/payments/order/cancel-subscribe?subscriptionContractId=${subscriptionContractId}`,
      options,
    );
  }

  unsubscribeCancelOnline({ orderId, memberNo }, options) {
    return this.httpClient.delete(
      `/v1/payments/subscribe/${orderId}/unsubscribe/${memberNo}`,
      options,
    );
  }

  cancelAutoPay({ academyId, billId, studentId, autoPayYn = 'N' }, options) {
    return this.httpClient.put(
      `/v1/payments/order/auto-pay`,
      { academyId, billId, studentId, autoPayYn },
      options,
    );
  }

  requestTrial({ memberNo, ...rest }, options) {
    return this.httpClient.post(
      `/v1/payments/manage/subscribes/free-trial/${memberNo}`,
      { memberNo, ...rest },
      options,
    );
  }
}

const orderService = new OrderService(api);
export default orderService;
