import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';
import { addressSelector, getAddress, select } from '@store/slice/orderSlice';
import { accountSelector } from '@store/slice/accountSlice';
import { toPhoneNumber } from '@helper/commonHelper';
import deliveryService from '@service/DeliveryService';

import PopupAddress from './PopupAddress';
import Popup from './base/Popup';
import BaseModal from '../modal/base/BaseModal';

function PopupSelectAddress({ id, targetId, ...rest }) {
  const dispatch = useDispatch();

  const { memberNo } = useSelector(accountSelector);
  const { address, hasNext } = useSelector(addressSelector);

  const { showPopup, closePopup, showDialog, closeModal } = useOverlayContext();

  const handleSelect = (item) => {
    dispatch(select(item));
    closePopup(id);
  };

  const handleDelete = ({ addressId }) => {
    const fetchRemove = async () => {
      await deliveryService.remove({ memberNo, addressId });
      dispatch(getAddress({ memberNo }));
      closeModal();
    };

    showDialog(BaseModal, {
      title: '알림',
      content: '해당 주소를 삭제하시겠습니까?',
      buttons: [
        {
          label: '아니오',
          action: () => closeModal(),
          className: 'btnOutline',
        },
        {
          label: '네',
          action: () => fetchRemove(),
          className: 'btnPrimary',
        },
      ],
    });
  };
  const handleEdit = (item) => {
    showPopup(PopupAddress, { delivery: item });
    setTimeout(() => {
      // 팝업은 하나만 띄우기
      closePopup(id);
    }, 300);
  };

  const showAddressPopup = () => {
    showPopup(PopupAddress);
    // 팝업 화면이 뜨는 시간이 있음 그 이후에 닫아야 함
    setTimeout(() => {
      // 팝업은 하나만 띄우기
      closePopup(id);
    }, 300);
  };

  return (
    <Popup {...rest}>
      <Popup.Header id={id} hasClose>
        배송지 선택
      </Popup.Header>
      <Popup.Body className="boxShippingAddress">
        <div className="boxFlexGrow">
          <p className="boxTitle">배송지 선택하기</p>
          <ul className="addressSelection">
            {address.map((item, index) => (
              <li key={`address_${index}`}>
                <label className="radioBox">
                  <input
                    type="radio"
                    name="address"
                    defaultChecked={targetId === item.addressId}
                    onClick={() => handleSelect(item)}
                  />
                  <span className="labelText">
                    <div className="textTitle">
                      <em>{item.title}</em>
                      {item.isDefaultYn === 'Y' && (
                        <strong className="flagDefault">기본배송지</strong>
                      )}
                    </div>
                    <p className="textRow">
                      {item.addr} ({item.post})
                    </p>
                    <p className="textRow">{toPhoneNumber(item.mobile)}</p>
                  </span>
                </label>
                <div className="btnArea">
                  <Button.Normal
                    className="btnTxt"
                    label="수정"
                    onClick={() => handleEdit(item)}
                  />
                  {item.isDefaultYn === 'N' && (
                    <Button.Normal
                      className="btnTxt"
                      label="삭제"
                      onClick={() => handleDelete(item)}
                    />
                  )}
                </div>
              </li>
            ))}
          </ul>

          <Button.Wrapper className="typeBlock">
            {hasNext && (
              <Button.Normal className="btnMore" large label="더보기" />
            )}
            {/* <Button.Normal className="btnMore typeFold" large label="닫기" /> */}
          </Button.Wrapper>
        </div>

        <Button.Wrapper flexNone>
          <Button.Primary
            large
            label="배송지 추가"
            onClick={showAddressPopup}
          />
        </Button.Wrapper>
      </Popup.Body>
    </Popup>
  );
}

export default PopupSelectAddress;
