import { useMemo } from 'react';
import classnames from 'classnames';

function PayDueDate({ dueDate, isBook }) {
  const isHighlight = useMemo(() => {
    const day = Number(dueDate);
    return day > -3;
  }, [dueDate]);

  return (
    <>
      {dueDate > 0 && (
        <span className="flagFilled tyUnpaid">
          {isBook ? `D+${dueDate}` : '미납'}
        </span>
      )}
      {dueDate <= 0 && (
        <span
          className={classnames(
            isHighlight ? 'flagFilled' : 'flagOutline',
            'tyDday',
          )}
        >
          {dueDate === 0 ? 'D-DAY' : `D${dueDate}`}
        </span>
      )}
    </>
  );
}

export default PayDueDate;
