import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import academyService from '@service/AcademyService';
import { isNil } from 'lodash';

export const getNotice = createAsyncThunk(
  'notice/getNotice',
  async ({ memberNo, pageNo }, { getState }) => {
    const {
      notice: { pageNo: statePageNo },
    } = getState();
    const page = isNil(pageNo) ? statePageNo + 1 : pageNo;

    const response = await academyService.notice({
      memberId: memberNo,
      page,
    });
    return response;
  },
);

export const getDetail = createAsyncThunk(
  'notice/getDetail',
  async ({ notificationId, memberNo }, { getState }) => {
    const response = await academyService.noticeDetail({
      notificationId,
      memberId: memberNo,
    });
    return response;
  },
);

const initialState = {
  notice: [],
  noticeDetail: {},
  pageNo: 0,
  hasNext: false,
  isFetch: false,
};

export const noticeSlice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    clear: (state) => {
      state.students = [];
      state.notice = [];
      state.noticeDetail = {};
      state.pageNo = 0;
      state.hasNext = false;
      state.isFetch = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotice.fulfilled, (state, action) => {
        const { list, pageNum, hasNextPage } = action.payload;

        state.notice = [...state.notice, ...list];
        state.pageNo = pageNum;
        state.hasNext = hasNextPage;
        state.isFetch = true;
      })
      .addCase(getDetail.fulfilled, (state, action) => {
        state.noticeDetail = action.payload;
      });
  },
});

const noticeState = (state) => {
  return {
    notice: state.notice.notice,
    hasNext: state.notice.hasNext,
    pageNo: state.notice.pageNo,
    isFetch: state.notice.isFetch,
  };
};
export const noticeSelector = createSelector(noticeState, (state) => state);

export const noticeDetailSelector = createSelector(
  noticeState,
  (state) => state.notice.noticeDetail,
);

export const { clear } = noticeSlice.actions;

export default noticeSlice.reducer;
