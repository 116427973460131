import { useNavigate } from 'react-router-dom';

import { Button, PaymentDueDate } from '@components/ui';
import { DefinitionBoxOrder, DefinitionBoxPayment } from '@components/box';
import { format, KO_YEAR_DATE_INCLUDE_WEEK } from '@helper/timeHelper';
import { toCommaNumber } from '@helper/commonHelper';

import Modal from './base/Modal';

// 결재대기 > 학원결제 바텀시트
function ModalOrderAcademy({ billing, orderNumber, ...rest }) {
  const navigate = useNavigate();

  const goOrder = () => {
    navigate(`order-academy/${orderNumber}`);
  };

  return (
    <Modal isBasic {...rest}>
      <Modal.Header hasClose>
        {format(billing.issueYearmon, 'MM')}월 청구서
      </Modal.Header>
      <Modal.Body className="popBill">
        {billing && (
          <>
            <DefinitionBoxOrder
              category={[
                { name: '상호명', topic: billing.academyName },
                { name: '클래스명', topic: billing.className },
                { name: '자녀 이름', topic: billing.studentName || '-' },
                {
                  name: '납부 마감일',
                  topic: (
                    <>
                      {format(billing.payEndDate, KO_YEAR_DATE_INCLUDE_WEEK)}
                      <PaymentDueDate dueDate={billing.diffDueDate} />
                    </>
                  ),
                },
              ]}
            />

            <DefinitionBoxPayment
              isModal
              isSmall
              upper
              title="결제내역"
              amount={billing.payAmount}
              category={[
                { name: '교습비', topic: billing.lessonFee },
                { name: '기타경비', topic: billing.chargeEtc },
                { name: '할인', topic: billing.discount },
              ]}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {billing && (
          <Button.Primary
            onClick={goOrder}
            label={`${toCommaNumber(billing.payAmount)}원 결제`}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalOrderAcademy;
