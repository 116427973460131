/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import dayjs from 'dayjs';

import { classSelector, studentSelector } from '@store/slice/accountSlice';
import { useNavigate } from 'react-router-dom';

function AcademyReportItem({
  reportSendId,
  classId,
  studentId,
  reportSendDate,
  logo,
}) {
  const navigate = useNavigate();

  const classes = useSelector(classSelector);
  const { academyName, className } =
    classes.find((item) => item.classId === classId) || {};

  const { memberName } = useSelector(studentSelector(studentId));

  const isNewReport = useMemo(() => {
    return dayjs().diff(dayjs(reportSendDate), 'hour') < 24;
  }, [reportSendDate]);

  const goDetail = (id) => {
    navigate(`/learning-report-academy/${id}`);
  };

  return (
    <li>
      <a className="linkLearningReport" onClick={() => goDetail(reportSendId)}>
        <div className="boxClassInfo">
          <span className={classnames('logoImg', !logo && 'noImg')}>
            {logo && (
              <img src="../../public/images/temp/temp_manage_logo3.png" />
            )}
          </span>
          <p className="txtAcademy">
            <span>{academyName}</span>
            {isNewReport && (
              <span className="icoNew">
                <em>New</em>
              </span>
            )}
          </p>
          <p className="txtClass">{className}</p>
          <p className="txtName">{memberName}</p>
        </div>
      </a>
    </li>
  );
}

export default AcademyReportItem;
