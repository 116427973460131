import { MEMBER_TYPE_CODE } from '@constants/common';
import api from '@utils/Api';

class AcademyService {
  constructor(client) {
    this.httpClient = client;
  }

  // 출석
  attendance({ academyId, studentId, page, pageSize = 20 }, options = {}) {
    return this.httpClient.get(
      '/v1/academy/class-attendance/list',
      { academyId, studentId, page, pageSize },
      options,
    );
  }

  // 입회 테스트 목록
  diagnosis({ classYn, studentId, page, pageSize = 20 }, options = {}) {
    return this.httpClient.get(
      '/v1/academy/counseling/level-diagnosis-grades-student-list',
      { classYn, studentId, page, pageSize },
      options,
    );
  }

  // 입회 테스트
  diagnosisDetail({ academyId, studentId, teacherId }, options = {}) {
    return this.httpClient.get(
      '/v1/academy/counseling/level-diagnosis',
      { academyId, studentId, teacherId },
      options,
    );
  }

  notice({ memberId, page, pageSize = 20 }, options = {}) {
    return this.httpClient.get(
      '/v1/hbm/notifications',
      { memberId, page, pageSize },
      options,
    );
  }

  noticeDetail({ memberId, notificationId }, options = {}) {
    return this.httpClient.get(
      `/v1/hbm/notifications/${notificationId}`,
      { memberId },
      options,
    );
  }

  addComment(
    {
      memberId,
      memberName,
      notificationCommentContents,
      notificationId,
      parentsNotificationCommentId,
      secretYn = 'N',
      commentMemberType = MEMBER_TYPE_CODE.PARENTS,
    },
    options,
  ) {
    return this.httpClient.post(
      '/v1/hbm/notifications/comment',
      {
        memberId,
        memberName,
        notificationCommentContents,
        notificationId,
        parentsNotificationCommentId,
        secretYn,
        commentMemberType,
      },
      options,
    );
  }

  modifyComment(
    {
      memberId,
      notificationId,
      notificationCommentId,
      notificationCommentContents,
      secretYn,
    },
    options,
  ) {
    return this.httpClient.put(
      '/v1/hbm/notifications/comment',
      {
        memberId,
        notificationCommentContents,
        notificationId,
        notificationCommentId,
        secretYn,
      },
      options,
    );
  }

  deleteComment({ notificationId, notificationCommentId, memberId }, options) {
    return this.httpClient.delete(
      `/v1/hbm/notifications/${notificationId}/${notificationCommentId}?memberId=${memberId}`,
      {},
      options,
    );
  }

  consultation({ parentsId, page = 1, pageSize = 1000 }, options = {}) {
    return this.httpClient.get(
      `/v1/hbm/counsl-reserv/parents-request/${parentsId}`,
      {
        page,
        pageSize,
      },
      options,
    );
  }

  confirmConsultation(
    { parentsId, counslReservDateAfter, page = 1, pageSize = 1000 },
    options = {},
  ) {
    return this.httpClient.get(
      `/v1/hbm/counsl-reserv/${parentsId}`,
      {
        page,
        pageSize,
        counslReservDateAfter,
      },
      options,
    );
  }

  reserve(
    {
      parentsId,
      studentId,
      teacherId,
      counslReservCategoryCd,
      academyNo: academyId,
      classNo: classId,
    },
    options,
  ) {
    return this.httpClient.post(
      `/v1/hbm/counsl-reserv`,
      {
        academyId,
        classId,
        parentsId,
        studentId,
        teacherId,
        counslReservCategoryCd,
      },
      options,
    );
  }
}

const academyService = new AcademyService(api);
export default academyService;
