/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useMount } from 'ahooks';

import { PaymentOnlineLearning } from '@components/payment';
import orderService from '@service/OrderService';
import { useAuthContext } from '@context/AuthContext';
import storage from '@utils/Storage';

// 온라인 구독 상품 목록
function OnlineLearningPage() {
  const [online, setOnline] = useState([]);
  const [academy, setAcademy] = useState([]);

  const { refreshAccount } = useAuthContext();

  useEffect(() => {
    const fetch = async () => {
      const result = await orderService.getOnline();
      const products = result.map((item) => ({
        ...item,
        price: item.minPrice,
        overPrice: item.minPrice !== item.maxPrice,
      }));
      setOnline(products.filter((item) => item.subjectCd === 'ALL'));
      setAcademy(products.filter((item) => item.subjectCd !== 'ALL'));
    };

    fetch();
  }, []);

  useMount(() => {
    refreshAccount({ id: storage.getMemberNo() });
  });

  return (
    <div className="contentBox boxOnlineProducts">
      <div className="definitionBox typeFull">
        <div className="prdTitleBox">
          <p>
            <b>전국 최다 도입 프로그램 </b> ‘캐츠홈’ 1:1 맞춤학습
          </p>
        </div>

        <ul className="listItems">
          {online.map((item, index) => (
            <PaymentOnlineLearning
              key={`all_${index}`}
              {...item}
              goodsNm={
                <>
                  {item.title} <b>{item.subjectNm}</b>
                </>
              }
              noStudentName
            />
          ))}
        </ul>
      </div>

      {academy.length > 0 && (
        <div className="definitionBox typeSubject">
          <div className="prdTitleBox">
            <p>
              <b>과목별 학원선생님용</b> 학습이 필요하다면,
            </p>
          </div>

          <ul className="listItems">
            {academy
              .filter((item) => item.subjectCd !== 'ALL')
              .map((item, index) => (
                <PaymentOnlineLearning
                  key={`subject_${index}`}
                  {...item}
                  goodsNm={
                    <>
                      {item.title} <b>{item.subjectNm}</b>
                    </>
                  }
                />
              ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default OnlineLearningPage;
