import classnames from 'classnames';

import useScroll from '@hooks/useScroll';

function GoTop() {
  const { isTop } = useScroll({});

  const handleGoTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <div className={classnames('goToBtn', isTop && 'hide')}>
      <button type="button" className="btnGoTop" onClick={handleGoTop}>
        <span>맨 위로</span>
      </button>
    </div>
  );
}

export default GoTop;
