import withPrivate from '@hoc/withPrivate';
import academyRouteConfig from './private/academyRouteConfig';
import homeRouteConfig from './private/homeRouteConfig';
import learningRouteConfig from './private/learningRouteConfig';
import myRouteConfig from './private/myRouteConfig';
import onlineRouteConfig from './private/onlineRouteConfig';
import orderRouteConfig from './private/orderRouteConfig';
import paymentRouteConfig from './private/paymentRouteConfig';
import paymentManageRouteConfig from './private/paymentManageRouteConfig';
import reportRouteConfig from './private/reportRouteConfig';

const privateRouteConfig = [
  ...homeRouteConfig,
  ...academyRouteConfig,
  ...learningRouteConfig,
  ...paymentRouteConfig,
  ...orderRouteConfig,
  ...onlineRouteConfig,
  ...paymentManageRouteConfig,
  ...reportRouteConfig,
  ...myRouteConfig,
].map((item) => {
  const Component = withPrivate(item.element);

  return {
    ...item,
    element: <Component />,
  };
});

export default privateRouteConfig;
