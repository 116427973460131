/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useState } from 'react';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';
import { loadBrandPay } from '@tosspayments/brandpay-sdk';

import { useOverlayContext } from '@context/OverlayContext';
import { PopupOrderFailed, PopupOrderSuccess } from '@components/overlay';
import orderService from '@service/OrderService';
import homeService from '@service/HomeService';
import paymentService from '@service/PaymentService';
import { PAYMENT_TYPE } from '@constants/tab';

// TODO: Path 값 normal / culture 변경 필요
const ACCESS_TOKEN_REDIRECT_URL = `${process.env.REACT_APP_PUBLIC_API_BASE_URL}payments/authorizations/access-token/normal`;

function useBrandPay({ memberNo, loadCard = false }) {
  const { showPopup, showToast } = useOverlayContext();

  const [billingCard, setBillingCard] = useState({});
  const [cardList, setCardList] = useState([]);

  const loadBillingCard = async () => {
    try {
      const result = await paymentService.brandPayCard({ memberNo });
      setBillingCard(result.data);
    } catch (error) {
      console.warn(error);
    }
  };

  const loadCardList = async () => {
    try {
      const result = await paymentService.brandPayCards({ memberNo });
      setCardList(result);
    } catch (error) {
      console.warn(error);
    }
  };

  const changeMethod = async ({ methodKey }, ignore = false) => {
    if (methodKey === billingCard?.methodKey) {
      showToast({ message: '이미 설정된 결제수단입니다.' });
      return;
    }
    await paymentService.changeMethodBrandPay({
      memberNo,
      methodKey,
    });
    await loadBillingCard();
    await loadCardList();
    // 온라인 구독학습은 결제가 바로 진행됨
    if (!ignore) {
      showToast({ message: '변경된 카드가 반영되었습니다.' });
    }
  };

  const startBrandPay = () => {
    return loadBrandPay(process.env.REACT_APP_BRAND_PAY_NORMAL, memberNo, {
      redirectUrl: ACCESS_TOKEN_REDIRECT_URL,
      ui: {
        highlightColor: '#26C2E3',
        buttonStyle: 'full',
        labels: {
          oneTouchPay: '내 상점 원터치결제',
        },
      },
      windowTarget: 'iframe',
    });
  };

  const addPaymentMethod = async () => {
    try {
      const brandPay = await startBrandPay({ memberNo });
      await brandPay.addPaymentMethod('카드');

      // 카드가 등록된 이후 카드정보 다시 가져오기
      await loadBillingCard();
      await loadCardList();
    } catch (error) {
      if (error.code === 'USER_CANCEL') {
        console.warn('결제 고객이 결제창을 닫았을 때 에러');
        return;
      }

      // 일반적인 오류
      showPopup(PopupOrderFailed, { error });
    }
  };

  const deletPaymentMethod = async ({ methodKey }) => {
    try {
      await paymentService.deleteMethodBrandPay({ memberNo, methodKey });
      await loadBillingCard();
      await loadCardList();
      showToast({ message: '카드가 삭제되었습니다.' });
    } catch (error) {
      console.warn(error);
    }
  };

  const requestAgreement = async ({ brandPay, scope }) => {
    // 브랜드 페이가 로드 안되어 있으면
    if (isEmpty(brandPay)) {
      brandPay = await startBrandPay({ memberNo });
    }

    const terms = await orderService.billingTerms({
      memberNo,
      scope,
    });
    for (const item of terms) {
      await brandPay.requestAgreement(item);
    }
  };

  const requestExchangeCost = async ({ amount, orderPaymentId, orderName }) => {
    try {
      const brandPay = await startBrandPay({ memberNo });

      // 약관 동의
      await requestAgreement({
        brandPay,
        memberNo,
        scope: 'CARD',
      });

      const param = {
        amount,
        orderName,
        orderId: orderPaymentId,
      };

      const requestPayment = await brandPay.requestPayment(param);

      await orderService.confirm(
        {
          memberNo,
          confirms: [
            {
              cultureTaxDeductionRequestYn: 'N', // 문화소득공제
              amount: requestPayment.amount,
              orderPaymentId: requestPayment.orderId,
              paymentKey: requestPayment.paymentKey,
            },
          ],
        },
        {
          ignore: true,
        },
      );

      showPopup(PopupOrderSuccess, {
        order: { amount, paymentType: PAYMENT_TYPE.EXCHANGE },
      });
    } catch (error) {
      if (error.code === 'USER_CANCEL') {
        console.warn('결제 고객이 결제창을 닫았을 때 에러');
        return;
      }

      // 일반적인 오류
      showPopup(PopupOrderFailed, { error });
    }
  };

  const requestBilling = async (
    billingParam,
    goodsInfo,
    methodId,
    isBook = false,
  ) => {
    try {
      const preOrder = isBook
        ? await orderService.preorderBook(billingParam)
        : await orderService.preorder(billingParam);

      const brandPay = await startBrandPay({ memberNo: billingParam.memberNo });

      // 약관 동의
      await requestAgreement({
        brandPay,
        memberNo: billingParam.memberNo,
        scope: billingParam.scope,
      });

      const param = {
        methodId,
        amount: preOrder.orderPaymentDtoList?.[0].amount,
        orderId: preOrder.orderPaymentDtoList?.[0].orderPaymentId,
        orderName: preOrder.orderPaymentDtoList?.[0].orderName,
      };

      const requestPayment = await brandPay.requestPayment(param);

      await orderService.confirm(
        {
          memberNo: billingParam.memberNo,
          confirms: [
            {
              cultureTaxDeductionRequestYn: 'N', // 문화소득공제
              amount: requestPayment.amount,
              orderPaymentId: requestPayment.orderId,
              paymentKey: requestPayment.paymentKey,
            },
          ],
        },
        {
          ignore: true,
        },
      );

      // 피드 숨기기
      await homeService.hidden({
        parentNo: billingParam.memberNo,
        studentNo: billingParam.studentNo,
        feedLinkKey: billingParam.feedLinkKey,
        type: billingParam.type,
      });

      showPopup(PopupOrderSuccess, {
        order: { ...goodsInfo, orderName: param.orderName },
      });
    } catch (error) {
      if (error.code === 'USER_CANCEL') {
        console.warn('결제 고객이 결제창을 닫았을 때 에러');
        return;
      }

      // 일반적인 오류
      showPopup(PopupOrderFailed, {
        error,
        message:
          error.response?.data?.resultMsg?.replace(/^.*\||\[.*?\]/g, '') || '',
      });
    }
  };

  useMount(() => {
    if (loadCard) {
      loadBillingCard();
      loadCardList();
    }
  });

  return {
    billingCard,
    cardList,
    setBillingCard,
    setCardList,
    loadBillingCard,
    loadCardList,
    changeMethod,
    startBrandPay,
    requestAgreement,
    addPaymentMethod,
    deletPaymentMethod,
    requestExchangeCost,
    requestBilling,
  };
}

export default useBrandPay;
