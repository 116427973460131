import { Navigate } from 'react-router-dom';

import { Redirect } from '@components/common';
import { HEADER_TYPE } from '@constants/layout';
import {
  Login,
  Parent,
  ResetPassword,
  ConfirmUser,
  AddChild,
  Error,
  Success,
} from '@pages';

const publicRoutingConfig = [
  {
    path: 'login',
    element: <Login />,
    option: {
      name: '로그인',
      headerType: HEADER_TYPE.NONE,
    },
  },
  {
    path: 'confirm',
    element: <ConfirmUser />,
    option: {
      name: '비밀번호 찾기',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'reset',
    element: <ResetPassword />,
    option: {
      name: '비밀번호 재설정',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'parent',
    element: <Parent />,
    option: {
      name: '학부모/자녀 회원가입',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'child-add',
    element: <AddChild />,
    option: {
      name: '학부모/자녀 회원가입',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'child-link',
    element: <AddChild />,
    option: {
      name: '자녀계정 만들기',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'child-integrated',
    element: <AddChild />,
    option: {
      name: '자녀계정 만들기',
      headerType: HEADER_TYPE.TITLE,
    },
  },
  {
    path: 'child-success',
    element: <Success />,
    option: {
      name: '학부모/자녀 회원가입',
      headerType: HEADER_TYPE.DEFAULT,
    },
  },
  {
    path: '/error',
    element: <Error />,
    option: {
      name: '에러',
      headerType: HEADER_TYPE.NONE,
    },
  },
  {
    path: '/redirect',
    element: <Redirect />,
  },
  {
    path: '*',
    element: <Navigate to="/error" />,
  },
];

export default publicRoutingConfig;
