import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';

import { BOARD } from '@constants/board';
import { DATE_TIME, format } from '@helper/timeHelper';
import boardService from '@service/BoardService';

function NoticeDetailPage() {
  const { id } = useParams();

  const [post, setPost] = useState();

  useMount(() => {
    (async () => {
      const result = await boardService.getPost({
        boardId: BOARD.NOTICE,
        postId: id,
      });

      setPost(result);
    })();
  });
  return (
    <div className="contentBox boxBoardDetails">
      {!isEmpty(post) && (
        <>
          <div className="boardTop typeNotice">
            <div className="rowTitle">
              <p>{post.postTitle}</p>
            </div>
            <div className="rowOther">
              <span className="textDate">
                {format(post.regDate, DATE_TIME)}
              </span>
            </div>
          </div>

          <div
            className="boardContent"
            dangerouslySetInnerHTML={{ __html: post.postDesc }}
          />

          <div className="boardAttachment">
            {post.fileList && post.fileList.length > 0 && (
              <ul className="fileList">
                {post.fileList.map((file) => (
                  <li key={`file_${file.url}`}>
                    <Link
                      to={file.url}
                      target="_blank"
                      className="btnfile"
                      download
                    >
                      <div className="innerBtnBox">
                        <span className="fileName">{file.orgFileNm}</span>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default NoticeDetailPage;
