import { useDispatch } from 'react-redux';
import { useMount } from 'ahooks';

import { viewHomePopup } from '@store/slice/accountSlice';

import Modal from './base/Modal';

function ModalFirstLogin({ ...rest }) {
  const dispatch = useDispatch();

  const handleFirstLogin = (value) => {
    localStorage.setItem('noMorePopup', value ? 'Y' : '');
  };

  useMount(() => {
    dispatch(viewHomePopup());
  });

  return (
    <Modal {...rest} isTop className="typeNoMore mainFirstEntry" isBasic>
      <Modal.Body {...rest} className="popFirstEntry" isClose>
        <div className="boxTitle">
          <p>안녕하세요 학부모님,</p>
          <p>
            캐츠 맞춤학습 서비스에
            <br /> 가입해 주셔서 감사합니다.
          </p>
        </div>
        <div className="boxContents">
          <p>
            이제 곧 우리 자녀를 위한 맞춤 학습을 제공해 드릴 예정입니다. 자녀가
            학습에 로그인할 수 있도록 안내해 주세요.
          </p>

          <p>
            자녀의
            <b> 학습현황 및 결과는 본 사이트에서 확인</b>하실 수 있으며, 자녀는
            가입하신 상품에 따라
            <b> 안내 받으신 디바이스로 로그인 후, 학습을 시작</b>할 수 있습니다.
          </p>
        </div>
      </Modal.Body>
      <div className="popNeverSeeAgain">
        <label className="checkBox">
          <input
            type="checkbox"
            onChange={(e) => handleFirstLogin(e.target.checked)}
          />
          <span className="labelText">
            <em>더 이상 보지 않기</em>
          </span>
        </label>
      </div>
    </Modal>
  );
}

export default ModalFirstLogin;
