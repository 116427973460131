import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { Button } from '@components/ui';
import { FormPopupInput, FormDelivery } from '@components/form';
import { accountSelector } from '@store/slice/accountSlice';
import { getAddress } from '@store/slice/orderSlice';
import { useOverlayContext } from '@context/OverlayContext';
import deliveryService from '@service/DeliveryService';

import Popup from './base/Popup';

function PopupAddress({ id, delivery = {}, ...rest }) {
  const dispatch = useDispatch();
  const { memberNo } = useSelector(accountSelector);

  const { closePopup } = useOverlayContext();
  const methods = useForm({
    defaultValues: {
      ...delivery,
      isDefaultYn: true,
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const submit = (formValue) => {
    const fetchUpdate = async () => {
      formValue = {
        ...formValue,
        memberNo,
        isDefaultYn: formValue.isDefaultYn ? 'Y' : 'N',
      };
      try {
        // 추가 / 수정 구분
        if (isEmpty(delivery)) {
          await deliveryService.add(formValue);
        } else {
          await deliveryService.update(formValue);
        }
        // 변경된 것 알리기
        dispatch(getAddress({ memberNo }));
        closePopup(id);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchUpdate();
  };

  return (
    <Popup {...rest}>
      <Popup.Header id={id} hasClose>
        배송지 입력
      </Popup.Header>
      <Popup.Body className="boxShippingAddress">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submit)}>
            <div className="boxFlexGrow">
              <ul className="formEnterAddress">
                <li>
                  <FormPopupInput
                    label="배송지명"
                    name="title"
                    maxLength="20"
                    placeholder="20자 이내로 입력"
                    option={{
                      required: '배송지명을 입력해주세요.',
                    }}
                  />
                </li>
                <li>
                  <FormPopupInput
                    label="수령인"
                    name="name"
                    placeholder="30자 이내로 입력"
                    maxLength="30"
                    option={{
                      required: '수령인을 입력해주세요.',
                    }}
                  />
                </li>
                <li>
                  <FormPopupInput
                    label="연락처"
                    name="mobile"
                    type="number"
                    option={{
                      required: '연락처를 입력해주세요.',
                    }}
                  />
                </li>
                <li>
                  <FormDelivery />
                </li>
              </ul>
            </div>
            <Button.Wrapper flexNone>
              <Button.Primary
                disabled={!isEmpty(errors)}
                type="submit"
                large
                label="입력"
              />
            </Button.Wrapper>
          </form>
        </FormProvider>
      </Popup.Body>
    </Popup>
  );
}

export default PopupAddress;
