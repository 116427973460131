import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Alarm from './base/Alarm';

function AlarmNotice({ id, noticeLinkKey, ...rest }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/notice?id=${noticeLinkKey}`);
  };
  return <Alarm {...rest} onClick={handleClick} />;
}

export default memo(AlarmNotice);
