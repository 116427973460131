/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { Button } from '@components/ui';
import { DefinitionBoxPayment, OnlineInfoBox } from '@components/box';
import { format } from '@helper/timeHelper';
import { getCardInfo, getServicePeriod } from '@helper/billingHelper';
import { toCommaNumber } from '@helper/commonHelper';

import Modal from './base/Modal';

function ModalHistoryOnline({ payment, hasClose, ...rest }) {
  const navigate = useNavigate();

  const goManage = () => {
    navigate('/payment-manage/online');
  };

  const order = useMemo(() => {
    return payment?.orderGoodsDtoList?.[0] || payment;
  }, [payment]);

  const billing = useMemo(() => {
    return payment?.orderPayDtoList?.[0];
  }, [payment]);

  // TODO: 나중에 코드로 받아야 함 & 무료 회원 구분
  const isWinkMember = useMemo(() => {
    return payment.orderPaymentAmount === 0;
  }, [payment.orderPaymentAmount]);

  return (
    <Modal isBasic className="typePaymentDetails" {...rest}>
      <Modal.Header hasClose>결제내역</Modal.Header>
      <Modal.Body className="popPaymentDetails">
        <OnlineInfoBox isWinkMember={isWinkMember} {...order} />

        <DefinitionBoxPayment
          isModal
          isSmall
          isWinkMember={isWinkMember}
          className="typePaymentInfo"
          category={[
            {
              name: '결제정보',
              topic: [
                { subName: '자녀이름', value: order.studentName },
                { subName: '구독기간', value: getServicePeriod(order) },
              ],
            },
            !isWinkMember
              ? {
                  name: `결제금액`,
                  topic: [
                    {
                      subName: '정가',
                      value: `${toCommaNumber(payment.orderPrice)}원`,
                    },
                    {
                      hidden: payment.orderCouponUsePrice === 0,
                      subName: '쿠폰할인',
                      value: `-${toCommaNumber(payment.orderCouponUsePrice)}원`,
                    },
                    {
                      cost: true,
                      subName: '결제금액',
                      value: `${toCommaNumber(payment.orderPaymentAmount)}원`,
                    },
                  ],
                }
              : { topic: [] },
            !isWinkMember
              ? {
                  name: '결제상세',
                  topic: [
                    {
                      subName: '결제일시',
                      value: format(payment.paymentDate) || '-',
                    },
                    {
                      subName: '결제정보',
                      value: !isEmpty(billing) ? getCardInfo(billing) : '-',
                    },
                  ],
                }
              : { topic: [] },
          ]}
          amount={order.orderPaymentAmount !== 0 && order.orderPaymentAmount}
        />
      </Modal.Body>
      {!isWinkMember && (
        <Modal.Footer>
          <Button.Primary label="CATS 정기구독 관리" onClick={goManage} />
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default ModalHistoryOnline;
