import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import boardService from '@service/BoardService';
import { isNil } from 'lodash';

export const getBoardInfo = createAsyncThunk(
  'board/getBoardInfo',
  async (id) => {
    const result = await boardService.getBoardInfo(id);
    return result;
  },
);

export const getBoardList = createAsyncThunk(
  'board/getBoardList',
  async ({ boardId, keyword, pageNo }, { getState }) => {
    const {
      board: { pageNo: statePageNo },
    } = getState();
    const page = isNil(pageNo) ? statePageNo + 1 : pageNo;

    const result = await boardService.getBoardList({
      boardId,
      keyword,
      page,
    });
    return result;
  },
);

const initialState = {
  board: {},
  boardList: [],
  pageNo: 0,
  isFetch: false,
  hasNext: false,
};

export const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    clear: (state) => {
      state.board = {};
      state.boardList = [];
      state.pageNo = 0;

      state.isFetch = false;
      state.hasNext = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoardInfo.pending, (state) => {
        state.isFetch = false;
      })
      .addCase(getBoardInfo.fulfilled, (state, action) => {
        state.board = action.payload;
        state.isFetch = true;
      })
      .addCase(getBoardList.fulfilled, (state, action) => {
        const { list, pageNum, hasNextPage } = action.payload;

        state.boardList = [...state.boardList, ...list];
        state.pageNo = pageNum;
        state.hasNext = hasNextPage;
        state.isFetch = true;
      });
  },
});
const boardState = (state) => {
  return {
    board: state.board.board,
    isFetch: state.board.isFetch,
  };
};

export const boardSelector = createSelector(boardState, (state) => state);

const boardPostState = (state) => {
  return {
    boardList: state.board.boardList,
    pageNo: state.board.pageNo,
    hasNext: state.board.hasNext,
    isFetch: state.board.isFetch,
  };
};

export const boardPostSelector = createSelector(
  boardPostState,
  (state) => state,
);

export const { clear } = boardSlice.actions;

export default boardSlice.reducer;
