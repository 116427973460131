function MyPageMenu({ menuName, buttons }) {
  return (
    <>
      <dt>{menuName}</dt>
      <dd>
        <ul>
          {buttons.map((button, index) => (
            <li key={`menu_${index}`}>
              {button.type === 'version' ? (
                <div className="infoVersion">
                  <span>{button.title}</span>
                  <strong>{button.subTitle}</strong>
                </div>
              ) : (
                <button
                  type="button"
                  onClick={button.handleClick}
                  className="linkBtn"
                >
                  <span>{button.title}</span>
                </button>
              )}
            </li>
          ))}
        </ul>
      </dd>
    </>
  );
}

export default MyPageMenu;
