import { MathJaxContext } from 'better-react-mathjax';

function withMathJax(Component) {
  const config = {
    tex: {
      inlineMath: [['@#', '@#']],
    },
    svg: {
      fontCache: 'local',
      scale: 0.9,
      minScale: 0.6,
    },
    options: {
      renderActions: {
        addMenu: [0, '', ''],
        assistiveMML: [],
      },
      menuOptions: {
        settings: {
          assistiveMml: false,
        },
      },
    },
  };
  return (props) => {
    return (
      <MathJaxContext config={config}>
        <Component {...props} />
      </MathJaxContext>
    );
  };
}

export default withMathJax;
