import classnames from 'classnames';
import FormTextArea from './FormTextArea';
import Input from './Input';

function FormRadio({ label, isStudent = false, hasTextBox = false, ...rest }) {
  return (
    <>
      <label className={classnames(!isStudent ? 'radioBox' : 'radioBoxBorder')}>
        <Input type="radio" {...rest} />
        <span className="labelText">
          <em>{label}</em>
        </span>
      </label>
      {hasTextBox && (
        <div className="textarea">
          <FormTextArea
            name="textArea"
            rows="3"
            placeholder="내용입력 (최대 50자)"
          />
        </div>
      )}
    </>
  );
}

export default FormRadio;
