import { useSelector } from 'react-redux';

import { examStatusSelector } from '@store/slice/learningStatusSlice';

import LearningStatusExamItem from './LearningStatusExamItem';

function LearningStatusExam() {
  const { exam } = useSelector(examStatusSelector);

  return (
    <>
      {exam.length > 0 && (
        <ul className="listPreparation">
          {exam.map((item, index) => (
            <LearningStatusExamItem key={`exam_${index}`} {...item} />
          ))}
        </ul>
      )}
    </>
  );
}

export default LearningStatusExam;
