function Wrapper({ children }) {
  return <div className="boxFlexGrow typeFieldset">{children}</div>;
}

function List({ children }) {
  return <ul className="fieldsetList">{children}</ul>;
}

function Item({ children }) {
  const noWrap = !!children.props?.noWrap;
  return <>{noWrap ? <>{children}</> : <li>{children}</li>}</>;
}

function ButtonBox({ children, linkButton, contact = true }) {
  return (
    <div className="boxFlexNone">
      <div className="btnsWrapper typeBlock">{children}</div>

      {linkButton && { linkButton }}
      {contact && (
        <div className="boxContact">
          <strong className="textTitle">문의하기</strong>
          <a href="tel:1660-1244" className="btnCS">
            <span>고객센터문의</span>
          </a>
          {/* 
        // FIXME: 일단 미노출
        <button type="button" className="btnKakao">
          <span>카카오톡 문의</span>
        </button> */}
        </div>
      )}
    </div>
  );
}

export default {
  Wrapper,
  List,
  Item,
  ButtonBox,
};
