// export const phonePatten = /01[016789][0-9]{3,4}[0-9]{3,4}/;
export const phonePatten = /010[0-9]{4,4}[0-9]{4,4}/;
export const passwordPatten =
  /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\\d`~!@#$%^&*()-_=+]{8,16}$/;
// /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*()-_=+|[]{};:'",.<>?])[A-Za-z0-9]{8,16}$/;
// /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d]{8,16}$/;
// /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*[#?!@$ %^&*-()_]).{8,16}$/;

// 한글 이름 2~4자 이내
export const korNamePatten = /^[가-힣]{2,5}$/;
// 영문 이름 2~10자 이내 : 띄어쓰기(\s)가 들어가며 First, Last Name 형식
export const engNamePatten = /^(?=.{2,21}$)[A-Z]+(?:\s[A-Z]+)?$/;

export const namePatten = /^[가-힣A-Z]{2,21}$/;
