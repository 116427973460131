import {
  BOOK_DELIVERY_METHOD,
  BOOK_DELIVERY_TYPE,
  COUPON_TYPE,
} from '@constants/order';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import couponService from '@service/CouponService';
import deliveryService from '@service/DeliveryService';
import orderService from '@service/OrderService';
import { changeRoute } from '@store/action/commonAction';

const PAGE_RANGE = 4;

export const getAddress = createAsyncThunk(
  'order/getAddress',
  async ({ memberNo }) => {
    const response = await deliveryService.address({ memberNo });
    return response;
  },
);

export const getOrderBook = createAsyncThunk(
  'order/getOrderBook',
  async (data) => {
    const response = await orderService.getBookLink(data, {
      ignore: true,
    });
    return response;
  },
);

export const getOrderOnline = createAsyncThunk(
  'order/getOrderOnline',
  async ({ courseCd, subjectCd, memberNo, studentNo }, { rejectWithValue }) => {
    try {
      const response = await orderService.getCheckOnline(
        {
          courseCd,
          subjectCd,
          memberNo,
          studentNo,
        },
        { ignore: true },
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  },
);

export const getCheckOrder = createAsyncThunk(
  'order/getCheckOrder',
  async (data, { rejectWithValue }) => {
    try {
      const response = await orderService.pricecheck(data, { ignore: true });
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response);
    }
  },
);

export const getCoupons = createAsyncThunk(
  'order/getCoupons',
  async ({ memberNo, couponType }) => {
    const response = await couponService.getCoupons({ memberNo, couponType });
    return response;
  },
);

export const initialState = {
  deliveryMethod: 'BATCH_SEND',
  delivery: {},
  address: [],
  onlineProducts: [],
  order: {},
  orderAnswer: {},
  checkOrder: {},
  coupons: [],
  rejectMessage: '',
  demand: '',
  hasNext: false,
  isFetch: false,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    select: (state, action) => {
      state.delivery = action.payload;
    },
    setDemand: (state, action) => {
      state.demand = action.payload;
    },
    setDeliveryMethod: (state, action) => {
      state.deliveryMethod = action.payload;
      if (action.payload === BOOK_DELIVERY_METHOD.IMMEDIATELY) {
        state.delivery = {};
        if (state.address.length > 0) {
          state.delivery = {
            ...state.address[0],
          };
        }
      }

      if (
        action.payload === BOOK_DELIVERY_METHOD.BATCH_SEND &&
        state.order.textbookDeliveryKindCode === BOOK_DELIVERY_TYPE.ACADEMY
      ) {
        state.delivery = {
          addr: state.order.addrBase,
          addrDetail: state.order.addrDetail,
          mobile: state.order.receiverTel,
          name: state.order.receiverName,
          title: state.order.academyName,
          post: state.order.zipcode,
        };
      }
    },
    // 추가하면 현재 상품 배송은 추가한 주소로..
    add: (state, action) => {
      state.delivery = action.payload;
      state.address = [action.payload, ...state.address];
    },
    reset: (state) => {
      state.hasNext = state.address > PAGE_RANGE || state.hasNext;
      state.address = state.address.slice(0, PAGE_RANGE);
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setLinkOrder: (state, action) => {
      const order = action.payload;
      state.order = order;

      if (order.answerIncludeYn === 'Y') {
        state.orderAnswer = {
          ...order,
          goodsId: order.answerGoodsId,
          price: order.answerGoodsPrice,
        };
      }

      if (order.dueDay < 0) {
        state.order.textbookDeliveryKindCode = BOOK_DELIVERY_TYPE.HOME;
        order.textbookDeliveryKindCode = BOOK_DELIVERY_TYPE.HOME;
      }

      state.deliveryMethod =
        order.textbookDeliveryKindCode === BOOK_DELIVERY_TYPE.ACADEMY
          ? BOOK_DELIVERY_METHOD.BATCH_SEND
          : BOOK_DELIVERY_METHOD.IMMEDIATELY;

      if (order.textbookDeliveryKindCode === BOOK_DELIVERY_TYPE.ACADEMY) {
        state.delivery = {
          addr: order.addrBase,
          addrDetail: order.addrDetail,
          mobile: order.receiverTel,
          name: order.receiverName,
          title: order.academyName,
          post: order.zipcode,
        };
      }
    },
    publishCoupon: (state, action) => {
      state.coupons = state.coupons.map((coupon) => {
        return {
          ...coupon,
          publishYn:
            coupon.couponId === action.payload.couponId
              ? 'Y'
              : coupon.publishYn,
        };
      });
    },
    setMessage: (state, action) => {
      state.rejectMessage = action.payload;
    },
    clear: (state) => {
      state.delivery = {};
      state.demand = '';
      state.address = [];
      state.order = {};
      state.orderAnswer = {};
      state.coupons = [];
      state.checkOrder = {};
      state.hasNext = false;
      state.isFetch = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddress.fulfilled, (state, action) => {
        state.address = action.payload;
        state.hasNext = action.payload.length > PAGE_RANGE;
        state.isFetch = true;
        state.delivery = action.payload.length > 0 ? action.payload[0] : {};
      })
      .addCase(getOrderBook.fulfilled, (state, action) => {
        const order = action.payload;
        state.order = order;

        if (order.answerIncludeYn === 'Y') {
          state.orderAnswer = {
            ...order,
            goodsId: order.answerGoodsId,
            price: order.answerGoodsPrice,
          };
        }

        state.deliveryMethod = BOOK_DELIVERY_TYPE.ACADEMY
          ? BOOK_DELIVERY_METHOD.BATCH_SEND
          : BOOK_DELIVERY_METHOD.IMMEDIATELY;

        if (order.textbookDeliveryKindCode === BOOK_DELIVERY_TYPE.ACADEMY) {
          state.delivery = {
            addr: order.addrBase,
            addrDetail: order.addrDetail,
            mobile: order.receiverTel,
            name: order.receiverName,
            title: order.academyName,
            post: order.zipcode,
          };
        }
      })
      .addCase(getOrderOnline.fulfilled, (state, action) => {
        state.onlineProducts = action.payload;
        state.order = action.payload?.[0] || {};
      })
      .addCase(getOrderOnline.rejected, (state, action) => {
        state.rejectMessage = action.payload?.data?.resultMsg || '';
      })
      .addCase(getCoupons.fulfilled, (state, action) => {
        state.coupons = action.payload;
      })
      .addCase(getCheckOrder.fulfilled, (state, action) => {
        state.checkOrder = action.payload;
      })
      .addCase(getCheckOrder.rejected, (state, action) => {
        state.rejectMessage = action.payload?.data?.resultMsg || '';
      });

    builder.addCase(changeRoute, (state, action) => {
      // 주문 url은 order-... 으로 시작함
      if (action.payload.includes('/order-')) {
        state.delivery = {};
        state.demand = '';
        state.address = [];
        state.order = {};
        state.orderAnswer = {};
        state.checkOrder = {};
        state.coupons = [];
        state.hasNext = false;
        state.isFetch = false;
      }
    });
  },
});

const rejectMessageState = (state) => state.order.rejectMessage;
export const rejectMessageSelector = createSelector(
  rejectMessageState,
  (state) => state,
);

const couponsState = (state) => state.order.coupons;
export const couponsSelector = createSelector(couponsState, (state) => state);

const orderState = (state) => {
  return {
    onlineProducts: state.order.onlineProducts,
    order: state.order.order,
    orderAnswer: state.order.orderAnswer,
    checkOrder: state.order.checkOrder,
  };
};
export const orderSelector = createSelector(orderState, (state) => state);

const deliveryState = (state) => {
  return {
    delivery: state.order.delivery,
    demand: state.order.demand,
    deliveryMethod: state.order.deliveryMethod,
  };
};
export const deliverySelector = createSelector(deliveryState, (state) => state);

const addressState = (state) => {
  return {
    address: state.order.address,
    hasNext: state.order.hasNext,
    isFetch: state.order.isFetch,
  };
};
export const addressSelector = createSelector(addressState, (state) => state);

export const {
  select,
  add,
  reset,
  setOrder,
  setLinkOrder,
  setDemand,
  setDeliveryMethod,
  publishCoupon,
  setMessage,
  clear,
} = orderSlice.actions;
export default orderSlice.reducer;
