const AUTH_STATUS = {
  NOT_CHECK: 'NOT_CHECK',
  INVALID: 'INVALID',
  VALID: 'VALID',
};

const DUPLICATE_STATUS = {
  NOT_CHECK: 'NOT_CHECK',
  DUPLICATE: 'DUPLICATE',
  VALID: 'VALID',
};

const VERIFY_STATUS = {
  READY: 'READY',
  SEND: 'SEND',
  INVALID_CODE: 'INVALID_CODE',
  INVALID_TIMEOUT: 'INVALID_TIMEOUT',
  INVALID_NO_ACCOUNT: 'INVALID_NO_ACCOUNT',
  VALID: 'VALID',
};

const USER_TYPE = {
  PARENTS: 'PARENTS',
  STUDENT: 'STUDENT',
};

const MEMBER_TYPE_CODE = {
  PARENTS: 'CD10003',
  STUDENTS: 'CD10002',
  TEACHER: 'CD10001',
};

const MEMBER_TYPE = {
  [MEMBER_TYPE_CODE.PARENTS]: '학부모',
  [MEMBER_TYPE_CODE.STUDENTS]: '학생',
  [MEMBER_TYPE_CODE.TEACHER]: '선생님',
};

export {
  DUPLICATE_STATUS,
  VERIFY_STATUS,
  AUTH_STATUS,
  USER_TYPE,
  MEMBER_TYPE_CODE,
  MEMBER_TYPE,
};
