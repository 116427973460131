function EnglishLevelTestScore({ areas }) {
  return (
    <div className="reportSection boxScoreDetails">
      <div className="sectionTitleBox">
        <p className="sectionTitle">영역별 점수</p>
      </div>
      <div className="divisionWrapper">
        <div className="reportDivision barChartVertical">
          <ul className="chartLegend">
            <li>
              <span className="legendColor" />
              <span className="legendTxt">나의 정답 수</span>
            </li>
            <li>
              <span className="legendColor typeAverage" />
              <span className="legendTxt">레벨 평균 정답 수</span>
            </li>
          </ul>

          <div className="barCharBox">
            <ul className="xAxisGroup">
              {areas?.map(
                ({
                  systemAreaCd,
                  systemAreaNm,
                  avgAnswerCnt,
                  correctAnswerCnt,
                  totalQuestionCnt,
                }) => (
                  <li key={`score_${systemAreaCd}`}>
                    <div className="xAxisValue">
                      <p>{systemAreaNm}</p>
                    </div>
                    <div className="xAxisGraph">
                      <div className="barGraph">
                        <span
                          className="per"
                          style={{
                            height: `${
                              (correctAnswerCnt / totalQuestionCnt) * 100
                            }%`,
                          }}
                        >
                          <em>{correctAnswerCnt}</em>
                        </span>
                      </div>
                      <div className="barGraph typeAverage">
                        <span
                          className="per"
                          style={{
                            height: `${
                              (avgAnswerCnt / totalQuestionCnt) * 100
                            }%`,
                          }}
                        >
                          <em>{avgAnswerCnt}</em>
                        </span>
                      </div>
                    </div>
                  </li>
                ),
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnglishLevelTestScore;
