import { useNavigate } from 'react-router-dom';

import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';

import Popup from './base/Popup';

function PopupOrderFailed({ id, message = '', ...rest }) {
  const { closePopup } = useOverlayContext();

  const navigate = useNavigate();

  const goHome = () => {
    navigate('/home', { replace: true });
  };

  const close = () => {
    closePopup(id);
  };

  return (
    <Popup {...rest}>
      <Popup.Header id={id} hasClose>
        결제완료
      </Popup.Header>
      <Popup.Body className="boxResultsPayment">
        <div className="boxFlexGrow">
          <div className="paymentResultsWrap">
            <div className="topBox">
              <span className="logo">CATS</span>
            </div>

            <div className="resultMessage">
              <p className="textMain icoFaild">
                <b>결제에 실패</b>하였습니다.
              </p>
              <p className="textSub">이용에 불편을 드려 죄송합니다.</p>
            </div>

            <div className="errorInfo">
              <dl className="infoDetails">
                <div>
                  <dt>실패사유</dt>
                  <dd className="textReason">
                    {message.length > 0 ? message : '시스템 오류'}
                  </dd>
                </div>
              </dl>

              <div className="guideInfo">
                <ul className="listDotted">
                  <li>
                    실패사유를 확인한 후 다시 결제를 시도해주시기 바랍니다.
                  </li>
                  <li>
                    도움이 필요하시다면{' '}
                    <Button.Normal
                      className="btnUnderline secondary"
                      label="고객센터"
                    />
                    로 문의해주세요.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Button.Wrapper flexNone>
          <Button.Outline large label="홈으로 이동" onClick={goHome} />
          <Button.Primary large label="다시 결제하기" onClick={close} />
        </Button.Wrapper>
      </Popup.Body>
    </Popup>
  );
}

export default PopupOrderFailed;
