import { memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Feed from './base/Feed';

function FeedMathTestReport({ feed }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/learning-report-math/${feed.feedLinkKey}`);
  };
  const feedData = useMemo(
    () => ({
      ...feed,
      feedContents: '수학 수준진단평가 리포트가 도착했습니다.',
    }),
    [feed],
  );
  return <Feed feed={feedData} onClick={handleClick} />;
}

export default memo(FeedMathTestReport);
