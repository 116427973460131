import { useState } from 'react';
import { useMount } from 'ahooks';

import boardService from '@service/BoardService';
import { BOARD } from '@constants/board';

import FaqPosts from './FaqPosts';

function FaqBestPost() {
  const [posts, setPosts] = useState([]);

  useMount(() => {
    (async () => {
      const response = await boardService.getBestPost(BOARD.FAQ);
      setPosts(response);
    })();
  });

  return (
    <>
      {setPosts && setPosts.length > 0 && (
        <div className="definitionBox">
          <p className="boxTitle">BEST 질문</p>
          <FaqPosts posts={posts} />
        </div>
      )}
    </>
  );
}

export default FaqBestPost;
