function MathTestSummary({ level, score, totalCnt, correctAnswerCnt }) {
  return (
    <div className="reportDivision">
      <ul className="comprehensiveData">
        <li className="colScore">
          <strong className="label">점수</strong>
          <div className="labelContents">
            <b>{score.toFixed(0)}점</b>
          </div>
        </li>
        <li className="colGrade">
          <strong className="label">평가 등급</strong>
          <div className="labelContents">
            <b>{level}등급</b>
          </div>
        </li>
        <li className="colCorrectValues">
          <strong className="label">종합 정답률</strong>
          <div className="labelContents">
            <span className="textFraction">
              <em className="txtCurrent">{correctAnswerCnt}개 정답</em>
              <em className="txtTotal">{totalCnt}개</em>
            </span>
            <span className="textFinalRatio">
              <b>{((correctAnswerCnt / totalCnt) * 100).toFixed(0)}%</b>
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default MathTestSummary;
