import { useLayoutContext } from '@context/LayoutContext';
import { RouteLink } from '@components/common';

function Tabbar() {
  const { config } = useLayoutContext();

  return (
    <>
      {!!config.hasTabbar && (
        <>
          <div className="bottomNavBar">
            <nav className="navBox">
              <ul>
                <RouteLink to="/home" label="홈" className="navBtnHome" />
                <RouteLink
                  disabled
                  to="/learning-status"
                  label="학습현황"
                  className="navBtnStatus"
                />
                <RouteLink
                  disabled
                  to="/learning-report"
                  label="학습리포트"
                  className="navBtnReport"
                />
                <RouteLink
                  disabled
                  to="/academy/notice"
                  label="학원"
                  className="navBtnInstitution"
                />
                <RouteLink to="/my" label="MY" className="navBtnMy" />
              </ul>
            </nav>
          </div>
          {/* <div className="notifyMessageBox show">
            <p>결제 할 건이 총 2건 있습니다.</p>
            <button type="button" className="btnPaying">
              <span>결제하기</span>
            </button>
            <button type="button" className="btnClose">
              <span>닫기</span>
            </button>
          </div> */}
        </>
      )}
    </>
  );
}

export default Tabbar;
