import { useState } from 'react';
import { useMount } from 'ahooks';
import classnames from 'classnames';

function PopupContainer({ children }) {
  const [isOpen, setOpen] = useState(false);

  useMount(() => {
    // 애니메이션 적용
    setTimeout(() => setOpen(true), 100);
    return () => setOpen(false);
  }, []);

  return (
    <div className={classnames('panelCover', isOpen && 'isOpen')}>
      <div className="dimmed" />
      {children}
    </div>
  );
}

export default PopupContainer;
