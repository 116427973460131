import { createContext, useContext, useState, useCallback } from 'react';
import { useMount } from 'ahooks';

import { Loading } from '@components/common';
import api from '@utils/Api';

const LoadingContext = createContext();

function LoadingProvider({ children }) {
  const [requestCount, setRequestCount] = useState(0);

  const enable = useCallback(() => setRequestCount((pre) => pre + 1), []);
  const disable = useCallback(
    () => setRequestCount((pre) => (pre === 0 ? 0 : pre - 1)),
    [],
  );

  // axios injection
  useMount(() => {
    api.ajax.interceptors.request.use(
      (request) => {
        // true인 것만 enable 아니면 그냥 패스
        if (request.loading) {
          enable();
        }
        return request;
      },
      (error) => {
        disable();
        return Promise.reject(error);
      },
    );

    api.ajax.interceptors.response.use(
      (response) => {
        disable();
        return response;
      },
      (error) => {
        disable();
        return Promise.reject(error);
      },
    );
  });

  return (
    <LoadingContext.Provider value={{ enable, disable }}>
      <Loading isLoading={requestCount > 0} />
      {children}
    </LoadingContext.Provider>
  );
}

function useLoadingContext() {
  return useContext(LoadingContext);
}

export { LoadingProvider, useLoadingContext };
