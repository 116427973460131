/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { CATS_ONLINE_PRODUCT_IMAGE } from '@constants/image';

// 결제/구독 관리
function PaymentPage() {
  const navigate = useNavigate();

  const isAcademy = useMatch('/payment-academy');
  const isOnline = useMatch('/payment-online');

  const goMethod = useCallback(() => {
    navigate(isAcademy ? '/payment-method/academy' : '/payment-method/online');
  }, [isAcademy, navigate]);

  const goManage = useCallback(() => {
    navigate(isAcademy ? '/payment-manage/academy' : '/payment-manage/online');
  }, [isAcademy, navigate]);

  const goOnline = useCallback(() => {
    navigate('/online-learning');
  }, [navigate]);

  return (
    <div className="contentBox">
      <div className="regularPayment">
        <p className="textCategory">자동결제</p>
        <button type="button" className="directLink" onClick={goMethod}>
          <span>결제수단 변경</span>
        </button>
      </div>

      {isAcademy && (
        <div className="regularPayment">
          <p className="textCategory">학원비</p>
          <button type="button" className="directLink" onClick={goManage}>
            <span>자동결제 관리</span>
          </button>
        </div>
      )}

      {isOnline && (
        <div className="regularPayment">
          <p className="textCategory">CATS 온라인 학습</p>
          <button type="button" className="directLink" onClick={goManage}>
            <span>구독상품관리</span>
          </button>
        </div>
      )}

      {isOnline && (
        <div className="boxOtherService pd0">
          <p className="textTitle">다른 상품에 관심이 있다면</p>

          <div className="promotionalBox">
            <a className="bnrLink" onClick={goOnline}>
              <img
                src={CATS_ONLINE_PRODUCT_IMAGE}
                alt="학습&amp;케어를 온라인과 오프라인 동시에! CATS 온라인 맞춤학습"
              />
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentPage;
