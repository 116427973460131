function EnglishLevelTestResult({ cefr, lexile }) {
  return (
    <div className="reportSection boxMyLevel">
      <div className="sectionTitleBox">
        <p className="sectionTitle">CEFR 및 Lexile</p>
      </div>
      <div className="divisionWrapper">
        <div className="reportDivision">
          <div className="levelTable">
            <dt>CEFR</dt>
            <dd>{cefr}</dd>
            <dt>Lexile</dt>
            <dd>{lexile}</dd>
          </div>

          <dl className="guideLevelDescription">
            <div>
              <dt>CEFR이란?</dt>
              <dd>
                CEFR은 유럽연합 공통언어 표준등급을 나타냅니다. A1부터 C2까지 총
                6단계 레벨로 구성되어 있습니다. 외국어를 얼마나 잘 말하고 이해할
                수 있는지 설명해주는 기준으로 활용되고 있습니다.
              </dd>
            </div>
            <div>
              <dt>Lexile이란?</dt>
              <dd>
                Lexile(렉사일) 지수는 영어 읽기 능력 지수를 나타냅니다. 영어
                도서와 나의 지수를 비교하여 알맞은 학습 수준을 비교할 수
                있습니다.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default EnglishLevelTestResult;
