import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  emptyLearningSelector,
  learningStudentsSelector,
  setStudent,
  studentIdSelector,
} from '@store/slice/homeSlice';
import { studentsSelector } from '@store/slice/accountSlice';
import withMathJax from '@hoc/withMathJax';

import HomeLearningHeader from './HomeLearningHeader';
import HomeLearningAcademy from './HomeLearningAcademy';
import HomeLearningOnline from './HomeLearningOnline';

function HomeLearning() {
  const dispatch = useDispatch();

  const studentId = useSelector(studentIdSelector);
  const student = useSelector(studentsSelector({ hasAll: false }));
  const learningStudents = useSelector(learningStudentsSelector);

  const isEmptyLearning = useSelector(emptyLearningSelector);

  const [studentNo, setStudentNo] = useState(studentId);

  const handleSelect = useCallback(
    (value) => {
      setStudentNo(value);
      dispatch(setStudent(value));
    },
    [dispatch],
  );

  return (
    <>
      {!isEmptyLearning && learningStudents?.length !== 0 && (
        <div className="learningStatus">
          <HomeLearningHeader student={student} onSelect={handleSelect} />
          {studentNo && (
            <>
              <HomeLearningAcademy studentNo={studentNo} />
              <HomeLearningOnline studentNo={studentNo} />
            </>
          )}
        </div>
      )}
    </>
  );
}

export default withMathJax(HomeLearning);
