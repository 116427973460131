import { format } from '@helper/timeHelper';
import { studentSelector } from '@store/slice/accountSlice';
import { useSelector } from 'react-redux';

function LearningStatusExamItem({
  studentId,
  examTypeNm,
  examStartDate,
  examEndDate,
  dday,
}) {
  const { memberName } = useSelector(studentSelector(studentId));
  return (
    <ul>
      <li>
        <div className="examPreparation">
          <div className="boxName">
            <span>{memberName}</span>
          </div>
          <div className="boxTestPeriod">
            <dl>
              <dt>
                <strong>{examTypeNm}</strong>
                <span className="flagFilled tyDday">
                  {dday === 0 ? 'D-DAY' : `D${dday > 0 ? '+' : ''}${dday}`}
                </span>
              </dt>
              <dd>
                <span>시험대비 기간</span>
                <p>
                  {format(examStartDate, 'MM일 DD일')} ~{' '}
                  {format(examEndDate, 'MM일 DD일')}
                </p>
              </dd>
            </dl>
          </div>
        </div>
      </li>
    </ul>
  );
}

export default LearningStatusExamItem;
