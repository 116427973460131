import { FormFileUpload } from '@components/form';

function DefinitionBoxImage() {
  return (
    <div className="definitionBox boxAttachingPhoto">
      <p className="boxTitle">
        사진 첨부<span className="textOptional">(선택사항)</span>
      </p>
      <div className="boxDescription">
        <p>
          상품의 상태를 잘 나타낼 수 있는 사진을 첨부해주시면 신속하게 처리할 수
          있습니다.
        </p>
        <p className="colorBlue">최대 5개 파일, 10MB까지 첨부 가능</p>
      </div>

      <FormFileUpload />
    </div>
  );
}

export default DefinitionBoxImage;
