import { useCallback } from 'react';
import { useOverlayContext } from '@context/OverlayContext';
import { Button } from '@components/ui';

function ModalHeader({ id, children, onClose, hasClose = false }) {
  const { closeModal } = useOverlayContext();

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
    closeModal(id);
  }, [closeModal, id, onClose]);

  return (
    <div className="popHeader">
      <h1 className="popH1">{children}</h1>
      {hasClose && (
        <Button.Normal
          className="btnClosePopup"
          label="닫기"
          onClick={handleClose}
        />
      )}
    </div>
  );
}

export default ModalHeader;
