import { useNavigate } from 'react-router-dom';
import Modal from './base/Modal';

function ModalError({ message, isBack, ...rest }) {
  const navigate = useNavigate();
  const handleClose = () => {
    if (isBack) {
      navigate('/home', { replace: true });
    }
  };
  return (
    <Modal isBasic {...rest}>
      <Modal.Header hasClose onClose={handleClose}>
        오류
      </Modal.Header>
      <Modal.Body>
        <p className="textSub" style={{ textAlign: 'center' }}>
          {message || '서버 오류 입니다. 시스템팀에 문의 바랍니다.'}
        </p>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
}

export default ModalError;
