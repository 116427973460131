import {
  LearningReport,
  LearningReportAcademy,
  LearningReportOnline,
  LearningReportEnglish,
  LearningReportMath,
  LearningReportTask,
} from '@pages';
import { HEADER_TYPE } from '@constants/layout';

const reportRouteConfig = [
  {
    path: 'learning-report',
    element: LearningReport,
    option: {
      name: '학습리포트',
      headerType: HEADER_TYPE.BACK,
      hasTabbar: true,
    },
  },
  {
    path: 'learning-report-academy/:id',
    element: LearningReportAcademy,
    option: {
      name: '학원 수강 과목 리포트',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'learning-report-online/:id/:date',
    element: LearningReportOnline,
    option: {
      name: '캐츠업 리포트',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'learning-report-level/:id/:memberNo',
    element: LearningReportEnglish,
    option: {
      name: '영어 레벨테스트',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'learning-report-math/:id',
    element: LearningReportMath,
    option: {
      name: '수학 수준 진단평가',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'learning-report-task/:id',
    element: LearningReportTask,
    option: {
      name: '과제 리포트',
      headerType: HEADER_TYPE.BACK,
    },
  },
];

export default reportRouteConfig;
