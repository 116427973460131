import { HIGH_SCHOOL } from '@constants/school';
import classnames from 'classnames';

const levels = Array.from({ length: 12 }, (_, i) => ({
  className: `colLv${(i + 1).toString().padStart(1)}`,
  level: i + 1,
}));
const grades = [
  {
    name: '초1',
    gradeCd: 'CD10012',
    low: [],
    middle: [3],
    high: [1, 2],
  },
  {
    name: '초2',
    gradeCd: 'CD10013',
    low: [5],
    middle: [1, 4],
    high: [2, 3],
  },
  {
    name: '초3',
    gradeCd: 'CD10014',
    low: [1, 6],
    middle: [2, 5],
    high: [3, 4],
  },
  {
    name: '초4',
    gradeCd: 'CD10015',
    low: [2],
    middle: [3, 6, 7],
    high: [4, 5],
  },
  {
    name: '초5',
    gradeCd: 'CD10016',
    low: [3, 9],
    middle: [4, 8],
    high: [5, 6, 7],
  },
  {
    name: '초6',
    gradeCd: 'CD10017',
    low: [4, 10],
    middle: [5, 9],
    high: [6, 7, 8],
  },
  {
    name: '중1',
    gradeCd: 'CD10018',
    low: [4, 11],
    middle: [5, 10],
    high: [6, 7, 8, 9],
  },
  {
    name: '중2',
    gradeCd: 'CD10019',
    low: [5, 12],
    middle: [11],
    high: [6, 7, 8, 9, 10],
  },
  {
    name: '중3',
    gradeCd: 'CD10020',
    low: [6],
    middle: [7, 12],
    high: [8, 9, 10, 11],
  },
  {
    name: '고등',
    gradeCd: '',
    low: [7],
    middle: [8],
    high: [9, 10, 11, 12],
  },
];

function EnglishLevelTestGuideTable({
  gradeCd: studentGradeCd,
  courseCd: studentCourseCd,
  level,
}) {
  return (
    <div className="reportSection boxLevelGuideTable">
      <div className="sectionTitleBox">
        <p className="sectionTitle">CATS LEVEL GUIDE</p>
      </div>
      <div className="divisionWrapper">
        <div className="reportDivision">
          <div className="outerFrame">
            <div className="sectionTable typeFixing">
              <table>
                <thead>
                  <tr>
                    <th className="colLabel">{}</th>
                  </tr>
                </thead>
                <tbody>
                  {grades.map(({ name, gradeCd }, index) => (
                    <tr
                      key={`row_${index}`}
                      className={classnames(
                        HIGH_SCHOOL === studentCourseCd && 'active',
                        gradeCd === studentGradeCd && 'active',
                      )}
                    >
                      <th className="colLabel">
                        <span>{name}</span>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="coloredTable">
              <div className="sectionTop">
                <div className="boxSetPosition">
                  <p className="textBasic">Basic</p>
                  <p className="textIntermediate">Intermediate</p>
                  <p className="textdvanced">Advanced</p>
                </div>
              </div>
              <div className="sectionTable">
                <table>
                  <thead>
                    <tr>
                      <th className="colLabel">{}</th>
                      {levels.map((item, index) => (
                        <th
                          key={`level_th_${index}`}
                          className={item.className}
                        >
                          Lv.{item.level}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {grades.map((grade, index) => (
                      <tr key={`tr_${index}`}>
                        <th className="colLabel">
                          <span>{grade.name}</span>
                        </th>
                        {levels.map((item) => (
                          <td
                            key={`td_${index}_${item.level}`}
                            className={item.className}
                          >
                            <span
                              className={classnames(
                                grade.low.includes(item.level) && 'rangeLv2',
                                grade.middle.includes(item.level) && 'rangeLv3',
                                grade.high.includes(item.level) && 'rangeLv4',
                              )}
                            />
                            {grade.gradeCd === studentGradeCd &&
                              level === item.level && (
                                <div className="txtMyLevel">나의레벨</div>
                              )}
                            {studentCourseCd === HIGH_SCHOOL &&
                              level === item.level && (
                                <div className="txtMyLevel">나의레벨</div>
                              )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="boxRangeLevel">
            <div className="legendBox">
              <span className="rangeLv1">
                <em className="hidden">가장낮음</em>
              </span>
              <span className="rangeLv2">
                <em className="hidden">낮음</em>
              </span>
              <span className="rangeLv3">
                <em className="hidden">높음</em>
              </span>
              <span className="rangeLv4">
                <em className="hidden">가장높음</em>
              </span>
            </div>
            <p>
              *해당 학년의 레벨 분포도이며, 색상이 진할 수록 분포가 높음을
              나타냅니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnglishLevelTestGuideTable;
