import { Button } from '@components/ui';

import Modal from './base/Modal';

function ModalRequestChangePassword({ onSuccess, ...rest }) {
  const goHome = () => {
    onSuccess('/home');
  };

  const goResetPassword = () => {
    onSuccess('/my-confirm');
  };

  return (
    <Modal {...rest}>
      <Modal.Header>비밀번호를 변경해주세요!</Modal.Header>
      <Modal.Body className="popRequestChange">
        <p className="textSub">회원님은 초기비밀번호를 사용하고 계십니다.</p>

        <p className="textSub">
          <span>회원님의 소중한 개인정보를 안전하게</span> 보호하기 위해 <br />
          <strong>
            MY<span className="icoGt">내 정보</span>
            <span className="icoGt">내 정보관리</span>
          </strong>
          에서 비밀번호를 변경 후 <br />
          서비스를 이용해주세요.
        </p>

        <p className="guidanceText">
          영문, 숫자를 포함하여 8~16자리로 등록해주세요.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button.Outline label="다음에 할래요" onClick={goHome} />
        <Button.Primary label="변경하기" onClick={goResetPassword} />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalRequestChangePassword;
