import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import Payment from './base/Payment';

function PaymentOnlineLearning({ subjectCd, ...rest }) {
  const navigate = useNavigate();

  const handleSelect = () => {
    navigate(`/order-online/${subjectCd}`);
  };

  return <Payment onSelect={handleSelect} {...rest} />;
}

export default memo(PaymentOnlineLearning);
