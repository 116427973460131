import { Button } from '@components/ui';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

import Modal from './base/Modal';

function ModalIntegratedUserConfirm({ complete, createAt, ...rest }) {
  const navigate = useNavigate();

  const goChildLink = () => {
    setTimeout(() => {
      navigate('/child-integrated');
    });
  };

  return (
    <Modal {...rest}>
      <Modal.Header>통합계정 로그인 안내</Modal.Header>
      <Modal.Body>
        <p className="textSub">
          <b className="colorGreen">CATS 선생님</b> 에 가입되어있는
          회원이시네요!
          <br />
          {createAt && `(가입일 : ${dayjs(createAt).format('YYYY-MM-DD')})`}
          <br />
          <br />
          기존에 사용하시던 계정 하나로
          <br />
          회원가입 없이 간편하게 로그인해보세요.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button.Primary
          label="학부모 통합계정 시작하기"
          onClick={goChildLink}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalIntegratedUserConfirm;
