import { clear } from '@store/slice/paymentHistorySlice';
import { useDispatch } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';

// 결재대기 > 교재 > 주문, 교환/환불 > 성공
function PaymentHistorySuccessPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isClaimPage = useMatch('/payment-claim-success');
  const isCancelPage = useMatch('/payment-cancel-success');

  return (
    <div className="contentBox boxCompleted">
      <div className="boxFlexGrow typeMiddle">
        {isCancelPage && (
          <p className="textMain icoFaild">
            <b>주문이 취소</b>되었습니다.
          </p>
        )}
        {isClaimPage && (
          <>
            <p className="textMain">
              <b>교환/반품 요청</b>이 완료되었습니다.
            </p>
            <p className="textSub">담당자가 확인한 후 연락드리겠습니다.</p>
          </>
        )}
      </div>
      <div className="boxFlexNone">
        <div className="btnsWrapper">
          <button
            type="button"
            className="btnOutline large"
            onClick={() => {
              dispatch(clear());
              navigate('/payment-history');
            }}
          >
            <span>결제내역 목록가기</span>
          </button>
          <button
            type="button"
            className="btnPrimary large"
            onClick={() => navigate('/home')}
          >
            <span>홈으로 이동하기</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentHistorySuccessPage;
