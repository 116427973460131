/* eslint-disable react/button-has-type */
import classnames from 'classnames';

function Button({
  className,
  large,
  small,
  wide,
  label,
  type = 'button',
  ...rest
}) {
  return (
    <button
      type={type}
      className={classnames(
        className,
        large && 'large',
        small && 'small',
        wide && 'wide',
      )}
      {...rest}
    >
      <span>{label}</span>
    </button>
  );
}

function ButtonWrapper({ className, children, flexNone = false }) {
  const wrapper = (
    <div className={classnames('btnsWrapper', className)}>{children}</div>
  );
  return (
    <>{flexNone ? <div className="boxFlexNone">{wrapper}</div> : wrapper}</>
  );
}

function ButtonNormal({ ...rest }) {
  return <Button {...rest} />;
}
function ButtonPrimary({ className, ...rest }) {
  return (
    <Button
      className={classnames(className && className, 'btnPrimary')}
      {...rest}
    />
  );
}

function ButtonOutline({ className, ...rest }) {
  return (
    <Button
      className={classnames(className && className, 'btnOutline')}
      {...rest}
    />
  );
}

export default Object.assign(Button, {
  Wrapper: ButtonWrapper,
  Normal: ButtonNormal,
  Primary: ButtonPrimary,
  Outline: ButtonOutline,
});
