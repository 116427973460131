import { configureStore } from '@reduxjs/toolkit';

import createReducer from './rootReducer';

// reload 될 때 store clear
if (process.env.REACT_APP_MODE === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}

const middlewares = [];

if (process.env.REACT_APP_MODE === 'development') {
  const { createLogger } = require('redux-logger');
  const logger = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
  });

  middlewares.push(logger);
}

const store = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.REACT_APP_MODE === 'development',
});

export default store;
