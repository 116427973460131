import { REPORT_TABS, REPORT_TYPE } from '@constants/tab';
import Tabs from './base/Tabs';

function ReportTab({ defaultType, ...rest }) {
  return (
    <Tabs
      isFixed
      isScroll
      tabs={REPORT_TABS}
      defaultType={defaultType || REPORT_TYPE.ACADEMY}
      {...rest}
    />
  );
}

export default ReportTab;
