import { useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import { Button, PaymentDueDate } from '@components/ui';
import { GoodInfoBox } from '@components/box';
import { format, KO_DATE_INCLUDE_WEEK } from '@helper/timeHelper';
import { toCommaNumber } from '@helper/commonHelper';
import { BOOK_DELIVERY_TYPE } from '@constants/order';
import { useOverlayContext } from '@context/OverlayContext';
import orderService from '@service/OrderService';

import Modal from './base/Modal';
import BaseModal from './base/BaseModal';

// 결재대기 > 교재
function ModalOrderBook({ id, billing, memberNo, onSaleYn, ...rest }) {
  const navigate = useNavigate();
  const { showDialog, closeModal, showToast } = useOverlayContext();

  const goOrder = () => {
    navigate(`/order-book-link/${billing.orderRequestLinkId}`);
  };

  const handleDelete = () => {
    const fetch = async () => {
      try {
        await orderService.removeOrder({
          memberNo,
          cartId: billing.cartId,
        });
        closeModal(id);
        showToast({ message: '해당 청구서가 삭제되었습니다.' });
        navigate(0);
      } catch (error) {
        console.warn(error);
      }
    };
    fetch();
  };

  const handleConfirm = () => {
    showDialog(BaseModal, {
      title: '알림',
      content: '해당 청구서를 삭제하시겠습니까?',
      buttons: [
        {
          isClose: true,
          label: '아니오',
          className: 'btnOutline',
        },
        {
          label: '네',
          action: () => handleDelete(),
          className: 'btnPrimary',
        },
      ],
    });
  };

  const isNonPayment = useMemo(() => {
    return billing.diffDueDay > 0;
  }, [billing.diffDueDay]);

  return (
    <Modal isBasic {...rest}>
      <Modal.Header id={id} hasClose>
        청구서
      </Modal.Header>
      <Modal.Body className="popBill">
        <GoodInfoBox {...billing} />

        <div className="popDefinitionBox">
          <dl className="grayOrderInfo">
            <div>
              <dt>자녀 이름</dt>
              <dd>{billing.studentName || '-'}</dd>
            </div>
            <div>
              <dt>배송지</dt>
              {billing.textbookDeliveryKindCode ===
                BOOK_DELIVERY_TYPE.ACADEMY && (
                <dd>
                  {billing.academyName || '-'} ({billing.addrBase})
                </dd>
              )}
              {billing.textbookDeliveryKindCode === BOOK_DELIVERY_TYPE.HOME && (
                <dd>-</dd>
              )}
            </div>
            {onSaleYn === 'Y' && (
              <>
                <div>
                  <dt>결제기한</dt>
                  <dd>
                    {format(billing.dueDate, KO_DATE_INCLUDE_WEEK)}
                    <PaymentDueDate dueDate={billing.diffDueDay} isBook />
                  </dd>
                </div>
                <div>
                  <dt>발송일</dt>
                  <dd>
                    오늘 결제 시{' '}
                    {dayjs(
                      isEmpty(billing.diffDueDay) || billing.diffDueDay > 0
                        ? Date.now()
                        : billing.dueDate,
                    )
                      .businessDaysAdd(4)
                      .format(KO_DATE_INCLUDE_WEEK)}{' '}
                    까지 발송예정
                  </dd>
                </div>
              </>
            )}
          </dl>
          {isNonPayment && (
            <p className="textComment">
              결제기한부터 1년까지만 구매 가능합니다.
            </p>
          )}
        </div>
        <div className="popDefinitionBox">
          <dl className="boxOrderInfo typeTotalPrice">
            <dt>상품금액</dt>
            <dd className={classnames(onSaleYn === 'N' && 'textDeleted')}>
              <b>
                {toCommaNumber(
                  Number(billing.answerGoodsPrice) + Number(billing.price),
                )}
              </b>
              원
            </dd>
          </dl>
        </div>
      </Modal.Body>
      <Modal.Footer noWrap>
        {onSaleYn === 'N' && (
          <Button.Wrapper>
            <Button.Normal
              className="btnGray"
              label="삭제"
              onClick={handleConfirm}
            />
          </Button.Wrapper>
        )}
        {onSaleYn === 'Y' && (
          <Button.Wrapper
            className={classnames(isNonPayment && 'typeBasisAuto')}
          >
            {isNonPayment && (
              <Button.Normal
                className="btnGray"
                label="삭제"
                onClick={handleConfirm}
              />
            )}
            <Button.Normal
              className={classnames('btnSecondary', isNonPayment && 'wide')}
              onClick={goOrder}
              label={`${toCommaNumber(
                Number(billing.answerGoodsPrice) + Number(billing.price),
              )}원`}
            />
          </Button.Wrapper>
        )}
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToPros = (state) => {
  return { memberNo: state.account.user.memberNo };
};

export default connect(mapStateToPros)(ModalOrderBook);
