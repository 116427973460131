import { useNavigate } from 'react-router-dom';

function HomeFooter() {
  const navigate = useNavigate();

  const goTerms = (id) => {
    navigate('/terms', { state: { conditionsId: id } });
  };
  return (
    <div className="footerBox">
      <div className="companyInfo">
        <strong className="logoDanbi">
          <span>단비교육</span>
        </strong>
        <ul>
          <li>
            <button type="button" className="aLink" onClick={() => goTerms(2)}>
              <span>이용약관</span>
            </button>
          </li>
          <li>
            <button type="button" className="aLink" onClick={() => goTerms(1)}>
              <span>개인정보 처리방침</span>
            </button>
          </li>
        </ul>
      </div>
      <div className="customerCenter">
        <span>캐츠 공감센터</span>
        <a href="tel:1660-1244" className="aLinkTel">
          <span>1660-1244</span>
        </a>
      </div>
      <footer>
        <p>COPYRIGHT ⓒ DANBI EDU ALL RIGHT RESERVED.</p>
      </footer>
    </div>
  );
}

export default HomeFooter;
