import { v4 as uuidv4 } from 'uuid';

import { useOverlayContext } from '@context/OverlayContext';

import Modal from './Modal';

function BaseModal({ id, title, content, buttons, ...rest }) {
  const { closeModal } = useOverlayContext();
  return (
    <Modal {...rest}>
      <Modal.Header hasClose>{title}</Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: 'center' }}>
          <span>{content}</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {buttons.map(({ isClose, className, action, label }) => {
          return isClose ? (
            <button
              key={uuidv4()}
              type="button"
              className={className}
              onClick={() => closeModal(id)}
            >
              {label}
            </button>
          ) : (
            <button
              key={uuidv4()}
              type="button"
              className={className}
              onClick={() => action(id)}
            >
              {label}
            </button>
          );
        })}
      </Modal.Footer>
    </Modal>
  );
}

export default BaseModal;
