import { Button } from '@components/ui';
import { useNavigate } from 'react-router-dom';

import Modal from './base/Modal';

function ModalResetPassword({ ...rest }) {
  const navigate = useNavigate();

  const goLogin = () => {
    setTimeout(() => {
      navigate('/login');
    });
  };

  return (
    <Modal {...rest}>
      <Modal.Header>비밀번호 설정완료</Modal.Header>
      <Modal.Body>비밀번호 재설정이 완료되었습니다!</Modal.Body>
      <Modal.Footer>
        <Button.Primary label="로그인하기" onClick={goLogin} />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalResetPassword;
