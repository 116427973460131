import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';

import Input from './Input';

function FormInput({
  label,
  type,
  name,
  time,
  className,
  isPassword = false,
  noMessage = false,
  ...rest
}) {
  const [focus, setFocus] = useState(false);
  const [inputType, setInputType] = useState(type);

  const {
    setValue,
    setFocus: setFormFocus,
    formState: { errors },
  } = useFormContext();

  const handleBlur = (event) => {
    if (event.relatedTarget?.dataset?.name === name) {
      setFocus(true);
    } else {
      setFocus(false);
    }
  };

  const handleFocus = () => {
    setFocus(true);
  };

  const handleClear = () => {
    setValue(name, '');
    setFormFocus(name);
  };

  const handleVisiable = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  return (
    <>
      {label && (
        <label className="label" htmlFor={name}>
          {label}
        </label>
      )}
      <div
        className={classnames(
          'inputBox',
          className && className,
          errors?.[name] && 'error',
          focus && 'isFocus',
          rest?.disabled && 'disabled',
        )}
      >
        <Input
          type={inputType}
          name={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...rest}
        />
        <button
          type="button"
          className="btnRemoveText"
          data-name={name}
          onClick={handleClear}
        >
          <span>입력삭제</span>
        </button>
        {isPassword && (
          <button
            type="button"
            className={classnames(
              'btnPasswordView',
              inputType !== 'password' && 'isActive',
            )}
            onClick={handleVisiable}
          >
            <span>비밀번호 숨기기</span>
          </button>
        )}
        {time && <span className="timeRemaining">{time}</span>}
      </div>
      {!noMessage && errors?.[name] && (
        <p className="errorMessage">{errors[name].message}</p>
      )}
    </>
  );
}

export default FormInput;
