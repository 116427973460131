import { memo } from 'react';
import Alarm from './base/Alarm';

function AlarmAcademyResult({ id, noticeLinkKey, ...rest }) {
  const handleClick = () => {
    console.log('입회 결과로 이동~', noticeLinkKey);
  };
  return <Alarm {...rest} onClick={handleClick} />;
}

export default memo(AlarmAcademyResult);
