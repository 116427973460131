/* eslint-disable jsx-a11y/alt-text */
import { format } from '@helper/timeHelper';
import dayjs from 'dayjs';
import AcademyInfoBox from './AcademyInfoBox';

function DefinitionBoxAcademy({
  title,
  payEndDate,
  diffDueDate,
  isNonPayment,
  ...rest
}) {
  return (
    <div className="definitionBox">
      <p className="boxTitle">{title}</p>
      <AcademyInfoBox {...rest} />

      {isNonPayment && (
        <div className="boxPaymentCycle">
          <ul className="subtopicList">
            <li className="typeUnpaid">
              <div className="txtLabel">미납된 결제일</div>
              <div className="txtSubtopic">
                {format(payEndDate, 'YYYY.MM.DD')}{' '}
                <span className="flagFilled tyUnpaid">미납</span>
              </div>
            </li>
            <li>
              <div className="txtLabel">다음 결제일</div>
              <div className="txtSubtopic">
                {/* {dayjs(payEndDate)} */}
                {format(dayjs(payEndDate).add(1, 'month'), 'YYYY.MM.DD')}
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default DefinitionBoxAcademy;
