/* eslint-disable jsx-a11y/alt-text */
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AcademyInfoBox, GoodDeliveryDate, GoodInfoBox } from '@components/box';
import { Button } from '@components/ui';
import { PAYMENT_TYPE } from '@constants/tab';
import { toCommaNumber } from '@helper/commonHelper';
import { clear as clearHistory } from '@store/slice/paymentHistorySlice';
import { clear as clearWaiting } from '@store/slice/paymentWaitingSlice';

import Popup from './base/Popup';

function PopupOrderSuccess({ id, order, ...rest }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goHistory = () => {
    dispatch(clearHistory());
    navigate('/payment-history', { replace: true });
  };

  const goWaiting = () => {
    dispatch(clearWaiting());
    navigate('/payment-waiting', { replace: true });
  };

  return (
    <Popup {...rest}>
      <Popup.Header id={id} onClose={goHistory}>
        결제완료
      </Popup.Header>
      <Popup.Body className="boxResultsPayment">
        <div className="boxFlexGrow">
          <div className="paymentResultsWrap">
            <div className="topBox">
              <span className="logo">CATS</span>
            </div>
            <div className="resultMessage">
              <p className="textMain">
                <b>결제가 완료</b>되었습니다.
              </p>
            </div>

            <div className="orderItemInfo">
              {/* 교환 */}
              {order.paymentType === PAYMENT_TYPE.EXCHANGE && (
                <dl className="infoDetails">
                  <div>
                    <dt>교환비용</dt>
                    <dd className="textTotalCost">
                      <b>{order.amount}</b>원
                    </dd>
                  </div>
                </dl>
              )}
              {order.paymentType === PAYMENT_TYPE.BOOK && (
                <>
                  <GoodInfoBox {...order} />
                  <GoodDeliveryDate />
                </>
              )}

              {order.paymentType === PAYMENT_TYPE.ACADEMY && (
                <AcademyInfoBox {...order} />
              )}

              {order.paymentType === PAYMENT_TYPE.ONLINE && (
                <div className="grayOrderItem typeCompletePayment">
                  <ul className="listOrderProduct">
                    <li>
                      <p className="textTitle">
                        {order.orderGoodsDtoList?.[0]?.goodsName}
                      </p>
                      <div className="textPrice">
                        <span className="txtNum">
                          {toCommaNumber(
                            order.orderPaymentDtoList?.[0]?.amount ||
                              order.price ||
                              order.orderPrice,
                          )}
                        </span>
                        <em className="txtUnit">원</em>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <Button.Wrapper flexNone>
          <Button.Outline large label="결제내역 목록" onClick={goHistory} />
          <Button.Primary large label="결제대기 목록" onClick={goWaiting} />
        </Button.Wrapper>
      </Popup.Body>
    </Popup>
  );
}

export default PopupOrderSuccess;
