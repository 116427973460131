import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';

import storage from '@utils/Storage';
import { useNavigate } from 'react-router-dom';

function RouteIndex({ url = '' }) {
  const navigate = useNavigate();
  useMount(() => {
    const hasSession = storage.hasSession();
    // 로그인 세션이 없고 초기 페이지는 마케팅 페이지
    if (!isEmpty(url) && !hasSession) {
      window.location.href = url;
    }
    if (!isEmpty(url) && hasSession) {
      navigate('/home');
    }
  });

  return null;
}

export default RouteIndex;
