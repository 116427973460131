/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import dayjs from 'dayjs';

import { read } from '@store/slice/homeSlice';
import { KO_DATE, format } from '@helper/timeHelper';

function Feed({ feed, onClick }) {
  const dispatch = useDispatch();

  const isNewFeed = useMemo(() => {
    return dayjs().diff(dayjs(feed.regDate), 'hour') < 24;
  }, [feed.regDate]);

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      dispatch(read({ feedId: feed.feedId }))
        .unwrap()
        .then(() => {
          onClick?.();
        });
    },
    [dispatch, feed, onClick],
  );

  return (
    <li>
      <a
        className={classnames('informBox', feed.readYn === 'Y' && 'typeReaded')}
        onClick={handleClick}
      >
        <div className="rowSource">
          {isNewFeed && (
            <span className="tagDotNew">
              <em className="hidden">new</em>
            </span>
          )}
          <span className={classnames('logoImg', !feed.logoUrl && 'noImg')}>
            {feed.logoUrl && <img src={feed.logoUrl} />}
          </span>
          <p className="txtAcademy">{feed.feedTitle}</p>
          {/* 나중에 추가할 수도 있음 - <p className="txtClass">내신집중 A반</p> */}
        </div>
        <div className="rowMessage">
          <p>{feed.feedContents}</p>
        </div>
        {feed?.message && (
          <div className="rowMsgContent">
            <p dangerouslySetInnerHTML={{ __html: feed.message }} />
          </div>
        )}
        {feed?.testDueDate && (
          <div className="rowTestDueDate">
            <span className="txtTitle">테스트 마감일</span>
            <span className="txtDate">
              {format(feed?.testDueDate, KO_DATE)}
            </span>
          </div>
        )}
        <div className="colFrom">
          <span className="txtName">{feed.studentName}</span>
          <span className="txtDate">{feed.feedRegDt}</span>
        </div>
      </a>
    </li>
  );
}

export default memo(Feed);
