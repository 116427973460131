import FormInput from './base/FormInput';

function FormEmail({ name = 'email', label = '아이디(이메일)', ...rest }) {
  return (
    <FormInput
      name={name}
      label={label}
      placeholder="예: cats@gmail.com"
      type="email"
      {...rest}
    />
  );
}

export default FormEmail;
