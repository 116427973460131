import { LEARNING_TABS, LEARNING_TYPE } from '@constants/tab';
import Tabs from './base/Tabs';

function LearningTab({ defaultType, ...rest }) {
  return (
    <Tabs
      isFixed
      tabs={LEARNING_TABS}
      defaultType={defaultType || LEARNING_TYPE.ACADEMY}
      {...rest}
    />
  );
}

export default LearningTab;
