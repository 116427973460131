/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDeepCompareEffect, useUnmount } from 'ahooks';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';
import { accountSelector } from '@store/slice/accountSlice';
import { toCommaNumber } from '@helper/commonHelper';
import {
  clear,
  getCheckOrder,
  orderSelector,
  rejectMessageSelector,
  setOrder,
} from '@store/slice/orderSlice';
import {
  makeBillingOnlineParams,
  makeCheckOrderOnlineParams,
} from '@helper/billingHelper';
import { DefinitionBoxAddress, DefinitionBoxPrice } from '@components/box';
import { PAYMENT_TYPE } from '@constants/tab';
import {
  ModalOrderOnlineReject,
  PopupPaymentMethod,
} from '@components/overlay';

import { ELEMENTARY_SCHOOL } from '@constants/school';
import OrderOnlineConfirm from './components/OrderOnlineConfirm';
import OrderOnlineLearnerSelector from './components/OrderOnlineLearnerSelector';

// 온라인 학습상품 > 상품선택 > 온라인 구독상품 결제
function OrderOnlinePage() {
  const dispatch = useDispatch();
  const { subjectCd } = useParams();

  const parents = useSelector(accountSelector);

  const { onlineProducts, order, checkOrder } = useSelector(orderSelector);
  const rejectMessage = useSelector(rejectMessageSelector);

  const { showDialog, showPopup } = useOverlayContext();

  const [apply, setApply] = useState(false);
  const [student, setStudent] = useState();
  const [delivery, setDelivery] = useState();

  // 결제 가격
  const totalPrice = useMemo(() => {
    return !isEmpty(checkOrder)
      ? checkOrder?.orderPaymentDtoList?.[0]?.amount
      : 0;
  }, [checkOrder]);

  const isElementary = useMemo(() => {
    return student?.courseCd === ELEMENTARY_SCHOOL;
  }, [student?.courseCd]);

  const includeSubject = useMemo(() => {
    const studentSubjects =
      student?.enrolledAcademyClass.reduce(
        (pre, current) => {
          return [...pre, ...current.teacherSubjects];
        },
        ['ALL'],
      ) || [];
    return studentSubjects.includes(subjectCd);
  }, [student?.enrolledAcademyClass, subjectCd]);

  const isEnable = useMemo(() => {
    if (isEmpty(student) || isEmpty(checkOrder)) {
      return false;
    }
    if (
      isElementary &&
      (isEmpty(delivery.address) ||
        isEmpty(delivery.detailAddress) ||
        isEmpty(delivery.zipCode))
    ) {
      return false;
    }
    return includeSubject && apply;
  }, [apply, checkOrder, delivery, includeSubject, isElementary, student]);

  // 가격체크
  const handleCheckPrice = useCallback(() => {
    const reqParam = makeCheckOrderOnlineParams({
      parents,
      student,
      order,
    });
    dispatch(getCheckOrder(reqParam));
  }, [dispatch, order, parents, student]);

  // 결제 주문
  const handleBilling = useCallback(() => {
    const reqParam = makeBillingOnlineParams({
      parents,
      student,
      checkOrder,
    });

    showPopup(PopupPaymentMethod, {
      delivery,
      billing: reqParam,
      goodsInfo: {
        ...checkOrder,
        paymentType: PAYMENT_TYPE.ONLINE,
      },
    });
  }, [checkOrder, delivery, parents, showPopup, student]);

  const handleSetDelivery = useCallback((value) => {
    setDelivery(value);
  }, []);

  const handleSetProduct = useCallback(
    (product) => {
      dispatch(setOrder(product));
    },
    [dispatch],
  );

  // 단과과목 중복 체크
  useDeepCompareEffect(() => {
    if (!isEmpty(student) && !includeSubject) {
      showDialog(ModalOrderOnlineReject, {
        rejectMessage: `${student?.memberName} 학생이 수강중인 과목이 아닙니다. 확인 후 구독해주시기 바랍니다.`,
        subjectCd,
        gradeCd: student?.gradeCd,
      });
    }
  }, [includeSubject, student]);

  // 오류창 띄우기
  useDeepCompareEffect(() => {
    if (!isEmpty(rejectMessage)) {
      showDialog(ModalOrderOnlineReject, {
        rejectMessage,
        subjectCd,
        gradeCd: student.gradeCd,
      });
    }
  }, [rejectMessage]);

  // 가격체크
  useDeepCompareEffect(() => {
    if (!isEmpty(order) && !isEmpty(student)) {
      handleCheckPrice();
    }
  }, [order]);

  useUnmount(() => {
    dispatch(clear());
  });

  return (
    <div className="contentBox boxPaying">
      <OrderOnlineLearnerSelector
        student={student}
        onSelectStudent={setStudent}
      />

      {isElementary && (
        <DefinitionBoxAddress
          {...parents}
          {...delivery}
          onSetAddress={handleSetDelivery}
        />
      )}

      <div className="definitionBox">
        <p className="boxTitle">학습선택</p>

        <div className="combinationProductBox">
          {onlineProducts?.map((product, index) => (
            <div
              className={classnames(
                'boxProductInfo',
                order.goodsId === product.goodsId && 'activeOn',
              )}
              key={`product_${index}`}
              onClick={() => handleSetProduct(product)}
            >
              <p className="textTitle">{product.goodsName}</p>

              <div className="rowPriceBox">
                <div className="textPrice">
                  <span className="txtNum">
                    <i>월</i>
                    {(product?.price && toCommaNumber(product?.price)) || '0'}
                  </span>
                  <em className="txtUnit">원</em>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {!isEmpty(checkOrder) && (
        <DefinitionBoxPrice
          isOnline
          price={totalPrice}
          category={[
            {
              name: '구독상품 정가',
              topic: (
                <>
                  <b>{toCommaNumber(checkOrder?.orderPrice || 0)}</b>원
                </>
              ),
            },
          ]}
        />
      )}

      <OrderOnlineConfirm onApply={setApply} />

      <div className="definitionBox typeFinalCta">
        <Button.Wrapper>
          <Button.Normal
            disabled={!isEnable}
            large
            className="btnSecondary"
            label={`${toCommaNumber(totalPrice) || 0}원 결제`}
            onClick={handleBilling}
          />
        </Button.Wrapper>
      </div>
    </div>
  );
}
export default OrderOnlinePage;
