import { useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

import { accountSelector } from '@store/slice/accountSlice';
import { format } from '@helper/timeHelper';
import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';
import { BaseModal, ModalButtons } from '@components/overlay';
import academyService from '@service/AcademyService';
import { MEMBER_TYPE_CODE } from '@constants/common';

import NoticeWriterBox from './NoticeWriterBox';

function NoticeReplyContent({
  assistantYn = 'N',
  notificationId,
  notificationCommentId,
  parentsNotificationCommentId,
  memberId,
  memberName,
  studentNames,
  commentMemberType,
  notificationCommentContents,
  regDate,
  modDate,
  useYn,
  children,
  onRefresh,
  isSubComment = false,
  isSecret = false,
}) {
  const [editMode, setEditMode] = useState(false);
  const [reply, setReply] = useState(false);

  const { memberNo: parentsId } = useSelector(accountSelector);

  const { showBottomSheet, showDialog, showToast, closeModal } =
    useOverlayContext();

  const handleReply = () => {
    setReply(true);
  };

  const handleModify = () => {
    setEditMode(true);
    closeModal();
  };

  const handleDelete = () => {
    const fetchDelete = async () => {
      try {
        await academyService.deleteComment({
          notificationId,
          notificationCommentId,
          memberId,
        });
        closeModal();
        showToast({ message: '삭제되었습니다.' });
        onRefresh?.();
      } catch (error) {
        console.warn(error);
      }
    };

    closeModal();
    showDialog(BaseModal, {
      title: '알림',
      content: `작성하신 댓글을 삭제하시겠습니까?`,
      buttons: [
        {
          label: '아니오',
          action: () => closeModal(),
          className: 'btnOutline',
        },
        {
          label: '네',
          action: () => fetchDelete(),
          className: 'btnPrimary',
        },
      ],
    });
  };

  const handleManage = () => {
    showBottomSheet(ModalButtons, {
      buttons: [
        { className: 'commentModify', label: '수정', onClick: handleModify },
        { className: 'commentDelete', label: '삭제', onClick: handleDelete },
      ],
    });
  };
  return (
    <li className={classnames(isSecret && 'secretComment')}>
      {!editMode && (
        <div className="replyContent">
          <p className="infoCommenter">
            {commentMemberType === MEMBER_TYPE_CODE.TEACHER && (
              <>
                <strong className="infoName">{memberName || ''} 선생님</strong>
                {assistantYn === 'Y' && (
                  <span className="tagAssistant">조교</span>
                )}
              </>
            )}
            {commentMemberType === MEMBER_TYPE_CODE.STUDENTS && (
              <strong className="infoName">{memberName || ''} 학생</strong>
            )}
            {commentMemberType === MEMBER_TYPE_CODE.PARENTS && (
              <strong className="infoName">{memberName || ''} 학부모</strong>
            )}
          </p>
          <div className="infoTxt" style={{ whiteSpace: 'break-spaces' }}>
            {notificationCommentContents}
          </div>
          <div className="infoReply">
            <span className="textDate">
              {isEmpty(modDate) ? format(regDate) : `${format(modDate)} (수정)`}
            </span>
            {useYn === 'Y' && !isSubComment && (
              <>
                {!reply && (
                  <Button.Normal
                    className="btnText btnReply"
                    label="답글 쓰기"
                    onClick={handleReply}
                  />
                )}
              </>
            )}
          </div>
          {useYn === 'Y' && parentsId === memberId && (
            <div className="boxManageMyComments">
              <Button.Normal
                className="btnViewMenu"
                label="댓글관리"
                onClick={handleManage}
              />
            </div>
          )}
        </div>
      )}

      {editMode && (
        <NoticeWriterBox
          notificationId={notificationId}
          notificationCommentId={notificationCommentId}
          parentsNotificationCommentId={parentsNotificationCommentId}
          contents={notificationCommentContents}
          onRefresh={() => {
            setEditMode(false);
            onRefresh?.();
          }}
          onCancel={() => {
            setEditMode(false);
          }}
          isSecret={isSecret}
          isEditMode
        />
      )}
      {reply && (
        <NoticeWriterBox
          notificationId={notificationId}
          parentsNotificationCommentId={notificationCommentId}
          onRefresh={() => {
            setReply(false);
            onRefresh?.();
          }}
          onCancel={() => {
            setReply(false);
          }}
          isSecret={isSecret}
        />
      )}
      {children && children}
    </li>
  );
}

export default NoticeReplyContent;
