/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMount } from 'ahooks';

import { accountSelector } from '@store/slice/accountSlice';
import { PaymentHistoryAcademyAutoPay } from '@components/payment';
import orderService from '@service/OrderService';

function PaymentManageAcademyPage() {
  const { memberNo } = useSelector(accountSelector);

  const [history, setHistory] = useState();

  const fetchAcademySubscribe = () => {
    (async () => {
      try {
        const result = await orderService.getAcademySubscribe({ memberNo });
        setHistory(result);
      } catch (error) {
        console.warn(error);
      }
    })();
  };

  useMount(() => {
    fetchAcademySubscribe();
  });

  return (
    <div className="contentBox boxListType">
      <div className="listDivision">
        <div className="listTitle">
          <strong>자동 결제 중인 학원비</strong>
        </div>
        <ul className="listItems">
          {history &&
            history.map((item, index) => (
              <PaymentHistoryAcademyAutoPay
                key={`${history}_${index}`}
                onSuccess={() => fetchAcademySubscribe()}
                {...item}
              />
            ))}
        </ul>
      </div>
      {history && history.length === 0 && (
        <div className="boxFlexGrow typeMiddle">
          <div className="noList">
            <div className="icoNoList">
              <p>자동결제내역이 없습니다.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentManageAcademyPage;
