/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import classnames from 'classnames';

import useScroll from '@hooks/useScroll';
import { BOARD } from '@constants/board';
import { format, ONLY_DATE } from '@helper/timeHelper';
import {
  boardPostSelector,
  clear,
  getBoardList,
} from '@store/slice/boardSlice';
import { useUnmount } from 'ahooks';

function NoticePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const noticeRef = useRef(null);
  const { isBottom, reset } = useScroll({ ref: noticeRef });

  const { boardList, hasNext, isFetch } = useSelector(boardPostSelector);

  useEffect(() => {
    if (boardList.length !== 0 && hasNext && isBottom) {
      // reset();
      dispatch(getBoardList({ boardId: BOARD.NOTICE }));
    }
  }, [dispatch, hasNext, isBottom, boardList.length, reset]);

  useEffect(() => {
    if (boardList.length === 0) {
      dispatch(getBoardList({ boardId: BOARD.NOTICE, pageNo: 1 }));
    }
  }, [boardList.length, dispatch]);

  const goDetail = (id) => {
    navigate(`/notice-detail/${id}`);
  };

  useUnmount(() => {
    dispatch(clear());
  });

  return (
    <div className="contentBox boxCsNotice" ref={noticeRef}>
      {isFetch && (
        <ul className="listBoard">
          {!isEmpty(boardList) &&
            boardList.map((item) => (
              <li
                key={`notice_${item.postId}`}
                className={classnames(item.noticeYn === 'Y' && 'rowNotice')}
              >
                {item.noticeYn === 'Y' && (
                  <span className="flagOutline tyCsNotice">공지</span>
                )}
                {item.noticeYn === 'N' && (
                  <span className="textOrder">{item.idx}</span>
                )}
                <a
                  className="aLinkNotice"
                  onClick={() => goDetail(item.postId)}
                >
                  <p className="textTitle">
                    {item.postTitle}
                    {dayjs().diff(dayjs(item.regDate), 'hour') < 24 && (
                      <span className="icoNew" style={{ marginLeft: '5px' }}>
                        <em>New</em>
                      </span>
                    )}
                  </p>
                  <p className="textDate">{format(item.regDate, ONLY_DATE)}</p>
                </a>
              </li>
            ))}
        </ul>
      )}
      {isFetch && boardList.length === 0 && (
        <div className="boxFlexGrow typeMiddle">
          <div className="noList">
            <div className="icoNoList">
              <p>공지사항 목록이 없습니다.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NoticePage;
