import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import Feed from './base/Feed';

function FeedTaskReport({ feed }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/learning-report-task/${feed.feedLinkKey}`);
  };

  return <Feed feed={feed} onClick={handleClick} />;
}

export default memo(FeedTaskReport);
