import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { PopupAddress, PopupSelectAddress } from '@components/overlay';
import {
  BOOK_DELIVERY_METHOD,
  BOOK_DELIVERY_TYPE,
  DELIVERY_MESSAGE,
} from '@constants/order';
import { useOverlayContext } from '@context/OverlayContext';
import {
  addressSelector,
  deliverySelector,
  setDemand,
} from '@store/slice/orderSlice';
import { toPhoneNumber } from '@helper/commonHelper';
import { Button, DropDown } from '@components/ui';

function DefinitionBoxDelivery({ order }) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState(null);

  const { address, isFetch } = useSelector(addressSelector);
  const { delivery, deliveryMethod } = useSelector(deliverySelector);

  const { showPopup } = useOverlayContext();

  const handleSelect = (item) => {
    setMessage(item.value);
    dispatch(setDemand(item.value));
  };
  const showDeliveryPopup = () => {
    showPopup(PopupAddress);
  };

  const showChangeAddress = () => {
    showPopup(PopupSelectAddress, { targetId: delivery.addressId });
  };

  return (
    <>
      {isFetch && (
        <div className="definitionBox typeDelivery">
          <p className="boxTitle">배송지 정보</p>
          {/* 배송지 정보 없음 */}
          {deliveryMethod !== BOOK_DELIVERY_METHOD.BATCH_SEND &&
            address.length === 0 && (
              <div className="boxNoAddress">
                <p className="textNoData">배송받을 곳의 주소를 입력해주세요.</p>
                <Button.Outline
                  small
                  label="배송지 입력"
                  onClick={showDeliveryPopup}
                />
              </div>
            )}

          {deliveryMethod !== BOOK_DELIVERY_METHOD.BATCH_SEND &&
            !isEmpty(delivery) && (
              <>
                <dl className="grayOrderInfo deliveryInfo">
                  <div>
                    <dt>수령인</dt>
                    <dd>
                      {delivery.name}
                      <Button.Outline
                        className="btnAddressChange"
                        label="변경"
                        onClick={showChangeAddress}
                      />
                    </dd>
                  </div>
                  <div>
                    <dt>연락처</dt>
                    <dd>{toPhoneNumber(delivery.mobile)}</dd>
                  </div>
                  <div>
                    <dt>배송지</dt>
                    <dd>
                      {delivery.title} ( {delivery.addr} )
                    </dd>
                  </div>
                </dl>

                <div className="deliveryMessageBox">
                  <DropDown
                    defaultMessage="배송시 요청사항을 선택해주세요."
                    onSelect={handleSelect}
                    items={DELIVERY_MESSAGE}
                  />

                  <div className="textarea">
                    <textarea
                      onChange={(e) => setMessage(e.target.value)}
                      onBlur={() => dispatch(setDemand(message))}
                      value={message || ''}
                      rows="3"
                      maxLength="50"
                      placeholder="배송시 요청사항을 입력해주세요. (최대 50자)"
                    />
                  </div>
                </div>
              </>
            )}
          {/* 학원배송인 경우 */}
          {deliveryMethod === BOOK_DELIVERY_METHOD.BATCH_SEND && (
            <>
              <p className="textBundle">학원으로 일괄배송됩니다.</p>
              <dl className="grayOrderInfo deliveryInfo">
                <div>
                  <dt>수령인</dt>
                  <dd>{order.receiverName}</dd>
                </div>
                <div>
                  <dt>연락처</dt>
                  <dd>{toPhoneNumber(order.receiverTel)}</dd>
                </div>
                <div>
                  <dt>배송지</dt>
                  <dd>
                    {order.academyName} ({order.addrBase} )
                  </dd>
                </div>
              </dl>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default DefinitionBoxDelivery;
