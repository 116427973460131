const PAYMENT_GOODS_TYPE = {
  SUBSCRIPTION: '캐츠 학습',
  ACADEMY_FEE: '학원비',
  TEACHER_TEXTBOOK: '교재',
};

const DELIVERY_MESSAGE = [
  {
    label: '요청사항을 직접 입력합니다.',
    value: '',
  },
  {
    label: '배송 전에 미리 연락 바랍니다.',
    value: '배송 전에 미리 연락 바랍니다.',
  },
  {
    label: '부재시 경비실에 맡겨주세요.',
    value: '부재시 경비실에 맡겨주세요.',
  },
  {
    label: '부재시 전화 주시거나 문자 남겨주세요.',
    value: '부재시 전화 주시거나 문자 남겨주세요.',
  },
];

const BOOK_DELIVERY_TYPE = {
  ACADEMY: 'ACADEMY_BATCH',
  HOME: 'BUYER_INDIVIDUAL',
};

const BOOK_DELIVERY_METHOD = {
  IMMEDIATELY: 'IMMEDIATELY', // 개별배송(집)
  BATCH_SEND: 'BATCH_SEND', // 일괄배송(학원)
};

const SHIPPING_TYPE = {
  A103_ORDER_CONFIRMING: '인쇄준비중',
};

const COUPON_TYPE = {
  // 학원연계
  ACADEMY: 'ACADEMY',
  // 윙크 회원
  WINK: 'WINK',
};

const COUPON_GOODS_KIND = {
  // 전과목
  GOODS_KIND_SUB_ALL: 'GOODS_KIND_SUB_ALL',
  // 단과
  GOODS_KIND_SUB_PART: 'GOODS_KIND_SUB_PART',
  // 교재
  GOODS_KIND_TEXTBOOK: 'GOODS_KIND_TEXTBOOK',
};

const BILL_PAY_STATE_CODE = {
  DIRECT_PAY: 'CD13241',
  COMPLETE: 'CD13242',
  MANUAL_COMPLETE: 'CD13962',
  CANCEL: 'CD13243',
  REFUND: 'CD13244',
  NON_PAY: 'CD13245',
  USING: 'CD12736',
};

const BILL_PAY_STATE = {
  [BILL_PAY_STATE_CODE.DIRECT_PAY]: '완납',
  [BILL_PAY_STATE_CODE.COMPLETE]: '결제완료',
  [BILL_PAY_STATE_CODE.CANCEL]: '결제취소',
  [BILL_PAY_STATE_CODE.REFUND]: '환불',
  [BILL_PAY_STATE_CODE.NON_PAY]: '미납',
  [BILL_PAY_STATE_CODE.USING]: '사용중',
};

const BILL_METHOD_CODE = {
  SINGLE: 'CD12735',
  REGULAR: 'CD12734',
};

const CLAIM_TYPE = {
  ORDER_MISTAKE: 'order',
  BROKEN_BOOK: 'book',
  DELIVERY: 'delivery',
};

const CLAIM_TYPE_MESSAGE = {
  [CLAIM_TYPE.ORDER_MISTAKE]: '단순 변심 및 주문 실수',
  [CLAIM_TYPE.BROKEN_BOOK]: '교재의 파손 및 인쇄 불량',
  [CLAIM_TYPE.DELIVERY]: '다른 상품이 배송됨',
};

const CLAIM_SOLUTION_TYPE = {
  EXCHANGE: '1',
  REFUND: '0',
};

const ORDER_CANCEL_TYPE = {
  ORDER_MISTAKE: 'order',
  RE_ORDER: 'reOrder',
  ETC: 'etc',
};

const ORDER_CANCEL_MESSAGE = {
  [ORDER_CANCEL_TYPE.ORDER_MISTAKE]: '단순 변심 및 주문 실수',
  [ORDER_CANCEL_TYPE.RE_ORDER]: '다시 결제하기 위해서',
  [ORDER_CANCEL_TYPE.ETC]: '기타',
};

export {
  DELIVERY_MESSAGE,
  BOOK_DELIVERY_TYPE,
  BOOK_DELIVERY_METHOD,
  COUPON_GOODS_KIND,
  COUPON_TYPE,
  BILL_PAY_STATE_CODE,
  BILL_PAY_STATE,
  CLAIM_TYPE,
  CLAIM_TYPE_MESSAGE,
  CLAIM_SOLUTION_TYPE,
  ORDER_CANCEL_TYPE,
  ORDER_CANCEL_MESSAGE,
  PAYMENT_GOODS_TYPE,
  SHIPPING_TYPE,
  BILL_METHOD_CODE,
};
