import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { isObject } from 'lodash';

import { phonePatten } from '@constants/validate';
import { VERIFY_STATUS } from '@constants/common';
import useCheckAccount from '@hooks/useCheckAccount';
import useMobileVerify from '@hooks/useMobileVerify';
import { timeFormat } from '@helper/timeHelper';

import FormInput from './base/FormInput';
import FormHidden from './base/FormHidden';
import FormWithButton from './base/FormWithButton';

function FormMobileVerify({ password = false }) {
  const { watch, reset, setValue, getValues, trigger } = useFormContext();

  const { isTimeout, timer, verifyCode, sendCode } = useMobileVerify();

  const { checkAccount } = useCheckAccount({
    complete: () => reset(),
  });

  const verifyStatus = watch('verifyStatus');

  const handleSendCode = async () => {
    const mobileNumber = getValues('mobileNumber');
    const memberName = getValues('memberName');
    // 전송 시 인증과 관련된 값 제거
    setValue('verifyStatus', VERIFY_STATUS.READY);
    setValue('requestId', '');
    setValue('verificationCode', '');

    // call API
    const result = await sendCode({ mobileNumber, memberName, password });
    setValue('requestId', result?.requestId);

    // 정상인 경우
    if (result?.requestId) {
      setValue('verifyStatus', VERIFY_STATUS.SEND);
    } else if (password && !result?.requestId) {
      setValue('verifyStatus', VERIFY_STATUS.INVALID_NO_ACCOUNT);
    }

    // revalidate
    trigger('verificationCode');
    trigger('mobileNumber');
  };

  const handleVerifyCode = useCallback(async () => {
    const [memberName, mobileNumber, verificationCode, requestId] = getValues([
      'memberName',
      'mobileNumber',
      'verificationCode',
      'requestId',
    ]);

    const result = await verifyCode({
      memberName,
      mobileNumber,
      verificationCode,
      requestId,
      password,
    });

    // 인증 성공하면 계정 상태 확인(통합계정, 중복확인 등등)
    if (result?.roles) {
      checkAccount(result.roles);
      setValue('verifyStatus', VERIFY_STATUS.VALID);
    }

    // 인증 성공
    if (isObject(result)) {
      setValue('authToken', result.authToken);

      if (result.memberNo) {
        setValue('memberNo', result.memberNo);
      }

      setValue('verifyStatus', VERIFY_STATUS.VALID);
    }
    // 인증 실패
    else {
      setValue('verifyStatus', VERIFY_STATUS.INVALID_CODE);
    }
    trigger('verificationCode');
    trigger('authToken');
  }, [password, checkAccount, getValues, setValue, verifyCode, trigger]);

  const handleValiateCode = useCallback(
    (code) => {
      const value = getValues('verifyStatus');

      if (value === VERIFY_STATUS.INVALID_CODE) {
        return '인증번호를 다시 확인해주세요.';
      }
      if (value === VERIFY_STATUS.INVALID_NO_ACCOUNT) {
        return '입력하신 휴대폰 번호는 가입되지 않는 계정입니다.';
      }
      if (value === VERIFY_STATUS.INVALID_TIMEOUT) {
        return '입력시간이 경과되었습니다.';
      }
      if (code.length !== 6) {
        return '인증번호 6자리를 입력해주세요.';
      }

      return true;
    },
    [getValues],
  );

  const statusMessage = useMemo(() => {
    if (verifyStatus === VERIFY_STATUS.SEND) {
      return <p className="successMessage">인증번호를 발송했습니다.</p>;
    }
    if (verifyStatus === VERIFY_STATUS.VALID) {
      return <p className="successMessage">인증되었습니다.</p>;
    }
  }, [verifyStatus]);

  useEffect(() => {
    if (isTimeout) {
      setValue('verifyStatus', VERIFY_STATUS.INVALID_TIMEOUT);
      // revalidate
      trigger('verificationCode');
    }
  }, [isTimeout, setValue, trigger]);

  const statusBtnText = useMemo(() => {
    return verifyStatus === VERIFY_STATUS.READY
      ? '인증번호 받기'
      : '인증번호 다시 받기';
  }, [verifyStatus]);

  return (
    <>
      <li>
        <FormWithButton
          label="휴대폰 번호"
          button={{ action: handleSendCode, label: statusBtnText }}
        >
          <FormInput
            noMessage
            type="number"
            name="mobileNumber"
            placeholder="'-'를 제외하고 입력해주세요."
            onChange={() => {
              setValue('verifyStatus', VERIFY_STATUS.READY);
              setValue('requestId');
              setValue('authToken');
            }}
            option={{
              required: '휴대폰 번호를 정확하게 입력해주세요.',
              validate: (value) => {
                const status = getValues('verifyStatus');
                if (status === VERIFY_STATUS.INVALID_NO_ACCOUNT) {
                  return '입력하신 이름/휴대폰 번호는 가입되지 않는 계정입니다.';
                }
                return (
                  phonePatten.test(value) ||
                  '휴대폰 번호를 정확하게 입력해주세요.'
                );
              },
            }}
          />
        </FormWithButton>
        <FormHidden name="requestId" />
        <FormHidden
          name="authToken"
          option={{
            required: '휴대폰 번호를 인증해주세요.',
          }}
        />
        <FormHidden name="verifyStatus" />
      </li>
      {verifyStatus !== VERIFY_STATUS.INVALID_NO_ACCOUNT &&
        verifyStatus !== VERIFY_STATUS.READY && (
          <li>
            <FormWithButton
              className="typeVerification"
              label="인증번호"
              button={{
                action: handleVerifyCode,
                label: '확인',
                disabled: isTimeout || verifyStatus === VERIFY_STATUS.VALID,
              }}
            >
              <FormInput
                noMessage
                type="number"
                name="verificationCode"
                placeholder="인증번호 6자리 입력"
                option={{
                  validate: handleValiateCode,
                }}
                maxLength="6"
                time={timeFormat(timer)}
              />
            </FormWithButton>
            {statusMessage && statusMessage}
          </li>
        )}
    </>
  );
}

export default FormMobileVerify;
