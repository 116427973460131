import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { passwordPatten } from '@constants/validate';
import FormInput from './base/FormInput';

function FormPassword({
  name = 'password',
  label = '비밀번호',
  placeholder = '8~16자리 영문, 숫자',
  confirm = false,
  ...rest
}) {
  const { getValues } = useFormContext();

  const option = useMemo(() => {
    return confirm
      ? {
          required: '비밀번호가 일치하지 않습니다.',
          validate: (value) => {
            const originPassword = getValues('password');
            if (originPassword !== value) {
              return '비밀번호가 일치하지 않습니다.';
            }
            return true;
          },
        }
      : {
          required: '8~16자리 영문, 숫자를 포함하여 입력해주세요.',
          validate: (value) =>
            passwordPatten.test(value) ||
            '8~16자리 영문, 숫자를 포함하여 입력해주세요.',
        };
  }, [confirm, getValues]);

  return (
    <FormInput
      isPassword
      type="password"
      name={name}
      label={label}
      placeholder={placeholder}
      option={option}
      maxLength="16"
      {...rest}
    />
  );
}

export default FormPassword;
