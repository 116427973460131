import { MathJaxText } from '@components/ui';

/* eslint-disable jsx-a11y/anchor-is-valid */
function LearningStatusOnlineHomework({
  completeYn,
  semesterNm,
  studentNm,
  subjectNm,
  sectionNm,
  examPaperNm,
  elements,
}) {
  return (
    <li>
      <div className="subjectHmeStudy">
        <div className="boxTopEtc">
          {examPaperNm && <span className="boxForExam">시험대비학습</span>}
          {completeYn === 'Y' && (
            <span className="flagFilled tyCompleted">완료</span>
          )}
        </div>
        <div className="boxLearner">
          <span className="textName">{studentNm}</span>
          <span className="textSemester">{semesterNm}</span>
        </div>
        <div className="boxSubject">
          <strong className="textSubject">{subjectNm}</strong>
          <div className="textCurriculum">
            <p className="textMain">
              <MathJaxText text={sectionNm || examPaperNm} />
            </p>
          </div>
        </div>
        {examPaperNm && (
          <div className="boxLearning typeBtnBox">
            <div className="btnsWrapper">
              <button
                type="button"
                className="btnOutline midium"
                style={{ height: '40px' }}
              >
                <span>열람하기</span>
              </button>
            </div>
          </div>
        )}
        {!examPaperNm && (
          <div className="boxLearning">
            <div className="scrollBox">
              <ul className="learningSequence">
                {elements.map((item) => (
                  <li key={`element_${item.elementId}`}>
                    <div className="subSection typeDone">
                      {item.completeYn === 'Y' && (
                        <span className="flagDone">완료</span>
                      )}
                      <p>{item.elementNm}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </li>
  );
}

export default LearningStatusOnlineHomework;
