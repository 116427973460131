import {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMount } from 'ahooks';
import { first, isEmpty } from 'lodash';

import {
  accountSelector,
  clear,
  getParent,
  setUser,
} from '@store/slice/accountSlice';
import { clear as clearHome } from '@store/slice/homeSlice';
import {
  ModalError,
  ModalIntegratedUserConfirm,
  ModalRejectTrial,
  ModalRequestChangePassword,
} from '@components/overlay';
import storage from '@utils/Storage';
import accountService from '@service/AccountService';
import orderService from '@service/OrderService';
import callApp from '@interface/externalInterface';

import { useOverlayContext } from './OverlayContext';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const { state } = useLocation();

  const [isTeacher] = useState(storage.isOnlyTeacherRole());
  const { showDialog, showBottomSheet } = useOverlayContext();

  const { memberNo } = useSelector(accountSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginComplete = useCallback(
    (redirect) => {
      if (state?.from) {
        navigate(`${state?.from?.pathname}${state?.from?.search}`, {
          replace: true,
        });
      } else {
        navigate(redirect, { replace: true });
      }
    },
    [navigate, state?.from],
  );

  const requestTrial = (account) => {
    const student = first(account.family)?.studentMember || {};

    (async () => {
      try {
        await orderService.requestTrial(
          {
            memberNo: account.memberNo,
            memberName: account.memberName,
            courseCode: student.courseCd,
            students: {
              memberNo: student.memberNo,
              memberName: student.memberName,
            },
          },
          {
            ignore: true,
          },
        );
      } catch (_) {
        showBottomSheet(ModalRejectTrial);
      }
    })();
  };

  const login = async ({
    username,
    mobileNum,
    password,
    redirect = '',
    isRequestTrial = false,
    keepLogin = true,
  }) => {
    const result = await accountService.login(
      { username, mobileNum, password },
      {
        ignore: true,
      },
    );
    // 로그인 실패
    if (result === 'FAILED') {
      return false;
    }
    const { accessToken, refreshToken, data, details, pwdChangeRequired } =
      result;

    // 계정정보 저장
    dispatch(
      setUser({
        ...details,
        ...data,
        ...(isEmpty(details) && {
          mobileNumber: data.mobile,
          memberName: data.userNm,
        }),
      }),
    );

    storage.setSession(
      accessToken,
      refreshToken,
      details?.memberNo || data.id,
      data.userRoles,
    );
    storage.setKeepLogin(keepLogin);

    // 회원가입과 동시에 무료주문 넣기
    if (isRequestTrial && !isEmpty(details)) {
      requestTrial(details);
    }

    // 일단 모달창 닫힌 후에 다음 화면 보여주기
    if (!isEmpty(details) && pwdChangeRequired === 'Y') {
      showBottomSheet(ModalRequestChangePassword, {
        onSuccess: (path) => loginComplete(path),
      });
      return result;
    }

    // 통합계정이 아닌 경우에만 로그인 완료 처리
    if (!isEmpty(details)) {
      loginComplete(redirect);
    }
    // 통합계정인 경우
    else {
      showBottomSheet(ModalIntegratedUserConfirm, {
        createAt: data?.accountCreatedAt,
      });
    }

    return result;
  };

  const refreshAccount = async (value) => {
    try {
      await dispatch(getParent(value)).unwrap();
    } catch (error) {
      showDialog(ModalError);
    }
  };

  const logout = () => {
    // 토큰 삭제
    storage.clearToken();
    // 상태 제거
    dispatch(clear());
    dispatch(clearHome());

    // 웹뷰인 경우 logout 호출
    callApp('logout');

    if (window.appInterface.DEVICE === 'WEB') {
      navigate('/login');
    }
  };

  const isViewComponent = useMemo(() => {
    // 세션이 없으면 그냥 리다이렉트로 빠지거나, 로그인/회원가입 페이지 임
    if (!storage.hasSession()) {
      return true;
    }
    return !!memberNo;
  }, [memberNo]);

  // 자동 로그인
  useMount(async () => {
    if (!storage.hasSession()) {
      // 로그인 상태 유지가 아닌데 토큰이 남아 있는 경우 지우기
      storage.clearToken();
      dispatch(clear());
    }
    // 사용자 정보가 있으면 조회하기
    if (storage.hasSession()) {
      await refreshAccount({ id: storage.getMemberNo() });
    }

    // 통합계정인 경우
    if (isTeacher) {
      showBottomSheet(ModalIntegratedUserConfirm);
    }
  });

  return (
    <AuthContext.Provider value={{ login, logout, refreshAccount }}>
      {isViewComponent && children}
    </AuthContext.Provider>
  );
}

function useAuthContext() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuthContext };
