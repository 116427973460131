import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { format } from './timeHelper';

const getStudent = ({ students, order }) => {
  return students.find((member) => member.memberNo === `${order.studentNo}`)
    ?.studentMember;
};

const getCardInfo = (payment) => {
  return `${payment?.paymentDesc} (${payment?.cardNumber?.slice(0, 4)})`;
};

const getServicePeriod = (payment) => {
  return dayjs().diff(payment.serviceStartDate) < 0
    ? '-'
    : `${format(payment?.serviceStartDate, 'YYYY.MM.DD')} ~ ${format(
        payment?.serviceEndDate,
        'YYYY.MM.DD',
      )}`;
};

const makeBillingParams = ({ parents, delivery, deliveryMethod, linkId }) => {
  return {
    linkId,
    scope: 'CARD',
    // 주문자 정보
    memberName: parents.memberName,
    memberNo: parents.memberNo,
    memberTel: parents.mobileNumber,
    // 배송지 정보
    deliveryMethodCode: deliveryMethod,
    addrBase: delivery.addr,
    addrDetail: delivery.addrDetail,
    receiverName: delivery.name,
    receiverTel: delivery.mobile,
    zipcode: delivery.post,
    demand: delivery.demand,
    // 주문정보
    isCulturalExpensesRequestYn: 'N',
    // 피드 데이터 있으면 지우기 위해서
    feedLinkKey: linkId,
  };
};

const makeBillingOnlineParams = ({ parents, student, checkOrder }) => {
  return makeCheckOrderOnlineParams({
    parents,
    student,
    order: checkOrder.orderGoodsDtoList[0],
  });
};

const makeCheckOrderOnlineParams = ({ parents, student, order }) => {
  return {
    scope: 'BILLING',
    // 주문자 정보
    memberName: parents.memberName,
    memberNo: parents.memberNo,
    memberTel: parents.mobileNumber,
    // 주문 정보
    clientKindCode: 'PARENT_APP',
    isCulturalExpensesRequestYn: 'N',
    orderName: order.orderName || order.goodsName,
    orderGoodsList: [
      {
        couponId: order.couponId,
        goodsId: order.goodsId,
        // TODO: 여기 이거는 뭐지?
        // lumpSumYn: order.contractKindCode === 'LIMIT' ? 'Y' : 'N',
        lumpSumYn: 'N',
        orderEa: 1,
        studentName: student.memberName,
        studentNo: Number(student.memberNo),
      },
    ],

    // 피드 데이터 있으면 지우기 위해서
    parentNo: parents.memberNo,
    studentNo: student.memberNo,
    feedLinkKey: order.goodsId,
    type: 'SUBSCRIBE',
  };
};

export {
  getStudent,
  getCardInfo,
  getServicePeriod,
  makeCheckOrderOnlineParams,
  makeBillingParams,
  makeBillingOnlineParams,
};
