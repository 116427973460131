import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

import { BOARD } from '@constants/board';
import boardService from '@service/BoardService';

function FaqPosts({ posts, keyword, isCategory = false }) {
  const [postId, setPostId] = useState('');
  const [post, setPost] = useState('');

  const handleSearch = (id) => {
    const fetchPost = async (key) => {
      const response = await boardService.getPost({
        boardId: BOARD.FAQ,
        postId: key,
      });
      setPost(response);
    };

    setPost({});
    setPostId(id);
    fetchPost(id);
  };

  const wrapKeyword = useCallback(
    (title) => {
      if (keyword) {
        const startIdx = title.indexOf(keyword);

        return (
          <span>
            {title.substring(startIdx, -1)}
            <b className="keyword">{keyword}</b>
            {title.substring(startIdx + keyword.length, title.length)}
          </span>
        );
      }
      return title;
    },
    [keyword],
  );

  return (
    <ul className="listFaq">
      {posts.map((item) => (
        <li
          key={`post_${item.postId}`}
          className={classnames(postId === item.postId && 'isActive')}
        >
          <button
            type="button"
            className="btnTextQuestion"
            disabled={postId === item.postId}
            onClick={() => handleSearch(item.postId)}
          >
            <div className="txtBox">
              {!isCategory && <em>{`[${item.depth1}]`}</em>}
              <span>{wrapKeyword(item.postTitle)}</span>
            </div>
          </button>
          {!isEmpty(post) && postId === item.postId && (
            <>
              <div className="boxAnswerContent">
                <div
                  className="answerDetails"
                  dangerouslySetInnerHTML={{ __html: post.postDesc }}
                />
              </div>
              {post.fileList && post.fileList.length > 0 && (
                <ul className="answerFiles">
                  {post.fileList.map((file) => (
                    <li key={`post_file_${file.url}`}>
                      <Link
                        to={file.url}
                        target="_blank"
                        className="btnfile"
                        download
                      >
                        <div className="innerBtnBox">
                          <span className="fileName">{file.orgFileNm}</span>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
        </li>
      ))}
    </ul>
  );
}

export default FaqPosts;
