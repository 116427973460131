import api from '@utils/Api';

class LearningService {
  constructor(client) {
    this.httpClient = client;
  }

  getAcademyHomework({ studentIds, submitYn }, options = {}) {
    return this.httpClient.get(
      `/v1/learning/academy-homework`,
      {
        studentIds,
      },
      options,
    );
  }

  getAcademyHomeworkPast({ studentIds, page, pageSize = 10 }, options = {}) {
    return this.httpClient.get(
      `/v1/learning/academy-homework/past-list`,
      {
        studentIds,
        page,
        pageSize,
      },
      options,
    );
  }

  getOnlineHomework({ studentIds }, options = {}) {
    return this.httpClient.get(
      `/v1/learning/subject-homework`,
      {
        studentIds,
      },
      options,
    );
  }

  getOnlineHomeworkPast({ studentIds, pageNo, pageSize = 10 }, options = {}) {
    return this.httpClient.get(
      `/v1/learning/subject-homework/past-page`,
      {
        studentIds,
        pageNo,
        pageSize,
      },
      options,
    );
  }

  getExamPeriod({ studentIds }, options = {}) {
    return this.httpClient.get(
      `/v1/learning/prepare-exam/period`,
      {
        studentIds,
      },
      options,
    );
  }
}

const learningService = new LearningService(api);
export default learningService;
