function DefinitionBoxSelection({ title, category }) {
  return (
    <div className="definitionBox">
      <p className="boxTitle">{title}</p>
      <div className="graySelectionBox">
        <ul>
          {category.map(({ topic }, index) => (
            <li key={`title_${title}_${index}`}>{topic}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default DefinitionBoxSelection;
