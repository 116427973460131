import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';

import { AutoComplete, FieldSet, SignUpSteps } from '@components/common';
import {
  FormHidden,
  FormName,
  FormMobileDuplicated,
  FormStudentGrade,
} from '@components/form';
import { accountSelector } from '@store/slice/accountSlice';
import {
  ELEMENTARY,
  ELEMENTARY_SCHOOL,
  MIDDLE,
  MIDDLE_SCHOOL,
} from '@constants/school';
import { TermsOfService } from '@components/terms';
import { DUPLICATE_STATUS, USER_TYPE } from '@constants/common';
import { Button, PasswordGuide } from '@components/ui';
import useAccount from '@hooks/useAccount';
import storage from '@utils/Storage';
import { clear } from '@store/slice/homeSlice';
import { useOverlayContext } from '@context/OverlayContext';
import { ModalVerifyUser } from '@components/overlay';
import { useAppContext } from '@context/AppContext';

// 자녀추가
function AddChildPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const { isWinkDevice } = useAppContext();

  const { showDialog } = useOverlayContext();
  const parents = useSelector(accountSelector);

  const isChildAddPage = useMatch({
    path: '/child-add',
  });

  const isChildLinkPage = useMatch({
    path: '/child-link',
  });

  const isChildIntegratedPage = useMatch({
    path: '/child-integrated',
  });

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      dupCheck: DUPLICATE_STATUS.NOT_CHECK,
      inboundChannel: searchParams.get('promotion_code') || '',
    },
  });

  const {
    formState: { isValid, dirtyFields },
    reset,
    setValue,
    trigger,
    handleSubmit,
    watch,
  } = methods;

  const studentNo = watch('studentNo');

  const { join, link, integrated, schoolCode } = useAccount({
    complete: (result) => {
      reset(result);
      dispatch(clear());
    },
  });

  const handleSelect = useCallback(
    (item) => {
      // 리셋인 경우
      if (isEmpty(item)) {
        setValue('schoolId');
        setValue('courseCd');
        setValue('gradeCd');
        return;
      }

      setValue('schoolId', item.schoolId);
      setValue('courseCd', item.courseInstructionCode);

      // 학교 선택 시 학년 기본 설정
      if (item.courseInstructionCode === ELEMENTARY_SCHOOL) {
        setValue('gradeCd', ELEMENTARY[0].value);
      }
      if (item.courseInstructionCode === MIDDLE_SCHOOL) {
        setValue('gradeCd', MIDDLE[0].value);
      }
      trigger('courseCd');
    },
    [setValue, trigger],
  );

  const handleVerifyUser = (params) => {
    showDialog(ModalVerifyUser, params);
  };

  const goKakao = () => {
    navigate('/redirect', {
      state: { url: 'https://pf.kakao.com/_zsxdLxj' },
    });
  };

  const submit = async (formValue) => {
    if (!isValid) {
      return;
    }

    // 자녀 링크
    if (isChildLinkPage) {
      link({ ...formValue, parentNo: parents.memberNo }, '/home');
    }
    // 통합계정 전환
    if (isChildIntegratedPage) {
      // store에 값이 없고 임시로 가지고 있는 곳에 있음
      integrated({
        memberNo: parents.id,
        student: formValue,
      });
    }
    // 학부모/자녀 전체 등록
    if (isChildAddPage) {
      join({
        parents,
        student: formValue,
        inboundChannel: formValue.inboundChannel,
      });
    }
  };

  useMount(() => {
    // 이전 화면에서 때문에 아래쪽으로 이동 할 수도 있음
    window.scrollTo({ top: 0, left: 0 });

    // 부모 정보가 없으면 다시 처음으로 이동
    if (isChildAddPage && isEmpty(parents)) {
      storage.clearToken();
      navigate('/login');
    }

    // 통합계정은 다시 로그인 하여 세션을 변경해야 함. 일단 세션 지우기
    if (isChildIntegratedPage) {
      storage.clearToken();
    }
  });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(submit)}
        className="contentBox joinMembership"
      >
        {isChildAddPage && !isWinkDevice && (
          <div className="guidanceBox typeTopJoin">
            <ul className="listDotted">
              <li>학부모님 전용 회원가입 페이지입니다.</li>
              <li>
                학원연계 '온라인 맞춤 학습관'을 이용하고 계실 경우, 별도의 학원
                전용 계정이 발급되어 있으니, 캐츠 공감센터에 문의해 주시길
                바랍니다.
              </li>
            </ul>

            <div className="boxContact">
              <strong className="textTitle">캐츠 공감센터 문의하기</strong>
              <a href="tel:1660-1244" className="btnCS">
                <span>고객센터문의</span>
              </a>
              <button type="button" className="btnKakao" onClick={goKakao}>
                <span>카카오톡 문의</span>
              </button>
            </div>
          </div>
        )}
        <FieldSet.Wrapper>
          <SignUpSteps />

          <FieldSet.List>
            <FieldSet.Item>
              <FormName
                onChange={() => {
                  setValue('dupCheck', DUPLICATE_STATUS.NOT_CHECK);
                  trigger('dupCheck');
                  // 휴대번호가 변경된 경우에만
                  if (dirtyFields.mobileNumber) {
                    trigger('mobileNumber');
                  }
                }}
              />
            </FieldSet.Item>

            <FieldSet.Item>
              <FormMobileDuplicated onVerifyUser={handleVerifyUser} />
            </FieldSet.Item>

            {/* 학생 정보가 없는 경우에만 학교/학년/반 선택 */}
            {!studentNo && (
              <>
                <FieldSet.Item>
                  <PasswordGuide />
                </FieldSet.Item>
                <FieldSet.Item>
                  <label className="label" htmlFor="school">
                    학교명
                  </label>
                  <AutoComplete fetcher={schoolCode} onSelect={handleSelect} />
                  <FormHidden
                    option={{ required: '학교명을 입력해주세요.' }}
                    name="courseCd"
                  />
                </FieldSet.Item>

                <FieldSet.Item>
                  <strong className="label" htmlFor="gradeCd">
                    학년
                  </strong>
                  <dl className="schoolYear">
                    <FormStudentGrade
                      label="초등"
                      activeCourse={ELEMENTARY_SCHOOL}
                      options={ELEMENTARY}
                    />
                    <FormStudentGrade
                      label="중등"
                      activeCourse={MIDDLE_SCHOOL}
                      options={MIDDLE}
                    />
                  </dl>
                </FieldSet.Item>

                <FieldSet.Item>
                  <TermsOfService userType={USER_TYPE.STUDENT} />
                </FieldSet.Item>
              </>
            )}
          </FieldSet.List>
        </FieldSet.Wrapper>

        <FieldSet.ButtonBox contact={!isWinkDevice}>
          <Button.Primary
            large
            type="submit"
            disabled={!isValid}
            label="확인"
          />
        </FieldSet.ButtonBox>
      </form>
    </FormProvider>
  );
}

export default AddChildPage;
