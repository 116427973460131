/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'ahooks';

import { PaymentOnlineSubscribe } from '@components/payment';
import { CATS_ONLINE_PRODUCT_IMAGE } from '@constants/image';
import { accountSelector } from '@store/slice/accountSlice';
import orderService from '@service/OrderService';

// 온라인 구독상품 목록
function PaymentManageOnlinePage() {
  const { memberNo, students } = useSelector(accountSelector);
  const [items, setItems] = useState([]);
  const navigate = useNavigate();

  const goOnline = useCallback(() => {
    navigate('/online-learning');
  }, [navigate]);

  const handleSubscribe = () => {
    (async () => {
      try {
        const result = await orderService.getOnlineSubscribe({ memberNo });
        setItems(
          result?.subscribeDetails.map((item) => {
            return {
              ...item,
              studentNm: students.find(
                (student) => student.memberNo === item.studentNo,
              )?.memberName,
              goodsNm: item.goodsName,
            };
          }),
        );
      } catch (error) {
        console.warn(error);
      }
    })();
  };

  useMount(() => {
    handleSubscribe();
  });

  return (
    <div className="contentBox boxListType">
      <div className="listDivision">
        <div className="listTitle">
          <strong>구독중인 상품</strong>
        </div>
        <ul className="listItems typeSubscription">
          {items.map((item, index) => (
            <PaymentOnlineSubscribe
              key={`payment_${index}`}
              onSuccess={handleSubscribe}
              {...item}
            />
          ))}
        </ul>
      </div>

      <div className="boxOtherService topSpace">
        <p className="textTitle">다른 상품에 관심이 있다면</p>
        <div className="promotionalBox">
          <a className="bnrLink" onClick={goOnline}>
            <img
              src={CATS_ONLINE_PRODUCT_IMAGE}
              alt="학습&amp;케어를 온라인과 오프라인 동시에! CATS 온라인 맞춤학습"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default PaymentManageOnlinePage;
