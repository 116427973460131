import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';

import { accountSelector } from '@store/slice/accountSlice';
import orderService from '@service/OrderService';
import { orderSelector, setLinkOrder } from '@store/slice/orderSlice';

import OrderBookPage from './OrderBookPage';

function OrderBookRedirectPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { memberNo } = useSelector(accountSelector);

  const { order } = useSelector(orderSelector);

  useMount(() => {
    const fetch = async () => {
      try {
        const response = await orderService.getBookLink(
          {
            linkId: id,
          },
          { fail: { message: '구매할 수 없는 교재입니다.', isBack: true } },
        );

        if (isEmpty(response)) {
          navigate('/payment-waiting');
        } else {
          dispatch(setLinkOrder(response));
        }
      } catch (error) {
        console.warn(error);
      }
    };

    fetch();
  });

  return <>{!isEmpty(order) && <OrderBookPage />}</>;
}

export default OrderBookRedirectPage;
