import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMount } from 'ahooks';

import academyService from '@service/AcademyService';
import { accountSelector } from '@store/slice/accountSlice';
import { Button } from '@components/ui';

import Notice from './components/Notice';
import NoticeComments from './components/NoticeComments';
import NoticeFile from './components/NoticeFile';
import NoticeWriterBox from './components/NoticeWriterBox';

import 'react-quill/dist/quill.snow.css';

function AcademyNoticeDeailPage() {
  const { memberNo } = useSelector(accountSelector);
  const { id } = useParams();

  const [notice, setNotice] = useState({});

  const handleDetail = () => {
    const fetch = async () => {
      try {
        const result = await academyService.noticeDetail({
          memberId: memberNo,
          notificationId: id,
        });
        setNotice({
          ...result,
          commentCnt: result?.commentList?.filter((item) => item.useYn === 'Y')
            .length,
        });
      } catch (error) {
        console.warn(error);
      }
    };
    fetch();
  };

  useMount(() => {
    handleDetail();
  });

  return (
    <div className="contentBox boxBoardDetails">
      <Notice.Content {...notice} />

      <div
        className="boardContent ql-editor"
        dangerouslySetInnerHTML={{
          __html: decodeURI(notice?.notificationContents || ''),
        }}
      />

      {notice?.files?.length > 0 && (
        <div className="boardAttachment">
          <ul className="fileList">
            {notice?.files.map((item) => (
              <NoticeFile key={`file_${item.fileId}`} {...item} />
            ))}
          </ul>
        </div>
      )}

      {notice?.notificationCommentYn === 'Y' && (
        <div className="boardComment commentWrap">
          <p className="replyTitle">
            댓글
            <b>{notice.commentCnt || 0}</b>
          </p>
          {notice?.commentList && (
            <NoticeComments
              classId={notice.classId}
              notificationId={notice.notificationId}
              comments={notice?.commentList}
              onRefresh={handleDetail}
            />
          )}
          {/* <Button.Wrapper>
            <Button.Normal className="btnMore" label="더보기" />
          </Button.Wrapper> */}
          <NoticeWriterBox
            notificationId={notice.notificationId}
            onRefresh={handleDetail}
            isNew
          />
        </div>
      )}
    </div>
  );
}

export default AcademyNoticeDeailPage;
