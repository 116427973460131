import api from '@utils/Api';

class PaymentService {
  constructor(client) {
    this.httpClient = client;
  }

  /**
   * 정기결제 카드 변경
   * @param {*} param0
   * @param {*} options
   * @returns
   */
  changeMethodBrandPay({ memberNo, methodKey }, options = {}) {
    return this.httpClient.post(
      `/v1/payments/manage/subscribe/card/${memberNo}?methodKey=${methodKey}`,
      {},
      options,
    );
  }

  deleteMethodBrandPay({ memberNo, methodKey }, options = {}) {
    return this.httpClient.delete(
      `/v1/payments/manage/subscribe/card/${memberNo}/${methodKey}`,
      {},
      options,
    );
  }
  /**
   * 정기결제 카드
   * @param {*} param0
   * @param {*} options
   * @returns
   */
  brandPayCard({ memberNo }, options = {}) {
    return this.httpClient.get(
      `/v1/payments/manage/subscribe/card/${memberNo}`,
      {},
      options,
    );
  }

  /**
   * 등록된 카드 목록
   * @param {₩} param0
   * @param {*} options
   * @returns
   */
  brandPayCards({ memberNo }, options = {}) {
    return this.httpClient.get(
      `/v1/payments/manage/subscribe/cards/${memberNo}`,
      {},
      options,
    );
  }

  // 결제수단 등록 url 가져오기
  addMethodSeeroo({ memberNo, academyId }, options = {}) {
    return this.httpClient.get(
      `/v1/payments/manage/card/${memberNo}?academyId=${academyId}`,
      {},
      options,
    );
  }

  // 결제수단 변경
  changeMethodSeeroo({ memberNo, academyId, cardId }, options = {}) {
    return this.httpClient.put(
      `/v1/payments/manage/cards/${memberNo}?academyId=${academyId}&cardId=${cardId}`,
      {},
      options,
    );
  }

  seerooCards({ memberNo, academyId }, options = {}) {
    return this.httpClient.get(
      `/v1/payments/manage/cards/${memberNo}`,
      { academyId },
      options,
    );
  }

  paymentMethodSeeroo({ memberNo }, options = {}) {
    return this.httpClient.get(
      `/v1/payments/manage/subscribes/academy-payment/${memberNo}`,
      {},
      options,
    );
  }
}

const paymentService = new PaymentService(api);
export default paymentService;
