import { REPORT_TYPE } from '@constants/tab';
import { KO_YEAR_MONTH, format } from '@helper/timeHelper';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import reportService from '@service/ReportService';
import { changeRoute } from '@store/action/commonAction';
import { isEmpty } from 'lodash';

const reportFunctions = {
  [REPORT_TYPE.ACADEMY]: reportService.getReportHistory.bind(reportService),
  [REPORT_TYPE.ONLINE]:
    reportService.getOnlineReportHistory.bind(reportService),
  [REPORT_TYPE.ENGLISH]:
    reportService.getLevelReportHistory.bind(reportService),
  [REPORT_TYPE.MATH]: reportService.getMathReportHistory.bind(reportService),
  [REPORT_TYPE.TASK]: reportService.getTaskReportHistory.bind(reportService),
};

export const getReportHistory = createAsyncThunk(
  'report/getReportHistory',
  async ({ parentNo, studentIds, type }) => {
    const result = await reportFunctions[type]({
      parentNo,
      studentIds,
      pageNo: 1,
    });

    return { result, type };
  },
);

export const nextReportHistory = createAsyncThunk(
  'report/nextReportHistory',
  async ({ parentNo, studentIds, type }, { getState }) => {
    const {
      report: { pageNo },
    } = getState();

    const result = await reportFunctions[type]({
      parentNo,
      studentIds,
      pageNo: pageNo + 1,
    });
    return { result, type };
  },
);

const initialState = {
  contents: {},
  pageNo: 1,
  isFetch: false,
  hasNext: false,
  type: '',
};

const mergeReport = (list, contents) => {
  return list.reduce((pre, current) => {
    const yearMonth = format(
      current.reportSendDate || current.statisticsYearMonth,
      KO_YEAR_MONTH,
    );
    return {
      ...pre,
      [yearMonth]: isEmpty(pre[yearMonth])
        ? [current]
        : [...pre?.[yearMonth], current],
    };
  }, contents);
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload;
    },
    clear: (state) => {
      state.contents = {};
      state.pageNo = 1;
      state.isFetch = false;
      state.hasNext = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportHistory.fulfilled, (state, action) => {
        const {
          result: { list, pageNum, hasNextPage },
          type,
        } = action.payload;

        state.contents =
          type === REPORT_TYPE.ENGLISH ||
          type === REPORT_TYPE.MATH ||
          type === REPORT_TYPE.TASK
            ? list
            : mergeReport(list, {});

        state.pageNo = pageNum;
        state.hasNext = hasNextPage;

        state.isFetch = true;
      })
      .addCase(nextReportHistory.fulfilled, (state, action) => {
        const {
          result: { list, pageNum, hasNextPage },
          type,
        } = action.payload;

        if (type === REPORT_TYPE.ENGLISH || type === REPORT_TYPE.MATH) {
          state.contents = [...state.contents, ...list];
        } else {
          state.contents = [...state.contents, ...mergeReport(list, {})];
        }
        state.pageNo = pageNum;
        state.hasNext = hasNextPage;
      });

    builder.addCase(changeRoute, (state, action) => {
      if (action.payload.includes('/learning-report')) {
        state.contents = {};
        state.pageNo = 1;
        state.type = '';
        state.hasNext = false;
        state.isFetch = false;
      }
    });
  },
});
const reportState = (state) => {
  return {
    contents: state.report.contents,
    isFetch: state.report.isFetch,
    hasNext: state.report.hasNext,
  };
};

export const reportSelector = createSelector(reportState, (state) => state);

const typeState = (state) => state.report.type;
export const typeSelector = createSelector(typeState, (state) => state);

export const { clear, setType } = reportSlice.actions;

export default reportSlice.reducer;
