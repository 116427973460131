import { PAYMENT_TYPE } from '@constants/tab';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import orderService from '@service/OrderService';
import { changeRoute } from '@store/action/commonAction';

const PAGE_SIZE = 10;

export const all = createAsyncThunk(
  'paymentHistory/all',
  async ({ parentNo, type }) => {
    const response = await orderService.getHistoryList({
      parentNo,
      type,
    });
    return response;
  },
);

const initialState = {
  holder: [],
  history: [],
  type: PAYMENT_TYPE.ALL,
  isFetch: false,
  hasNext: false,
};

export const paymentHistorySlice = createSlice({
  name: 'paymentHistory',
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload;
    },
    next: (state) => {
      state.history = [...state.history, ...state.holder.slice(0, PAGE_SIZE)];
      state.holder = state.holder.slice(PAGE_SIZE, state.holder.length);
      state.hasNext = state.holder.length > 0;
      state.isFetch = true;
    },
    clear: (state) => {
      state.history = [];
      state.holder = [];
      state.type = PAYMENT_TYPE.ALL;
      state.hasNext = false;
      state.isFetch = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(all.fulfilled, (state, action) => {
      state.holder = action.payload.slice(PAGE_SIZE, action.payload.length);
      state.history = action.payload.slice(0, PAGE_SIZE);
      state.hasNext = state.holder.length > 0;
      state.isFetch = true;
    });

    builder.addCase(changeRoute, (state, action) => {
      if (action.payload.includes('/payment-history')) {
        state.history = [];
        state.holder = [];
        state.type = PAYMENT_TYPE.ALL;
        state.hasNext = false;
        state.isFetch = false;
      }
    });
  },
});

const paymentHistoryState = (state) => {
  return {
    history: state.paymentHistory.history,
    hasNext: state.paymentHistory.hasNext,
    isFetch: state.paymentHistory.isFetch,
    type: state.paymentHistory.type,
  };
};
export const paymentHistorySelector = createSelector(
  paymentHistoryState,
  (state) => state,
);

export const { next, clear, setType } = paymentHistorySlice.actions;
export default paymentHistorySlice.reducer;
