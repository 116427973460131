import { PAYMENT_TABS, PAYMENT_TYPE } from '@constants/tab';
import Tabs from './base/Tabs';

function PaymentTab({ tabs, defaultType, ...rest }) {
  return (
    <Tabs
      tabs={tabs || PAYMENT_TABS}
      defaultType={defaultType || PAYMENT_TYPE.ALL}
      isRound
      {...rest}
    />
  );
}

export default PaymentTab;
