import { HEADER_TYPE } from '@constants/layout';
import {
  Payment,
  PaymentMethodOnline,
  PaymentMethodAcademy,
  PaymentManageAcademy,
  PaymentManageOnline,
} from '@pages';

const paymentManageRouteConfig = [
  {
    path: 'payment-academy',
    element: Payment,
    option: {
      name: '학원비 자동결제 관리',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'payment-online',
    element: Payment,
    option: {
      name: 'CATS 정기구독 관리',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'payment-method/academy',
    element: PaymentMethodAcademy,
    option: {
      name: '결제수단변경',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'payment-method/online',
    element: PaymentMethodOnline,
    option: {
      name: '결제수단변경',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'payment-manage/academy',
    element: PaymentManageAcademy,
    option: {
      name: '자동결제관리',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'payment-manage/online',
    element: PaymentManageOnline,
    option: {
      name: '구독상품관리',
      headerType: HEADER_TYPE.BACK,
    },
  },
];

export default paymentManageRouteConfig;
