function MathTestCorrectAnswersByArea({
  studentNm,
  systemAreaList,
  actionAreaList,
}) {
  return (
    <div className="reportSection sectionLvByArea">
      <div className="sectionTitleBox">
        <p className="sectionTitle">{studentNm} 학생의 영역별 정답률</p>
      </div>
      <div className="reportDivision">
        <div className="titleBox">
          <p className="textTitle">계통 영역별 정답률</p>
        </div>

        <div className="achievementLevelBox">
          <div className="barChartVertical">
            <div className="barCharBox">
              <ul className="yAxisGroup" aria-hidden="true">
                <li>
                  <span className="pointNumber">100</span>
                </li>
                <li>
                  <span className="pointNumber">75</span>
                </li>
                <li>
                  <span className="pointNumber">50</span>
                </li>
                <li>
                  <span className="pointNumber">25</span>
                  <span className="pointNumber zeroPoint">0</span>
                </li>
              </ul>

              <ul className="xAxisGroup">
                {systemAreaList.map((item, index) => (
                  <li key={`systemArea_${index}`}>
                    <div className="xAxisValue">
                      <div className="xAxisItemUnit">
                        <p className="textSector">{item.areaNm}</p>
                      </div>
                    </div>
                    <div className="xAxisGraph">
                      <div className="barGraph">
                        <span
                          className="per"
                          style={{
                            height: `${(
                              (item.correctAnswerCnt / item.totalCnt) *
                              100
                            ).toFixed(0)}%`,
                          }}
                        >
                          <em>
                            {(
                              (item.correctAnswerCnt / item.totalCnt) *
                              100
                            ).toFixed(0)}
                          </em>
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="reportDivision">
        <div className="titleBox">
          <p className="textTitle">행동 영역별 정답률</p>
        </div>

        <div className="achievementLevelBox">
          <div className="barChartVertical">
            <div className="barCharBox">
              <ul className="yAxisGroup" aria-hidden="true">
                <li>
                  <span className="pointNumber">100</span>
                </li>
                <li>
                  <span className="pointNumber">75</span>
                </li>
                <li>
                  <span className="pointNumber">50</span>
                </li>
                <li>
                  <span className="pointNumber">25</span>
                  <span className="pointNumber zeroPoint">0</span>
                </li>
              </ul>

              <ul className="xAxisGroup">
                {actionAreaList.map((item, index) => (
                  <li key={`actionArea_${index}`}>
                    <div className="xAxisValue">
                      <div className="xAxisItemUnit">
                        <p className="textSector">{item.areaNm}</p>
                      </div>
                    </div>
                    <div className="xAxisGraph">
                      <div className="barGraph">
                        <span
                          className="per"
                          style={{
                            height: `${(
                              (item.correctAnswerCnt / item.totalCnt) *
                              100
                            ).toFixed(0)}%`,
                          }}
                        >
                          <em>
                            {(
                              (item.correctAnswerCnt / item.totalCnt) *
                              100
                            ).toFixed(0)}
                          </em>
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MathTestCorrectAnswersByArea;
