import dayjs from 'dayjs';

import { weekOfMonth } from '@helper/timeHelper';
import { StudentTab } from '@components/tabs';
import { useSelector } from 'react-redux';
import { learningStudentsSelector } from '@store/slice/homeSlice';
import { useEffect, useState } from 'react';

function HomeLearningTitle({ student, onSelect }) {
  const learningStudents = useSelector(learningStudentsSelector);

  const [enableStudents, setStudent] = useState([]);

  useEffect(() => {
    setStudent(student.filter((item) => learningStudents.includes(item.value)));
  }, [learningStudents, student]);

  return (
    <>
      <div className="statusTitle">
        <b>{dayjs().format('M')}</b>월 <b>{weekOfMonth(dayjs())}</b>주차{' '}
        <strong>학습현황</strong>
      </div>

      {enableStudents.length > 0 && (
        <StudentTab isFilter student={enableStudents} onSelect={onSelect} />
      )}
    </>
  );
}

export default HomeLearningTitle;
