function Consultation({
  studentName,
  academyName,
  className,
  teacherName,
  reservDesc,
  counslReservDate,
  parentsRequestYn = 'Y',
}) {
  return (
    <li>
      <div className="textPerson">
        {parentsRequestYn === 'Y' ? (
          <span className="flagOutline tyCallParent">학부모 요청</span>
        ) : (
          <span className="flagOutline tyCallTeacher">선생님 요청</span>
        )}

        <span className="txtName">{studentName || '-'}</span>
      </div>
      <dl className="definitionList">
        <div className="rowCourse">
          <dt>학원</dt>
          <dd>
            <p>{academyName || '-'} </p>
            <p className="txtGray">{className || '-'}</p>
          </dd>
        </div>
        <div>
          <dt>선생님</dt>
          <dd>{teacherName} 선생님</dd>
        </div>
        <div>
          <dt>상담일</dt>
          <dd>{counslReservDate || '-'}</dd>
        </div>
        <div>
          <dt>상담내용</dt>
          <dd>{reservDesc || '-'}</dd>
        </div>
      </dl>
    </li>
  );
}

export default Consultation;
