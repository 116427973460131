import { ModalBookDescription } from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';

function EnglishLevelTestCurriculumBook({
  title,
  section,
  imgSrc,
  description,
}) {
  const { showDialog } = useOverlayContext();

  const handleDescrption = () => {
    showDialog(ModalBookDescription, { title, description });
  };
  return (
    <li>
      <div className="imgBox">
        <img src={imgSrc} alt="Phonics" />
      </div>
      <div className="textBox">
        <div className="bookTitle">
          <span className="textCurriculum">{section}</span>
          <strong>{title}</strong>
        </div>
        <div className="bookDescription">
          <button
            type="button"
            className="btnDescription"
            onClick={handleDescrption}
          >
            <span>{section === '스토리영어' ? '콘텐츠설명' : '교재설명'}</span>
          </button>
        </div>
      </div>
    </li>
  );
}

export default EnglishLevelTestCurriculumBook;
