import React from 'react';
import { useFormContext } from 'react-hook-form';

function Input({
  readOnly = false,
  type = 'text',
  name,
  option = {},
  onChange = () => {},
  onBlur = () => {},
  ...rest
}) {
  const { register } = useFormContext();

  return (
    <input
      readOnly={readOnly}
      id={name}
      type={type}
      {...register(name, {
        ...option,
        onChange: (e) => onChange(e),
        onBlur: (e) => onBlur(e),
      })}
      {...rest}
    />
  );
}

export default Input;
