import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import academyService from '@service/AcademyService';
import dayjs from 'dayjs';

export const getConsultation = createAsyncThunk(
  'consultation/getConsultation',
  async ({ memberNo }) => {
    const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const [confirmReserve, noConfirmReserve] = await Promise.all([
      academyService.confirmConsultation({
        parentsId: memberNo,
        counslReservDateAfter: now,
      }),
      academyService.consultation({
        parentsId: memberNo,
      }),
    ]);

    return { confirmReserve, noConfirmReserve };
  },
);

const initialState = {
  confirmReserve: [],
  noConfirmReserve: [],
  isFetch: false,
};

export const consultationSlice = createSlice({
  name: 'consultation',
  initialState,
  reducers: {
    clear: (state) => {
      state.confirmReserve = [];
      state.noConfirmReserve = [];
      state.isFetch = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConsultation.fulfilled, (state, action) => {
      const { confirmReserve, noConfirmReserve } = action.payload;
      state.confirmReserve = confirmReserve?.list;
      state.noConfirmReserve = noConfirmReserve?.list;
      state.isFetch = true;
    });
  },
});

const consultationState = (state) => {
  return {
    confirmReserve: state.consultation.confirmReserve,
    noConfirmReserve: state.consultation.noConfirmReserve,
    isFetch: state.consultation.isFetch,
  };
};
export const consultationSelector = createSelector(
  consultationState,
  (state) => state,
);

export const { clear } = consultationSlice.actions;

export default consultationSlice.reducer;
