import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useOverlayContext } from '@context/OverlayContext';
import { accountSelector } from '@store/slice/accountSlice';

import Modal from './base/Modal';

function ModalOnlineSelectStudent({ onSelect, ...rest }) {
  const { closeModal } = useOverlayContext();
  const { students } = useSelector(accountSelector);

  const handleSelect = useCallback(
    (student) => {
      onSelect?.(student);
      closeModal();
    },
    [onSelect, closeModal],
  );

  return (
    <Modal {...rest} className="typeLearner">
      <Modal.Header>학습자 선택</Modal.Header>
      <Modal.Body className="popLearner">
        <p>유료학습을 신청할 학생을 선택해 주세요</p>

        <div className="labelGroupBlock">
          {students.map((item) => (
            <label
              key={`student_${item.memberNo}`}
              className="checkBoxBorder typeRight"
            >
              <input
                type="radio"
                name="learner"
                onChange={() => handleSelect(item)}
              />
              <span className="labelText">
                <em>{item.memberName}</em>
              </span>
            </label>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalOnlineSelectStudent;
