import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

function useScroll({ ref }) {
  const [isBottom, setBottom] = useState(false);
  const [isTop, setTop] = useState(window.pageYOffset === 0);

  useEffect(() => {
    const handleBottom = debounce(() => {
      if (ref && ref.current) {
        const { scrollTop } = document.documentElement;
        const { offsetHeight } = ref.current.parentElement;
        if (scrollTop !== 0 && window.innerHeight + scrollTop >= offsetHeight) {
          setBottom(true);
        } else {
          setBottom(false);
        }
      }
    }, 100);

    const handleTop = debounce(() => {
      setTop(window.pageYOffset === 0);
    }, 100);

    window.addEventListener('scroll', handleTop);
    window.addEventListener('scroll', handleBottom);

    return () => {
      window.removeEventListener('scroll', handleTop);
      window.removeEventListener('scroll', handleBottom);
    };
  }, [ref]);

  const reset = () => {
    setBottom(false);
  };

  return {
    isBottom,
    isTop,
    reset,
  };
}

export default useScroll;
