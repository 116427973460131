import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { phonePatten } from '@constants/validate';
import { accountSelector } from '@store/slice/accountSlice';
import { DUPLICATE_STATUS } from '@constants/common';
import accountService from '@service/AccountService';

import FormHidden from './base/FormHidden';
import FormInput from './base/FormInput';
import FormWithButton from './base/FormWithButton';

function FormMobileDuplicated({ onVerifyUser }) {
  const { mobileNumber: parentsNumber, memberName: parentsName } =
    useSelector(accountSelector);

  const {
    getValues,
    setValue,
    trigger,
    formState: { errors, touchedFields },
  } = useFormContext();

  const dupCheck = useWatch({
    name: `dupCheck`,
  });

  const name = useWatch({
    name: `memberName`,
  });

  const mobile = useWatch({
    name: `mobileNumber`,
  });

  const handleDuplicate = async () => {
    trigger('mobileNumber');

    const [memberName, mobileNumber] = getValues([
      'memberName',
      'mobileNumber',
    ]);

    const { isExists, isAcademyAccount } = await accountService.isExists({
      studentName: memberName,
      studentMobile: mobileNumber,
    });

    setValue(
      'dupCheck',
      !isExists ? DUPLICATE_STATUS.VALID : DUPLICATE_STATUS.DUPLICATE,
    );

    trigger('mobileNumber');

    // 회원정보가 없는 경우 -> 신규 자녀
    if (!isExists) {
      setValue('password', String(mobileNumber).slice(3, 11));
    }
    // 중복된 계정이고 학원에서 생성했을 경우에만 검증 절차를 거침
    if (isExists && isAcademyAccount) {
      onVerifyUser({
        studentName: name,
        studentMobile: mobile,
        onSuccess: ({ memberNo }) => {
          // 완료 처리
          setValue('studentNo', memberNo);
          setValue('dupCheck', DUPLICATE_STATUS.VALID);
          // TODO: 여기서 trigger 하는게 맞나?
          trigger('mobileNumber');
          trigger('studentNo');
        },
      });
    }
  };

  const dupCheckMessage = useMemo(() => {
    if (isEmpty(errors?.mobileNumber) && dupCheck === DUPLICATE_STATUS.VALID) {
      return <p className="successMessage">등록 가능한 정보입니다.</p>;
    }
    if (!isEmpty(errors?.dupCheck) && dupCheck === DUPLICATE_STATUS.NOT_CHECK) {
      return <p className="errorMessage">자녀계정 중복확인을 진행해주세요.</p>;
    }
  }, [dupCheck, errors?.dupCheck, errors?.mobileNumber]);

  const handleValidate = useCallback(
    (value) => {
      if (!phonePatten.test(value)) {
        return '휴대폰 번호를 정확하게 입력해주세요.';
      }
      const dupStatus = getValues('dupCheck');
      const studentName = getValues('memberName');

      if (dupStatus === DUPLICATE_STATUS.DUPLICATE) {
        return '이미 CATS에 가입되어있는 휴대폰 번호입니다.';
      }
      // 여기서 학무보 정보와 비교
      if (value === parentsNumber && studentName === parentsName) {
        return '학부모정보와 동일한 이름/휴대폰 번호를 사용할수 없습니다.';
      }
      return true;
    },
    [getValues, parentsName, parentsNumber],
  );

  useEffect(() => {
    trigger('dupCheck');
  }, [trigger, dupCheck]);

  return (
    <>
      <FormWithButton
        label="휴대폰 번호"
        button={{
          disabled:
            isEmpty(name) || isEmpty(mobile) || !isEmpty(errors?.mobileNumber),
          action: handleDuplicate,
          label: '자녀계정 중복확인',
        }}
      >
        <FormInput
          noMessage
          name="mobileNumber"
          placeholder="'-'를 제외하고 입력해주세요."
          onChange={() => {
            setValue('studentNo', '');
            setValue('dupCheck', DUPLICATE_STATUS.NOT_CHECK);

            trigger('studentNo');
            trigger('dupCheck');
            if (touchedFields.mobileNumber) {
              trigger('mobileNumber');
            }
          }}
          option={{
            required: '휴대폰 번호를 입력해주세요.',
            validate: handleValidate,
          }}
        />
      </FormWithButton>

      <FormHidden
        name="dupCheck"
        option={{
          validate: (value) =>
            value === DUPLICATE_STATUS.VALID ||
            '자녀계정 중복확인을 진행해주세요.',
        }}
      />
      {dupCheckMessage && dupCheckMessage}
      {/* 휴대폰 번호란이 비어 있는 경우에만 */}
      {isEmpty(mobile) && (
        <p className="errorMessage" style={{ display: 'block' }}>
          * 자녀의 휴대폰이 없는 경우 학부모님의 휴대폰 번호를 입력해 주세요.
        </p>
      )}
    </>
  );
}

export default FormMobileDuplicated;
