import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayjsBusinessDays from 'dayjs-business-days';
import 'dayjs/locale/ko';

import callApp, { webviewInterface } from '@interface/externalInterface';
import store from '@store/configure';
import { getBrowserType } from '@helper/commonHelper';

import routingConfig from './config/rootRouteConfig';
import './index.css';

// dayjs 플러그인 추가
dayjs.extend(weekOfYear);
dayjs.extend(dayjsBusinessDays);
dayjs.locale('ko');

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
}

window.appInterface = window.appInterface || {};
window.setWebViewSession = webviewInterface.setWebViewSession;
window.appInterface.DEVICE = 'WEB';

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter(routingConfig());

// if (getBrowserType() === 'ANDROID') {
//   const { accessToken, refreshToken, details, data, deviceInfo, pushToken } =
//     JSON.parse(window.appInterface.getSession());

//   webviewInterface.setWebViewSession(
//     accessToken,
//     refreshToken,
//     details,
//     data,
//     deviceInfo,
//     pushToken,
//   );
// }
// if (getBrowserType() === 'IOS') {
//   callApp('getSession');
// }

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>,
);
