import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Feed from './base/Feed';

/**
 * 리포트 피드
 */
function FeedOnlineReport({ feed }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/learning-report-online/${feed.feedLinkKey?.replace('|', '/')}`);
  };
  return <Feed feed={feed} onClick={handleClick} />;
}

export default memo(FeedOnlineReport);
