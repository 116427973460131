import { AppProvider } from '@context/AppContext';
import { AuthProvider } from '@context/AuthContext';
import { LayoutProvider } from '@context/LayoutContext';
import { LoadingProvider } from '@context/LoadingContext';
import { OverlayProvider } from '@context/OverlayContext';

import Root from '@layout/Root';

function App() {
  return (
    <AppProvider>
      <LoadingProvider>
        <OverlayProvider>
          <AuthProvider>
            <LayoutProvider>
              <Root />
            </LayoutProvider>
          </AuthProvider>
        </OverlayProvider>
      </LoadingProvider>
    </AppProvider>
  );
}

export default App;
