import { ACADEMY_TABS, ACADEMY_TYPE } from '@constants/tab';
import Tabs from './base/Tabs';

function AcademyTab({ value = false, ...rest }) {
  return (
    <Tabs
      tabs={ACADEMY_TABS}
      defaultType={value || ACADEMY_TYPE.CONSULTATION}
      {...rest}
    />
  );
}

export default AcademyTab;
