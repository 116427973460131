import PaymentHistoryAcademy from './PaymentHistoryAcademy';
import PaymentHistoryBook from './PaymentHistoryBook';
import PaymentHistoryOnline from './PaymentHistoryOnline';
import PaymentWaitingAcademy from './PaymentWaitingAcademy';
import PaymentWaitingBook from './PaymentWaitingBook';
import PaymentWaitingOnline from './PaymentWaitingOnline';

class PaymentMapper {
  mapper = {
    waiting: {
      SUBSCRIPTION: PaymentWaitingOnline,
      TEACHER_TEXTBOOK: PaymentWaitingBook,
      ACADEMY_FEE: PaymentWaitingAcademy,
    },
    history: {
      SUBSCRIPTION: PaymentHistoryOnline,
      TEACHER_TEXTBOOK: PaymentHistoryBook,
      ACADEMY_FEE: PaymentHistoryAcademy,
    },
  };

  get(paymentType, type) {
    return this.mapper?.[type]?.[paymentType];
  }
}

const paymentMapper = new PaymentMapper();
export default paymentMapper;
