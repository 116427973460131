import { useCallback } from 'react';

import { useOverlayContext } from '@context/OverlayContext';
import { Button } from '@components/ui';

import Modal from './base/Modal';

function ModalMathDiagnosis({ ...rest }) {
  const { closeModal } = useOverlayContext();

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <Modal className="typeLvGradeInfo" isBasic {...rest}>
      <Modal.Header hasClose />
      <Modal.Body className="popLvGradeInfo">
        <div className="topInfoText">
          <p className="textTitle">평가 등급이란?</p>
          <p className="textDesc">
            캐츠 전문가가 측정한 아래 등급 기준으로 구분하였을 때의 석차
            등급입니다.
          </p>
        </div>
        <div className="boxGradeDetails">
          <table>
            <colgroup>
              <col style={{ width: '60px' }} />
              <col style={{ width: 'auto' }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">등급</th>
                <th scope="col">기준</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="colGrade">1등급</td>
                <td>상위 10% 점수 이상</td>
              </tr>
              <tr>
                <td className="colGrade">2등급</td>
                <td>상위 34% 점수 이상 ~ 11% 점수 미만</td>
              </tr>
              <tr>
                <td className="colGrade">3등급</td>
                <td>상위 66% 점수 이상 ~ 35% 점수 미만</td>
              </tr>
              <tr>
                <td className="colGrade">4등급</td>
                <td>상위 90% 점수 이상 ~ 67% 점수 미만</td>
              </tr>
              <tr>
                <td className="colGrade">5등급</td>
                <td>상위 90% 점수 미만</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalMathDiagnosis;
