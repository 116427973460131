import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useOverlayContext } from '@context/OverlayContext';
import { accountSelector } from '@store/slice/accountSlice';
import orderService from '@service/OrderService';
import { PAYMENT_TYPE } from '@constants/tab';

import Feed from './base/Feed';

/**
 * 학원비 결제 피드
 */
function FeedPayAcademy({ feed }) {
  const navigate = useNavigate();
  const { showToast } = useOverlayContext();
  const { memberNo } = useSelector(accountSelector);

  const handleClick = () => {
    (async () => {
      try {
        const response = await orderService.getWatingDetail(
          {
            parentNo: memberNo,
            orderNumber: feed.feedLinkKey,
            type: PAYMENT_TYPE.ACADEMY,
          },
          {
            ignore: true,
          },
        );

        if (response.onSaleYn === 'Y') {
          navigate(`/order-academy/${feed.feedLinkKey}`);
        }
        if (response.onSaleYn === 'N') {
          showToast({
            message: '이미 결제한 청구서 입니다.',
          });
        }
      } catch (error) {
        if (error?.response?.data?.resultMsg.includes('999')) {
          showToast({
            message: '이미 결제한 청구서 입니다.',
          });
        } else {
          showToast({
            message: '구매할 수 없는 상품입니다.',
          });
        }
      }
    })();
  };
  return <Feed feed={feed} onClick={handleClick} />;
}

export default memo(FeedPayAcademy);
