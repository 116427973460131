import { Link } from 'react-router-dom';
import useFileDownload from '@hooks/useFiledownload';

function NoticeFile({ filePath, fileNm, orgFileNm }) {
  const download = useFileDownload();

  const handleDownload = () => {
    download(
      `${process.env.REACT_APP_PUBLIC_API_BASE_URL}common/file-name/${fileNm}`,
      orgFileNm,
    );
  };
  return (
    <li>
      <button type="button" className="btnfile" onClick={handleDownload}>
        <div className="innerBtnBox">
          <span className="fileName">{orgFileNm}</span>
        </div>
      </button>
      {/* <Link to={filePath} target="_blank" className="btnfile" download>
        <div className="innerBtnBox">
          <span className="fileName">{orgFileNm}</span>
        </div>
      </Link> */}
    </li>
  );
}

export default NoticeFile;
