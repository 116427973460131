import { memo, useMemo } from 'react';

import { useOverlayContext } from '@context/OverlayContext';

import Feed from './base/Feed';

/**
 * 수준진단평가 초대
 */
function FeedAssessment({ feed }) {
  const { showToast } = useOverlayContext();

  const handleClick = () => {
    showToast({
      message: '캐츠홈에서 수학 수준진단평가 응시가 가능합니다.',
    });
  };
  // feedContent에 dueDate 넣기로 했음
  const feedData = useMemo(
    () => ({
      ...feed,
      feedContents: '수학 수준 진단평가가 발송되었습니다.',
      testDueDate: feed?.feedContents?.replace(/\./g, '/') || '',
    }),
    [feed],
  );
  return <Feed feed={feedData} onClick={handleClick} />;
}

export default memo(FeedAssessment);
