import { HEADER_TYPE } from '@constants/layout';
import { Alarm, Home, Trial } from '@pages';

const homeRouteConfig = [
  {
    path: 'home',
    element: Home,
    option: {
      name: 'Home',
      headerType: HEADER_TYPE.HOME,
      hasTabbar: true,
      hasFooter: true,
    },
  },
  {
    path: 'alarm',
    element: Alarm,
    option: {
      name: 'Alarm',
      headerType: HEADER_TYPE.NONE,
    },
  },
  {
    path: 'trial',
    element: Trial,
    option: {
      name: '무료체험 학습시작 안내',
      headerType: HEADER_TYPE.DEFAULT,
    },
  },
];

export default homeRouteConfig;
