import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import {
  ModalAlert,
  ModalError,
  ModalResetPassword,
} from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';
import { accountSelector } from '@store/slice/accountSlice';
import { useAuthContext } from '@context/AuthContext';
import accountService from '@service/AccountService';
import codeService from '@service/CodeService';
import callApp from '@interface/externalInterface';
import { HIGH_SCHOOL } from '@constants/school';

function useAccount({ complete }) {
  const navigate = useNavigate();

  const user = useSelector(accountSelector);

  const { login, refreshAccount } = useAuthContext();

  const { showDialog, showBottomSheet } = useOverlayContext();

  const integrated = useCallback(
    async (formValue) => {
      await accountService.integrated({ ...formValue });

      complete?.(formValue);
      navigate('/login');
    },
    [complete, navigate],
  );

  const link = useCallback(
    async (formValue, from) => {
      await accountService.link({ ...formValue });

      complete?.(formValue);

      // 추가되었으니 계정정보 재조회
      await refreshAccount({
        id: formValue.parentNo || formValue.memberNo,
      });

      if (!isEmpty(from)) {
        navigate(from);
      }
      // my 페이지에서 자녀 추가
      else {
        showBottomSheet(ModalAlert, {
          title: '알림',
          content: '자녀계정 만들기가 완료되었습니다.',
          buttons: [{ label: '확인', onClick: () => navigate(from) }],
        });
      }
    },
    [complete, navigate, refreshAccount, showBottomSheet],
  );

  const join = useCallback(
    async (formValue) => {
      const { parents, student } = formValue;
      await accountService.join({
        inboundChannel: formValue?.inboundChannel || '',
        student,
        parent: {
          ...parents,
          address: parents.addr,
          detailAddress: parents.addrDetail,
          postNumber: parents.post,
          mobileVerifiedId: parents.authToken,
        },
      });

      try {
        complete?.(formValue);

        // 가입되면 자동 로그인
        const loginInfo = await login({
          username: user.memberName,
          mobileNum: user.mobileNumber,
          password: user.password,
          redirect: '/child-success',
          isRequestTrial: true,
        });

        // 앱에 로그인 정보 전달
        callApp('setSession', {
          details: loginInfo.details,
          data: loginInfo.data,
          password: user.password,
          accessToken: loginInfo.accessToken,
          refreshToken: loginInfo.refreshToken,
        });
      } catch (error) {
        showDialog(ModalError);
      }
    },
    [
      complete,
      login,
      showDialog,
      user.memberName,
      user.mobileNumber,
      user.password,
    ],
  );

  const checkDuplicate = useCallback(async (formValue) => {
    const result = await accountService.checkDuplicate({ ...formValue });
    return result;
  }, []);

  const changePassword = useCallback(
    async (formValue) => {
      await accountService.changePassword({
        ...formValue,
      });

      complete?.(formValue);
      showBottomSheet(ModalResetPassword);
    },
    [complete, showBottomSheet],
  );

  const schoolCode = useCallback(async (value) => {
    const result = await codeService.schoolCode(value, {
      loading: false,
    });
    return result
      .filter((item) => item.courseInstructionCode !== HIGH_SCHOOL)
      .map((item) => {
        return {
          ...item,
          label: item.schoolName,
          subLabel: ` ${item.regionName}`,
        };
      });
  }, []);

  return { join, link, integrated, schoolCode, checkDuplicate, changePassword };
}

export default useAccount;
