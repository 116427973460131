import Modal from './base/Modal';

function ModalBookDescription({ title, description }) {
  return (
    <Modal isBasic>
      <Modal.Header hasClose>{title}</Modal.Header>
      <Modal.Body>
        <p
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </Modal.Body>
    </Modal>
  );
}

export default ModalBookDescription;
