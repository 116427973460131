import { useCallback } from 'react';
import { useOverlayContext } from '@context/OverlayContext';

function PopupHeader({
  id,
  children,
  hasBack = false,
  hasClose = false,
  onClose = null,
}) {
  const { closePopup } = useOverlayContext();

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      closePopup(id);
    }
  }, [closePopup, id, onClose]);

  return (
    <div className="topAppBar">
      <div className="pageHeader">
        <header className="header">
          <div className="topLeft">
            {hasBack && (
              <button
                type="button"
                className="btnPrevious"
                onClick={handleClose}
              >
                <span>뒤로가기</span>
              </button>
            )}
          </div>

          <h1 className="h1">{children}</h1>
          <div className="topRight">
            {hasClose && (
              <button
                type="button"
                className="btnClosePanel"
                onClick={handleClose}
              >
                <span>닫기</span>
              </button>
            )}
          </div>
        </header>
      </div>
    </div>
  );
}

export default PopupHeader;
