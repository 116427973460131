import { useOverlayContext } from '@context/OverlayContext';

/* eslint-disable jsx-a11y/anchor-is-valid */
function InquiryPage() {
  const { showPopup } = useOverlayContext();

  return (
    <div className="contentBox boxInquiry">
      <div className="listTop btnsWrapper">
        <button type="button" className="btnOutline medium">
          <span>1:1 문의하기</span>
        </button>
        <button type="button" className="btnSns kakao medium">
          <span>카카오톡 문의</span>
        </button>
      </div>

      <div className="listBoard">
        <li>
          <p className="infoCategory">
            <span className="tagRect">상품</span>
            <span className="textDate">2022.12.26 18:00</span>
          </p>
          <a className="aLlinkInquiry">
            <span className="textTitle">
              CATS 3D 펜 패키지 상품 품절 언제 풀리나요?
            </span>
          </a>
          <p className="infoResponse">
            <span className="flagFilled tyNoResponse">답변대기</span>
          </p>
        </li>
        <li>
          <p className="infoCategory">
            <span className="tagRect">서비스 이용</span>
            <span className="textDate">2022.12.26 18:00</span>
          </p>
          <a className="aLlinkInquiry">
            <span className="textTitle">
              구독 정기결제랑 일회결제랑 가격 차이가 많이 나나요? 정기결제하고
              중간에 해지하면 환불이 얼마나 안되는지 알 수 있을…
            </span>
          </a>
          <p className="infoResponse">
            <span className="flagFilled tyResponse">답변완료</span>
          </p>
        </li>
      </div>
    </div>
  );
}

export default InquiryPage;
