import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

const ONLY_DATE = 'YYYY.MM.DD';
const DATE_TIME = 'YYYY.MM.DD HH:mm';
const KO_YEAR_DATE_INCLUDE_WEEK = 'YYYY년 MM월 DD일 (ddd)';
const KO_DATE_INCLUDE_WEEK = 'MM월 DD일 (ddd)';
const KO_YEAR_DATE = 'YYYY년 MM월 DD일';
const KO_DATE = 'MM월 DD일';
const KO_YEAR_MONTH = 'YYYY. MM';

const timeFormat = (time) => {
  const m = Math.floor(time / 60).toString();
  let s = (time % 60).toString();
  if (s.length === 1) s = `0${s}`;
  return `${m}:${s}`;
};

const format = (date, formatString = 'YYYY-MM-DD HH:mm') => {
  if (isEmpty(date) || !dayjs(date).isValid()) {
    return '-';
  }
  return dayjs(date).format(formatString);
};

const oneYears = () => {
  const before = format(dayjs().subtract(1, 'year'), 'YYYYMM');
  const now = format(dayjs(), 'YYYYMM');
  return [before, now];
};

const twoMonths = () => {
  const before = format(dayjs().subtract(1, 'month'), 'YYYYMM');
  const now = format(dayjs(), 'YYYYMM');
  return [before, now];
};

const weekOfMonth = (m) => m.week() - dayjs(m).startOf('month').week() + 1;

const beforeWeekRange = (before) => {
  const startDate = format(dayjs().subtract(before, 'week').day(1), 'YYYYMMDD');
  const endDate = format(dayjs().subtract(before, 'week').day(7), 'YYYYMMDD');
  return [startDate, endDate];
};

export {
  format,
  timeFormat,
  oneYears,
  twoMonths,
  weekOfMonth,
  beforeWeekRange,
  ONLY_DATE,
  DATE_TIME,
  KO_DATE,
  KO_YEAR_MONTH,
  KO_YEAR_DATE,
  KO_DATE_INCLUDE_WEEK,
  KO_YEAR_DATE_INCLUDE_WEEK,
};
