function TaskReportSummaryDetail({
  reportSendStudentNm,
  subjectCd,
  reportTaskSummary,
  isBeforeRegDate,
}) {
  return (
    <div className="reportSection rptSectionOverall">
      <div className="sectionTitleBox">
        <p className="sectionTitle">과제 종합 결과</p>
      </div>
      <div className="reportDivision">
        <div className="boxSubDetails typeAchievementRate">
          <div className="roundBox dataAchievement">
            <p className="txtInfoTitle">
              <span>
                <b>{reportSendStudentNm}</b>학생
              </span>
              <span>성취율</span>
            </p>
            {subjectCd === 'CD10006' && (
              <div className="txtAlignRight">
                <div className="textPercentage">
                  <span>원과제</span>
                  <strong>{reportTaskSummary?.originCorrectAnsRate}%</strong>
                </div>
                <div className="textPercentage">
                  <span>처방포함</span>
                  <strong>{reportTaskSummary?.correctAnsRate}%</strong>
                </div>
              </div>
            )}
            {subjectCd !== 'CD10006' && (
              <div className="txtAlignRight">
                <div className="textPercentage">
                  <strong>{reportTaskSummary?.correctAnsRate}%</strong>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* 취약단원, 대표단원이 같이 않을 경우에만 보여주기 */}
        {reportTaskSummary?.goodSectionId !==
          reportTaskSummary?.badSectionId && (
          <div className="boxSubDetails typeAnalyze">
            <div className="roundBox dataPercentage">
              <div className="textInfoUnit">
                <p className="txtInfoTitle">대표 우수 단원</p>
                <p className="txtSmall">{reportTaskSummary?.goodSectionNm}</p>
              </div>
              <div className="textInfoPercentage">
                {reportTaskSummary?.goodSectionCorrectAnsRate}%
              </div>
            </div>

            <div className="roundBox dataPercentage">
              <div className="textInfoUnit">
                <p className="txtInfoTitle">대표 취약 단원</p>
                <p className="txtSmall">{reportTaskSummary?.badSectionNm}</p>
              </div>
              <div className="textInfoPercentage">
                {reportTaskSummary?.badSectionCorrectAnsRate}%
              </div>
            </div>
          </div>
        )}

        {isBeforeRegDate && (
          <div className="boxSubDetails typeAverage">
            <div className="roundBox dataRanking">
              <div className="textRanking">
                <p className="txtInfoTitle">학원 평균 성취율 및 등수</p>
                <div className="txtRankingData">
                  <span className="txtNyRank">
                    {reportTaskSummary?.rankInAcademy}등
                  </span>
                  <span className="txtTotal">
                    {reportTaskSummary?.academyTotalStudentCnt}명
                  </span>
                </div>
              </div>
              <div className="textAverage">
                <span>원과제 평균성취율</span>
                <span>{reportTaskSummary?.academyCorrectAnswerRate}%</span>
              </div>
            </div>

            <div className="roundBox dataRanking">
              <div className="textRanking">
                <p className="txtInfoTitle">클래스 평균 성취율 및 등수</p>
                <div className="txtRankingData">
                  <span className="txtNyRank">
                    {reportTaskSummary?.rankInClass}등
                  </span>
                  <span className="txtTotal">
                    {reportTaskSummary?.classTotalStudentCnt}명
                  </span>
                </div>
              </div>
              <div className="textAverage">
                <span>원과제 평균성취율</span>
                <span>{reportTaskSummary?.classCorrectAnswerRate}%</span>
              </div>
            </div>
          </div>
        )}

        <div className="boxSubDetails">
          <div className="roundBox typeDataTable">
            <ul className="listGradingResults">
              <li>
                <div className="textTh">
                  <span>전체 문항 수</span>
                </div>
                <div className="textTd">
                  <p>
                    <strong>{reportTaskSummary?.totalQuestionCnt} 문항</strong>
                    <span>취약 처방 포함</span>
                  </p>
                </div>
              </li>
              <li>
                <div className="textTh">
                  <span>처방/오답풀이</span>
                </div>
                <div className="textTd">
                  <p>
                    <strong>
                      {reportTaskSummary?.prescriptionLearningCnt} 학습
                    </strong>
                    <span>총 {reportTaskSummary?.prescriptionCnt}회 처방</span>
                  </p>
                </div>
              </li>
              <li>
                <div className="textTh">
                  <span>정답 문항수</span>
                </div>
                <div className="textTd">
                  <p>
                    <strong>{reportTaskSummary?.correctAnsCnt} 문항</strong>
                  </p>
                </div>
              </li>
              <li>
                <div className="textTh">
                  <span>오답 문항수</span>
                </div>
                <div className="textTd">
                  <p>
                    <strong>{reportTaskSummary?.wrongAnsCnt} 문항</strong>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskReportSummaryDetail;
