import { Outlet, useMatch } from 'react-router-dom';
import classnames from 'classnames';

import { FoldList, GoTop } from '@components/common';

import Tabbar from './Tabbar';
import Header from './Header';

function Root() {
  const isLoginPage = useMatch('/login');

  const isHomePage = useMatch('/home');

  const isLearningPage = useMatch('/learning-status');
  const isAcademyReportPage = useMatch('/learning-report-academy/:id');
  const isOnlineReportPage = useMatch('/learning-report-online/:id/:date');
  const isLevelTestPage = useMatch('/learning-report-level/:id');
  const isTaskReportPage = useMatch('/learning-report-task/:id');
  const isAcademyPage = useMatch('/academy/consultation');
  const isMyPage = useMatch('/my');

  return (
    <>
      {isLoginPage ? (
        <div
          className="contentsRegion typeGrow"
          style={{ backgroundColor: 'white' }}
        >
          <Outlet />
        </div>
      ) : (
        <>
          <Header />
          <div
            className={classnames(
              'contentsRegion typeGrow hasBottomBar',
              isHomePage && 'mainPage',
              (isAcademyPage || isLearningPage) && 'typeLearningStatus',
              (isAcademyReportPage ||
                isOnlineReportPage ||
                isLevelTestPage ||
                isTaskReportPage) &&
                'bgGray',
            )}
          >
            <Outlet />
          </div>
          <Tabbar />
        </>
      )}

      {!isMyPage && <GoTop />}
      {isHomePage && <FoldList />}
    </>
  );
}

export default Root;
