/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { Button } from '@components/ui';

function AutoComplete({ fetcher, onSelect }) {
  const optionsRef = useRef(null);
  const timeRef = useRef(null);

  const [focus, setFocus] = useState(false);
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [searchText, setSearchText] = useState('');

  const handleChange = useCallback(
    (e) => {
      const { value } = e.currentTarget;
      setSearchText(value);
      // 두자리 이상
      if (value.length >= 2) {
        if (timeRef.current) {
          clearTimeout(timeRef.current);
        }
        timeRef.current = setTimeout(async () => {
          const result = await fetcher(value);

          setOptions(result);
          setShowOptions(true);
          setActiveIndex(0);
          timeRef.current = null;
        }, 300);
      }

      // 입력된게 없으면 초기화
      if (value.length < 2) {
        setOptions([]);
        setShowOptions(false);
        setActiveIndex(0);
      }
    },
    [fetcher],
  );

  const handleClick = useCallback(
    (item) => {
      setSearchText(() => item.label);
      setOptions([]);
      setShowOptions(false);
      setActiveIndex(0);

      onSelect?.(item);
    },
    [onSelect],
  );

  const handleKeyDown = useCallback(
    (e) => {
      // 위로
      if (e.keyCode === 38) {
        if (activeIndex === 0) {
          return;
        }
        setActiveIndex(activeIndex - 1);
      }
      // 아래로
      else if (e.keyCode === 40) {
        if (activeIndex === options.length - 1) {
          return;
        }
        setActiveIndex(activeIndex + 1);
      }
    },
    [activeIndex, options],
  );

  const handleFocus = useCallback(() => {
    setFocus(true);

    setSearchText('');
    setOptions([]);
    setShowOptions(false);
    setActiveIndex(0);

    onSelect();
  }, [onSelect]);

  const handleBlur = useCallback(() => {
    setFocus(false);
  }, []);

  const isViewOptions = useMemo(() => {
    return showOptions && searchText && options.length > 0;
  }, [options.length, searchText, showOptions]);

  const renderOptions = useCallback(() => {
    return (
      <div className="dropdownLayer">
        <ul ref={optionsRef}>
          {options.map((item, index) => {
            const startIdx = item.label.indexOf(searchText);

            return (
              <li
                className={classnames(index === activeIndex && 'selected')}
                key={`${item.name}_${index}`}
                onClick={() => handleClick(item)}
              >
                <div className="textData">
                  <span>
                    {item.label.substring(startIdx, -1)}
                    <b>{searchText}</b>
                    {item.label.substring(
                      startIdx + searchText.length,
                      item.label.length,
                    )}
                  </span>
                  {item.subLabel && item.subLabel}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }, [options, activeIndex, searchText, handleClick]);

  return (
    <div
      className={classnames(
        'searchInput',
        'large',
        isViewOptions && 'isActive',
      )}
    >
      <div className={classnames('inputBox', focus && 'isFocus')}>
        <input
          type="text"
          className="search-box"
          placeholder="학교명을 입력해주세요."
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={searchText}
        />
        <Button.Normal className="btnSearch" label="조회" />
      </div>
      {renderOptions()}
    </div>
  );
}

export default AutoComplete;
