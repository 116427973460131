import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';

import { KO_YEAR_DATE, format } from '@helper/timeHelper';
import reportService from '@service/ReportService';

import EnglishLevelTestGrade from './components/english/EnglishLevelTestGrade';
import EnglishLevelTestScore from './components/english/EnglishLevelTestScore';
import EnglishLevelTestResult from './components/english/EnglishLevelTestResult';
import EnglishLevelTestGuideTable from './components/english/EnglishLevelTestGuideTable';
import EnglishLevelTestDescription from './components/english/EnglishLevelTestDescription';
import EnglishLevelTestCurriculum from './components/english/EnglishLevelTestCurriculum';

function LearningReportEnglishPage() {
  const { id: taskId, memberNo: studentNo } = useParams();

  const [report, setReport] = useState();

  useMount(() => {
    (async () => {
      try {
        const result = await reportService.getLevelReport({
          taskId,
          studentNo,
        });
        setReport({ ...result, level: result.level === 0 ? 1 : result.level });
      } catch (error) {
        console.warn(error);
      }
    })();
  });

  return (
    <div className="contentBox boxReportDetails">
      {!isEmpty(report) && (
        <div className="wrapLearningReport wrapLevelReport">
          <div className="reportTopArea typeEngLvTest">
            <div className="boxTitle">
              <strong>{report?.studentName}</strong>레벨테스트 리포트
            </div>

            <ul className="topTestInfo">
              <li>
                <strong>학원명</strong>
                <p>{report?.academyName}</p>
              </li>
              <li>
                <strong>Test Type</strong>
                <p>{report.testTypeNm}</p>
              </li>
              <li>
                <strong>테스트 응시일</strong>
                <p>{format(report.testDate, KO_YEAR_DATE)}</p>
              </li>
            </ul>
          </div>

          <div className="reportContentWrap typeEngLvTest">
            <EnglishLevelTestGrade {...report} />
            <EnglishLevelTestScore {...report} />
            <EnglishLevelTestResult {...report} />
            <EnglishLevelTestGuideTable {...report} />
            <EnglishLevelTestDescription {...report} />
            <EnglishLevelTestCurriculum {...report} />
          </div>
        </div>
      )}
    </div>
  );
}

export default LearningReportEnglishPage;
