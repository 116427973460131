import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { beforeWeekRange } from '@helper/timeHelper';
import learningService from '@service/LearningService';

export const getAcademyHomework = createAsyncThunk(
  'learningStatus/getAcademyHomework',
  async ({ studentIds }) => {
    const [recent, past] = await Promise.all([
      learningService.getAcademyHomework({
        studentIds,
      }),
      learningService.getAcademyHomeworkPast({
        studentIds,
        page: 1,
      }),
    ]);
    return { thisWeek: recent, pastWeek: past };
  },
);

export const nextAcademyHomework = createAsyncThunk(
  'learningStatus/nextAcademyHomework',
  async ({ studentIds }, { getState }) => {
    const {
      learningStatus: { pageNo },
    } = getState();

    const result = await learningService.getAcademyHomeworkPast({
      studentIds,
      page: pageNo + 1,
    });

    return result;
  },
);

export const getOnlineHomework = createAsyncThunk(
  'learningStatus/getOnlineHomework',
  async ({ studentIds }) => {
    const [recent, past, exam] = await Promise.all([
      learningService.getOnlineHomework({
        studentIds,
      }),
      learningService.getOnlineHomeworkPast({
        studentIds,
        pageNo: 1,
      }),
      learningService.getExamPeriod({ studentIds }),
    ]);
    return { thisWeek: recent, pastWeek: past, exam, pageNo: 1 };
  },
);

export const nextOnlineHomework = createAsyncThunk(
  'learningStatus/nextOnlineHomework',
  async ({ studentIds }, { getState }) => {
    const {
      learningStatus: { pageNo },
    } = getState();

    const result = await learningService.getOnlineHomeworkPast({
      studentIds,
      pageNo: pageNo + 1,
    });

    return result;
  },
);

const initialState = {
  // 이번주
  thisWeek: [],
  // 지난주
  pastWeek: [],
  exam: [],
  pageNo: 1,
  isFetch: false,
  hasNext: false,
};

export const learningStatusSlice = createSlice({
  name: 'learningStatus',
  initialState,
  reducers: {
    clear: (state) => {
      state.thisWeek = [];
      state.pastWeek = [];
      state.exam = [];
      state.pageNo = 1;
      state.isFetch = false;
      state.hasNext = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAcademyHomework.fulfilled, (state, action) => {
        const { thisWeek, pastWeek } = action.payload;

        state.thisWeek = thisWeek;
        state.pastWeek = pastWeek.list;
        state.exam = [];
        state.pageNo = pastWeek.pageNum;
        state.hasNext = pastWeek.hasNextPage;

        state.isFetch = true;
      })
      .addCase(nextAcademyHomework.fulfilled, (state, action) => {
        const { list, pageNum, hasNextPage } = action.payload;
        state.pastWeek = [...state.pastWeek, ...list];
        state.pageNo = pageNum;
        state.hasNext = hasNextPage;
      })
      .addCase(getOnlineHomework.fulfilled, (state, action) => {
        const { thisWeek, pastWeek, exam } = action.payload;

        state.thisWeek = thisWeek;
        state.pastWeek = pastWeek.list;
        state.exam = exam;
        state.pageNo = pastWeek.pageNum;
        state.hasNext = pastWeek.hasNextPage;

        state.isFetch = true;
      })
      .addCase(nextOnlineHomework.fulfilled, (state, action) => {
        const { list, pageNum, hasNextPage } = action.payload;
        state.pastWeek = [...state.pastWeek, ...list];
        state.pageNo = pageNum;
        state.hasNext = hasNextPage;
      });
  },
});
const examStatusState = (state) => {
  return {
    exam: state.learningStatus.exam,
  };
};
const recentStatusState = (state) => {
  return {
    thisWeek: state.learningStatus.thisWeek,
  };
};

const pastStatusState = (state) => {
  return {
    pastWeek: state.learningStatus.pastWeek,
    isFetch: state.learningStatus.isFetch,
    hasNext: state.learningStatus.hasNext,
  };
};

export const examStatusSelector = createSelector(
  examStatusState,
  (state) => state,
);
export const recentStatusSelector = createSelector(
  recentStatusState,
  (state) => state,
);

export const pastStatusSelector = createSelector(
  pastStatusState,
  (state) => state,
);

export const { clear } = learningStatusSlice.actions;

export default learningStatusSlice.reducer;
