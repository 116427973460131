import api from '@utils/Api';

class HomeService {
  constructor(client) {
    this.httpClient = client;
  }

  /**
   * 홈에서 필요한 모든 데이터 리턴
   */
  homeInfo({ memberId, studentIds }, options) {
    return this.httpClient.get(
      `/v1/home/list`,
      {
        memberId,
        studentIds,
      },
      options,
    );
  }

  /**
   * 피드 목록 조회
   */
  feed({ memberId, pageNo }, options) {
    return this.httpClient.get(
      `/v1/home/feed/list`,
      {
        memberId,
        pageNo,
      },
      options,
    );
  }

  /**
   * 피드 읽음
   */
  read({ feedId }, options) {
    return this.httpClient.put(`/v1/home/feed/read/${feedId}`, {}, options);
  }

  /**
   * 피드 숨기기
   * @returns
   */
  hidden({ parentNo, studentNo, type, feedLinkKey }, options) {
    return this.httpClient.put(
      `/v1/home-feed/un-expose-detail`,
      { studentNo, type, feedLinkKey, parentNo },
      options,
    );
  }

  /**
   * 학원 초대 수락
   */
  inviteAcademy(
    { feedLinkKey, parentNo, studentNo, parentName, studentName, courseCode },
    options,
  ) {
    return this.httpClient.put(
      `/v1/home/feed/confirm-invite/${feedLinkKey}/${parentNo}/${studentNo}?parentNm=${parentName}&studentNm=${studentName}&courseCode=${courseCode}`,
      {},
      options,
    );
  }

  inviteDetail(feedLinkKey, options) {
    return this.httpClient.get(
      `/v1/home/feed-class-invite/${feedLinkKey}`,
      {},
      options,
    );
  }
}

const homeService = new HomeService(api);
export default homeService;
