/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { classSelector, studentSelector } from '@store/slice/accountSlice';
import { useNavigate } from 'react-router-dom';

function TaskReportItem({
  reportSendId,
  reportTitle,
  reportSendDate,
  reportSendPeriodStart,
  reportSendPeriodEnd,
  subjectNm,
  studentId,
  classId,
}) {
  const navigate = useNavigate();

  const isNewReport = useMemo(() => {
    return dayjs().diff(dayjs(reportSendDate), 'hour') < 24;
  }, [reportSendDate]);

  const goDetail = () => {
    navigate(`/learning-report-task/${reportSendId}`);
  };

  const classes = useSelector(classSelector);
  const { academyName } =
    classes.find((item) => item.classId === classId) || {};

  const { memberName } = useSelector(studentSelector(studentId));

  return (
    <li>
      <a className="aLink" onClick={() => goDetail()}>
        <div className="boxClassInfo">
          <span className="logoImg noImg" />
          <p className="txtAcademy">
            <span>{academyName} 과제 리포트</span>
            {isNewReport && (
              <span className="icoNew">
                <em>New</em>
              </span>
            )}
          </p>
          <p className="textName">{memberName}</p>
        </div>
        <ul className="subtopicList">
          <li>
            <div className="txtLabel">과목</div>
            <div className="txtSubtopic">{subjectNm || '-'}</div>
          </li>
          <li>
            <div className="txtLabel">리포트명</div>
            <div className="txtSubtopic">{reportTitle || '-'}</div>
          </li>
          <li>
            <div className="txtLabel">리포트 기간</div>
            <div className="txtSubtopic">
              {`${reportSendPeriodStart} ~ ${reportSendPeriodEnd}` || '-'}
            </div>
          </li>
        </ul>
      </a>
    </li>
  );
}

export default TaskReportItem;
