import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';

import { FieldSet } from '@components/common';
import { Button } from '@components/ui';
import { FormInput, FormPassword } from '@components/form';
import { ModalAlert, ModalProfileImage } from '@components/overlay';
import { VERIFY_STATUS } from '@constants/common';
import { useOverlayContext } from '@context/OverlayContext';
import { setParents, userSelector } from '@store/slice/accountSlice';
import { getCharacterImage } from '@helper/imageHelper';
import profileService from '@service/ProfileService';

// MY 페이지 > 프로필 변경
function MyProfileDetailPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { memberNo, memberName, mobileNumber, profilePhoto } =
    useSelector(userSelector);
  const { state } = useLocation();

  const { showDialog, showBottomSheet } = useOverlayContext();

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      memberName,
      mobileNumber,
      memberNo,
      profilePhoto,
      verifyStatus: VERIFY_STATUS.READY,
    },
  });

  const {
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = methods;

  const profileImage = watch('profilePhoto');

  const handleClick = () => {
    showDialog(ModalProfileImage, {
      profilePhoto: profileImage,
      onComplete: (value) => setValue('profilePhoto', value),
    });
  };

  const submit = async (formValue) => {
    if (!isEmpty(errors)) {
      return;
    }

    profileService
      .update({
        memberNo,
        authToken: state?.authToken,
        newPassword: formValue.password,
        profilePhoto: formValue.profilePhoto,
      })
      .then(() => {
        // 성공하면 정보 제거 날려야 함
        dispatch(setParents({}));

        showBottomSheet(ModalAlert, {
          title: '알림',
          content: '내 정보가 수정되었습니다.',
          buttons: [{ label: '확인', onClick: () => navigate('/my') }],
        });
      });
  };

  useMount(() => {
    // 인증키값이 없으면 my페이지로 이동
    if (isEmpty(state?.authToken)) {
      navigate('/my');
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)} className="contentBox personalnfo">
        <FieldSet.Wrapper>
          <div className="profilePhoto">
            <div className="photoBox">
              <span className="img">
                <img
                  src={getCharacterImage(profileImage || '01')?.src}
                  alt="프로필사진"
                />
              </span>
              <button
                type="button"
                className="btnChangePhoto"
                onClick={handleClick}
              >
                <span>사진변경</span>
              </button>
            </div>
          </div>
          <FieldSet.List>
            <FieldSet.Item>
              <FormInput name="memberName" disabled label="이름" />
            </FieldSet.Item>
            <FieldSet.Item>
              <FormInput name="mobileNumber" disabled label="전화번호" />
            </FieldSet.Item>
            <FieldSet.Item>
              <FormPassword />
            </FieldSet.Item>
            <FieldSet.Item>
              <FormPassword
                confirm
                name="newPassword"
                label="새 비밀번호 확인"
                placeholder="새 비밀번호 확인"
              />
            </FieldSet.Item>
          </FieldSet.List>
        </FieldSet.Wrapper>
        <FieldSet.ButtonBox>
          <Button.Primary large type="submit" label="확인" />
        </FieldSet.ButtonBox>
        {/* <div className="boxFlexNone">
          <div className="boxSecession">
            <button type="button" className="btnLink">
              <span>탈퇴하기</span>
            </button>
          </div>
        </div> */}
      </form>
    </FormProvider>
  );
}

export default MyProfileDetailPage;
