import { useNavigate } from 'react-router-dom';

import { useAppContext } from '@context/AppContext';
import callApp from '@interface/externalInterface';

// 자녀추가 성공(회원가입시에만 노출)
function SuccessPage() {
  const navigate = useNavigate();
  const { isWinkDevice, isCatsApp } = useAppContext();

  const goHome = () => {
    navigate('/home');
  };

  const goCatsHome = () => {
    // 웹뷰인 경우 닫기
    callApp('closeWindow');
  };

  return (
    <div className="contentBox membershiDescendant">
      <div className="boxFlexGrow typeMiddle">
        <div className="membershipMarketing">
          <div className="topTexts">
            <p className="textMain typeBlack">회원가입이 완료되었습니다.</p>
            <div className="textSub">
              <p>지금 바로 자녀를 위한 1:1 맞춤 학습을 시작해 보세요.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="boxFlex">
        <div className="btnsWrapper">
          {(isWinkDevice || isCatsApp) && (
            <button
              type="button"
              className="btnPrimary large"
              onClick={goCatsHome}
            >
              <span>캐츠홈으로 이동하기</span>
            </button>
          )}
          {!isWinkDevice && !isCatsApp && (
            <button type="button" className="btnPrimary large" onClick={goHome}>
              <span>학부모 홈으로 이동하기</span>
            </button>
          )}
        </div>
      </div>
      {/* 
        <div className="boxContact">
          <strong className="textTitle">문의하기</strong>
          <a href="tel:1660-1244" className="btnCS">
            <span>고객센터문의</span>
          </a>
          <button type="button" className="btnKakao" onClick={goKakao}>
            <span>카카오톡 문의</span>
          </button>
        </div>
      </div> */}
      {/* <div className="boxFlex">
        <div className="imgBtnWrap typeIcoSm" onClick={goTrial}>
          <a className="imgBtn">
            <span className="hidden">무료체험 신청하기</span>
          </a>
        </div>
        <div className="boxContact">
          <strong className="textTitle">문의하기</strong>
          <a href="tel:1660-1244" className="btnCS">
            <span>고객센터문의</span>
          </a>
          <button type="button" className="btnKakao" onClick={goKakao}>
            <span>카카오톡 문의</span>
          </button>
        </div>
      </div> */}
    </div>
  );
}

export default SuccessPage;
