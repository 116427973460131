/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect, useMount } from 'ahooks';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import PullToRefresh from 'react-simple-pull-to-refresh';

import { accountSelector } from '@store/slice/accountSlice';
import {
  academyInviteSelector,
  feedSelector,
  getHomeInfo,
  homeSelector,
  isEmptyHomeSelector,
} from '@store/slice/homeSlice';
import { clear } from '@store/slice/paymentWaitingSlice';
import { useOverlayContext } from '@context/OverlayContext';
import { ModalFirstLogin, ModalClassInvitation } from '@components/overlay';

import HomeFeed from './components/HomeFeed';
import HomeLearning from './components/HomeLearning';
import HomeFooter from './components/HomeFooter';
import HomeEmpty from './components/HomeEmpty';

// 홈
function HomePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showDialog } = useOverlayContext();
  const { memberNo, students, viewHomePopup } = useSelector(accountSelector);
  const { waitingCount } = useSelector(homeSelector);
  const { pageNo } = useSelector(feedSelector);
  const academyInvite = useSelector(academyInviteSelector);

  const isEmptyHome = useSelector(isEmptyHomeSelector);

  const goPayment = useCallback(() => {
    dispatch(clear());
    navigate('/payment-waiting');
  }, [dispatch, navigate]);

  const handleInit = useCallback(
    (memberId, student, loading = true) => {
      const studentIds = student.map((item) => item.memberNo);
      // promise 리턴으로 pullToRefresh 종료시점 파악해야 함
      return dispatch(getHomeInfo({ memberId, studentIds, loading })).unwrap();
    },
    [dispatch],
  );

  useEffect(() => {
    if (pageNo === 0) {
      handleInit(memberNo, students);
    }
  }, [handleInit, pageNo, memberNo, students]);

  useDeepCompareEffect(() => {
    if (academyInvite?.length > 0) {
      showDialog(ModalClassInvitation, {
        academyInvite,
        onSuccess: () => {
          handleInit(memberNo, students);
        },
      });
    }
  }, [academyInvite]);

  useMount(() => {
    window.scrollTo({ top: 0, left: 0 });
    if (!viewHomePopup && isEmpty(localStorage.getItem('noMorePopup'))) {
      setTimeout(() => {
        showDialog(ModalFirstLogin);
      });
    }
  });

  return (
    <>
      <div
        className={classnames(
          'notificationMessage',
          waitingCount > 0 && 'isActive',
        )}
      >
        <p className="textMessage">
          결제할 건이{' '}
          <strong>
            총<b>{waitingCount}</b>건
          </strong>{' '}
          있습니다.
        </p>
        <a className="btnLink secondary" onClick={goPayment}>
          <span>결제하기</span>
        </a>
      </div>
      {isEmptyHome && <HomeEmpty />}
      {!isEmptyHome && (
        <>
          <PullToRefresh
            onRefresh={() => handleInit(memberNo, students, false)}
            className="contentBox"
            pullingContent=""
          >
            <HomeFeed />
            <HomeLearning />
          </PullToRefresh>
          <HomeFooter />
        </>
      )}
    </>
  );
}

export default HomePage;
