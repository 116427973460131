import { boardSelector } from '@store/slice/boardSlice';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

function FaqHashTag({ onSearch }) {
  const { board } = useSelector(boardSelector);
  const { setValue } = useFormContext();

  const handleSearch = (keyword) => {
    setValue('keyword', keyword);
    onSearch({ keyword });
  };
  return (
    <div className="searchWordTags">
      {board?.hasTags?.map((item) => (
        <button
          key={`button_${item}`}
          type="button"
          className="tagWord"
          onClick={() => handleSearch(item)}
        >
          <span>{item}</span>
        </button>
      ))}
    </div>
  );
}

export default FaqHashTag;
