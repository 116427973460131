import { useMatch, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button } from '@components/ui';
import { clear } from '@store/slice/paymentHistorySlice';

import Popup from './base/Popup';

function PopupClaimSuccess({ id, ...rest }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isClaimPage = useMatch('/payment-claim/:id');
  const isCancelPage = useMatch('/payment-cancel/:id');

  const goHome = () => {
    navigate('/home', { replace: true });
  };

  const goPaymentHistory = () => {
    dispatch(clear());
    navigate('/payment-history', { replace: true });
  };

  return (
    <Popup {...rest}>
      <Popup.Header id={id} hasClose>
        {isClaimPage && '교환/반품요청'}
        {isCancelPage && '주문취소'}
      </Popup.Header>
      <Popup.Body className="boxCompleted">
        <div className="boxFlexGrow typeMiddle">
          {isCancelPage && (
            <p className="textMain icoFaild">
              <b>주문이 취소</b>되었습니다.
            </p>
          )}
          {isClaimPage && (
            <>
              <p className="textMain">
                <b>교환/반품 요청</b>이 완료되었습니다.
              </p>
              <p className="textSub">담당자가 확인한 후 연락드리겠습니다.</p>
            </>
          )}
        </div>
        <Button.Wrapper flexNone>
          <Button.Outline
            large
            label="결제내역 목록가기"
            onClick={goPaymentHistory}
          />
          <Button.Primary large label="홈으로 이동" onClick={goHome} />
        </Button.Wrapper>
      </Popup.Body>
    </Popup>
  );
}

export default PopupClaimSuccess;
