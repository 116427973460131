import { memo } from 'react';
import { connect } from 'react-redux';

import { PAYMENT_TYPE } from '@constants/tab';
import { useOverlayContext } from '@context/OverlayContext';
import { ModalHistoryOnline } from '@components/overlay';
import orderService from '@service/OrderService';

import Payment from './base/Payment';

function PaymentHistoryOnline({ orderNumber, parentNo, ...rest }) {
  const { showDialog } = useOverlayContext();

  const handleSelect = async () => {
    try {
      const result = await orderService.getHistoryDetail({
        parentNo,
        orderNumber,
        type: PAYMENT_TYPE.ONLINE,
      });
      showDialog(ModalHistoryOnline, { orderNumber, payment: result });
    } catch (error) {
      console.error(error);
    }
  };

  return <Payment onSelect={handleSelect} {...rest} />;
}

const mapStateToPros = (state) => {
  return { parentNo: state.account.user.memberNo };
};

export default connect(mapStateToPros)(memo(PaymentHistoryOnline));
