/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

function MathTestReportItem({
  reportSendId,
  studentNm,
  academyNm,
  reportContents,
}) {
  const navigate = useNavigate();

  const isNewReport = useMemo(() => {
    return dayjs().diff(dayjs(reportContents?.testDate), 'hour') < 24;
  }, [reportContents?.testDate]);

  const goDetail = () => {
    navigate(`/learning-report-math/${reportSendId}`);
  };

  return (
    <li>
      <a className="aLink" onClick={() => goDetail()}>
        <div className="boxClassInfo">
          <span className="logoImg noImg" />
          <p className="txtAcademy">
            <span>{academyNm} 수준 진단 평가 리포트</span>
            {isNewReport && (
              <span className="icoNew">
                <em>New</em>
              </span>
            )}
          </p>
          <p className="textName">{studentNm}</p>
        </div>
        <ul className="subtopicList">
          <li>
            <div className="txtLabel">평가지</div>
            <div className="txtSubtopic">{reportContents?.title || '-'}</div>
          </li>
          <li>
            <div className="txtLabel">난이도</div>
            <div className="txtSubtopic">{reportContents?.type || '-'}</div>
          </li>
          <li>
            <div className="txtLabel">테스트 응시일</div>
            <div className="txtSubtopic">{reportContents?.testDate || '-'}</div>
          </li>
        </ul>
      </a>
    </li>
  );
}

export default MathTestReportItem;
