import { Button } from '@components/ui';
import { useNavigate, useRouteError } from 'react-router-dom';

function RuntimeErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError();
  console.log(error);

  return (
    <div className="systemBox">
      <div className="errorMessageBox">
        <p className="textTitle">
          죄송합니다.
          <br />
          Runtime Error
        </p>
        <p className="textSub">
          시스템 에러가 발생하여 페이지를 표시할 수 없습니다.
          <br />
          관리자에게 문의하시거나 잠시 후 다시 시도하세요.
        </p>
      </div>
      <Button.Wrapper>
        <Button.Outline
          large
          label="이전 페이지로"
          onClick={() => navigate(-2)}
        />
        <Button.Primary
          large
          label="홈으로 가기"
          onClick={() => navigate('/home')}
        />
      </Button.Wrapper>

      <div className="guidanceText">
        <p>
          혹시 문의사항이 있으시다면
          <br />
          <a href="tel:1660-1244" className="aLink">
            고객센터
          </a>
          로 연락주세요!
        </p>
      </div>
    </div>
  );
}

export default RuntimeErrorPage;
