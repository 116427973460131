import { memo } from 'react';
import { useSelector } from 'react-redux';

import { useOverlayContext } from '@context/OverlayContext';
import { PAYMENT_TYPE } from '@constants/tab';
import { ModalHistoryAcademyAutoPay } from '@components/overlay';
import { accountSelector } from '@store/slice/accountSlice';
import orderService from '@service/OrderService';

import Payment from './base/Payment';

function PaymentHistoryAcademyAutoPay({
  orderNumber,
  studentName,
  payRqstAmount,
  academyName,
  className,
  onSuccess,
  ...rest
}) {
  const { showDialog } = useOverlayContext();
  const { memberNo } = useSelector(accountSelector);

  const handleSelect = async () => {
    try {
      const result = await orderService.getHistoryDetail({
        parentNo: memberNo,
        orderNumber,
        type: PAYMENT_TYPE.ACADEMY,
      });
      showDialog(ModalHistoryAcademyAutoPay, {
        orderNumber,
        onSuccess,
        payment: result,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Payment
      {...rest}
      onSelect={handleSelect}
      studentNm={studentName}
      goodsNm={`${academyName} - ${className || ''}`}
      price={payRqstAmount}
      payStateCd=""
      issueYearmon=""
    />
  );
}

export default memo(PaymentHistoryAcademyAutoPay);
