import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMount } from 'ahooks';

import { ModalOnlineSelectStudent } from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';
import { accountSelector } from '@store/slice/accountSlice';
import { getOrderOnline } from '@store/slice/orderSlice';

function OrderOnlineLearnerSelector({ student, onSelectStudent }) {
  const dispatch = useDispatch();

  const { subjectCd } = useParams();

  const { memberNo } = useSelector(accountSelector);

  const { showDialog } = useOverlayContext();

  // 학생 선택된 후 callback
  const handleSelect = useCallback(
    (selectStudent) => {
      onSelectStudent(selectStudent);
      dispatch(
        getOrderOnline({
          ...selectStudent,
          subjectCd,
          memberNo,
          studentNo: selectStudent.memberNo,
        }),
      );
    },
    [dispatch, memberNo, onSelectStudent, subjectCd],
  );

  // 학생선택
  const handleShowStudents = useCallback(() => {
    // 모달 뜨는 시간이 필요
    setTimeout(() => {
      showDialog(ModalOnlineSelectStudent, { onSelect: handleSelect });
    });
  }, [handleSelect, showDialog]);

  useMount(() => {
    handleShowStudents();
  });

  return (
    <div className="definitionBox typeLearner">
      <dl>
        <dt>
          <span>학습자</span>
          <strong>{student?.memberName || '-'}</strong>
        </dt>
        <dd>
          <button
            type="button"
            className="btnOutline small"
            onClick={handleShowStudents}
          >
            <span>학습자 변경</span>
          </button>
        </dd>
      </dl>
    </div>
  );
}

export default OrderOnlineLearnerSelector;
