import { useNavigate } from 'react-router-dom';

import { Button } from '@components/ui';
import { DefinitionBoxOrder, DefinitionBoxPayment } from '@components/box';
import { format } from '@helper/timeHelper';
import { toCommaNumber } from '@helper/commonHelper';
import { BILL_PAY_STATE_CODE } from '@constants/order';

import Modal from './base/Modal';

// 결재내역 > 학원비 바텀시트
function ModalHistoryAcademy({ payment, ...rest }) {
  const navigate = useNavigate();

  const goManage = () => {
    navigate('/payment-manage/academy');
  };

  return (
    <Modal isBasic {...rest}>
      <Modal.Header hasClose>상세정보</Modal.Header>
      <Modal.Body className="popPaymentDetails">
        <DefinitionBoxOrder
          title="결제정보"
          category={[
            { name: '학원명', topic: payment.academyName },
            { name: '클래스명', topic: payment.className || '-' },
            { name: '자녀 이름', topic: payment.studentName || '-' },
            {
              name: '청구월',
              topic: <>{format(payment.issueYearmon, 'YYYY년 MM월')}</>,
            },
            {
              name: '결제방식',
              topic: (
                <>
                  {payment.issueAutoPayYn === 'Y' && '자동결제'}
                  {payment.issueAutoPayYn === 'N' &&
                    payment.payStateCd === BILL_PAY_STATE_CODE.DIRECT_PAY &&
                    '직접납부'}
                  {payment.issueAutoPayYn === 'N' &&
                    payment.payStateCd !== BILL_PAY_STATE_CODE.DIRECT_PAY &&
                    '수동결제'}
                </>
              ),
            },
            {
              name: '결제금액',
              className: 'totalCost',
              topic: `${toCommaNumber(payment.payAmount)}원`,
            },
          ]}
        />

        <DefinitionBoxPayment
          isModal
          title="결제내역"
          category={[
            { name: '교습비', topic: payment.lessonFee },
            { name: '기타경비', topic: payment.chargeEtc },
            { name: '할인', topic: payment.discount },
          ]}
        />

        {payment.payStateCd !== BILL_PAY_STATE_CODE.DIRECT_PAY && (
          <DefinitionBoxOrder
            title="결제상세"
            category={[
              { name: '결제일시', topic: format(payment.payDate) },
              {
                name: '결제정보',
                topic: `${payment.cardName}(${payment.cardNum})`,
              },
            ]}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        {payment.autoPayYn === 'Y' && (
          <Button.Primary label="학원비 자동결제 관리" onClick={goManage} />
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalHistoryAcademy;
