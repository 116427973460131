const PAYMENT_TYPE = {
  ALL: '0',
  BOOK: '1',
  ONLINE: '2',
  ACADEMY: '3',
  EXCHANGE: '4',
};

const PAYMENT_TABS = [
  { name: '전체', value: PAYMENT_TYPE.ALL },
  { name: '교재', value: PAYMENT_TYPE.BOOK },
  { name: '학원비', value: PAYMENT_TYPE.ACADEMY },
  { name: '캐츠 학습', value: PAYMENT_TYPE.ONLINE },
];

const PAYMENT_WITHOUT_ALL_TABS = [
  { name: '교재', value: PAYMENT_TYPE.BOOK },
  { name: '학원비', value: PAYMENT_TYPE.ACADEMY },
  { name: '캐츠 학습', value: PAYMENT_TYPE.ONLINE },
];

const ACADEMY_TYPE = {
  // ATTENDANCE: 'attendance',
  CONSULTATION: 'consultation',
  NOTICE: 'notice',
  // TEST: 'test',
};

const ACADEMY_TABS = [
  // { name: '출결관리', value: ACADEMY_TYPE.ATTENDANCE },
  { name: '알림장', value: ACADEMY_TYPE.NOTICE },
  { name: '상담', value: ACADEMY_TYPE.CONSULTATION },
  // { name: '입회테스트 결과', value: ACADEMY_TYPE.TEST },
];

const LEARNING_TYPE = {
  ACADEMY: 'academy',
  ONLINE: 'online',
};

const LEARNING_TABS = [
  { name: '학원학습/숙제', value: LEARNING_TYPE.ACADEMY },
  { name: '캐츠 학습', value: LEARNING_TYPE.ONLINE },
];

const REPORT_TYPE = {
  ACADEMY: 'academy',
  ONLINE: 'online',
  ENGLISH: 'english',
  MATH: 'math',
  TASK: 'task',
};

const REPORT_TABS = [
  { name: '학원 수강 과목', value: REPORT_TYPE.ACADEMY },
  { name: '캐츠 학습', value: REPORT_TYPE.ONLINE },
  { name: '영어 레벨테스트', value: REPORT_TYPE.ENGLISH },
  { name: '수학 수준진단평가', value: REPORT_TYPE.MATH },
  { name: '과제 리포트', value: REPORT_TYPE.TASK },
];

export {
  PAYMENT_TYPE,
  PAYMENT_TABS,
  PAYMENT_WITHOUT_ALL_TABS,
  ACADEMY_TYPE,
  ACADEMY_TABS,
  LEARNING_TYPE,
  LEARNING_TABS,
  REPORT_TYPE,
  REPORT_TABS,
};
