function TaskReportAreaRate({
  subjectCd,
  reportSendStudentNm,
  reportTaskAreas,
  isBeforeRegDate,
}) {
  return (
    <div className="reportSection rptSectionByArea">
      <div className="sectionTitleBox">
        <p className="sectionTitle">영역별 성취율</p>
      </div>
      <div className="reportDivision">
        <div className="roundBox typeChart">
          <ul className="chartLegend">
            <li>
              <span className="legendColor typeStudent" />
              <span className="legendTxt">{reportSendStudentNm} 학생</span>
            </li>
            {isBeforeRegDate && (
              <li>
                <span className="legendColor typeAverage" />
                <span className="legendTxt">평균 정답률</span>
              </li>
            )}
          </ul>

          <div className="barChartHorizontal typeAchievementByArea">
            <div className="barCharBox">
              <ul className="xAxisGroup" aria-hidden="true">
                <li>
                  <span className="pointNumber zeroPoint">0</span>
                  <span className="pointNumber">20%</span>
                </li>
                <li>
                  <span className="pointNumber">40%</span>
                </li>
                <li>
                  <span className="pointNumber">60%</span>
                </li>
                <li>
                  <span className="pointNumber">80%</span>
                </li>
                <li>
                  <span className="pointNumber">100%</span>
                </li>
              </ul>

              <ul className="yAxisGroup">
                {reportTaskAreas
                  .filter((item) => item.totalCnt > 0)
                  .map(
                    ({ areaNm, avgCorrectAnswerRate, correctAnswerRate }) => (
                      <li key={`${areaNm}_axis`}>
                        <div className="yAxisValue">
                          <p>
                            {areaNm === '문제해결력' ? (
                              <>
                                문제
                                <br />
                                해결력
                              </>
                            ) : (
                              areaNm
                            )}
                          </p>
                        </div>
                        <div className="yAxisGraph">
                          <div className="barGraph">
                            <span
                              className="per"
                              style={{ width: `${correctAnswerRate}%` }}
                            >
                              <em>{correctAnswerRate}%</em>
                            </span>
                          </div>
                          {isBeforeRegDate && (
                            <div className="barGraph tyAverage">
                              <span
                                className="per"
                                style={{ width: `${avgCorrectAnswerRate}%` }}
                              >
                                <em>{avgCorrectAnswerRate}%</em>
                              </span>
                            </div>
                          )}
                        </div>
                      </li>
                    ),
                  )}
              </ul>
            </div>
          </div>
        </div>
        {/* 수학인경우에만 노출 */}
        {subjectCd === 'CD10006' && (
          <div className="roundBox typeDataTable">
            <div className="tableWrap">
              <table>
                <colgroup>
                  <col style={{ width: 'auto' }} />
                  <col style={{ width: '19%' }} span="4" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">구분</th>
                    {reportTaskAreas.map(({ areaNm }) => (
                      <th key={`${areaNm}_col`} scope="col">
                        {areaNm}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="borderN">
                  <tr className="rowAchievementRate">
                    <th scope="row">성취율</th>
                    {reportTaskAreas.map(({ correctAnswerRate }, index) => (
                      <td key={`correctAnswerRate_${index}`}>
                        {correctAnswerRate}%
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th scope="row">정답 문항수</th>
                    {reportTaskAreas.map(({ correctAnswerCnt }, index) => (
                      <td key={`correctAnswerCnt_${index}`}>
                        {correctAnswerCnt}
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th scope="row">전체 문항수</th>
                    {reportTaskAreas.map(({ totalCnt }, index) => (
                      <td key={`totalCnt_${index}`}>{totalCnt}</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TaskReportAreaRate;
