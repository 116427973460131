import { accountSelector } from '@store/slice/accountSlice';
import { feedSelector, first } from '@store/slice/homeSlice';
import { useDispatch, useSelector } from 'react-redux';

function FoldList() {
  const dispatch = useDispatch();
  const { memberNo } = useSelector(accountSelector);
  const { pageNo } = useSelector(feedSelector);

  const handleClick = () => {
    dispatch(first({ memberId: memberNo }));
    window.scrollTo({ top: 0, left: 0 });
  };
  return (
    <>
      {pageNo > 1 && (
        <div className="listController">
          <button type="button" className="btnFold" onClick={handleClick}>
            <span>접기</span>
          </button>
        </div>
      )}
    </>
  );
}

export default FoldList;
