/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

import { classSelector } from '@store/slice/accountSlice';
import { format } from '@helper/timeHelper';

function Notice({
  assistantYn,
  assistantId,
  teacherId,
  regName,
  commentCnt,
  institutionName,
  notificationCommentYn = 'Y',
  notificationName,
  notiSummary,
  regDate,
  modDate,
}) {
  const isNewNotice = useMemo(() => {
    return dayjs().diff(dayjs(regDate), 'hour') < 24;
  }, [regDate]);
  return (
    <>
      <div className="rowRecipient">
        <span className="textName">{regName || '-'}</span>

        {assistantYn === 'Y' && assistantId !== teacherId && (
          <span className="tagAssistant">조교</span>
        )}
        <span className="textFrom">
          <em>{institutionName}</em>
        </span>
      </div>
      <div className="rowTitle">
        <p>{notificationName}</p>
        {isNewNotice && (
          <span className="icoNew">
            <em>New</em>
          </span>
        )}
      </div>
      <div className="rowContent">
        <p>{notiSummary}</p>
      </div>
      <div className="rowOther">
        {notificationCommentYn === 'Y' && (
          <span className="textReply">{commentCnt}</span>
        )}
        <span className="textDate">
          {isEmpty(modDate) ? format(regDate) : `${format(modDate)} (수정)`}
        </span>
      </div>
    </>
  );
}

function NoticeLink({ notificationId, ...rest }) {
  const navigate = useNavigate();
  const goDetail = () => {
    navigate(`/academy/notice/${notificationId}`);
  };

  return (
    <li>
      <a className="aLink" onClick={goDetail}>
        <Notice {...rest} />
      </a>
    </li>
  );
}

function NoticeContent({ ...rest }) {
  const classes = useSelector(classSelector);
  const targetClass = classes.find((item) => item.classId === rest.classId);

  return (
    <div className="boardTop typeAcademy">
      <Notice {...rest} {...targetClass} />
    </div>
  );
}

export default Object.assign(Notice, {
  Link: NoticeLink,
  Content: NoticeContent,
});
