import { useCallback } from 'react';

import { useOverlayContext } from '@context/OverlayContext';
import { Button } from '@components/ui';

import Modal from './base/Modal';

function ModalFailedVerify({ ...rest }) {
  const { closeModal } = useOverlayContext();

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <Modal {...rest}>
      <Modal.Header hasClose>안내</Modal.Header>
      <Modal.Body>
        <p className="textSub">
          이름, 전화번호 또는 비밀번호를 5회 이상 잘못 입력하여
          <br />
          자녀의 CATS HOME 인증이 완료되지 않았습니다.
        </p>
        <p className="textSub">
          인증관련 문의 사항은 고객센터(1644-1244)로
          <br />
          연락주시면 학부모/자녀 회원가입에 대해 친절히 도와드리겠습니다.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button.Primary label="확인" onClick={handleClose} />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalFailedVerify;
