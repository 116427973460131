import {
  Academy,
  AcademyAttendance,
  AcademyConsultation,
  AcademyNotice,
  AcademyNoticeDeail,
  AcademyTest,
  AcademyTestDetail,
} from '@pages';
import withPrivate from '@hoc/withPrivate';
import { HEADER_TYPE } from '@constants/layout';

const academyRouteConfig = [
  {
    path: 'academy',
    element: Academy,
    option: {
      name: '학원',
      headerType: HEADER_TYPE.BACK,
      hasTabbar: true,
    },
    children: [
      {
        index: true,
        element: AcademyNotice,
      },
      // {
      //   path: 'attendance',
      //   element: AcademyAttendance,
      // },
      {
        path: 'notice',
        element: AcademyNotice,
      },
      {
        path: 'consultation',
        element: AcademyConsultation,
      },
      // {
      //   path: 'test',
      //   element: AcademyTest,
      // },
      {
        path: 'notice/:id',
        element: AcademyNoticeDeail,
        option: {
          name: '알림장 상세',
          headerType: HEADER_TYPE.BACK,
        },
      },
      {
        path: 'test/:id',
        element: AcademyTestDetail,
        option: {
          name: '입회테스트 결과',
          headerType: HEADER_TYPE.BACK,
        },
      },
    ].map((item) => {
      const Component = withPrivate(item.element);
      return {
        ...item,
        element: <Component />,
      };
    }),
  },
];

export default academyRouteConfig;
