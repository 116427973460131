import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMount } from 'ahooks';

import { Button } from '@components/ui';
import { accountSelector } from '@store/slice/accountSlice';
import { PAYMENT_TYPE } from '@constants/tab';
import orderService from '@service/OrderService';
import { useCallback, useState } from 'react';
import { toCommaNumber } from '@helper/commonHelper';
import useBrandPay from '@hooks/useBrandPay';

// 결제내역 > 교재 > 교환비용결제
function OrderExchangeCostPage() {
  const { memberNo } = useSelector(accountSelector);
  const { id } = useParams();

  const { requestExchangeCost } = useBrandPay({ memberNo });

  const [exchangeCost, setExchangeCost] = useState({});

  const handlePayment = () => {
    requestExchangeCost(exchangeCost);
  };
  useMount(() => {
    (async () => {
      const result = await orderService.getHistoryDetail({
        type: PAYMENT_TYPE.BOOK,
        orderNumber: id,
        parentNo: memberNo,
      });

      setExchangeCost(
        result.orderGoodsDtoList?.[0].orderClaimPaymentDtoList?.[0],
      );
    })();
  });

  return (
    <div className="contentBox boxPaying">
      <div className="definitionBox">
        <p className="boxTitle">교환비용</p>
        <div className="grayOrderItem typeExchange">
          {exchangeCost?.changeReturnMessage}
        </div>
      </div>

      <div className="definitionBox">
        <p className="boxTitle">결제금액</p>
        <div className="boxPriceRow">
          <b>{toCommaNumber(exchangeCost?.amount)}</b>원
        </div>
      </div>
      <div className="definitionBox typeFinalCta">
        <Button.Wrapper>
          <Button.Normal
            onClick={handlePayment}
            large
            className="btnSecondary"
            label={`${toCommaNumber(exchangeCost?.amount || 0)}원 결제`}
          />
        </Button.Wrapper>
      </div>
    </div>
  );
}

export default OrderExchangeCostPage;
