import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import FormAddress from '@components/form/FormAddress';
import { useOverlayContext } from '@context/OverlayContext';
import PopupZipCode from '@components/overlay/popup/PopupZipCode';

function DefinitionBoxAddress({
  address,
  detailAddress,
  zipCode,
  onSetAddress,
}) {
  const { showPopup } = useOverlayContext();

  const methods = useForm({
    defaultValues: {
      addr: address || '',
      addrDetail: detailAddress || '',
      post: zipCode || '',
    },
  });
  const { setValue, trigger, watch } = methods;

  const [addr, addrDetail, post] = watch(['addr', 'addrDetail', 'post']);

  const isEmptyAddress = useMemo(() => !addr || !post, [addr, post]);

  const showPopupZipCode = () => {
    showPopup(PopupZipCode, {
      oncomplete: ({ address: add, zonecode }) => {
        setValue('addr', add);
        setValue('post', zonecode);
        trigger('addr');
        trigger('post');
      },
    });
  };

  useEffect(() => {
    onSetAddress({
      address: addr,
      detailAddress: addrDetail,
      zipCode: post,
    });
  }, [watch, onSetAddress, addr, addrDetail, post]);

  return (
    <FormProvider {...methods}>
      <form className="definitionBox typeAddress">
        <p className="boxTitle">배송지</p>

        {!isEmptyAddress ? (
          <>
            <div className="guidanceBox">
              <p>
                배송지를 변경하시려면 주소 검색버튼을 클릭하여
                <br />
                주소를 다시 입력한 후 배송지입력 버튼을 클릭해주세요.
              </p>
            </div>
            <FormAddress isRequired className="addressBox" />
          </>
        ) : (
          <dl>
            <dt>
              <div className="guidanceBox">
                <p>배송받을 곳의 주소를 입력해주세요.</p>
              </div>
            </dt>

            <dd>
              <div className="guidanceBox">
                <button
                  type="button"
                  className="btnOutline small"
                  onClick={showPopupZipCode}
                >
                  <span>배송지 입력</span>
                </button>
              </div>
            </dd>
          </dl>
        )}
      </form>
    </FormProvider>
  );
}

export default DefinitionBoxAddress;
