import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';

import withMathJax from '@hoc/withMathJax';
import reportService from '@service/ReportService';
import dayjs from 'dayjs';
import TaskReportSummary from './components/task/TaskReportSummary';
import TaskReportSummaryDetail from './components/task/TaskReportSummaryDetail';
import TaskReportDifficultyRate from './components/task/TaskReportDifficultyRate';
import TaskReportScorecard from './components/task/TaskReportScorecard';
import TaskReportAreaRate from './components/task/TaskReportAreaRate';

function LearningReportTaskPage() {
  const { id: reportSendId } = useParams();

  const [report, setReport] = useState();

  useMount(() => {
    (async () => {
      try {
        const result = await reportService.getTaskReport({
          reportSendId,
        });

        // 마감일 이전인지 확인
        const isBeforeRegDate = dayjs(
          result?.reportTaskSummary?.dueDate,
        ).isBefore(result?.reportGenerateDate);

        setReport({ ...result, isBeforeRegDate });
      } catch (error) {
        console.warn(error);
      }
    })();
  });
  return (
    <div className="contentBox boxReportDetails">
      <div className="wrapLearningReport wrapAssignmentsReport">
        {!isEmpty(report) && (
          <>
            <TaskReportSummary {...report} />
            <div className="reportContentWrap reportTypeAssignment">
              <TaskReportSummaryDetail {...report} />
              <TaskReportDifficultyRate {...report} />
              {!['CD10005', 'CD10007', 'CD11458'].includes(report?.subjectCd) &&
                report.reportTaskAreas?.length > 0 && (
                  <TaskReportAreaRate {...report} />
                )}
              <TaskReportScorecard {...report} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default withMathJax(LearningReportTaskPage);
