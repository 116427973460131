import { useCallback } from 'react';
import { Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';

import { AcademyTab } from '@components/tabs';

function AcademyPage() {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [, , subPath] = pathname.split('/');

  const isTestDetailPage = useMatch('/academy/test/:id');
  const isNoticeDetailPage = useMatch('/academy/notice/:id');

  const handleSelect = useCallback(
    (item) => {
      navigate(`/academy/${item}`);
    },
    [navigate],
  );

  return (
    <>
      {!isTestDetailPage && !isNoticeDetailPage && (
        <>
          <AcademyTab
            onSelect={handleSelect}
            setDefault={false}
            value={subPath}
          />
        </>
      )}
      <Outlet />
    </>
  );
}

export default AcademyPage;
