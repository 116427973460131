import { useState } from 'react';
import { useMount } from 'ahooks';
import classnames from 'classnames';

function ModalContainer({
  children,
  className,
  bottomSheet = false,
  dialog = false,
  isBasic = false,
  isTop = false,
}) {
  const [isOpen, setOpen] = useState(false);

  useMount(() => {
    // 애니메이션 적용
    setTimeout(() => setOpen(true), 100);
    return () => setOpen(false);
  }, []);

  return (
    <div
      className={classnames(
        'panelDialog',
        isOpen && 'isOpen',
        bottomSheet && 'popSheetBottom',
        !isBasic && dialog && 'popAlert',
        isBasic && 'popBasic',
      )}
      style={{ zIndex: isTop ? '9999' : '1000' }}
    >
      <div className="dimmed" />

      <div className="popOuter">
        <div className="popInner">
          <div className={classnames('layerBox', className && className)}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalContainer;
