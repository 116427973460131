import api from '@utils/Api';

class ReportService {
  constructor(client) {
    this.httpClient = client;
  }

  getReportHistory(
    { parentNo, studentIds, pageNo, pageSize = 20 },
    options = {},
  ) {
    return this.httpClient.get(
      `/v1/report/${parentNo}`,
      {
        pageNo,
        pageSize,
        studentIdString: studentIds.join('|'),
      },
      options,
    );
  }

  getAcademyLearningHistory(
    { reportSendId, subject, pageNo, pageSize = 5 },
    options = {},
  ) {
    return this.httpClient.get(
      `/v1/report/learning-history/${reportSendId}/${subject}`,
      {
        pageNo,
        pageSize,
      },
      options,
    );
  }
  getAcademyReport({ reportSendId }, options = {}) {
    return this.httpClient.get(
      `/v1/report/published-report`,
      {
        reportSendId,
      },
      options,
    );
  }

  getOnlineReportHistory({ studentIds, pageNo, pageSize = 20 }, options = {}) {
    return this.httpClient.get(
      `/v1/report/cats-home`,
      {
        pageNo,
        pageSize,
        studentIdList: studentIds,
      },
      options,
    );
  }

  getOnlineReport({ studentNo, yearMonth, pageSize = 5 }, options = {}) {
    return this.httpClient.get(
      `/v1/report/cats-home/${studentNo}|${yearMonth}`,
      {
        pageSize,
      },
      options,
    );
  }

  getOnlineHomeworkHistory(
    { studentNo, teacherId, yearMonth, classId, pageNo, pageSize = 5 },
    options = {},
  ) {
    return this.httpClient.get(
      `/v1/report/cats-home/homework-list/${studentNo}`,
      {
        teacherId,
        yearMonth,
        classId,
        pageNo,
        pageSize,
      },
      options,
    );
  }

  getEnglishReportHistory(
    { studentNo, yearMonth, pageNo, pageSize = 5 },
    options = {},
  ) {
    return this.httpClient.get(
      `/v1/report/cats-home/english-library-list/${studentNo}`,
      {
        yearMonth,
        pageNo,
        pageSize,
      },
      options,
    );
  }

  getLevelReportHistory(
    { parentNo, studentIds, reportTypeCd = 'CD13932', pageNo, pageSize = 20 },
    options = {},
  ) {
    return this.httpClient.get(
      `/v1/report/${parentNo}`,
      {
        pageNo,
        pageSize,
        reportTypeCd,
        studentIdString: studentIds.join('|'),
      },
      options,
    );
  }

  getLevelReport({ taskId, studentNo }, options = {}) {
    return this.httpClient.get(
      `/v1/level-test/${taskId}/${studentNo}`,
      {},
      options,
    );
  }

  getMathReportHistory(
    { parentNo, studentIds, reportTypeCd = 'CD14021', pageNo, pageSize = 20 },
    options = {},
  ) {
    return this.httpClient.get(
      `/v1/report/${parentNo}`,
      {
        pageNo,
        pageSize,
        reportTypeCd,
        studentIdString: studentIds.join('|'),
      },
      options,
    );
  }
  getTaskReportHistory(
    { parentNo, studentIds, reportTypeCd = 'CD15102', pageNo, pageSize = 20 },
    options = {},
  ) {
    return this.httpClient.get(
      `/v1/report/${parentNo}`,
      {
        pageNo,
        pageSize,
        reportTypeCd,
        studentIdString: studentIds.join('|'),
      },
      options,
    );
  }

  getMathReport({ reportSendId }, options = {}) {
    return this.httpClient.get(
      `/v1/report/level-diagnosis/${reportSendId}`,
      {},
      options,
    );
  }

  getTaskReport({ reportSendId }, options = {}) {
    return this.httpClient.get(
      `/v1/report/task-summary/${reportSendId}`,
      {},
      options,
    );
  }
}

const reportService = new ReportService(api);
export default reportService;
