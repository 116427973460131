import { useLocation } from 'react-router-dom';

import routingConfig from '@config/rootRouteConfig';

// FIXME: 라우팅 키값 나중에 리펙토링 필요함
const PATH_KEY = ['/:id', '/:subjectCd', '/:type', '/:date', '/:memberNo'];

function useLayout() {
  const [{ children: routeConfig }] = routingConfig();
  const { pathname } = useLocation();

  const [, path, subPath] = pathname.split('/');

  let targetRoute = routeConfig.find((item) => {
    if (item.path === `${path}/${subPath}`) {
      return item;
    }
    const replacePath = PATH_KEY.reduce(
      (pre, current) => pre.replace(current, ''),
      item.path,
    );
    return item.path === path || replacePath === path;
  });

  if (targetRoute?.children && subPath) {
    const subRoute = targetRoute.children.find((item) =>
      item.path?.includes(subPath),
    );
    targetRoute = subRoute.option ? subRoute : targetRoute;
  }

  return targetRoute?.option;
}

export default useLayout;
