import api from '@utils/Api';

class CodeService {
  constructor(client) {
    this.httpClient = client;
  }

  schoolCode(searchSchoolName, options = {}) {
    return this.httpClient.get(
      `/auth/school-list`,
      { searchSchoolName },
      options,
    );
  }
}

const codeService = new CodeService(api);
export default codeService;
