import { useNavigate } from 'react-router-dom';

import { Button } from '@components/ui';

import Modal from './base/Modal';

function ModalRegisteredUser({ complete, ...rest }) {
  const navigate = useNavigate();

  const goLogin = () => {
    complete?.();
    setTimeout(() => {
      navigate('/login');
    });
  };

  return (
    <Modal {...rest}>
      <Modal.Header hasClose>가입된 계정 안내</Modal.Header>
      <Modal.Body>
        <p className="textSub">
          입력하신 휴대폰 번호로 이미 생성된 계정이 존재합니다.
        </p>
        <p className="textSub">입력하신 정보로 로그인 해주세요.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button.Primary label="로그인 하기" onClick={goLogin} />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalRegisteredUser;
