import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { CatsReport, SERVICE_TYPE } from '@danbiedu-cats/common';
import Pagination from '@components/ui/Pagination';
import reportService from '@service/ReportService';
import { studentSelector } from '@store/slice/accountSlice';

function LearningReportOnlinePage() {
  const { id: studentNo, date: yearMonth } = useParams();

  const { memberName } = useSelector(studentSelector(studentNo));

  const [report, setReport] = useState({});
  const [homework, setHomework] = useState({});
  const [english, setEnglish] = useState({});

  const handleHomeworkPage = ({ page, classId, teacherId }) => {
    (async () => {
      try {
        const result = await reportService.getOnlineHomeworkHistory({
          studentNo,
          teacherId,
          classId,
          yearMonth,
          pageNo: page,
        });

        setHomework((pre) => ({
          ...pre,
          [classId]: {
            ...pre[classId],
            homeworkPage: result,
          },
        }));
      } catch (error) {
        console.warn(error);
      }
    })();
  };

  const handleEnglishPage = ({ page }) => {
    (async () => {
      try {
        const result = await reportService.getEnglishReportHistory({
          studentNo,
          yearMonth,
          pageNo: page,
        });

        setEnglish(result);
      } catch (error) {
        console.warn(error);
      }
    })();
  };

  const homeworkPagination = ({ pageNum, pages, ...item }) => (
    <Pagination
      pageNo={pageNum}
      pages={pages}
      onPage={(page) => handleHomeworkPage({ ...item, page })}
    />
  );

  const englishLibraryPagination = ({ pageNum, pages, ...item }) => {
    return (
      <Pagination
        pageNo={pageNum}
        pages={pages}
        onPage={(page) => handleEnglishPage({ ...item, page })}
      />
    );
  };

  useMount(() => {
    (async () => {
      try {
        const {
          homework: { monthlyHomeworkList },
          englishLibrary,
          ...rest
        } = await reportService.getOnlineReport({
          studentNo,
          yearMonth,
        });
        setReport(rest);
        setHomework(
          monthlyHomeworkList.reduce(
            (pre, current) => ({
              ...pre,
              [current.classId]: current,
            }),
            {},
          ),
        );
        setEnglish(englishLibrary);
      } catch (error) {
        console.warn(error);
      }
    })();
  });

  return (
    <div className="contentBox boxReportDetails">
      <div className="wrapLearningReport">
        {!isEmpty(report) && (
          <CatsReport
            {...report}
            homework={homework}
            englishLibrary={english}
            homeworkPagination={homeworkPagination}
            englishLibraryPagination={englishLibraryPagination}
            serviceType={SERVICE_TYPE.PARENT}
            studentNm={memberName}
          />
        )}
      </div>
    </div>
  );
}

export default LearningReportOnlinePage;
