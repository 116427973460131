/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PaymentTab } from '@components/tabs';
import { PaymentMapper } from '@components/payment';
import {
  all,
  next,
  paymentWaitingSelector,
  setType,
} from '@store/slice/paymentWaitingSlice';
import { Button } from '@components/ui';
import { accountSelector } from '@store/slice/accountSlice';

// 결재대기 목록
function PaymentWaitingPage() {
  const dispatch = useDispatch();

  const { waiting, hasNext, isFetch, type } = useSelector(
    paymentWaitingSelector,
  );
  const { memberNo } = useSelector(accountSelector);

  const handleSelect = useCallback(
    (value) => {
      dispatch(setType(value));
      dispatch(
        all({
          type: value,
          parentNo: memberNo,
        }),
      );
    },
    [dispatch, memberNo],
  );

  // 처음 한번만 조회
  useEffect(() => {
    if (waiting.length === 0) {
      handleSelect(type);
    }
  }, [handleSelect, type, waiting.length]);

  return (
    <>
      <PaymentTab
        isFixed
        onSelect={handleSelect}
        setDefault={false}
        defaultType={type}
      />
      <div className="contentBox boxListType">
        {isFetch && waiting.length === 0 && (
          <div className="boxFlexGrow typeMiddle">
            <div className="noList">
              <div className="icoNoList">
                <p>결제할 건이 없습니다.</p>
              </div>
              {/* <div className="btnBox">
                <a className="btnLink secondary" onClick={goHistory}>
                  <span>결제한 내역 보기</span>
                </a>
              </div> */}
            </div>
          </div>
        )}
        {/* 데이터가 있는 경우 */}
        {waiting.length > 0 && (
          <>
            <div className="listDivision topLine">
              <ul className="listItems">
                {waiting.map((item, index) => {
                  const Component = PaymentMapper.get(
                    item.goodsTypeCd,
                    'waiting',
                  );
                  return Component ? (
                    <Component key={`${item.orderNumber}_${index}`} {...item} />
                  ) : (
                    ''
                  );
                })}
              </ul>
            </div>
            {hasNext && (
              <Button.Wrapper>
                <Button.Normal
                  large
                  className="btnMore"
                  label="더보기"
                  onClick={() => dispatch(next())}
                />
              </Button.Wrapper>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default PaymentWaitingPage;
