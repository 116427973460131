import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { FormName, FormMobileVerify, FormPassword } from '@components/form';
import { accountSelector, setParents } from '@store/slice/accountSlice';
import { USER_TYPE, VERIFY_STATUS } from '@constants/common';
import { FieldSet, SignUpSteps } from '@components/common';
import { TermsOfService } from '@components/terms';
import { useAppContext } from '@context/AppContext';
import useLeaveConfirm from '@hooks/useLeaveConfirm';
import { Button } from '@components/ui';
import FormAddress from '@components/form/FormAddress';

// 로그인 > 회원가입
function ParentPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isWinkDevice } = useAppContext();
  const [searchParams] = useSearchParams();

  const parents = useSelector(accountSelector);
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: { verifyStatus: VERIFY_STATUS.READY, ...parents },
  });

  const {
    formState: { isDirty, isValid },
    reset,
    setValue,
    handleSubmit,
  } = methods;

  useLeaveConfirm(isDirty);

  const submit = (formValue) => {
    if (!isValid) {
      return;
    }

    dispatch(setParents(formValue));

    // isDirty reset
    reset(formValue);

    setTimeout(() => {
      navigate({
        pathname: '/child-add',
        search: `?${createSearchParams(
          [...searchParams].reduce(
            (pre, current) => ({ ...pre, [current[0]]: current[1] }),
            {},
          ),
        )}`,
      });
      // navigate('/child-add');
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        className="contentBox joinMembership"
        onSubmit={handleSubmit(submit)}
      >
        <FieldSet.Wrapper>
          <SignUpSteps />

          <FieldSet.List>
            <FieldSet.Item>
              {/* 이름이 변경될때 다시 검증해야 함 */}
              <FormName
                onChange={() => {
                  setValue('verifyStatus', VERIFY_STATUS.READY);
                  setValue('authToken');
                  setValue('requestId');
                }}
              />
            </FieldSet.Item>

            <FieldSet.Item>
              <FormMobileVerify noWrap />
            </FieldSet.Item>

            <FieldSet.Item>
              <FormPassword />
            </FieldSet.Item>

            <FieldSet.Item>
              <FormPassword
                name="confirmPassword"
                label="비밀번호 확인"
                placeholder="비밀번호 확인"
                confirm
              />
            </FieldSet.Item>

            <FieldSet.Item>
              <FormAddress label="주소" className="addressBox" />
            </FieldSet.Item>

            <FieldSet.Item>
              <TermsOfService userType={USER_TYPE.PARENT} />
            </FieldSet.Item>
          </FieldSet.List>
        </FieldSet.Wrapper>

        <FieldSet.ButtonBox contact={!isWinkDevice}>
          <Button.Primary
            large
            type="submit"
            label="다음"
            disabled={!isValid}
          />
        </FieldSet.ButtonBox>
      </form>
    </FormProvider>
  );
}

export default ParentPage;
