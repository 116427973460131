import { Button } from '@components/ui';
import { useNavigate } from 'react-router-dom';

import Modal from './base/Modal';

function ModalIntegratedUser({ complete, ...rest }) {
  const navigate = useNavigate();

  const goLogin = () => {
    complete?.();
    setTimeout(() => {
      navigate('/login');
    });
  };

  return (
    <Modal {...rest}>
      <Modal.Header hasClose>통합계정 로그인 안내</Modal.Header>
      <Modal.Body>
        <p className="textSub">
          입력하신 휴대폰 번호는
          <br /> 이미 <b className="colorGreen">CATS선생님</b> 에 가입되어 있는
          정보입니다.
        </p>
        <p className="textSub">
          기존에 사용하시던 계정 하나로 <br />
          회원가입없이 간편하게 로그인해보세요.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button.Primary label="통합계정 로그인 하기" onClick={goLogin} />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalIntegratedUser;
