/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getNotice, noticeSelector } from '@store/slice/noticeSlice';
import { accountSelector } from '@store/slice/accountSlice';
import useScroll from '@hooks/useScroll';

import Notice from './components/Notice';

function AcademyNoticePage() {
  const dispatch = useDispatch();

  const noticeRef = useRef();
  const { isBottom, reset } = useScroll({ ref: noticeRef });
  const { memberNo } = useSelector(accountSelector);
  const { hasNext, isFetch, notice } = useSelector(noticeSelector);

  useEffect(() => {
    if (notice.length !== 0 && hasNext && isBottom) {
      reset();
      dispatch(getNotice({ memberNo }));
    }
  }, [dispatch, hasNext, isBottom, memberNo, notice.length, reset]);

  useEffect(() => {
    if (notice.length === 0 && memberNo) {
      dispatch(getNotice({ memberNo, pageNo: 1 }));
    }
  }, [dispatch, memberNo, notice.length]);

  return (
    <div className="contentBox boxNotice">
      {isFetch && (
        <ul className="listBoard" ref={noticeRef}>
          {notice?.map((item, index) => (
            <Notice.Link key={`noticeLink_${index}`} {...item} />
          ))}
        </ul>
      )}
      {isFetch && notice.length === 0 && (
        <div className="boxFlexGrow typeMiddle">
          <div className="noList">
            <div className="icoNoList">
              <p>아직 알림장을 작성하지 않았습니다.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AcademyNoticePage;
