import { MathJax } from 'better-react-mathjax';

function MathJaxText({ text = '' }) {
  return (
    <>
      {text?.includes('@#') && <MathJax>{text}</MathJax>}
      {!text?.includes('@#') && text} {!text && <div>&nbsp;</div>}
    </>
  );
}

export default MathJaxText;
