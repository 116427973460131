/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from 'react';
import classnames from 'classnames';

import { Button } from '@components/ui';
import { LEARNING_TYPE } from '@constants/tab';

import LearningStatusAcademyHomework from './LearningStatusAcademyHomework';
import LearningStatusOnlineHomework from './LearningStatusOnlineHomework';

function LearningStatusPeriod({
  title,
  period,
  learning,
  isToggle,
  onNext,
  type,
  hasNext = false,
}) {
  const [toggle, setToggle] = useState(false);

  const handleNext = () => {
    onNext?.();
  };

  return (
    <div
      className={classnames(
        'listDivision',
        !isToggle && learning.length === 0 && 'typeNoList',
        isToggle && 'typeToggleBox',
        toggle && 'isOpen',
      )}
    >
      {period && (
        <div className="listTopPeriod">
          <strong className="titleDot">{title}</strong>
          <span className="textPeriod">{period}</span>
        </div>
      )}
      {isToggle && learning.length > 0 && (
        <button
          type="button"
          className="listTopPeriod typeBtn"
          onClick={() => setToggle(() => !toggle)}
        >
          <strong className="titleDot">{title}</strong>
        </button>
      )}

      {!isToggle && (
        <ul
          className={classnames(
            'listCards',
            type === LEARNING_TYPE.ONLINE && 'typeOnlineLearning',
          )}
        >
          {learning.length > 0 &&
            learning.map((item, index) => {
              const Component =
                type === LEARNING_TYPE.ACADEMY
                  ? LearningStatusAcademyHomework
                  : LearningStatusOnlineHomework;
              return (
                <Component key={`learning_card_${title}_${index}`} {...item} />
              );
            })}
          {learning.length === 0 && (
            <ul className="listCards">
              <li>
                <div className="noList">
                  <div className="icoNoList">
                    <p>진행중인 학습이 없습니다.</p>
                  </div>
                </div>
              </li>
            </ul>
          )}
        </ul>
      )}
      {isToggle && learning.length > 0 && (
        <div className="toggleWrapper">
          <ul
            className={classnames(
              'listCards',
              type === LEARNING_TYPE.ONLINE && 'typeOnlineLearning',
            )}
          >
            {learning.map((item, index) => {
              const Component =
                type === LEARNING_TYPE.ACADEMY
                  ? LearningStatusAcademyHomework
                  : LearningStatusOnlineHomework;
              return (
                <Component key={`learning_card_${title}_${index}`} {...item} />
              );
            })}
          </ul>
          {hasNext && (
            <Button.Wrapper>
              <Button.Normal
                className="btnMore"
                onClick={handleNext}
                label="더보기"
              />
            </Button.Wrapper>
          )}
        </div>
      )}
    </div>
  );
}

export default LearningStatusPeriod;
