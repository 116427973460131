import { namePatten } from '@constants/validate';

import FormInput from './base/FormInput';

function FormName({ name = 'memberName', noForeigner = false, ...rest }) {
  return (
    <FormInput
      name={name}
      label="이름"
      placeholder="한글, 영어만 입력해주세요."
      style={{ textTransform: 'uppercase' }}
      maxLength="21"
      option={{
        setValueAs: (value) => value.toUpperCase(),
        required: '이름을 정확하게 입력해주세요.',
        validate: (value) => {
          return namePatten.test(value) || '이름을 정확하게 입력해주세요.';
        },
      }}
      {...rest}
    />
  );
}

export default FormName;
