import { LearningReport, LearningStatus } from '@pages';
import withPrivate from '@hoc/withPrivate';
import { HEADER_TYPE } from '@constants/layout';

const learningRouteConfig = [
  {
    path: 'learning-status',
    element: LearningStatus,
    option: {
      name: '학습현황',
      headerType: HEADER_TYPE.BACK,
      hasTabbar: true,
    },
  },
];

export default learningRouteConfig;
