import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';
import { setMessage } from '@store/slice/orderSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Modal from './base/Modal';

function ModalOrderOnlineReject({
  gradeCd,
  subjectCd,
  rejectMessage,
  ...rest
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { closeModal } = useOverlayContext();

  const handleClose = () => {
    dispatch(setMessage(''));
    navigate(-1);
    closeModal();
  };
  const content = useMemo(() => {
    if (
      rejectMessage.includes('전과목 구독중') ||
      rejectMessage.includes('동일과목 구독중')
    ) {
      return `이미 ${
        subjectCd !== 'ALL' ? '학원 상품이 포함된' : ''
      } CATS HOME 상품을 구독하고 있는 학생입니다.`;
    }

    if (
      // 역사코드
      subjectCd === 'CD11458' &&
      rejectMessage.includes('상품정보를 찾을 수 없습니다')
    ) {
      return '역사 과목은 중등만 이용할 수 있습니다.';
    }

    if (rejectMessage.includes('사용가능한 쿠폰이 없습니다')) {
      return '사용 가능한 쿠폰이 아닙니다.';
    }

    return rejectMessage;
  }, [rejectMessage, subjectCd]);

  return (
    <Modal {...rest}>
      <Modal.Header hasClose onClose={handleClose}>
        알림
      </Modal.Header>
      <Modal.Body className="popContent typeSimpleMessage">
        <div style={{ textAlign: 'center' }}>
          <span>{content}</span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button.Primary label="확인" onClick={handleClose} />
      </Modal.Footer>
    </Modal>
  );
}

export default ModalOrderOnlineReject;
