import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';
import { useMount } from 'ahooks';
import Popup from './base/Popup';

function PopupTermsDetail({ id, title, content, ...rest }) {
  const { closePopup } = useOverlayContext();
  const handleClose = () => {
    closePopup(id);
  };

  useMount(() => {
    window.scrollTo({ top: 0, left: 0 });
  });
  return (
    <Popup {...rest}>
      <Popup.Header id={id} hasClose>
        {title}
      </Popup.Header>
      <Popup.Body containerClassName="h100" className="boxTermsContent">
        <div className="boxFlexGrow">
          <div className="termsWrapper">
            <div className="sectionBox boxTermsAndConditions">
              <div className="termsHeader">
                <h1 className="title">이용약관</h1>
              </div>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </div>
        <Button.Wrapper flexNone>
          <Button.Primary large label="닫기" onClick={handleClose} />
        </Button.Wrapper>
      </Popup.Body>
    </Popup>
  );
}

export default PopupTermsDetail;
