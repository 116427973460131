import { RouteIndex } from '@components/common';

import RuntimeErrorPage from '@pages/RuntimeErrorPage';
import App from '../App';
import publicRoutingConfig from './publicRoutingConfig';
import privateRouteConfig from './privateRouteConfig';

const routingConfig = () => {
  return [
    {
      path: '/',
      element: <App />,
      errorElement: <RuntimeErrorPage />,
      children: [...publicRoutingConfig, ...privateRouteConfig],
    },
    {
      index: true,
      // element: <Navigate to="/home" />,
      element: <RouteIndex url="/html/intro.html" />,
    },
  ];
};

export default routingConfig;
