import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useMatch } from 'react-router-dom';
import useLayout from '@hooks/useLayout';

import { useOverlayContext } from './OverlayContext';

const LayoutContext = createContext();

function LayoutProvider({ children }) {
  const menuLayout = useLayout();

  const { isOverlay } = useOverlayContext();

  const isHomePage = useMatch('/home');

  const isMyPage = useMatch('/my');

  const isLearningPage = useMatch('/learning-status');

  const isLevelTestReportPage = useMatch(
    '/learning-report-level/:id/:memberNo',
  );

  const [config, setConfig] = useState({
    name: '',
    headerType: false,
    hasTabbar: false,
    hasFooter: false,
  });

  const [history, setHistory] = useState([]);

  const setLayout = useCallback((layout) => setConfig(layout), []);

  const setHeaderName = useCallback(
    (name) => setConfig((pre) => ({ ...pre, name })),
    [],
  );
  const resetLayout = useCallback(() => setConfig(menuLayout), [menuLayout]);

  const popHistory = useCallback(() => {
    if (history.length > 0) {
      const [first] = history;
      setHistory(() => history.slice(1, history.length));
      return first;
    }
  }, [history]);

  const pushHistory = useCallback((item) => {
    setHistory((pre) => [...pre, item]);
  }, []);

  useEffect(() => {
    if (menuLayout) {
      setConfig(menuLayout);
    }
  }, [menuLayout]);

  useEffect(() => {
    const element = document.querySelector('#root');

    element.classList.remove('mainPage');
    element.classList.remove('myPage');
    element.classList.remove('bgGray');

    if (isHomePage) {
      element.classList.add('mainPage');
    }
    if (isMyPage) {
      element.classList.add('myPage');
    }
    if (isLearningPage) {
      element.classList.add('bgGray');
    }
  }, [isHomePage, isLearningPage, isMyPage]);

  // useEffect(() => {
  //   const element = document.querySelector('#root');

  //   element.classList.remove('scrollLock');

  //   if (isOverlay && !isLevelTestReportPage) {
  //     element.classList.add('scrollLock');
  //   }
  // }, [isLevelTestReportPage, isOverlay]);

  return (
    <LayoutContext.Provider
      value={{
        config,
        setLayout,
        setHeaderName,
        resetLayout,
        popHistory,
        pushHistory,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

function useLayoutContext() {
  return useContext(LayoutContext);
}

export { useLayoutContext, LayoutProvider };
