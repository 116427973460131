/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { LearningAcademySummary } from '@components/learning';
import { learningSelector } from '@store/slice/homeSlice';

function HomeLearningAcademy({ studentNo }) {
  const navigate = useNavigate();
  const { homework } = useSelector(learningSelector({ studentNo }));

  const goHomework = useCallback(() => {
    navigate('/learning-status');
  }, [navigate]);

  return (
    <>
      {!isEmpty(homework) && (
        <div className="groupStatus typeFromTeacher">
          <div className="roundBox">
            <a className="listTitle" onClick={goHomework}>
              <strong>학원학습/숙제</strong>
              <span className="hidden">더보기</span>
            </a>
            <ul className="learningList">
              {homework?.map((item, index) => {
                return (
                  <LearningAcademySummary
                    key={`learning_homework_${index}`}
                    {...item}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default HomeLearningAcademy;
