import { useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import { findTextWithinBrackets } from '@helper/commonHelper';

function MathTestCorrectAnswersByDifficulty({ studentNm, difficultyList }) {
  const chartOptions = useMemo(() => {
    return {
      grid: {
        containLabel: false,
        bottom: '20',
        top: '10',
        left: '40',
        right: '20',
      },
      xAxis: {
        type: 'category',
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      yAxis: {
        min: 0,
        max: 110,
        axisLabel: {
          formatter: (value) => {
            if (value === 110) {
              return '';
            }
            return `${value}%`;
          },
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
          },
        },
      },
      series: [
        {
          type: 'line',
          symbol: 'circle',
          label: {
            show: true,
            formatter: '{@[1]}%',
            fontWeight: 'bold',
            color: '#2b9bed',
          },
          itemStyle: {
            color: '#2b9bed',
          },
          data:
            difficultyList?.map((item) => [
              findTextWithinBrackets(item.difficultyNm),
              ((item.correctAnswerCnt / item.totalCnt) * 100).toFixed(0),
            ]) || [],
        },
      ],
    };
  }, [difficultyList]);
  return (
    <div className="reportSection sectionLvByDifficulty">
      <div className="sectionTitleBox">
        <p className="sectionTitle">{studentNm} 학생의 난이도별 정답률</p>
      </div>
      <div className="reportDivision">
        <ReactECharts option={chartOptions} />
      </div>
    </div>
  );
}

export default MathTestCorrectAnswersByDifficulty;
