function PasswordGuide() {
  return (
    <>
      <label className="label" htmlFor="password">
        비밀번호
      </label>
      <div className="initialPasswordGuide">
        <ul className="guidanceText">
          <li>
            <p>
              자녀계정 초기 비밀번호는 자녀 휴대폰 번호 마지막 8자리로
              설정됩니다. 휴대폰 번호를 정확히 입력해주세요.
            </p>
            <p>
              * 비밀번호는 로그인 후 반드시 변경해 주세요.
              <br />
              (캐츠홈 또는 캐츠업 &gt; 마이페이지 &gt; 내 정보 관리에서 새
              비밀번호로 변경할 수 있습니다.)
            </p>
          </li>
        </ul>
      </div>
    </>
  );
}

export default PasswordGuide;
