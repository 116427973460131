import { memo } from 'react';
import { ModalSubscribeOnline } from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';

import Payment from './base/Payment';

function PaymentOnlineSubscribe({ onSuccess, ...rest }) {
  const { showDialog } = useOverlayContext();

  const handleSelect = () => {
    showDialog(ModalSubscribeOnline, { onSuccess, payment: rest });
  };

  return (
    <Payment
      onSelect={handleSelect}
      {...rest}
      price={rest.paymentAmount}
      isVisible={false}
      isSubscribe
    />
  );
}

export default memo(PaymentOnlineSubscribe);
