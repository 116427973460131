import api from '@utils/Api';

class AlarmService {
  constructor(client) {
    this.httpClient = client;
  }

  count({ parentId }, options) {
    return this.httpClient.get(
      '/v1/home/notice/count',
      {
        parentId,
      },
      options,
    );
  }

  alarms({ parentId, pageNo = 0, pageSize = 20 }, options) {
    return this.httpClient.get(
      '/v1/home/notice/list',
      {
        parentId,
        pageNo,
        pageSize,
      },
      options,
    );
  }

  rollback({ noticeIds }, options) {
    return this.httpClient.put(
      '/v1/home/notice/cancel/list',
      { noticeIds },
      options,
    );
  }

  removeAll({ parentId }, options) {
    return this.httpClient.put('/v1/home/notice/list', { parentId }, options);
  }

  remove({ noticeId }, options) {
    return this.httpClient.put(`/v1/home/notice/${noticeId}`, {}, options);
  }

  read({ noticeId }, options) {
    return this.httpClient.put(`/v1/home/notice/read/${noticeId}`, {}, options);
  }
}

const alarmService = new AlarmService(api);
export default alarmService;
