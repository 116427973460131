import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'ahooks';

import { accountSelector } from '@store/slice/accountSlice';
import { useOverlayContext } from '@context/OverlayContext';
import {
  alarmSelector,
  clear,
  getAlarmCount,
  getAlarms,
  removeAll,
} from '@store/slice/alarmSlice';
import { Alarm, AlarmMapper } from '@components/alarm';
import useScroll from '@hooks/useScroll';

import Popup from './base/Popup';
import ModalDeleteNoticeConfirm from '../modal/ModalDeleteNoticeConfirm';

function PopupAlarms({ id, ...rest }) {
  const navigate = useNavigate();

  const alarmsRef = useRef(null);
  const dispatch = useDispatch();

  const { showDialog } = useOverlayContext();

  const { memberNo } = useSelector(accountSelector);
  const { count, alarms, pageNo, hasNext } = useSelector(alarmSelector);

  const { isBottom, reset } = useScroll({ ref: alarmsRef });

  const handleClose = useCallback(() => {
    // 일단 지우기
    dispatch(clear());
    navigate(-1);
  }, [dispatch, navigate]);

  const handleRemoveAll = useCallback(() => {
    showDialog(ModalDeleteNoticeConfirm, {
      complete: () => dispatch(removeAll()),
    });
  }, [dispatch, showDialog]);

  useEffect(() => {
    if (alarms.length !== 0 && hasNext && isBottom) {
      // dispatch(getAlarms({ memberNo: '135790' }));
      reset();
      dispatch(getAlarms({ memberNo }));
    }
  }, [dispatch, alarms.length, hasNext, isBottom, memberNo, reset]);

  useEffect(() => {
    if (alarms.length === 0 && memberNo) {
      // dispatch(getAlarms({ memberNo: '135790' }));
      // dispatch(getAlarmCount({ memberNo: '135790' }));
      dispatch(getAlarms({ memberNo, pageNum: 1 }));
      dispatch(getAlarmCount({ memberNo }));
    }
  }, [alarms.length, dispatch, memberNo]);

  return (
    <Popup {...rest}>
      <Popup.Header id={id} onClose={handleClose} hasClose>
        알림
      </Popup.Header>
      <Popup.Body className="notificationBox">
        <div className="listTop" ref={alarmsRef}>
          <p className="textReadCount">
            읽지 않은 알림<b>{count}</b>개
          </p>
          {alarms.length > 0 && (
            <button
              type="button"
              className="btnRemoveNotice"
              onClick={handleRemoveAll}
            >
              <span>알림 모두 삭제</span>
            </button>
          )}
        </div>
        {/* 알람이 있는 경우 보여주기 */}
        {alarms.length > 0 && (
          <ul className="noticeList">
            {alarms.map((item) => {
              const Component = AlarmMapper.get(item.noticeLinkCd);
              return Component ? (
                <Component key={`alarm_${item.noticeId}`} {...item} />
              ) : (
                ''
              );
            })}
          </ul>
        )}
        {/* 조회를 했는데 알람이 없으면 없다고 보여주기 */}
        {pageNo === 1 && alarms.length === 0 && (
          <div className="noList">
            <div className="icoNoList">
              <p>새로운 알림이 없습니다.</p>
            </div>
          </div>
        )}
      </Popup.Body>
    </Popup>
  );
}

export default PopupAlarms;
