import { Button } from '@components/ui';

import Modal from './base/Modal';

function ModalLeaveConfirm({ onCancel, onConfirm, ...rest }) {
  return (
    <Modal {...rest}>
      <Modal.Header>알림</Modal.Header>
      <Modal.Body>
        <p className="textSub">
          뒤로가기를 실행하면 입력하신 내용이 모두 초기화됩니다.
          <br />
          회원가입을 중단하시겠습니까?
        </p>
      </Modal.Body>
      <Modal.Footer noWrap>
        <Button.Wrapper className="typeBlock">
          <Button.Primary label="아니요" onClick={onCancel} />
          <Button.Outline label="예, 중단합니다." onClick={onConfirm} />
        </Button.Wrapper>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalLeaveConfirm;
