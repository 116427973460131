import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import homeService from '@service/HomeService';
import { changeRoute } from '@store/action/commonAction';
import { forIn, isEmpty } from 'lodash';

export const getHomeInfo = createAsyncThunk(
  'home/getHomeInfo',
  async ({ memberId, studentIds, loading }) => {
    const response = await homeService.homeInfo(
      { memberId, studentIds },
      { loading },
    );
    return response;
  },
);

export const first = createAsyncThunk('home/first', async ({ memberId }) => {
  const response = await homeService.feed({
    memberId,
    pageNo: 1,
  });
  return response;
});

export const next = createAsyncThunk(
  'home/next',
  async ({ memberId }, { getState }) => {
    const { home } = getState();

    const response = await homeService.feed({
      memberId,
      pageNo: home.pageNo + 1,
    });
    return response;
  },
);

export const read = createAsyncThunk('home/read', async ({ feedId }) => {
  await homeService.read({ feedId });
  return feedId;
});

const initialState = {
  feed: [],
  learningOnline: {},
  learningHomework: {},
  learningStudents: [],
  canFreeTrialStudent: [],
  academyInvite: [],
  waitingCount: 0,
  pageNo: 0,
  hasAlarms: false,
  hasNext: false,
  studentId: false,
  isFetch: false,
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setStudent: (state, action) => {
      state.studentId = action.payload;
    },
    clear: (state) => {
      state.feed = [];
      state.learningOnline = {};
      state.learningHomework = {};
      state.learningStudents = [];
      state.canFreeTrialStudent = [];
      state.academyInvite = [];
      state.waitingCount = 0;
      state.pageNo = 0;
      state.hasAlarms = false;
      state.hasNext = false;
      state.studentId = false;
      state.isFetch = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHomeInfo.fulfilled, (state, action) => {
        const {
          feedList: { list, pageNum, hasNextPage },
          cartListCount,
          noticeNotReadCount,
          onlineHomeLearningList,
          learningStatuslist,
          canFreeTrialStudent,
          academyInvite,
        } = action.payload;

        state.waitingCount = cartListCount;
        state.hasAlarms = noticeNotReadCount > 0;
        state.feed = list;

        state.learningOnline = onlineHomeLearningList;
        state.learningHomework = learningStatuslist;
        state.academyInvite = academyInvite;
        state.canFreeTrialStudent = canFreeTrialStudent
          .filter((item) => item.orderAvailableYn === 'Y')
          .map((item) => item.studentNo);

        const learningStudents = [];
        forIn(onlineHomeLearningList, (value, key) => {
          if (value.length > 0 && !learningStudents.includes(key)) {
            learningStudents.push(Number(key));
          }
        });
        forIn(learningStatuslist, (value, key) => {
          if (value.length > 0 && !learningStudents.includes(key)) {
            learningStudents.push(Number(key));
          }
        });
        state.learningStudents = learningStudents;

        state.pageNo = pageNum;
        state.hasNext = hasNextPage;

        state.isFetch = true;
      })
      .addCase(next.fulfilled, (state, action) => {
        const { list, pageNum, hasNextPage } = action.payload;
        const feedIds = state.feed.reduce(
          (pre, current) => [...pre, current.feedId],
          [],
        );
        state.feed = [
          ...state.feed,
          ...list.filter((item) => !feedIds.includes(item.feedId)),
        ];
        state.pageNo = pageNum;
        state.hasNext = hasNextPage;
      })
      .addCase(first.fulfilled, (state, action) => {
        const { list, pageNum, hasNextPage } = action.payload;
        state.feed = list;
        state.pageNo = pageNum;
        state.hasNext = hasNextPage;
      })
      .addCase(read.fulfilled, (state, action) => {
        // 읽는것만 설정
        state.feed = state.feed.map((item) => {
          if (item.feedId === action.payload) {
            return {
              ...item,
              readYn: 'Y',
            };
          }
          return item;
        });
      });
    // 메뉴 눌러서 라우팅 변경되면 store 지우기
    builder.addCase(changeRoute, (state, action) => {
      if (action.payload === '/home') {
        state.feed = [];
        state.learningOnline = {};
        state.learningHomework = {};
        state.canFreeTrialStudent = [];
        state.academyInvite = [];
        state.waitingCount = 0;
        state.pageNo = 0;
        state.hasAlarms = false;
        state.hasNext = false;
        state.studentId = false;
        state.isFetch = false;
      }
    });
  },
});

const canFreeTrialStudentState = (state) => state.home.canFreeTrialStudent;
export const canFreeTrialStudentSelector = createSelector(
  canFreeTrialStudentState,
  (state) => state,
);

const academyInviteState = (state) => state.home.academyInvite;
export const academyInviteSelector = createSelector(
  academyInviteState,
  (state) => state,
);

const studentIdState = (state) => state.home.studentId;
export const studentIdSelector = createSelector(
  studentIdState,
  (state) => state,
);

const feedState = (state) => {
  return {
    feed: state.home.feed,
    pageNo: state.home.pageNo,
    hasNext: state.home.hasNext,
  };
};
export const feedSelector = createSelector(feedState, (state) => state);

const learningStudentsState = (state) => state.home.learningStudents;
export const learningStudentsSelector = createSelector(
  learningStudentsState,
  (state) => state,
);

const learningState =
  ({ studentNo }) =>
  (state) => {
    return {
      online: state.home.learningOnline?.[studentNo],
      homework: state.home.learningHomework?.[studentNo],
    };
  };

export const learningSelector = createSelector(learningState, (state) => state);

export const emptyLearningSelector = ({ home }) => {
  const isEmptyHomework = Object.keys(home?.learningHomework || {}).reduce(
    (pre, current) => {
      return pre && isEmpty(home.learningHomework[current]);
    },
    true,
  );
  const isEmptyOnline = Object.keys(home?.learningOnline || {}).reduce(
    (pre, current) => {
      return pre && isEmpty(home.learningOnline[current]);
    },
    true,
  );

  return isEmptyHomework && isEmptyOnline;
};

export const isEmptyHomeSelector = ({ home }) => {
  const isEmptyHomework = Object.keys(home?.learningHomework || {}).reduce(
    (pre, current) => {
      return pre && isEmpty(home.learningHomework[current]);
    },
    true,
  );
  const isEmptyOnline = Object.keys(home?.learningOnline || {}).reduce(
    (pre, current) => {
      return pre && isEmpty(home.learningOnline[current]);
    },
    true,
  );

  return (
    isEmptyHomework && isEmptyOnline && home?.feed.length === 0 && home.isFetch
  );
};

const homeState = (state) => {
  return {
    waitingCount: state.home.waitingCount,
    hasAlarms: state.home.hasAlarms,
  };
};
export const homeSelector = createSelector(homeState, (state) => state);

export const { clear, setStudent } = homeSlice.actions;
export default homeSlice.reducer;
