import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';

function FormStudentGrade({ label, options, activeCourse }) {
  const { watch, setValue } = useFormContext();

  const handleSetValue = (value) => () => {
    setValue('gradeCd', value);
  };

  const [grade, course] = watch(['gradeCd', 'courseCd']);

  return (
    <div>
      <dt>{label}</dt>
      <dd>
        <div className="choiceItems">
          {options.map((item, index) => {
            return (
              <button
                type="button"
                key={`button_${item.name}_${index}`}
                disabled={activeCourse !== course}
                className={classnames(
                  'choiceItem',
                  grade === item.value && 'isSelected',
                )}
                onClick={handleSetValue(item.value)}
              >
                <span>{item.name}</span>
              </button>
            );
          })}
        </div>
      </dd>
    </div>
  );
}

export default FormStudentGrade;
