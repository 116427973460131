import { memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Feed from './base/Feed';

/**
 * 학원연결 초대
 */
function FeedLevelTestReport({ feed }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/learning-report-level/${feed.feedLinkKey}/${feed.studentId}`);
  };
  const feedData = useMemo(
    () => ({
      ...feed,
      feedContents: '영어 레벨테스트 리포트가 도착했습니다.',
    }),
    [feed],
  );
  return <Feed feed={feedData} onClick={handleClick} />;
}

export default memo(FeedLevelTestReport);
