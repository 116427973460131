import {
  OrderAcademy,
  OrderBook,
  OrderBookLink,
  OrderOnline,
  OrderOnlineNonPayment,
  OrderExchangeCost,
} from '@pages';
import { HEADER_TYPE } from '@constants/layout';

const orderRouteConfig = [
  {
    path: 'order-book/:id',
    element: OrderBook,
    option: {
      name: '주문/결제하기',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'order-book-link/:id',
    element: OrderBookLink,
    option: {
      name: '주문/결제하기',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'order-academy/:id',
    element: OrderAcademy,
    option: {
      name: '결제하기',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'order-academy/:id/:type',
    element: OrderAcademy,
    option: {
      name: '결제하기',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'order-online/:subjectCd',
    element: OrderOnline,
    option: {
      name: '결제하기',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'order-online/dealy/:id',
    element: OrderOnlineNonPayment,
    option: {
      name: '결제하기',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'order-exchange/:id',
    element: OrderExchangeCost,
    option: {
      name: '교환비용결제',
      headerType: HEADER_TYPE.BACK,
    },
  },
];

export default orderRouteConfig;
