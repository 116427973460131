import { createContext, useContext, useMemo } from 'react';
import { useMount } from 'ahooks';
import * as UAParser from 'ua-parser-js';

import storage from '@utils/Storage';

const AppContext = createContext({});

const uaParser = new UAParser.UAParser();
uaParser.setUA(window.navigator.userAgent);

function AppProvider({ children }) {
  useMount(() => {
    window.addEventListener('beforeunload', () => {
      if (!storage.getKeepLogin()) {
        storage.clearToken();
      }
      // 통합계정 전환 작업 전에 리로딩하면 토큰 제거
      if (storage.isOnlyTeacherRole()) {
        storage.clearToken();
      }
      window.history.replaceState({}, document.title);
    });
  });

  const isWinkDevice = useMemo(() => {
    return uaParser.getUA().includes('WINK');
  }, []);

  const isCatsApp = useMemo(() => {
    return uaParser.getUA().includes('CatsApp');
  }, []);

  return (
    <AppContext.Provider
      value={{
        userAgent: uaParser.getUA(),
        os: uaParser.getOS(),
        browser: uaParser.getBrowser(),
        cpu: uaParser.getCPU(),
        device: uaParser.getDevice(),
        engine: uaParser.getEngine(),
        isWinkDevice,
        isCatsApp,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

function useAppContext() {
  return useContext(AppContext);
}

export { AppProvider, useAppContext };
