/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import dayjs from 'dayjs';

import { read, removeOne, rollback } from '@store/slice/alarmSlice';
import { useOverlayContext } from '@context/OverlayContext';
import { Button } from '@components/ui';

function Alarm({
  noticeId,
  readYn,
  noticeContents,
  noticeDtm,
  noticeTitle,
  onClick,
}) {
  const { showToast } = useOverlayContext();
  const dispatch = useDispatch();

  const isNewAlarm = useMemo(() => {
    return dayjs().diff(dayjs(noticeDtm), 'hour') < 24;
  }, [noticeDtm]);

  const handleClick = useCallback(() => {
    dispatch(read({ noticeId }))
      .unwrap()
      .then(() => {
        onClick?.();
      });
  }, [dispatch, noticeId, onClick]);

  const handleDelete = useCallback(() => {
    dispatch(removeOne({ noticeId }))
      .unwrap()
      .then(() => {
        // 3초후에 자동적으로 사라지게 했음
        showToast({
          message: '삭제되었습니다.',
          buttonText: '삭제취소',
          onClick: () => {
            dispatch(rollback({ noticeId }));
          },
        });
      });
  }, [dispatch, noticeId, showToast]);

  return (
    <li>
      {isNewAlarm && (
        <span className="tagDotNew">
          <em className="hidden">new</em>
        </span>
      )}

      <a
        className={classnames('boxNotice', readYn === 'Y' && 'typeReaded')}
        onClick={handleClick}
      >
        <div className="rowBox">
          <p className="txtFrom">{noticeTitle}</p>
          <p className="txtMssg">{noticeContents}</p>
          <p className="txtDate">{noticeDtm}</p>
        </div>
      </a>
      <Button.Normal
        className="btnRemove"
        onClick={handleDelete}
        label="알림삭제"
      />
    </li>
  );
}

export default memo(Alarm);
