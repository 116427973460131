import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { BaseModal } from '@components/overlay';
import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';
import { CLAIM_SOLUTION_TYPE } from '@constants/order';
import { accountSelector } from '@store/slice/accountSlice';
import orderService from '@service/OrderService';

function PaymentHistoryDetailActions({ id, order, orderGoods, onRefresh }) {
  const navigate = useNavigate();

  const { memberNo } = useSelector(accountSelector);
  const { showDialog, closeModal, showToast } = useOverlayContext();

  const showActionConfirm = ({ action, message, confirm }) => {
    showDialog(BaseModal, {
      title: '알림',
      content: message,
      buttons: [
        {
          label: '아니오',
          action: () => closeModal(),
          className: 'btnOutline',
        },
        {
          label: '네',
          action: async () => {
            await action?.();
            showToast({ message: confirm });
            onRefresh?.();
            closeModal();
          },
          className: 'btnPrimary',
        },
      ],
    });
  };

  const handleOrderCancel = () => {
    // 주문 취소
    navigate(`/payment-cancel/${id}`);
  };

  const handleChange = () => {
    // 교환 환불
    navigate(`/payment-claim/${id}`);
  };

  const handleChangeCancel = () => {
    const fetchCancel = async () => {
      try {
        await orderService.exangeCancel({
          memberNo,
          orderId: id,
          orderGoodsIds: orderGoods.map((item) => item.orderGoodsId),
          type: CLAIM_SOLUTION_TYPE.EXCHANGE,
        });
      } catch (error) {
        console.warn(error);
      }
    };
    // 교환 취소
    showActionConfirm({
      action: () => fetchCancel(),
      message: '교환신청을 취소하시겠습니까?',
      confirm: '교환신청이 취소되었습니다.',
    });
  };
  const handleChangeFee = () => {
    // 교환 비용 결제
    navigate(`/order-exchange/${id}`);
  };

  const handleRefundCancel = () => {
    const fetchCancel = async () => {
      try {
        await orderService.refundCancel({
          memberNo,
          orderId: id,
          orderGoodsIds: orderGoods.map((item) => item.orderGoodsId),
          type: CLAIM_SOLUTION_TYPE.REFUND,
        });
      } catch (error) {
        console.warn(error);
      }
    };
    // 반품 신청 취소
    showActionConfirm({
      action: () => fetchCancel(),
      message: '반품신청을 취소하시겠습니까?',
      confirm: '반품신청이 취소되었습니다.',
    });
  };

  // TODO: 환불비용 버튼이 필요한지 확인 필요
  const handleRefundFee = () => {
    // 환불비용
  };

  const handleConfirm = () => {
    const fetchConfirm = async () => {
      try {
        await orderService.orderConfirm({
          memberNo,
          orderId: id,
          orderGoodsIds: orderGoods.map((item) => item.orderGoodsId),
        });
      } catch (error) {
        console.warn(error);
      }
    };
    // 구매 확정
    showActionConfirm({
      action: () => fetchConfirm(),
      message: '구매를 확정 하시겠습니까?',
      confirm: '구매가 확정 되었습니다.',
    });
  };
  return (
    <div className="btnsBox">
      {order.cancelButtonYn === 'Y' && (
        <Button.Outline label="주문취소" onClick={handleOrderCancel} />
      )}
      {order.changeButtonYn === 'Y' && (
        <Button.Outline label="교환/반품요청" onClick={handleChange} />
      )}
      {order.changeWithdrawButtonYn === 'Y' && (
        <Button.Outline label="교환취소" onClick={handleChangeCancel} />
      )}
      {order.changeDeliveryFeePayButtonYn === 'Y' && (
        <Button.Outline label="교환비용결제" onClick={handleChangeFee} />
      )}
      {order.returnWithdrawButtonYn === 'Y' && (
        <Button.Outline label="반품취소" onClick={handleRefundCancel} />
      )}
      {order.returnDeliveryFeeButtonYn === 'Y' && (
        <Button.Outline label="반품비용결제" onClick={handleRefundFee} />
      )}

      {order.decideOrderButtonYn === 'Y' && (
        <Button.Outline label="구매확정" onClick={handleConfirm} />
      )}
      {/* {order.returnButtonYn === 'Y' && <Button.Outline label="반품" />} */}
    </div>
  );
}

export default PaymentHistoryDetailActions;
