import { useEffect, useState } from 'react';
import { useMount } from 'ahooks';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';
import useBrandPay from '@hooks/useBrandPay';

import accountService from '@service/AccountService';
import Popup from './base/Popup';

function PopupPaymentMethod({ id, delivery, billing, goodsInfo, ...rest }) {
  const { closePopup } = useOverlayContext();

  const {
    billingCard,
    cardList,
    changeMethod,
    addPaymentMethod,
    requestBilling,
    requestAgreement,
  } = useBrandPay({ memberNo: billing.memberNo, loadCard: true });

  const [card, setCard] = useState();

  const handleBilling = () => {
    const fetchChange = async () => {
      // 배송지 설정 후
      await accountService.changeAddress({
        ...delivery,
        memberNo: billing.memberNo,
      });
      // 약관 동의
      if (isEmpty(billingCard)) {
        await requestAgreement({ scope: 'BILLING' });
      }
      // 다른 결제수단이면 결제수단 등록 후
      if (card?.id !== billingCard?.id) {
        await changeMethod({ methodKey: card?.methodKey }, true);
      }
      // 결제 시작
      requestBilling(billing, goodsInfo, card.id);
      closePopup(id);
    };

    fetchChange();
  };

  const handleChange = (item) => {
    setCard(item);
  };

  const handleAddPayment = () => {
    addPaymentMethod();
  };

  useEffect(() => {
    setCard(billingCard);
  }, [billingCard]);

  useMount(() => {
    window.scrollTo({ top: 0, left: 0 });
  });
  return (
    <Popup {...rest}>
      <Popup.Header id={id} hasBack />
      <Popup.Body
        containerClassName="changePaymentMethod"
        className="changePaymentMethod"
      >
        <div
          className={classnames(
            'boxFlexGrow',
            cardList.length === 0 && 'noPaymentMethod',
          )}
        >
          <div className="topMessages">
            <p className="textSub">정기결제 카드선택</p>
            <p className="textMain">등록 또는 선택해주시기 바랍니다.</p>
          </div>
          <div className="cardList">
            {cardList.length === 0 && (
              <div className="noCard">
                <p>
                  선택할 카드가 없습니다.
                  <br />
                  새로운 카드를 등록해주세요.
                </p>
              </div>
            )}
            {cardList.length > 0 && (
              <ul>
                {cardList.map((item, index) => (
                  <li key={`card_${index}`}>
                    <label className="radioBox typeCard">
                      <input
                        type="radio"
                        name="cardList"
                        checked={card?.id === item.id}
                        onChange={() => handleChange(item)}
                      />
                      <span className="labelText">
                        <strong className="cardImg">
                          <img src={item.iconUrl} alt="" />
                        </strong>
                        <div className="cardText">
                          <em className="cardName">{item.cardName}</em>
                          <em className="cardNumber">
                            {item.cardName.replace(item.cardType, '')}{' '}
                            {item.cardNumber.slice(0, 4)}
                          </em>
                        </div>
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="boxFlexNone">
          <p className="addPaymentMethod">
            <button
              type="button"
              className="btnLink"
              onClick={handleAddPayment}
            >
              <span>새로운 카드 등록하기</span>
            </button>
          </p>
          <Button.Wrapper>
            <Button.Primary
              large
              disabled={isEmpty(card)}
              label="정기결제 카드 등록"
              onClick={handleBilling}
            />
          </Button.Wrapper>
        </div>
      </Popup.Body>
    </Popup>
  );
}

export default PopupPaymentMethod;
