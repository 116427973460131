/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useSelector, useDispatch } from 'react-redux';
import { deliverySelector, setDeliveryMethod } from '@store/slice/orderSlice';

function DefinitionBoxDeliveryMethod() {
  const dispatch = useDispatch();
  // IMMEDIATELY / BATCH_SEND
  const { deliveryMethod } = useSelector(deliverySelector);

  const handleSetDeliveryMethod = (method) => {
    dispatch(setDeliveryMethod(method));
  };

  return (
    <div className="definitionBox typeDeliveryMethod">
      <p className="boxTitle">배송방식 선택</p>
      <ul className="listDeliveryMethod">
        <li>
          <label className="selectByRadio methodBulk">
            <input
              type="radio"
              name="deliveryMethod"
              value="BATCH_SEND"
              checked={deliveryMethod === 'BATCH_SEND'}
              onChange={() => {}}
            />
            <div
              className="labelText"
              onClick={() => handleSetDeliveryMethod('BATCH_SEND')}
            >
              <p className="textType">학원으로 일괄배송</p>
              <p className="textDesc">
                학원에서 학생이 교재를 받을 수 있습니다.
              </p>
              <div className="textDeliveryFee">
                <span>배송비</span>
                <strong>무료</strong>
              </div>
            </div>
          </label>
        </li>
        <li>
          <label className="selectByRadio methodIndividual">
            <input
              type="radio"
              name="deliveryMethod"
              value="IMMEDIATELY"
              checked={deliveryMethod === 'IMMEDIATELY'}
              onChange={() => {}}
            />
            <div
              className="labelText"
              onClick={() => handleSetDeliveryMethod('IMMEDIATELY')}
            >
              <p className="textType">배송지로 개별배송</p>
              <p className="textDesc">
                원하는 배송지로 직접 교재를 받을 수 있습니다.
              </p>
              <div className="textDeliveryFee">
                <span>배송비</span>
                <strong>
                  2,500<em>원</em>
                </strong>
              </div>
            </div>
          </label>
        </li>
      </ul>
    </div>
  );
}

export default DefinitionBoxDeliveryMethod;
