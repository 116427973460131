import { useMemo } from 'react';
import { isEmpty } from 'lodash';

import { LEVEL_BOOKS } from '@constants/report';

import EnglishLevelTestCurriculumBook from './EnglishLevelTestCurriculumBook';

function EnglishLevelTestCurriculum({ level }) {
  const books = useMemo(() => {
    return LEVEL_BOOKS.filter((book) => book.levels.includes(level)) || [];
  }, [level]);

  return (
    <>
      {!isEmpty(books) && (
        <div className="reportSection boxCurriculum">
          <div className="sectionTitleBox">
            <p className="sectionTitle">CATS 추천 커리큘럼</p>
          </div>

          <div className="divisionWrapper">
            <div className="reportDivision">
              <ul className="lisrtCurriculum">
                {books?.map((book, index) => (
                  <EnglishLevelTestCurriculumBook
                    key={`book_${index}`}
                    {...book}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EnglishLevelTestCurriculum;
