// CD10188-정기상담,CD10189-비정기상담,CD10190-입회상담,CD10191-퇴원상담
const CONSULTATION_TYPE_CODE = {
  REGULAR: 'CD10188',
  IRREGULAR: 'CD10189',
  ADMISSION: 'CD10190',
  DISCHARGE: 'CD10191',
};

const CONSULTATION_TYPE = {
  [CONSULTATION_TYPE_CODE.REGULAR]: '정기상담',
  [CONSULTATION_TYPE_CODE.IRREGULAR]: '비정기상담',
  [CONSULTATION_TYPE_CODE.ADMISSION]: '입회상담',
  [CONSULTATION_TYPE_CODE.DISCHARGE]: '퇴원상담',
};

export { CONSULTATION_TYPE, CONSULTATION_TYPE_CODE };
