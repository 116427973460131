import api from '@utils/Api';

class ProfileService {
  constructor(client) {
    this.httpClient = client;
  }

  checkPassword({ memberNo, password }, options = {}) {
    return this.httpClient.post(
      `/mypage/auth-token/${memberNo}`,
      { password },
      options,
    );
  }

  update({ memberNo, ...profile }, options = {}) {
    return this.httpClient.post(
      `/mypage/service-member/${memberNo}`,
      profile,
      options,
    );
  }
}

const profileService = new ProfileService(api);
export default profileService;
