/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { classSelector } from '@store/slice/accountSlice';

function EnglishLevelTestReportItem({
  studentId,
  studentNm,
  reportContents,
  classId,
  taskId,
}) {
  const navigate = useNavigate();

  const classes = useSelector(classSelector);
  const { academyName, className } =
    classes.find((item) => item.classId === classId) || {};

  const isNewReport = useMemo(() => {
    return dayjs().diff(dayjs(reportContents?.testDate), 'hour') < 24;
  }, [reportContents?.testDate]);

  const goDetail = () => {
    navigate(`/learning-report-level/${taskId}/${studentId}`);
  };

  return (
    <li>
      <a className="aLink" onClick={() => goDetail()}>
        <div className="boxClassInfo">
          <span className="logoImg noImg" />
          <p className="txtAcademy">
            <span>
              {academyName} {className} 레벨테스트 리포트
            </span>
            {isNewReport && (
              <span className="icoNew">
                <em>New</em>
              </span>
            )}
          </p>
          <p className="textName">{studentNm}</p>
        </div>
        <ul className="subtopicList">
          <li>
            <div className="txtLabel">응시레벨</div>
            <div className="txtSubtopic">{reportContents?.type || '-'}</div>
          </li>
          <li>
            <div className="txtLabel">테스트 응시일</div>
            <div className="txtSubtopic">{reportContents?.testDate || '-'}</div>
          </li>
        </ul>
      </a>
    </li>
  );
}

export default EnglishLevelTestReportItem;
