import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import alarmService from '@service/AlarmService';
import { isNil } from 'lodash';

export const getAlarmCount = createAsyncThunk(
  'alarm/getAlarmCount',
  async ({ memberNo }) => {
    const response = await alarmService.count({ parentId: memberNo });
    return response;
  },
);

export const getAlarms = createAsyncThunk(
  'alarm/getAlarms',
  async ({ memberNo, pageNum }, { getState }) => {
    const {
      alarm: { pageNo: statePageNo },
    } = getState();
    const pageNo = isNil(pageNum) ? statePageNo + 1 : pageNum;

    const response = await alarmService.alarms({ parentId: memberNo, pageNo });
    return response;
  },
);

export const removeAll = createAsyncThunk(
  'alarm/removeAll',
  async ({ memberNo }) => {
    const response = await alarmService.removeAll({ memberNo });
    return response;
  },
);

export const removeOne = createAsyncThunk(
  'alarm/remove',
  async ({ noticeId }) => {
    await alarmService.remove({ noticeId });
    return noticeId;
  },
);

export const rollback = createAsyncThunk(
  'alarm/rollback',
  async ({ noticeId }) => {
    await alarmService.rollback({
      noticeIds: [noticeId],
    });
    return noticeId;
  },
);

export const read = createAsyncThunk('alarm/read', async ({ noticeId }) => {
  await alarmService.read({ noticeId });
  return noticeId;
});

const initialState = {
  alarms: [],
  count: 0,
  pageNo: 0,
  hasNext: false,
};

export const alarmSlice = createSlice({
  name: 'alarm',
  initialState,
  reducers: {
    clear: (state) => {
      state.alarms = [];
      state.count = 0;
      state.pageNo = 0;
      state.hasNext = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAlarms.fulfilled, (state, action) => {
        const { list, pageNum, hasNextPage } = action.payload;

        const noticeIds = state.alarms.reduce(
          (pre, current) => [...pre, current.noticeId],
          [],
        );

        state.alarms = [
          ...state.alarms,
          ...list.filter((item) => !noticeIds.includes(item.noticeId)),
        ];
        state.pageNo = pageNum;
        state.hasNext = hasNextPage;
      })
      .addCase(getAlarmCount.fulfilled, (state, action) => {
        state.count = action.payload;
      })
      .addCase(removeAll.fulfilled, (state) => {
        // 전체 삭제 후 state 업데이트
        state.alarms = [];
        state.count = 0;
        state.holderIds = [];
        state.pageNo = 0;
        state.hasNext = false;
      })
      .addCase(removeOne.fulfilled, (state, action) => {
        // 삭제한것만 일단 Y 표시
        state.alarms = state.alarms.map((item) => {
          if (action.payload === item.noticeId) {
            return {
              ...item,
              deleteYn: 'Y',
            };
          }
          return item;
        });
        // 삭제했으니깐 하나빼기
        state.count -= 1;
      })
      .addCase(rollback.fulfilled, (state, action) => {
        // 삭제한것만 일단 Y 표시
        state.alarms = state.alarms.map((item) => {
          if (action.payload === item.noticeId) {
            return {
              ...item,
              deleteYn: 'N',
            };
          }
          return item;
        });
        // 복원했으니깐 하나더하기
        state.count += 1;
      })
      .addCase(read.fulfilled, (state, action) => {
        // 읽는것만 설정
        state.alarms = state.alarms.map((item) => {
          if (item.noticeId === action.payload) {
            return {
              ...item,
              readYn: 'Y',
            };
          }
          return item;
        });
      });
  },
});

const alarmState = (state) => {
  return {
    count: state.alarm.count,
    alarms: state.alarm.alarms.filter((item) => item.deleteYn === 'N'),
    pageNo: state.alarm.pageNo,
    hasNext: state.alarm.hasNext,
  };
};
export const alarmSelector = createSelector(alarmState, (state) => state);

export const { clear } = alarmSlice.actions;

export default alarmSlice.reducer;
