import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BaseModal } from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';

import Feed from './base/Feed';

/**
 * 교재 결제 피드
 */
function FeedPayBook({ feed }) {
  const navigate = useNavigate();
  const { showDialog, closeModal } = useOverlayContext();

  const handleCheckOrder = () => {
    if (feed.feedLinkKey) {
      navigate(`/order-book-link/${feed.feedLinkKey}`);
    }
    // (async () => {
    //   try {
    //     const response = await orderService.getWatingDetail(
    //       {
    //         parentNo: memberNo,
    //         orderNumber: feed.feedLinkKey,
    //         type: PAYMENT_TYPE.BOOK,
    //       },
    //       {
    //         ignore: true,
    //       },
    //     );
    //     if (response.onSaleYn === 'Y') {
    //       navigate(`/order-book/${feed.feedLinkKey}`);
    //     }
    //     if (response.onSaleYn === 'N') {
    //       showToast({
    //         message:
    //           '해당 교재는 판매가 중지되었습니다. 자세한 사항은 선생님에게 문의해주세요.',
    //       });
    //     }
    //   } catch (error) {
    //     if (error?.response?.data?.resultMsg.includes('999')) {
    //       showToast({
    //         message:
    //           '해당 교재는 판매가 중지되었습니다. 자세한 사항은 선생님에게 문의해주세요.',
    //       });
    //     } else {
    //       showToast({
    //         message: '구매할 수 없는 교재입니다.',
    //       });
    //     }
    //   }
    // })();
  };
  const handleClick = () => {
    if (feed.exposeYn === 'N') {
      showDialog(BaseModal, {
        title: '알림',
        content: '이미 결제한 청구서 입니다.',
        buttons: [
          {
            label: '네',
            action: () => {
              closeModal();
            },
            className: 'btnPrimary',
          },
        ],
      });
    } else {
      // 구매 가능한 주문인지 확인
      handleCheckOrder();
    }
  };
  return <Feed feed={feed} onClick={handleClick} />;
}

export default memo(FeedPayBook);
