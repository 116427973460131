import { useFormContext } from 'react-hook-form';

import PopupZipCode from '@components/overlay/popup/PopupZipCode';
import { Button } from '@components/ui';
import { useOverlayContext } from '@context/OverlayContext';

import FormInputBox from './base/FormInputBox';

function FormAddress({ label = '', className = '', isRequired = true }) {
  const { setValue, trigger } = useFormContext();
  const { showPopup } = useOverlayContext();

  const showZipCode = () => {
    showPopup(PopupZipCode, {
      oncomplete: ({ address: add, zonecode }) => {
        setValue('addr', add);
        setValue('post', zonecode);
        trigger('addr');
        trigger('post');
      },
    });
  };

  return (
    <>
      {label && (
        <label className="label" htmlFor="address">
          {label}
        </label>
      )}
      <ul className={className}>
        <li>
          <div className="rowZipcode">
            <FormInputBox
              name="post"
              disabled
              option={{
                required: isRequired && '우편번호를 입력해주세요.',
              }}
            />
            <Button.Outline label="주소 검색" onClick={showZipCode} />
          </div>
        </li>
        <li>
          <FormInputBox
            name="addr"
            disabled
            option={{
              required: isRequired && '주소를 입력해주세요.',
            }}
          />
        </li>
        <li>
          <FormInputBox
            name="addrDetail"
            option={{
              required: isRequired && '상세주소를 입력해주세요.',
            }}
          />
        </li>
      </ul>
    </>
  );
}

export default FormAddress;
