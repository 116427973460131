/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useFormContext } from 'react-hook-form';

function FormSelect({ name, option = {}, options, disalbed = false, ...rest }) {
  const { register } = useFormContext();

  return (
    <select {...register(name, option)} disabled={disalbed} {...rest}>
      {options.map((item, index) => {
        return (
          <option key={`option_${item.value}_${index}`} value={item.value}>
            {item.name}
          </option>
        );
      })}
    </select>
  );
}

export default FormSelect;
