/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { isEmpty } from 'lodash';

import { LEARNING_HOMEWORK_STATUS } from '@constants/learning';
import { studentSelector } from '@store/slice/accountSlice';
import { format } from '@helper/timeHelper';
import { MathJaxText } from '@components/ui';

function LearningStatusAcademyHomework({
  dueDateDiff,
  homeworkStatusCd,
  homeworkStatusNm,
  subjectName,
  studentId,
  homeworkSectionNm,
  homeworkNm,
  logoUrl,
  classId,
  completeDate,
}) {
  const student = useSelector(studentSelector(studentId));
  const academy = student.enrolledAcademyClass.find(
    (item) => item.classId === classId,
  );

  const diffDayClass = useMemo(() => {
    return Number(dueDateDiff) <= 0 ? 'flagFilled' : 'flagOutline';
  }, [dueDateDiff]);

  const homeStatusClass = useMemo(() => {
    if (homeworkStatusCd === LEARNING_HOMEWORK_STATUS.BEFORE_LEARNING) {
      return 'tyBefore';
    }
    if (homeworkStatusCd === LEARNING_HOMEWORK_STATUS.LEARNING) {
      return 'tyDuring';
    }
    return 'tyCompleted';
  }, [homeworkStatusCd]);
  return (
    <li>
      <a className="subjectInfo">
        <div className="statusFlag">
          {homeworkStatusCd === LEARNING_HOMEWORK_STATUS.LEARNING_COMPLETED && (
            <span className="flagDate">
              {format(completeDate, 'YYYY.MM.DD')}
            </span>
          )}
          {homeworkStatusCd !== LEARNING_HOMEWORK_STATUS.LEARNING_COMPLETED && (
            <span className={classnames(diffDayClass, 'tyDday')}>
              {dueDateDiff === 0
                ? 'D-DAY'
                : `D${dueDateDiff > 0 ? '+' : ''}${dueDateDiff}`}
            </span>
          )}
          <span className={classnames('flagFilled', homeStatusClass)}>
            {homeworkStatusNm}
          </span>
        </div>
        <p className="txtSubjectExtra">
          <strong>{subjectName}</strong>
          <span>{student?.memberName || '-'}</span>
        </p>
        <p className="txtTitle">
          <MathJaxText text={homeworkSectionNm} />
        </p>
        <p className="txtUnit">
          <MathJaxText text={homeworkNm} />
        </p>

        {!isEmpty(academy) && (
          <div className="boxClassInfo">
            <span className={classnames('logoImg', !logoUrl && 'noImg')}>
              {logoUrl && <img src={logoUrl} />}
            </span>
            <p className="txtAcademy">{academy.academyName}</p>
            <p className="txtClass">{academy.className}</p>
          </div>
        )}
      </a>
    </li>
  );
}

export default LearningStatusAcademyHomework;
