import {
  PaymentHistory,
  PaymentHistoryDetail,
  PaymentWaiting,
  PaymentHistoryClaim,
  PaymentHistoryCancel,
  PaymentHistorySuccess,
} from '@pages';
import { HEADER_TYPE } from '@constants/layout';

const paymentRouteConfig = [
  {
    path: 'payment-history',
    element: PaymentHistory,
    option: {
      name: '결제내역',
      headerType: HEADER_TYPE.BACK,
      hasTabbar: true,
    },
  },
  {
    path: 'payment-detail/:type/:id',
    element: PaymentHistoryDetail,
    option: {
      name: '결제내역 상세',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'payment-claim/:id',
    element: PaymentHistoryClaim,
    option: {
      name: '교환/반품요청',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'payment-cancel/:id',
    element: PaymentHistoryCancel,
    option: {
      name: '주문취소',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'payment-claim-success',
    element: PaymentHistorySuccess,
    option: {
      name: '교환/반품요청',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'payment-cancel-success',
    element: PaymentHistorySuccess,
    option: {
      name: '주문취소',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'payment-waiting',
    element: PaymentWaiting,
    option: {
      name: '결제대기',
      headerType: HEADER_TYPE.BACK,
      hasTabbar: true,
    },
  },
];

export default paymentRouteConfig;
