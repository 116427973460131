import { useMemo } from 'react';
import { isNaN } from 'lodash';
import { addLeadingZero } from '@helper/commonHelper';

function EnglishLevelTestGrade({
  timeRequired,
  level,
  totalQuestionCnt,
  correctAnswerCnt,
}) {
  const correctPercent = useMemo(() => {
    return isNaN(correctAnswerCnt / totalQuestionCnt)
      ? 0
      : ((correctAnswerCnt / totalQuestionCnt) * 100).toFixed(0);
  }, [correctAnswerCnt, totalQuestionCnt]);

  const [seconds, minutes] = useMemo(() => {
    return [
      addLeadingZero(Math.floor(timeRequired / 1000) % 60),
      addLeadingZero(Math.floor(timeRequired / 1000 / 60)),
    ];
  }, [timeRequired]);
  return (
    <div className="reportSection boxTestGrade">
      <div className="sectionTitleBox">
        <p className="sectionTitle">캐츠레벨</p>
      </div>
      <div className="divisionWrapper">
        <div className="reportDivision boxCatcLevel">
          <div className="timeRequired">
            <span className="textTitle">총 소요시간</span>
            <span className="textTime">
              {minutes}분 {seconds}초
            </span>
          </div>

          <div className="myGrade">
            <span className="flagCats">CATS</span>
            <span className="textLevel">
              Lv.<b>{level}</b>
            </span>
          </div>

          <dl className="myScore">
            <div>
              <dt>맞은 문항</dt>
              <dd>
                {correctAnswerCnt}/{totalQuestionCnt}
              </dd>
            </div>
            <div>
              <dt>정답률</dt>
              <dd>{correctPercent}%</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
export default EnglishLevelTestGrade;
