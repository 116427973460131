import { HEADER_TYPE } from '@constants/layout';
import {
  Event,
  My,
  MyProfileConfirm,
  MyProfileDetail,
  Faq,
  Notice,
  NoticeDetail,
  Inquiry,
  EventDetail,
  InquiryDetail,
  Terms,
} from '@pages';

const myRouteConfig = [
  {
    path: 'my',
    element: My,
    option: {
      name: 'My',
      headerType: HEADER_TYPE.NONE,
      hasTabbar: true,
    },
  },
  {
    path: 'my-confirm',
    element: MyProfileConfirm,
    option: {
      name: '내 정보 관리',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'my-profile',
    element: MyProfileDetail,
    option: {
      name: '내 정보 관리',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'faq',
    element: Faq,
    option: {
      name: '자주묻는질문',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'notice',
    element: Notice,
    option: {
      name: '공지사항',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'notice-detail/:id',
    element: NoticeDetail,
    option: {
      name: '공지사항',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'event',
    element: Event,
    option: {
      name: '이벤트',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'event-detail/:id',
    element: EventDetail,
    option: {
      name: '이벤트',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'inquiry',
    element: Inquiry,
    option: {
      name: '1:1문의',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'inquiry-detail/:id',
    element: InquiryDetail,
    option: {
      name: '1:1문의',
      headerType: HEADER_TYPE.BACK,
    },
  },
  {
    path: 'terms',
    element: Terms,
    option: {
      name: '약관 및 정책',
      headerType: HEADER_TYPE.BACK,
    },
  },
];

export default myRouteConfig;
