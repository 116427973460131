/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isArray, isNil } from 'lodash';
import { useUnmount } from 'ahooks';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { LearningTab, StudentTab } from '@components/tabs';
import { studentsSelector } from '@store/slice/accountSlice';
import {
  clear,
  getAcademyHomework,
  getOnlineHomework,
  nextAcademyHomework,
  nextOnlineHomework,
  pastStatusSelector,
  recentStatusSelector,
} from '@store/slice/learningStatusSlice';
import { LEARNING_TYPE } from '@constants/tab';
import { format, ONLY_DATE } from '@helper/timeHelper';
import withMathJax from '@hoc/withMathJax';

import LearningStatusPeriod from './components/LearningStatusPeriod';
import LearningStatusExam from './components/LearningStatusExam';

function LearningStatusPage() {
  const dispatch = useDispatch();
  const children = useSelector(studentsSelector({ hasAll: true }));

  const [searchParams] = useSearchParams();
  const viewType = searchParams.get('type');

  const [type, setType] = useState(viewType || LEARNING_TYPE.ACADEMY);
  const [studentIds, setStudentIds] = useState([]);

  const { thisWeek } = useSelector(recentStatusSelector);
  const { pastWeek, hasNext, isFetch } = useSelector(pastStatusSelector);

  const thisPeriod = useMemo(() => {
    return `${format(dayjs().day(1), ONLY_DATE)} ~ ${format(
      dayjs().day(7),
      ONLY_DATE,
    )}`;
  }, []);

  const isViewContent = useMemo(() => {
    return thisWeek.length > 0 || pastWeek.length > 0;
  }, [pastWeek.length, thisWeek.length]);

  const handleTabSelect = useCallback(
    (id) => {
      dispatch(clear());
      setType(id);
    },
    [dispatch],
  );

  const handleStudentSelect = useCallback(
    (id) => {
      if (id.length === 0) {
        id = children
          .filter((item) => item.value.length !== 0)
          .map((item) => item.value);
      }
      dispatch(clear());
      setStudentIds(isArray(id) ? id : [id]);
    },
    [children, dispatch],
  );

  const handleNext = () => {
    if (!isNil(type)) {
      const action =
        type === LEARNING_TYPE.ACADEMY
          ? nextAcademyHomework
          : nextOnlineHomework;
      dispatch(action({ studentIds }));
    }
  };

  useEffect(() => {
    if (!isNil(type) && studentIds.length > 0 && !isFetch) {
      const action =
        type === LEARNING_TYPE.ACADEMY ? getAcademyHomework : getOnlineHomework;
      dispatch(action({ studentIds }));
    }
  }, [dispatch, isFetch, studentIds, type]);

  useUnmount(() => {
    dispatch(clear());
  });

  return (
    <>
      <LearningTab onSelect={handleTabSelect} defaultType={type} />

      <StudentTab isSubMenu student={children} onSelect={handleStudentSelect} />

      <div className="contentBox boxLearningStatus">
        {isViewContent && (
          <>
            {type === LEARNING_TYPE.ONLINE && <LearningStatusExam />}
            <LearningStatusPeriod
              title="이번주"
              period={thisPeriod}
              learning={thisWeek}
              type={type}
            />

            <LearningStatusPeriod
              isToggle
              hasNext={hasNext}
              title="지난 현황 보기"
              learning={pastWeek}
              onNext={handleNext}
              type={type}
            />
          </>
        )}
        {isFetch && !isViewContent && (
          <div className="listDivision typeNoList">
            <ul className="listCards">
              <li>
                <div className="noList">
                  <div className="icoNoList">
                    <p>아직 진행중인 학습이 없습니다.</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default withMathJax(LearningStatusPage);
