import { useCallback } from 'react';
import {
  ModalIntegratedUser,
  ModalRegisteredUser,
  ModalStudentUser,
} from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';

function useCheckAccount({ complete }) {
  const { showBottomSheet } = useOverlayContext();

  const modalRegistedUser = useCallback(() => {
    showBottomSheet(ModalRegisteredUser, { complete });
  }, [complete, showBottomSheet]);

  const modalIntegratedUser = useCallback(() => {
    showBottomSheet(ModalIntegratedUser, { complete });
  }, [complete, showBottomSheet]);

  const modalStudentUser = useCallback(() => {
    showBottomSheet(ModalStudentUser, { complete });
  }, [complete, showBottomSheet]);

  const checkAccount = useCallback(
    (roles) => {
      // 등록 가능
      if (roles.length === 0) {
        return;
      }
      if (roles.includes('PARENTS')) {
        modalRegistedUser();
      }
      if (roles.includes('STUDENT')) {
        modalStudentUser();
      }
      if (roles.includes('TEACHER')) {
        modalIntegratedUser();
      }
    },
    [modalIntegratedUser, modalRegistedUser, modalStudentUser],
  );

  return { checkAccount };
}

export default useCheckAccount;
