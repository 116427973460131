/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { accountSelector } from '@store/slice/accountSlice';
import { CATS_ONLINE_PRODUCT_IMAGE } from '@constants/image';
import tempEmptyImage from '@assets/images/temp/temp_empty.png';
import HomeLink from './HomeLink';

/* eslint-disable jsx-a11y/anchor-is-valid */
function HomeEmpty() {
  const navigate = useNavigate();
  const { memberName } = useSelector(accountSelector);

  const goOnline = () => {
    navigate('/online-learning');
  };
  return (
    <div className="contentBox">
      <div className="topMessageBox">
        <div className="textMain">
          <p>
            <b>{memberName}</b>학부모님,
          </p>
          <p>
            캐츠 학부모에 오신 것을 환영합니다! <span className="icoSmile" />
          </p>
        </div>
        <p className="textSub">
          학부모 서비스에서는 학습리포트, 학원 알림장, 학원 교재구매, 학원비
          결제 등을 편리하게 진행하실 수 있습니다.
        </p>
      </div>

      <div className="feedsSection">
        <ul className="feeds">
          <li>
            <a className="informBox">
              <div className="rowSource">
                <span className="tagDotNew">
                  <em className="hidden">new</em>
                </span>
                <span className="logoImg logoCats">
                  <em className="hidden">CATS</em>
                </span>
                <p className="txtAcademy">캐츠 학습</p>
              </div>
              <div className="rowMessage">
                <p>03월 학습리포트가 도착했습니다.</p>
              </div>
              <div className="colFrom">
                <span className="txtName">김단비</span>
                <span className="txtDate">2023. 04. 10</span>
              </div>
            </a>
          </li>
          <li>
            <a className="informBox">
              <div className="rowSource">
                <span className="tagDotNew">
                  <em className="hidden">new</em>
                </span>
                <span className="logoImg icoTest1">
                  <img src={tempEmptyImage} />
                </span>
                <p className="txtAcademy">최고수학학원 중3 A반</p>
              </div>
              <div className="rowMessage">
                <p>알림장이 도착했습니다.</p>
              </div>
              <div className="colFrom">
                <span className="txtName">김단비</span>
                <span className="txtDate">2023. 04. 09</span>
              </div>
            </a>
          </li>
          <li>
            <a className="informBox">
              <div className="rowSource">
                <span className="logoImg icoTest1">
                  <img src={tempEmptyImage} />
                </span>
                <p className="txtAcademy">최고수학학원 중1 B반</p>
              </div>
              <div className="rowMessage">
                <p>수업 교재를 구매해주시기 바랍니다.</p>
              </div>
              <div className="colFrom">
                <span className="txtName">김푸름</span>
                <span className="txtDate">2023. 04. 07</span>
              </div>
            </a>
          </li>
          <li>
            <a className="informBox">
              <div className="rowSource">
                <span className="logoImg icoTest2">
                  <img src={tempEmptyImage} />
                </span>
                <p className="txtAcademy">ABC영어학원 내신집중반</p>
              </div>
              <div className="rowMessage">
                <p>03월 학원비를 결제해주시기 바랍니다.</p>
              </div>
              <div className="colFrom">
                <span className="txtName">김단비</span>
                <span className="txtDate">2023. 03. 25</span>
              </div>
            </a>
          </li>
          <li>
            <a className="informBox">
              <div className="rowSource">
                <span className="logoImg icoTest3">
                  <img src={tempEmptyImage} />
                </span>
                <p className="txtAcademy">가나다국어학원 내신대비특강</p>
              </div>
              <div className="rowMessage">
                <p>선생님이 상담을 요청하였습니다.</p>
              </div>
              <div className="colFrom">
                <span className="txtName">김푸름</span>
                <span className="txtDate">2022. 12. 16</span>
              </div>
            </a>
          </li>
        </ul>
      </div>

      {/* <HomeLink /> */}
      <div className="boxOtherService">
        <p className="textTitle">캐츠 학습 서비스를 구독하시려면,</p>
        <div className="promotionalBox">
          <a className="bnrLink" onClick={goOnline}>
            <img
              src={CATS_ONLINE_PRODUCT_IMAGE}
              alt="학습&amp;케어를 온라인과 오프라인 동시에! CATS 온라인 맞춤학습"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default HomeEmpty;
