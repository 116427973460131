import api from '@utils/Api';

class BoardService {
  constructor(client) {
    this.httpClient = client;
  }

  getBoardInfo(id, options = {}) {
    return this.httpClient.get(`/v1/board/${id}`, {}, options);
  }

  getBoardList(
    { boardId, keyword, depth1, page, pageSize = 10 },
    options = {},
  ) {
    return this.httpClient.get(
      `/v1/board/${boardId}/post/list`,
      { page, depth1, pageSize, searchKeyword: keyword },
      options,
    );
  }

  getPost({ boardId, postId }, options = {}) {
    return this.httpClient.get(
      `/v1/board/${boardId}/post/${postId}`,
      {},
      options,
    );
  }

  getBestPost(id, options = {}) {
    return this.httpClient.get(`/v1/board/${id}/post/best-list`, {}, options);
  }
}

const boardService = new BoardService(api);
export default boardService;
