import axios from 'axios';
import fileDownload from 'js-file-download';

import storage from '@utils/Storage';

function useFileDownload() {
  const { accessToken, refreshToken } = storage.getToken();

  const download = (url, fileName) => {
    axios
      .get(url, {
        responseType: 'blob',
        headers: {
          X_CATS_AT: accessToken,
          X_CATS_RT: refreshToken,
        },
      })
      .then((response) => {
        fileDownload(response.data, fileName);
      });
  };

  return download;
}

export default useFileDownload;
