import { memo, useMemo } from 'react';

import { useOverlayContext } from '@context/OverlayContext';

import Feed from './base/Feed';

/**
 * 학원연결 초대
 */
function FeedLevelTest({ feed }) {
  const { showToast } = useOverlayContext();

  const handleClick = () => {
    showToast({
      message: '레벨테스트가 완료되면 결과를 리포트로 발송해 드립니다.',
    });
  };
  // feedContent에 dueDate 넣기로 했음
  const feedData = useMemo(
    () => ({
      ...feed,
      feedContents: '영어 레벨테스트가 발송되었습니다.',
      testDueDate: feed?.feedContents?.replace(/\./g, '/') || '',
    }),
    [feed],
  );
  return <Feed feed={feedData} onClick={handleClick} />;
}

export default memo(FeedLevelTest);
