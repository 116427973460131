/* eslint-disable camelcase */
import book_phonics1 from '@assets/images/leveltest/book_phonics1.png';
import book_phonics2 from '@assets/images/leveltest/book_phonics2.png';
import book_phonics3 from '@assets/images/leveltest/book_phonics3.png';
import book_course_01 from '@assets/images/leveltest/book_course_01.png';
import book_course_02 from '@assets/images/leveltest/book_course_02.png';
import book_course_03 from '@assets/images/leveltest/book_course_03.png';
import book_course_04 from '@assets/images/leveltest/book_course_04.png';
import book_course_05 from '@assets/images/leveltest/book_course_05.png';
import book_course_06 from '@assets/images/leveltest/book_course_06.png';
import book_read_30w from '@assets/images/leveltest/book_read_30w.png';
import book_read_40w from '@assets/images/leveltest/book_read_40w.png';
import book_read_50w from '@assets/images/leveltest/book_read_50w.png';
import book_read_60w from '@assets/images/leveltest/book_read_60w.png';
import book_read_70w from '@assets/images/leveltest/book_read_70w.png';
import book_read_80w from '@assets/images/leveltest/book_read_80w.png';
import book_read_100w from '@assets/images/leveltest/book_read_100w.png';
import book_grammar_01 from '@assets/images/leveltest/book_grammar_01.png';
import book_grammar_02 from '@assets/images/leveltest/book_grammar_02.png';
import book_grammar5500_01 from '@assets/images/leveltest/book_grammar5500_01.png';
import book_grammar5500_02 from '@assets/images/leveltest/book_grammar5500_02.png';
import book_grammar5500_03 from '@assets/images/leveltest/book_grammar5500_03.png';
import book_listen_elementary01 from '@assets/images/leveltest/book_listen_elementary01.png';
import book_listen_elementary02 from '@assets/images/leveltest/book_listen_elementary02.png';
import book_listen_middle from '@assets/images/leveltest/book_listen_middle.png';
import book_listen_middle_g1 from '@assets/images/leveltest/book_listen_middle_g1.png';
import book_listen_middle_g2 from '@assets/images/leveltest/book_listen_middle_g2.png';
import book_listen_middle_g3 from '@assets/images/leveltest/book_listen_middle_g3.png';
import book_listen_high from '@assets/images/leveltest/book_listen_high.png';
import book_read_subject from '@assets/images/leveltest/book_read_subject.png';
import book_read_sat from '@assets/images/leveltest/book_read_sat.png';
import book_word800 from '@assets/images/leveltest/book_word800.png';
import book_word1200 from '@assets/images/leveltest/book_word1200.png';
import book_voca1600 from '@assets/images/leveltest/book_voca1600.png';
import book_voca2000 from '@assets/images/leveltest/book_voca2000.png';
import book_story from '@assets/images/leveltest/book_story.png';

const LEVEL_BOOKS = [
  {
    title: 'OK Phonics 1',
    levels: [1],
    imgSrc: book_phonics1,
    section: 'Phonics',
    description:
      '•수준: CEFR Pre A1 / 추천 학년: 미취학 및 초등 저학년 <br/> •구성: 총 5개 레벨 / 레벨별 12개 유닛, 4개 리뷰 <br/> 영어 학습을 시작하는 단계의 학습자를 대상으로 알파벳부터 시작하는 기초 파닉스 교재. <br/> 12개 유닛, 4개의 리뷰 활동으로 구성되어 있으며, 애니메이션과 다양한 온라인 학습활동, 음원 등 각종 멀티미디어 리소스와 다양한 플래시카드와 워크시트 등의 부가학습자료, 온라인 단원평가를 제공한다.',
  },
  {
    title: 'OK Phonics 2~3',
    levels: [1],
    imgSrc: book_phonics2,
    section: 'Phonics',
    description:
      '•수준: CEFR Pre A1 / 추천 학년 : 미취학 및 초등 저학년 <br/> •구성: 총 5개 레벨 / 레벨별 10~12개 유닛과 4~5개 리뷰 <br/> 예비 초등 및 초등 저학년 대상으로 단모음 및 단자음으로 된 문장 읽기를 완성하기 위한 기초 파닉스 교재. 레벨별 10~12개 유닛과 4~5개 리뷰 활동으로 구성되어 있으며, 애니메이션과 다양한 온라인 학습활동, 음원 등 각종 멀티미디어 리소스와 다양한 플래시카드와 워크시트 등의 부가학습자료, 온라인 단원평가를 제공한다.',
  },
  {
    title: 'OK Phonics 4~5',
    levels: [1],
    imgSrc: book_phonics3,
    section: 'Phonics',
    description:
      '•수준: CEFR Pre A1 / 추천 학년 : 미취학 및 초등 저학년 <br/> •구성: 총 5개 레벨 / 레벨별 12개 유닛, 4개 리뷰 <br/> 예비 초등 및 초등 저학년 대상으로 이중 자음 및 이중 모음으로 된 단어와 문장 읽기를 완성하기 위한 기초 파닉스 교재. 12개 유닛과 4개의 리뷰 활동으로 구성되어 있으며, 애니메이션과 다양한 온라인 학습활동, 음원 등 각종 멀티미디어 리소스와 다양한 플래시카드와 워크시트 등의 부가학습자료, 온라인 단원평가를 제공한다.',
  },
  {
    title: 'Okey Dokey English 1',
    levels: [1],
    section: 'Course Book',
    imgSrc: book_course_01,
    description:
      '•수준: CEFR Pre A1 / 추천 학년: 미취학 및 초등 1학년 <br/> •구성: 총 6개 레벨 / 레벨별 8개 유닛, 32개 레슨 <br/> 영어의 Reading, Listening, Writing Speaking 4가지 스킬을 골고루 향상하기 위한 ELT 코스북 시리즈. Student Book, Workbook 세트로, 총 8개의 유닛과 리뷰 학습으로 구성되어 있다. 교재와 함께 생생한 캐릭터들이 재미있는 대화를 나누는 애니메이션과 노래 영상, 원어민과 함께 말하기를 연습해 보는 강의와 음원 등 각종 멀티미디어 리소스를 풍부하게 활용할 수 있으며, 읽기와 말하기, 문장과 어휘 학습을 위한 인터렉티브 온라인 영어학습 앱과 단어 플래시카드, 각종 워크시트가 다양하게 제공된다.',
  },
  {
    title: 'Okey Dokey English 2',
    levels: [2],
    section: 'Course Book',
    imgSrc: book_course_02,
    description:
      '•수준: CEFR Pre A1~A1 / 추천 학년: 초등 1~2학년 <br/> •구성: 총 6개 레벨 / 레벨별 8개 유닛, 32개 레슨 <br/> 영어의 Reading, Listening, Writing Speaking 4가지 스킬을 골고루 향상하기 위한 ELT 코스북 시리즈. Student Book, Workbook 세트로, 총 8개의 유닛과 리뷰 학습으로 구성되어 있다. 교재와 함께 생생한 캐릭터들이 재미있는 대화를 나누는 애니메이션과 노래 영상, 원어민과 함께 말하기를 연습해 보는 강의와 음원 등 각종 멀티미디어 리소스를 풍부하게 활용할 수 있으며, 읽기와 말하기, 문장과 어휘 학습을 위한 인터렉티브 온라인 영어학습 앱과 단어 플래시카드, 각종 워크시트가 다양하게 제공된다.',
  },
  {
    title: 'Okey Dokey English 3',
    levels: [2],
    section: 'Course Book',
    imgSrc: book_course_03,
    description:
      '•수준: CEFR A1 / 추천 학년: 초등 2~3학년 <br/> •구성: 총 6개 레벨 / 레벨별 8개 유닛, 32개 레슨 <br/> 영어의 Reading, Listening, Writing Speaking 4가지 스킬을 골고루 향상하기 위한 ELT 코스북 시리즈. Student Book, Workbook 세트로, 총 8개의 유닛과 리뷰 학습으로 구성되어 있다. 교재와 함께 생생한 캐릭터들이 재미있는 대화를 나누는 애니메이션과 노래 영상, 원어민과 함께 말하기를 연습해 보는 강의와 음원 등 각종 멀티미디어 리소스를 풍부하게 활용할 수 있으며, 읽기와 말하기, 문장과 어휘 학습을 위한 인터렉티브 온라인 영어학습 앱과 단어 플래시카드, 각종 워크시트가 다양하게 제공된다.',
  },
  {
    title: 'Okey Dokey English 4',
    levels: [3],
    section: 'Course Book',
    imgSrc: book_course_04,
    description:
      '•수준: CEFR A1 / 추천 학년: 초등 3~4학년 <br/> •구성: 총 6개 레벨 / 레벨별 8개 유닛, 32개 레슨 <br/> 영어의 Reading, Listening, Writing Speaking 4가지 스킬을 골고루 향상하기 위한 ELT 코스북 시리즈. Student Book, Workbook 세트로, 총 8개의 유닛과 리뷰 학습으로 구성되어 있다. 교재와 함께 생생한 캐릭터들이 재미있는 대화를 나누는 애니메이션과 노래 영상, 원어민과 함께 말하기를 연습해 보는 강의와 음원 등 각종 멀티미디어 리소스를 풍부하게 활용할 수 있으며, 읽기와 말하기, 문장과 어휘 학습을 위한 인터렉티브 온라인 영어학습 앱과 단어 플래시카드, 각종 워크시트가 다양하게 제공된다.',
  },
  {
    title: 'Okey Dokey English 5',
    levels: [3],
    section: 'Course Book',
    imgSrc: book_course_05,
    description:
      '•수준: CEFR A1~A2 / 추천 학년: 초등 4~5학년 <br/> •구성: 총 6개 레벨 / 레벨별 8개 유닛, 32개 레슨 <br/> 영어의 Reading, Listening, Writing Speaking 4가지 스킬을 골고루 향상하기 위한 ELT 코스북 시리즈. Student Book, Workbook 세트로, 총 8개의 유닛과 리뷰 학습으로 구성되어 있다. 교재와 함께 생생한 캐릭터들이 재미있는 대화를 나누는 애니메이션과 노래 영상, 원어민과 함께 말하기를 연습해 보는 강의와 음원 등 각종 멀티미디어 리소스를 풍부하게 활용할 수 있으며, 읽기와 말하기, 문장과 어휘 학습을 위한 인터렉티브 온라인 영어학습 앱과 단어 플래시카드, 각종 워크시트가 다양하게 제공된다.',
  },
  {
    title: 'Okey Dokey English 6',
    levels: [4],
    section: 'Course Book',
    imgSrc: book_course_06,
    description:
      '•수준: CEFR A1~A2 / 추천 학년: 초등 5~6학년 <br/> •구성: 총 6개 레벨 / 레벨별 8개 유닛, 32개 레슨 <br/> 영어의 Reading, Listening, Writing Speaking 4가지 스킬을 골고루 향상하기 위한 ELT 코스북 시리즈. Student Book, Workbook 세트로, 총 8개의 유닛과 리뷰 학습으로 구성되어 있다. 교재와 함께 생생한 캐릭터들이 재미있는 대화를 나누는 애니메이션과 노래 영상, 원어민과 함께 말하기를 연습해 보는 강의와 음원 등 각종 멀티미디어 리소스를 풍부하게 활용할 수 있으며, 읽기와 말하기, 문장과 어휘 학습을 위한 인터렉티브 온라인 영어학습 앱과 단어 플래시카드, 각종 워크시트가 다양하게 제공된다.',
  },
  {
    title: 'Read Up! 30W 1~3',
    levels: [1],
    section: 'Reading',
    imgSrc: book_read_30w,
    description:
      '•수준: CEFR Pre A1 / 추천 학년: 미취학 및 초등 1학년 <br/> •구성: 총 3권 / 권별 16개 유닛 <br/> 지문의 길이와 난이도를 단계별로 나누어 교과 배경지식과 연계된 체계적인 읽기 활동을 통해 리딩의 기초를 다질 수 있는 ELT 리딩 교재. Read Up! 30W는 총 3권으로 이루어져 있으며, 권당 16개의 읽기 지문과 4개의 리뷰활동으로 구성되어 있다. 기초 일상생활 및 교과서 주제에 관련된 다양한 읽기 지문과 문제 풀이 활동을 통해 영어 읽기 능력의 기초를 다질 수 있다. 읽고 말하고 쓰는 등 영어의 4 스킬을 모두 활용하여 읽은 지문에 대한 단어와 문장을 복습할 수 있는 인터렉티브 온라인 학습 앱과 함께 출력하려 사용할 수 있는 다양한 단어와 문장 워크시트를 제공한다.',
  },
  {
    title: 'Read Up! 40W 1~3',
    levels: [2],
    section: 'Reading',
    imgSrc: book_read_40w,
    description:
      '•수준: CEFR Pre A1 / 추천 학년: 초등 1~2학년 <br/> •구성: 총 3권 / 권별 16개 유닛 <br/> 지문의 길이와 난이도를 단계별로 나누어 교과 배경지식과 연계된 체계적인 읽기 활동을 통해 리딩의 기초를 다질 수 있는 ELT 리딩 교재.  Read Up! 40W는 총 3권으로 이루어져 있으며, 권당 16개의 읽기 지문과 4개의 리뷰활동으로 구성되어 있다. 기초 일상생활 및 교과서 주제에 관련된 다양한 읽기 지문과 문제 풀이 활동을 통해 영어 읽기 능력의 기초를 다질 수 있다. 읽고 말하고 쓰는 등 영어의 4 스킬을 모두 활용하여 읽은 지문에 대한 단어와 문장을 복습할 수 있는 인터렉티브 온라인 학습 앱과 함께 출력하려 사용할 수 있는 다양한 단어와 문장 워크시트를 제공한다.',
  },
  {
    title: 'Read Up! 50W 1~3',
    levels: [3],
    section: 'Reading',
    imgSrc: book_read_50w,
    description:
      '•수준: CEFR A1 / 추천 학년: 초등 1~2학년 <br/> •구성: 총 3권 / 권별 16개 유닛 <br/> 지문의 길이와 난이도를 단계별로 나누어 교과 배경지식과 연계된 체계적인 읽기 활동을 통해 리딩의 기초를 다질 수 있는 ELT 리딩 교재. Read Up! 50W는 총 3권으로 이루어져 있으며, 권당 16개의 읽기 지문과 4개의 리뷰활동으로 구성되어 있다. 기초 일상생활 및 교과서 주제에 관련된 다양한 읽기 지문과 문제 풀이 활동을 통해 영어 읽기 능력의 기초를 다질 수 있다. 읽고 말하고 쓰는 등 영어의 4 스킬을 모두 활용하여 읽은 지문에 대한 단어와 문장을 복습할 수 있는 인터렉티브 온라인 학습 앱과 함께 출력하려 사용할 수 있는 다양한 단어와 문장 워크시트를 제공한다.',
  },
  {
    title: 'Read Up! 60W 1~3',
    levels: [4],
    section: 'Reading',
    imgSrc: book_read_60w,
    description:
      '•수준: CEFR A1 / 추천 학년: 초등 3~4학년 <br/> •구성: 총 3권 / 권별 16개 유닛 <br/> 지문의 길이와 난이도를 단계별로 나누어 교과 배경지식과 연계된 체계적인 읽기 활동을 통해 리딩의 기초를 다질 수 있는 ELT 리딩 교재. <br/> Read Up! 60W는 총 3권으로 이루어져 있으며, 권당 16개의 읽기 지문과 4개의 리뷰활동으로 구성되어 있다. 기초 일상생활 및 교과서 주제에 관련된 다양한 읽기 지문과 문제 풀이 활동을 통해 영어 읽기 능력의 기초를 다질 수 있다. 읽고 말하고 쓰는 등 영어의 4 스킬을 모두 활용하여 읽은 지문에 대한 단어와 문장을 복습할 수 있는 인터렉티브 온라인 학습 앱과 함께 출력하려 사용할 수 있는 다양한 단어와 문장 워크시트를 제공한다.',
  },
  {
    title: 'Read Up! 70W 1~3',
    levels: [4, 5],
    section: 'Reading',
    imgSrc: book_read_70w,
    description:
      '•수준: CEFR A1~A2 / 추천 학년: 초등 3~4학년 <br/> •구성: 총 3권 / 권별 16개 유닛 <br/> 지문의 길이와 난이도를 단계별로 나누어 교과 배경지식과 연계된 체계적인 읽기 활동을 통해 리딩의 기초를 다질 수 있는 ELT 리딩 교재. Read Up! 70W는 총 3권으로 이루어져 있으며, 권당 16개의 읽기 지문과 4개의 리뷰활동으로 구성되어 있다. 기초 일상생활 및 교과서 주제에 관련된 다양한 읽기 지문과 문제 풀이 활동을 통해 영어 읽기 능력의 기초를 다질 수 있다. 읽고 말하고 쓰는 등 영어의 4 스킬을 모두 활용하여 읽은 지문에 대한 단어와 문장을 복습할 수 있는 인터렉티브 온라인 학습 앱과 함께 출력하려 사용할 수 있는 다양한 단어와 문장 워크시트를 제공한다.',
  },
  {
    title: 'Read Up! 80W 1~3',
    levels: [5, 6],
    section: 'Reading',
    imgSrc: book_read_80w,
    description:
      '•수준: CEFR A1~A2 / 추천 학년: 초등 5~6학년 <br/> •구성: 총 3권 / 권별 16개 유닛 <br/> 지문의 길이와 난이도를 단계별로 나누어 교과 배경지식과 연계된 체계적인 읽기 활동을 통해 리딩의 기초를 다질 수 있는 ELT 리딩 교재. Read Up! 80W는 총 3권으로 이루어져 있으며, 권당 16개의 읽기 지문과 4개의 리뷰활동으로 구성되어 있다. 기초 일상생활 및 교과서 주제에 관련된 다양한 읽기 지문과 문제 풀이 활동을 통해 영어 읽기 능력의 기초를 다질 수 있다. 읽고 말하고 쓰는 등 영어의 4 스킬을 모두 활용하여 읽은 지문에 대한 단어와 문장을 복습할 수 있는 인터렉티브 온라인 학습 앱과 함께 출력하려 사용할 수 있는 다양한 단어와 문장 워크시트를 제공한다.',
  },
  {
    title: 'Read Up! 100W 1~3',
    levels: [6],
    section: 'Reading',
    imgSrc: book_read_100w,
    description:
      '•수준: CEFR A2 / 추천 학년: 초등 5~6학년 <br/> •구성: 총 3권 / 권별 16개 유닛 <br/> 지문의 길이와 난이도를 단계별로 나누어 교과 배경지식과 연계된 체계적인 읽기 활동을 통해 리딩의 기초를 다질 수 있는 ELT 리딩 교재. Read Up! 100W는 총 3권으로 이루어져 있으며, 권당 16개의 읽기 지문과 4개의 리뷰활동으로 구성되어 있다. 교과서 주제와 관련된 읽기 지문이 Fiction과 Non-Fiction 골고루 제공되며, 함께 수록된 문제 풀이 활동을 통해 영어 읽기 능력의 실력을 향상시킬 수 있다. 읽고 말하고 쓰는 등 영어의 4 스킬을 모두 활용하여 읽은 지문에 대한 단어와 문장을 복습할 수 있는 인터렉티브 온라인 학습 앱과 함께 출력하려 사용할 수 있는 다양한 단어와 문장 워크시트를 제공한다.',
  },
  {
    title: 'Grammar Up! 초등 영문법 Starter 1~2',
    levels: [3, 4],
    section: '문법',
    imgSrc: book_grammar_01,
    description:
      '•추천 학년: 초등 3~4학년 <br/> •구성: 총 2개 레벨 / 레벨별 8개 챕터, 24개 유닛 <br/> 초등 저학년을 위한 쉽고 재미있는 기초 문법 시리즈. Student Book과 Workbook 세트로, 초등학생에게 꼭 필요한 기초 필수 문법 사항이 8개 챕터, 24개 유닛으로 구성되어 있다. 사진과 이미지로 개념에 대한 이해를 돕고, 읽기와 쓰기 실력을 향상시킬 수 있는 다양한 연습문제가 수록되어 있으며, 친절한 개념설명 강의 영상과 온라인 문제은행, 단어 학습 앱, 문장 워크시트가 함께 제공된다.',
  },
  {
    title: 'Grammar Up! 초등 영문법 Basic 1~2',
    levels: [5, 6],
    section: '문법',
    imgSrc: book_grammar_02,
    description:
      '•추천 학년: 초등 5~6학년 및 예비 중등 <br/> •구성: 총 2개 레벨 / 레벨별 8개 챕터, 24개 유닛 <br/> 초등 고학년 대상으로 읽기 쓰기 실력 향상과 함께 중학교 내신을 대비하기 위한 초등 문법 교재. Student Book과 Workbook 세트로, 초등 과정에서 배워야 하는 기본 문법 개념이 10개 챕터, 30개 유닛으로 구성되어 있다. 자세한 설명과 단계별 연습문제, 중등 내신 대비를 위한 단원 평가가 수록되어 있으며, 실력 있는 강사님의 친절한 강의 영상과 온라인 문제은행, 단어 학습 앱, 문장 워크시트가 함께 제공된다.',
  },
  {
    title: 'Grammar Up! 중등 5500 기본',
    levels: [7, 8],
    section: '문법',
    imgSrc: book_grammar5500_01,
    description:
      '•추천 학년: 중등 1~2학년 <br/> •구성: 총 3개 레벨(기본, 실력, 심화) / 레벨별 10~12개 챕터, 30~36개 유닛 <br/> 중학생 대상의 기초 문법 학습 교재. 개념을 확실하게 이해하기 위한 개념편, 풍부한 문제풀이와 심화 학습활동을 담은 실력편이 한 세트이며, 중학생이 필수로 알아야 하는 중등 기초 문법 사항이 10개 챕터, 30개 유닛으로 구성되어 있다. 자세한 설명과 단계별 연습문제, 내신 및 수행평가 대비를 위한 다양한 유형의 테스트가 수록되어 있으며, 실력 있는 강사님의 친절한 강의 영상과 온라인 문제은행, 단어 학습 앱, 문장 워크시트가 함께 제공된다.',
  },
  {
    title: 'Grammar Up! 중등 5500 실력',
    levels: [8, 9],
    section: '문법',
    imgSrc: book_grammar5500_02,
    description:
      '•추천 학년: 중등 2~3학년 <br/> •구성: 총 3개 레벨(기본, 실력, 심화) / 레벨별 10~12개 챕터, 30~36개 유닛 <br/> 중학생 대상의 본격 문법 학습 교재. 개념을 확실하게 이해하기 위한 개념편, 풍부한 문제풀이와 심화 학습활동을 담은 실력편이 한 세트이며, 중학생이 필수로 알아야 하는 중등 필수 문법 사항이 12개 챕터, 36개 유닛으로 구성되어 있다. 자세한 설명과 단계별 연습문제, 내신 및 수행평가 대비를 위한 다양한 유형의 테스트가 수록되어 있으며, 실력 있는 강사님의 친절한 강의 영상과 온라인 문제은행, 단어 학습 앱, 문장 워크시트가 함께 제공된다.',
  },
  {
    title: 'Grammar Up! 중등 5500 심화',
    levels: [9],
    section: '문법',
    imgSrc: book_grammar5500_03,
    description:
      '•추천 학년: 중등 3학년 및 예비 고등 <br/> •구성: 총 3개 레벨(기본, 실력, 심화) / 레벨별 10~12개 챕터, 30~36개 유닛 <br/> 예비 고등 대상의 심화 문법 학습 교재. 개념을 확실하게 이해하기 위한 개념편, 풍부한 문제풀이와 심화 학습활동을 담은 실력편이 한 세트이며, 고등학교 내신과 수능을 대비하기 위한 중등 심화 문법 사항이 12개 챕터, 34개 유닛으로 구성되어 있다. 자세한 설명과 단계별 연습문제, 내신 및 수행평가 대비를 위한 다양한 유형의 테스트가 수록되어 있으며, 실력 있는 강사님의 친절한 강의 영상과 온라인 문제은행, 단어 학습 앱, 문장 워크시트가 함께 제공된다.',
  },
  {
    title: 'Listen Up! 초등 영어듣기 모의고사 5학년',
    levels: [5],
    section: '듣기',
    imgSrc: book_listen_elementary01,
    description:
      '•추천 학년: 초등 5~6학년 <br/> •구성: 총 2개 레벨(초등 5학년, 초등 6학년) / 듣기 모의고사 20회 수록 <br/> 초등 고학년 대상의 듣기 교재. 초등학교 영어 시험의 빈출 유형으로 문제를 구성하였다. 주제별 여러 상황의 듣기 지문과 대화문을 가지고 단어-어구-단락의 단계적으로 확장되는 받아쓰기 패턴 학습을 진행하고, 빈출 유형의 다양한 문제 풀이를 통해 각종 시험에 대비할 수 있다. 듣기 실력 향상을 위한 핵심 단어/표현 학습 및 리스닝 팁 등이 수록되어 있으며, 음원을 바로 들어볼 수 있는 온라인 문제은행과 초등 필수 어휘 연습을 위한 영어 학습 앱도 함께 제공된다.',
  },
  {
    title: 'Listen Up! 초등 영어듣기 모의고사 6학년',
    levels: [6],
    section: '듣기',
    imgSrc: book_listen_elementary02,
    description:
      '•추천 학년: 초등 5~6학년 <br/> •구성: 총 2개 레벨(초등 5학년, 초등 6학년) / 듣기 모의고사 20회 수록 <br/> 초등 고학년 대상의 듣기 교재. 초등학교 영어 시험의 빈출 유형으로 문제를 구성하였다. 주제별 여러 상황의 듣기 지문과 대화문을 가지고 단어-어구-단락의 단계적으로 확장되는 받아쓰기 패턴 학습을 진행하고, 빈출 유형의 다양한 문제 풀이를 통해 각종 시험에 대비할 수 있다. 듣기 실력 향상을 위한 핵심 단어/표현 학습 및 리스닝 팁 등이 수록되어 있으며, 음원을 바로 들어볼 수 있는 온라인 문제은행과 초등 필수 어휘 연습을 위한 영어 학습 앱도 함께 제공된다.',
  },
  {
    title: 'Listen Up! 중등 영어듣기 모의고사 유형편',
    levels: [7, 8],
    section: '듣기',
    imgSrc: book_listen_middle,
    description:
      '•추천 학년: 예비 중등 및 중등 1학년 <br/> •구성: 중등 16개 기출 유형 분석 및 실전 모의고사 5회 수록 <br/> 예비 중등 및 중학생이 듣기 문제를 유형별로 파악할 수 있는 듣기 교재. 전국 시도 교육청이 주관하는 중학 영어듣기평가를 완벽 분석한 유형별 실전 대비서로, 최신 듣기평가와 전국 학력평가에 자주 나오는 16개 기출 유형별 분석 및 유형별 리스닝 테스트, 그리고 실전 모의고사 5회로 구성되어 있다. 유형별 핵심 표현과 지시문 및 듣기 전략을 제시하고, 받아쓰기를 통해 빠르고 정확한 듣기 실력을 키울 수 있다. 각 유형별 듣기 전략과 기출문제 풀이를 명쾌하게 정리한 강의 영상과 기출 워크시트, 음원을 바로 들어볼 수 있는 온라인 문제은행 및 중등 필수 어휘 연습을 위한 영어 학습 앱도 함께 제공된다.',
  },
  {
    title: 'Listen Up! 중등 영어듣기 모의고사 1학년',
    levels: [7],
    section: '듣기',
    imgSrc: book_listen_middle_g1,
    description:
      '•추천 학년: 중등 1학년 <br/> •구성: 3개 레벨(중등 1학년, 중등 2학년, 중등 3학년) / 실전 모의고사 20회 수록 <br/> 전국 16개 시도 교육청 영어듣기평가 빈출 유형을 반영하여 모의고사 문제를 구성하였다. 실전 모의고사 문제 풀이 및 듣기 지문에 대한 받아쓰기 활동을 통해 듣기 실력의 향상과 함께 고등 학력 평가와 수능까지 대비할 수 있는 기초 실력을 다질 수 있다. 음원을 바로 들어볼 수 있는 온라인 문제은행 및 중등 필수 어휘 연습을 위한 영어 학습 앱이 함께 제공된다.',
  },
  {
    title: 'Listen Up! 중등 영어듣기 모의고사 2학년',
    levels: [8],
    section: '듣기',
    imgSrc: book_listen_middle_g2,
    description:
      '•추천 학년: 중등 2학년 <br/> •구성: 3개 레벨(중등 1학년, 중등 2학년, 중등 3학년) / 실전 모의고사 20회 수록 <br/> 전국 16개 시도 교육청 영어듣기평가 빈출 유형을 반영하여 모의고사 문제를 구성하였다. 실전 모의고사 문제 풀이 및 듣기 지문에 대한 받아쓰기 활동을 통해 듣기 실력의 향상과 함께 고등 학력 평가와 수능까지 대비할 수 있는 기초 실력을 다질 수 있다. 음원을 바로 들어볼 수 있는 온라인 문제은행 및 중등 필수 어휘 연습을 위한 영어 학습 앱이 함께 제공된다.',
  },
  {
    title: 'Listen Up! 중등 영어듣기 모의고사 3학년',
    levels: [9],
    section: '듣기',
    imgSrc: book_listen_middle_g3,
    description:
      '•추천 학년: 중등 3학년 <br/> •구성: 3개 레벨(중등 1학년, 중등 2학년, 중등 3학년) / 실전 모의고사 20회 수록 <br/> 전국 16개 시도 교육청 영어듣기평가 빈출 유형을 반영하여 모의고사 문제를 구성하였다. 실전 모의고사 문제 풀이 및 듣기 지문에 대한 받아쓰기 활동을 통해 듣기 실력의 향상과 함께 고등 학력 평가와 수능까지 대비할 수 있는 기초 실력을 다질 수 있다. 음원을 바로 들어볼 수 있는 온라인 문제은행 및 중등 필수 어휘 연습을 위한 영어 학습 앱이 함께 제공된다.',
  },
  {
    title: 'Listen Up! 고등 영어듣기 모의고사 유형편',
    levels: [9],
    section: '듣기',
    imgSrc: book_listen_high,
    description:
      '•추천 학년: 중등 3학년 및 예비 고등 <br/> •구성: 수능 14개 기출 유형 분석 및 실전 모의고사 10회 수록 <br/> 수능 듣기 영역을 대비하기 위한 예비 고등 및 고등학생 대상의 듣기 교재. 최신 수능 및 모의고사 출제경향을 철저히 분석하여 반영한 14개 수능 유형 분석 및 기출 변형 문제, 그리고 실전 모의고사 10회로 구성되어 있다. 수능 듣기 영역 고득점을 위한 유형별 풀이 전략을 명쾌하게 정리한 유형 강의 영상과 추가 기출 워크시트, 음원을 바로 들어볼 수 있는 온라인 문제은행 및 고등 필수 어휘 연습을 위한 영어 학습 앱도 함께 제공된다.',
  },
  {
    title: 'Subject Reading 1~3',
    levels: [6, 7, 8],
    section: '읽기',
    imgSrc: book_read_subject,
    description:
      '•추천 학년: 예비 중등 및 중등 1~2학년 <br/> •구성: 총 3권 / 권별 10개 유닛, 40개의 지문 <br/> 중학생을 위한 주제별 독해 교재. 중학 영어 독해실력의 기본을 다지기 위한 주제별 독해 교재. 체계적인 학습을 위해 계단식 난이도로 구성되어 있으며, 권당 10개 주제로 나뉜 40개 지문이 제공된다. 배경지식 코너를 통해 교과 연계 주제에 대한 지식과 상식을 넓힐 수 있으며, 지문과 관련된 중등 필수 문법과 구문을 익힐 수 있다. 최신 유형의 서술형을 포함한 다양한 유형의 문제 풀이 및 직독직해 활동으로 독해 사고력이 향상되고 학교 내신 대비가 가능하다. 교재에 수록된 모든 지문의 구문 분석과 해석을 설명해 주는 친절한 강의 영상 및 다양한 온라인 학습활동, 단어/문장 워크시트 및 온라인 단원평가가 제공된다.',
  },
  {
    title: '수능 영어 독해 첫걸음 입문, 실전',
    levels: [9],
    section: '읽기',
    imgSrc: book_read_sat,
    description:
      '•추천 학년: 수능 영어를 대비하고자 하는 중등 및 예비고등 <br/> •구성: 총 2권 <br/> 수능 영어 독해 유형을 처음 접하는 중등 및 예비 고등 대상의 읽기 교재. 중등 수준에 맞추어 고등 학력평가의 주요 기출 지문의 난이도를 조절하였고, 각 수능 유혈별 철저한 분석과 함께 수능 유형의 문제를 다양하게 많이 풀어볼 수 있다. 모든 문항에는 지문에 대한 해석 및 구문 분석, 풀이 전략이 포함된 자세한 해설서가 있으며, 온라인 문제은행, 단어 학습 앱과 단어 시험지 등 부가 학습 자료를 제공한다.',
  },
  {
    title: '초등 영단어 800 기본',
    levels: [3, 4],
    section: '어휘',
    imgSrc: book_word800,
    description:
      '•추천 학년: 초등 저학년 <br/> •구성: 총 2단계(기본, 실력) / 단계별 5개 Part, 60 Days <br/> 교육부 지정 초등 필수 단어 및 교과서에 자주 나오는 표현을 주제별로 암기하는 어휘 교재. 하루 한 차시씩 60일 학습으로 초등 필수 어휘 수준을 완성할 수 있다. 단어 암기에 도움이 되는 단어 일러스트, 발음과 뜻을 들어볼 수 있는 음원, 단어 뜻과 철자를 연습하기 위한 단어장과 단어시험지 등의 다양한 부가학습자료를 제공한다.',
  },
  {
    title: '초등 영단어 1200 실력',
    levels: [5, 6],
    section: '어휘',
    imgSrc: book_word1200,
    description:
      '•추천 학년: 초등 고학년 및 예비 중등 <br/> •구성: 총 2단계(기본, 실력) / 단계별 5개 Part, 60 Days <br/> 교육부 지정 초등 필수 및 기초 중등 수준의 단어를 주제별로 암기하는 어휘 교재. 하루 한 차시씩 60일 학습으로 초중등 필수 어휘 수준을 완성할 수 있다. 중등 과정 시험 빈출 단어와 교과서 주요 어휘를 수록하여 중학교 내신에 미리 대비할 수 있으며, 단어 암기에 도움이 되는 단어 일러스트, 발음과 뜻을 들어볼 수 있는 음원, 단어 뜻과 철자를 연습하기 위한 단어장과 단어시험지 등의 다양한 부가학습자료를 제공한다.',
  },
  {
    title: '중등 VOCA 1600 기본',
    levels: [7, 8],
    section: '어휘',
    imgSrc: book_voca1600,
    description:
      '•추천 학년: 중등 1~2학년 <br/> •구성: 총 2단계(기본, 실력) / 단계별 6개 Part, 60 Days <br/> 교육부 지정 중등 필수와 함께 교과서에서 사용 빈도 높은 어휘 및 회화 필수 표현 등을 주제별로 암기하는 어휘 교재. 하루 한 차시씩 60일 학습으로 중등 필수 어휘 수준을 완성할 수 있다. 유의어와 반의어, 보카팁과 예문 등 어휘 학습을 위한 풍부한 자료가 수록되어 있으며, 발음과 뜻을 들어볼 수 있는 음원, 단어 뜻과 철자를 연습하기 위한 단어장과 단어시험지 등의 다양한 부가학습자료를 제공한다.',
  },
  {
    title: '중등 VOCA 2000 실력',
    levels: [9],
    section: '어휘',
    imgSrc: book_voca2000,
    description:
      '•추천 학년: 중등 3학년 및 예비 고등 <br/> •구성: 총 2단계(기본, 실력) / 단계별 6개 Part, 60 Days <br/> 교육부 지정 중등 필수 및 기초 고등 수준 단어와 함께 시험 빈출 어휘와 실용표현 등을 주제별로 암기하는 어휘 교재. 하루 한 차시씩 60일 학습으로 중고등 필수 어휘 수준을 완성할 수 있다. 유의어와 반의어, 보카팁과 예문 등 어휘 학습을 위한 풍부한 자료가 수록되어 있으며, 발음과 뜻을 들어볼 수 있는 음원, 단어 뜻과 철자를 연습하기 위한 단어장과 단어시험지 등의 다양한 부가학습자료를 제공한다.',
  },
  {
    title: '스토리영어 1단계',
    levels: [1],
    section: '스토리영어',
    imgSrc: book_story,
    description:
      '•수준: CEFR Pre A1 / 추천 학년: 미취학 및 초등 저학년 <br/> •구성: 총 3단계 / 단계별 25편의 스토리, 편당 3차시 학습 <br/> 재미있는 영어 이야기로 자연스럽게 영어 듣기부터 시작하는 수준별 스토리 영어. 단순한 서사 구조와 재미있는 장면들을 통해, 생활 속에서 많이 쓰이는 기초 표현들을 자연스럽게 습득한다. 1, 2인칭 위주의 쉬운 문장을 중심으로 100개 이상의 생활 표현과 약 200개의 주요 낱말을 학습한다. 50주 동안 25편의 이야기로 학습이 진행되며, 각 스토리에 다양한 온라인 활동이 함께 제공된다.',
  },
  {
    title: '스토리영어 2단계',
    levels: [2],
    section: '스토리영어',
    imgSrc: book_story,
    description:
      '•수준: CEFR Pre A1~A1 / 추천 학년: 초등 1~2학년 <br/> •구성: 총 3단계 / 단계별 25편의 스토리, 편당 3차시 학습 <br/> 재미있는 영어 이야기로 자연스럽게 영어 듣기부터 시작하는 수준별 스토리 영어. 1단계보다 좀 더 서사적인 구조, 기승전결의 구성이 확실하고 갈등 구조가 명확히 보이는 이야기들로 구성되어 있다. 이웃, 사회 등 좀 더 확장된 생활 범위 속에서 유용하게 쓰이는 표현들이 재미있는 이야기 속에 녹아 있다. 3인칭 문장의 비중이 높아지며, 2단계 학습하는 동안 270여 개의 주요 낱말과 100여 개의 생활 표현을 익힐 수 있다. 50주 동안 25편의 이야기로 학습이 진행되며, 각 스토리에 다양한 온라인 활동이 함께 제공된다.',
  },
  {
    title: '스토리영어 3단계',
    levels: [3],
    section: '스토리영어',
    imgSrc: book_story,
    description:
      '•수준: CEFR A1 / 추천 학년: 초등 2~3학년 <br/> •구성: 총 3단계 / 단계별 25편의 스토리, 편당 3차시 학습 <br/> 재미있는 영어 이야기로 자연스럽게 영어 듣기부터 시작하는 수준별 스토리 영어. 3단계에서는 자연탐구 지식이나 좀 더 일반적인 주제 등 2단계에 비해 좀 더 폭넓은 영역과 주제를 다룬 이야기를 접할 수 있다. 복합적인 구조와 길이가 더 긴 문장의 비중이 높아지며, 문장의 형식 또한 좀 더 다양해진다. 3단계의 재미있는 이야기 속에는 약 250개의 주요 낱말과 100개 이상의 주요 표현들이 녹아 있다. 50주 동안 25편의 이야기로 학습이 진행되며, 각 스토리에 다양한 온라인 활동이 함께 제공된다.',
  },
];

export { LEVEL_BOOKS };
