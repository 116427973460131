import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';

import Input from './Input';

function FormInputBox({ name, ...rest }) {
  const {
    formState: { errors },
  } = useFormContext();
  return (
    <span className={classnames('inputBox', errors?.[name] && 'error')}>
      <Input name={name} {...rest} />
    </span>
  );
}

export default FormInputBox;
