import Input from './base/Input';
import FormAddress from './FormAddress';

function FormDelivery() {
  return (
    <>
      <strong className="label">배송지</strong>
      <div className="inputWrap">
        <div className="enterAddress">
          <FormAddress />
        </div>

        <ul className="optionBox">
          <li>
            <label className="checkBox">
              <Input type="checkbox" name="isDefaultYn" />
              <span className="labelText">
                <em>기본 배송지로 선택</em>
              </span>
            </label>
          </li>
        </ul>
      </div>
    </>
  );
}

export default FormDelivery;
