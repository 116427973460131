import OverlayPortal from '@components/overlay/OverlayPortal';

import PopupBody from './PopupBody';
import PopupContainer from './PopupContainer';
import PopupHeader from './PopupHeader';

function Popup({ children, ...rest }) {
  return (
    <OverlayPortal>
      <PopupContainer {...rest}>{children}</PopupContainer>
    </OverlayPortal>
  );
}

export default Object.assign(Popup, {
  Header: PopupHeader,
  Body: PopupBody,
});
