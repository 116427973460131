import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

import { Button } from '@components/ui';
import { GoodInfoBox, DefinitionBoxSelection } from '@components/box';
import { accountSelector } from '@store/slice/accountSlice';
import { PAYMENT_TYPE } from '@constants/tab';
import { FormRadio } from '@components/form';
import { ORDER_CANCEL_MESSAGE, ORDER_CANCEL_TYPE } from '@constants/order';
import orderService from '@service/OrderService';
import { PopupClaimSuccess } from '@components/overlay';
import { useOverlayContext } from '@context/OverlayContext';

// 결제내역 > 교재 > 주문 취소
function PaymentHistoryCancelPage() {
  const { showPopup } = useOverlayContext();

  const { id } = useParams();

  const [history, setHistory] = useState({});
  const [order, setOrder] = useState({});

  const { memberNo } = useSelector(accountSelector);

  const method = useForm({});
  const { handleSubmit, watch } = method;

  const claimType = watch('claimType');

  const isEnable = useMemo(() => {
    if (isEmpty(claimType)) {
      return false;
    }
    return true;
  }, [claimType]);

  const submit = (formValue) => {
    const fetchCancel = async () => {
      try {
        await orderService.orderCancel({
          memberNo,
          orderId: id,
          orderGoodsIds: order.map((item) => item.orderGoodsId),
          reason: ORDER_CANCEL_MESSAGE[formValue.claimType],
          reasonDetail: formValue.textArea,
        });

        // 교환/환불 신청 완료 페이지로 이동
        showPopup(PopupClaimSuccess);
      } catch (error) {
        console.warn(error);
      }
    };

    fetchCancel();
  };

  useEffect(() => {
    const fetchHistory = async () => {
      const result = await orderService.getHistoryDetail({
        // 교환 환불 페이지는 교재밖에 없음
        type: PAYMENT_TYPE.BOOK,
        orderNumber: id,
        parentNo: memberNo,
      });
      setHistory(result);
      setOrder(result.orderGoodsDtoList);
    };

    if (isEmpty(history)) {
      fetchHistory();
    }
  }, [history, id, memberNo]);

  return (
    <>
      {history && order && (
        <FormProvider {...method}>
          <form
            onSubmit={handleSubmit(submit)}
            className="contentBox boxDetailedInfo"
          >
            <div className="boxFlexGrow">
              <div className="definitionBox typeGoodsInfo">
                <GoodInfoBox {...order?.[0]} />
              </div>
              <DefinitionBoxSelection
                title={
                  <>
                    주문을 취소하는 이유를 알려주세요.{' '}
                    <span className="textOptional">(선택)</span>
                  </>
                }
                category={[
                  {
                    topic: (
                      <FormRadio
                        label={
                          ORDER_CANCEL_MESSAGE[ORDER_CANCEL_TYPE.ORDER_MISTAKE]
                        }
                        name="claimType"
                        value={ORDER_CANCEL_TYPE.ORDER_MISTAKE}
                      />
                    ),
                  },
                  {
                    topic: (
                      <FormRadio
                        label={ORDER_CANCEL_MESSAGE[ORDER_CANCEL_TYPE.RE_ORDER]}
                        name="claimType"
                        value={ORDER_CANCEL_TYPE.RE_ORDER}
                      />
                    ),
                  },
                  {
                    topic: (
                      <FormRadio
                        hasTextBox
                        label={ORDER_CANCEL_MESSAGE[ORDER_CANCEL_TYPE.ETC]}
                        name="claimType"
                        value={ORDER_CANCEL_TYPE.ETC}
                      />
                    ),
                  },
                ]}
              />
            </div>

            <Button.Wrapper flexNone>
              <Button.Primary
                large
                type="submit"
                label="주문 취소 하기"
                disabled={!isEnable}
              />
            </Button.Wrapper>
          </form>
        </FormProvider>
      )}
    </>
  );
}

export default PaymentHistoryCancelPage;
