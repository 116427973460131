import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';

import { accountSelector } from '@store/slice/accountSlice';

import NoticeReplyContent from './NoticeReplyContent';

function NoticeComments({ notificationId, classId, comments = [], onRefresh }) {
  const [parentsComment, setParentsComment] = useState();
  const { students } = useSelector(accountSelector);

  useEffect(() => {
    const mergeComments = comments
      .filter((item) => !item.parentsNotificationCommentId)
      .reduce((pre, current) => {
        const targetNames = students
          .filter((item) =>
            item.enrolledAcademyClass.find(
              (academy) => academy.classId === classId,
            ),
          )
          .map((item) => item.memberName)
          .join(', ');

        const subComments = orderBy(
          comments.filter(
            (subComment) =>
              subComment.parentsNotificationCommentId ===
              current.notificationCommentId,
          ),
          ['regDate'],
          ['asc'],
        );
        current.classId = classId;
        current.studentNames = targetNames;
        current.children = subComments.map((item) => {
          return {
            ...item,
            classId,
            studentNames: targetNames,
          };
        });
        return [...pre, current];
      }, []);
    setParentsComment(mergeComments);
  }, [classId, comments, students]);

  return (
    <ul className="commentList">
      {parentsComment?.map((comment) => (
        <NoticeReplyContent
          key={`comment_${comment.notificationCommentId}`}
          {...comment}
          notificationId={notificationId}
          isSecret={comment.secretYn === 'Y'}
          onRefresh={onRefresh}
        >
          <>
            {comment.children.length > 0 && (
              <NoticeCommentsInner>
                {comment.children.map((subComment) => (
                  <NoticeReplyContent
                    notificationId={notificationId}
                    key={`comment_${subComment.notificationCommentId}`}
                    {...subComment}
                    isSecret={subComment.secretYn === 'Y'}
                    onRefresh={onRefresh}
                    isSubComment
                  />
                ))}
              </NoticeCommentsInner>
            )}
          </>
        </NoticeReplyContent>
      ))}
    </ul>
  );
}

function NoticeCommentsInner({ children }) {
  return (
    <div className="innerReplyListWrap">
      <ul className="commentList">{children}</ul>
    </div>
  );
}

export default NoticeComments;
