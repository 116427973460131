import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { useMount } from 'ahooks';

import { FormPassword } from '@components/form';
import { accountSelector } from '@store/slice/accountSlice';
import useAccount from '@hooks/useAccount';
import { FieldSet } from '@components/common';
import { Button } from '@components/ui';

function ResetPasswordPage() {
  const navigate = useNavigate();
  const parents = useSelector(accountSelector);

  const methods = useForm({ mode: 'onBlur' });
  const {
    formState: { errors },
    reset,
    handleSubmit,
  } = methods;

  const { changePassword } = useAccount({
    complete: () => reset(),
  });

  const submit = (formValue) => {
    changePassword({
      ...parents,
      newRawPassword: formValue.password,
    });
  };

  useMount(() => {
    // 계정 정보가 없으면 다시 처음으로 이동
    if (isEmpty(parents)) {
      navigate('/login');
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)} className="contentBox findPassword">
        <FieldSet.Wrapper>
          <FieldSet.List>
            <FieldSet.Item>
              <FormPassword label="새 비밀번호" />
            </FieldSet.Item>

            <FieldSet.Item>
              <FormPassword
                confirm
                name="confirmPassword"
                label="새 비밀번호 확인"
                placeholder="새 비밀번호 확인"
              />
            </FieldSet.Item>
          </FieldSet.List>
        </FieldSet.Wrapper>

        <FieldSet.ButtonBox>
          <Button.Primary
            large
            type="submit"
            disabled={!isEmpty(errors)}
            label="비밀번호 설정하기"
          />
        </FieldSet.ButtonBox>
      </form>
    </FormProvider>
  );
}

export default ResetPasswordPage;
