import { useMount } from 'ahooks';

import Popup from './base/Popup';

function PopupReceipt({ id, src, ...rest }) {
  useMount(() => {
    window.scrollTo({ top: 0, left: 0 });
  });
  return (
    <Popup {...rest}>
      <Popup.Header id={id} hasClose>
        영수증
      </Popup.Header>
      <Popup.Body isReceipt style={{ width: '100%', height: '100%' }}>
        <iframe
          title="receipt"
          src={src}
          //   src="https://dashboard.tosspayments.com/receipt/redirection?transactionId=cp_da20230331083646d3do2&ref=PX"
          style={{ width: '100%', height: '100%' }}
        />
      </Popup.Body>
    </Popup>
  );
}

export default PopupReceipt;
