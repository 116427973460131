import { useCallback } from 'react';
import { isString } from 'lodash';

import accountService from '@service/AccountService';
import useTimer from './useTimer';

const validityTimeoutSec = 180;

function useMobileVerify() {
  // 인증 유효시간
  const { isTimeout, isWaiting, timer, startTimer, endTimer, cleanUp } =
    useTimer(180);

  const sendCode = useCallback(
    ({ mobileNumber, memberName, password }) => {
      const api = password
        ? accountService.passSendCode
        : accountService.sendCode;

      return api.call(
        accountService,
        {
          mobileNumber,
          memberName,
          validityTimeoutSec,
        },
        {
          onSuccess: (result) => {
            cleanUp();
            startTimer();
            return result;
          },
        },
      );
    },
    [cleanUp, startTimer],
  );

  const verifyCode = useCallback(
    ({ memberName, mobileNumber, verificationCode, requestId, password }) => {
      const api = password
        ? accountService.passVerifyCode
        : accountService.verifyCode;

      return api.call(
        accountService,
        {
          memberName,
          mobileNumber,
          verificationCode,
          requestId,
        },
        {
          onSuccess: (result) => {
            // 인증번호를 다시 확인해주세요는 endTimer를 안함
            if (!isString(result)) {
              endTimer();
            }
            return result;
          },
        },
      );
    },
    [endTimer],
  );

  return {
    isTimeout,
    isWaiting,
    timer,
    startTimer,
    sendCode,
    verifyCode,
  };
}

export default useMobileVerify;
