function MathTestCorrectAnswersBySection({ studentNm, sectionList }) {
  return (
    <div className="reportSection sectionLvByUnit">
      <div className="sectionTitleBox">
        <p className="sectionTitle">{studentNm} 학생의 단원별 정답률</p>
      </div>
      <div className="reportDivision">
        <div className="titleBox">
          <span className="textUnit">단위(%)</span>
        </div>
        <div className="barChartHorizontal">
          <div className="barCharBox">
            <ul className="yAxisGroup">
              {sectionList.map((item, index) => (
                <li key={`section_${index}`}>
                  <div className="yAxisValue">
                    <p>{item.sectionNm}</p>
                  </div>
                  <div className="yAxisGraph">
                    <div className="barGraph">
                      <span
                        className="per"
                        style={{
                          width: `${(
                            (item.correctAnswerCnt / item.totalCnt) *
                            100
                          ).toFixed(0)}%`,
                        }}
                      >
                        <em>
                          {(
                            (item.correctAnswerCnt / item.totalCnt) *
                            100
                          ).toFixed(0)}
                        </em>
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MathTestCorrectAnswersBySection;
