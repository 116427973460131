import { isArray, isEmpty } from 'lodash';

function loadScript(id, src) {
  return new Promise((resolve, _) => {
    let _d;
    if (typeof document !== 'undefined') {
      _d = document;
    }
    if (!_d.getElementById(id, src)) {
      const script = _d.createElement('script');
      script.id = id;
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      _d.body.appendChild(script);
    }
  });
}

function filterArrayByString(mainArr, searchText) {
  if (searchText === '') {
    return mainArr;
  }

  searchText = searchText.toLowerCase();

  return mainArr.filter((itemObj) => this.searchInObj(itemObj, searchText));
}

function first(target) {
  if (!isEmpty(target)) {
    const [key] = Object.keys(target);
    const targetItem = target[key];
    // array -> object
    if (isArray(targetItem)) {
      const firstItem = targetItem.find((item) => !!item);
      const [innerFirst] = Object.keys(firstItem);
      return firstItem[innerFirst];
    }
    return targetItem;
  }
}

function getBrowserType() {
  if (
    navigator.userAgent.match(/Android/i) &&
    window.appInterface?.getSession
  ) {
    return 'ANDROID';
  }
  if (
    navigator.userAgent.match(/IOS_PAD/i) &&
    window.webkit?.messageHandlers?.appInterface
  ) {
    return 'IOS';
  }
  return 'WEB';
}

function toCommaNumber(number) {
  try {
    return number ? Number(number).toLocaleString('ko-KR') : 0;
  } catch (error) {
    return 0;
  }
}

function toQueryString(data) {
  return new URLSearchParams(data).toString();
}

function toPhoneNumber(string) {
  try {
    return string.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  } catch (error) {
    return '-';
  }
}

function addLeadingZero(number) {
  return number < 10 ? `0${number}` : number.toString();
}

function humanFileSize(bytes, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    u += 1;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${bytes.toFixed(dp)} ${units[u]}`;
}

function findTextWithinBrackets(text) {
  const pattern = /\((.*?)\)/;
  const match = text.match(pattern);

  if (match) {
    return match[1];
  }
  return '';
}

export {
  first,
  loadScript,
  filterArrayByString,
  humanFileSize,
  getBrowserType,
  toCommaNumber,
  toQueryString,
  toPhoneNumber,
  addLeadingZero,
  findTextWithinBrackets,
};
