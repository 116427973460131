import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { passwordPatten } from '@constants/validate';
import { AUTH_STATUS } from '@constants/common';

import FormInput from './base/FormInput';
import FormHidden from './base/FormHidden';

function FormAuthPassword({
  name = 'password',
  label = '비밀번호',
  placeholder = '8~16자리 영문, 숫자',
  noPatten = false,
  ...rest
}) {
  const { getValues, setValue } = useFormContext();

  const option = useMemo(() => {
    return {
      required: '8~16자리 영문, 숫자를 포함하여 입력해주세요.',
      validate: (value) => {
        const authToken = getValues('authToken');
        if (!noPatten && !passwordPatten.test(value)) {
          return '8~16자리 영문, 숫자를 포함하여 입력해주세요.';
        }
        if (authToken === AUTH_STATUS.INVALID) {
          return '비밀번호가 일치하지 않습니다.';
        }
        return true;
      },
    };
  }, [getValues, noPatten]);

  return (
    <>
      <FormInput
        isPassword
        type="password"
        name={name}
        label={label}
        placeholder={placeholder}
        option={option}
        maxLength="16"
        onChange={() => setValue('authToken', AUTH_STATUS.NOT_CHECK)}
        {...rest}
      />
      <FormHidden name="authToken" />
    </>
  );
}

export default FormAuthPassword;
